
import { useAppDispatch } from "../../../../store/redux";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import { setPayment } from "../../../../store/Cart/ActionCart";
import SinglePayment from "./SinglePayment";
import SeperatePayment from "./SeperatePayment";

type props = {
    handleChangeSection: ()=> void
  }
const PaymentSection = (props:props) => {
    const data = useSelector((state: RootState) => state.cartReducer.checkoutState.paymentSelection)
    const dispatch = useAppDispatch();
    const handleRadioButton = (event: any) => {
        dispatch(setPayment(event.target.value))
    }
    return (
        <div className="bg-[#F3F3F4] mt-5 rounded-lg">
            {data === '1' ? <><SinglePayment /></> : <><SeperatePayment handleChangeSection={props.handleChangeSection} /></>}
        </div>
    );
};
export default PaymentSection;
