import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { useAppDispatch } from '../../store/redux';
import { handleModalDialog } from '../../store/UserDashboard/ActionUserDashboard';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
type props = {
    dialogTitle?: string,
    dialogContent?: string,
    children?: React.ReactNode,
    handleSubmitModalDialog: (data: Object) => void;
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-container': {
        width: '100%',
    },
    '& .MuiPaper-root': {
        width: '100%',
    },
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));
export default function AlertDialog(props: props) {
    const dispatch = useAppDispatch()
    const open = useSelector((state: RootState) => state.userDashboardReducer?.showDialog);

    const handleClose = (type: string) => {
        if (type === "submit") props.handleSubmitModalDialog({ title: 'awd' })
        dispatch(handleModalDialog(false))
    };

    return (
        <div>

            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <div className='flex justify-between items-center'>
                    <DialogTitle id="alert-dialog-title">
                        {props.dialogTitle}
                    </DialogTitle>
                    <IconButton
                        onClick={() => handleClose('cancel')}
                        aria-label="close"
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </div>

                <DialogContent dividers>

                    {props.children}


                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleClose('cancel')}>Cancel</Button>
                    <Button onClick={() => handleClose('submit')} autoFocus>
                        Save
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </div>
    );
}