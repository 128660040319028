import axios from "axios";
export const getLanguagesAxios = async () => {
    try {
        const response = await axios.get(
            '/api/languages'
        );
        return response.data.data

    } catch (error) {
        console.error("Error:", error);
        return null
    }
};