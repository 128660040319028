import React, { FC, useEffect } from "react";
import "./App.css";
import { useAppDispatch, useAppSelector } from "./store/redux";
import AppRouter from "./routes/AppRouter";
import SnackBar from "./component/SnackBar";
import BackDropLoader from "./component/BackDropLoader";
import "./i18n/i18n";
import { GenerateGuestToken } from "./store/Auth/ActionAuth";
import CookieBanner from "./component/CookieBanner";
import CookieConsent, {
  getCookieConsentValue,
  Cookies,
} from "react-cookie-consent";
import { initGA } from "./ga-utils";
export const App: FC = () => {
  const dispatch = useAppDispatch();
  const token = useAppSelector((state) => state.authExampleReducer.token);
  const clientToken = localStorage.getItem('clientToken')
  useEffect(()=>{
    if (!clientToken && !localStorage.getItem('userInfo')) {
      dispatch(GenerateGuestToken()).then((res) => {
          localStorage.setItem('clientToken', res);
      });
  }
  },[])

    useEffect(() => {
        const darkModeMediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
        const favicon = document.querySelector("link[rel='icon']") as HTMLLinkElement;

        const changeFavicon = (darkMode: boolean) => {
            if (darkMode) {
                favicon.href = "/favicon-dark.ico";
            } else {
                favicon.href = "/favicon.ico";
            }
        };
        changeFavicon(darkModeMediaQuery.matches);
        const darkModeChangeListener = (e: MediaQueryListEvent) => {
            changeFavicon(e.matches);
        };
        darkModeMediaQuery.addEventListener("change", darkModeChangeListener);
        return () => {
            darkModeMediaQuery.removeEventListener("change", darkModeChangeListener);
        };
    }, []);

  const handleAcceptCookie = () => {
    if (process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
      initGA(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
    }
  };

  const handleDeclineCookie = () => {
    Cookies.remove("_ga");
    Cookies.remove("_gat");
    Cookies.remove("_gid");
  };

  useEffect(() => {
    const isConsent = getCookieConsentValue();
    if (isConsent === "true") {
      handleAcceptCookie();
    }
  }, []);

    return (
    <>
      {/*<CookieBanner />*/}
      <CookieConsent
        enableDeclineButton
        onAccept={handleAcceptCookie}
        onDecline={handleDeclineCookie}
        buttonStyle={{
          backgroundColor: '#FF913F',
        }}
        declineButtonStyle={{
          backgroundColor: '#FF913F',
          color:"black",
        }}
      >
        This website uses cookies to enhance the user experience.
      </CookieConsent>
      <BackDropLoader />
      <SnackBar />
      <AppRouter />
    </>
  );
};
