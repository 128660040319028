import { useEffect, useState } from "react";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import TicketComponent from "./TicketComponent/TicketComponent";
import { useAppDispatch } from "../../../../store/redux";
import {
  getReservedTicket,
  getUserTickets,
} from "../../../../store/Cart/ActionCart";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Pagination } from "@mui/material";
import { scrollToTop } from "../../../../store/GlobalState/ActionGlobalState";
const Ticket = () => {
  const [activeTab, setActiveTab] = useState(1);
  const dispatch = useAppDispatch();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const currency = useSelector(
    (state: RootState) => state.currenciesReducer?.selectedCurrency,
  );
  const tickets = useSelector(
    (state: RootState) => state.cartReducer.userTickets,
  );

  const { t } = useTranslation();
  const navigate = useNavigate();
  const [openTicket, setOpenTicket] = useState(false);
  const handleOpen = (id: number | undefined) => {
    if (id !== undefined)
      dispatch(getReservedTicket(id))
        .then((res) => {
          setOpenTicket(true);
        })
        .catch(() => {});
  };
  const handleCloseModal = () => {
    setOpenTicket(false);
  };
  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    page: number,
  ) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    dispatch(scrollToTop);
    dispatch(getUserTickets(currentPage));
  }, [currentPage]);

  return (
    <div>
      {tickets?.data?.length > 0 ? (
        <>
          <div>
            {tickets.data?.map((ticket) => (
              <div
                key={`tickets_${ticket.id}`}
                className={
                  `hover-card mt-4 grid grid-cols-1 items-center gap-4 rounded-xl px-5 py-4 ${
                    ticket.splitters && ticket.splitters.length >= 1
                      ? "lg:grid-cols-2"
                      : "lg:grid-cols-[4fr_1fr]"
                  } ` +
                  (ticket.ticket_status === "Done"
                    ? "bg-[#ecf4ff]"
                    : "bg-[#edf9f3]")
                }
              >
                <div className="grid grid-cols-1 items-center lg:grid-cols-[0.5fr_2fr]">
                  <div
                    className={
                      "ml-2  " +
                      (ticket.ticket_status === "In Progress"
                        ? "text-[#42c07f]"
                        : "text-[#3f8cff]")
                    }
                  >
                    <li>{ticket.ticket_status}</li>
                  </div>
                  <Link
                    to={`/eventDetails/${ticket.event.id}`}
                    className="grid h-full cursor-pointer grid-cols-1 items-center justify-center gap-3 rounded-xl bg-white px-3 py-3 lg:grid-cols-[2fr_10fr]"
                  >
                    <img
                      loading="lazy"
                      src={ticket.event.images?.[0].image_path}
                      className="h-[100px] w-[100px] rounded-xl lg:h-[94px] lg:w-full "
                    />
                    <div>
                      <div className="flex items-center">
                        <LocationOnIcon
                          style={{
                            color: "#3f8cff",
                            fontSize: "18px",
                            width: "25px",
                            height: "25px",
                          }}
                        />
                        <p className="text-[14px]">{ticket.event.location}</p>
                      </div>
                      <p className="mt-3 text-base font-medium">
                        {ticket.event.description}
                      </p>
                    </div>
                  </Link>
                </div>

                <div className="grid h-full grid-cols-1 items-center gap-6 lg:grid-cols-[2fr_1fr]">
                  {ticket.splitters && ticket.splitters.length >= 1 && (
                    <>
                      <div className="flex h-full flex-col justify-between rounded-xl bg-white px-3 py-3">
                        <div className="">
                          {ticket.splitters?.map(
                            (user, index) =>
                              user.user_email !== null && (
                                <div
                                  key={index}
                                  className="grid grid-cols-1 gap-4 text-start lg:grid-cols-[1fr_2fr_1fr] lg:text-center"
                                >
                                  {user.status === "paid" ? (
                                    <div className="flex">
                                      <CheckCircleIcon
                                        sx={{ color: "#65cc93" }}
                                      />
                                      <p className="ms-2 text-[#65cc93]">
                                        {t("userProfile.tickets.paid")}
                                      </p>
                                    </div>
                                  ) : (
                                    <div className="flex">
                                      <WatchLaterIcon
                                        sx={{ color: "#fa9a3c" }}
                                      />
                                      <p className="ms-2 text-primary-orange">
                                        Pending
                                      </p>
                                    </div>
                                  )}
                                  <div>
                                    <p>{user.user_email}</p>
                                  </div>
                                  <div>
                                    <p>
                                      {currency.symbol}
                                      {(
                                        Number(user.paid_amount) *
                                        currency.exchange_rate
                                      ).toFixed(2)}
                                    </p>
                                  </div>
                                </div>
                              ),
                          )}
                        </div>
                      </div>
                    </>
                  )}

                  <button
                    onClick={() => handleOpen(ticket.id)}
                    className="flex items-center justify-center rounded-full border-[1px] border-solid border-[#FF913F] bg-white py-2 text-center"
                  >
                    {t("userProfile.tickets.viewTicket")}
                  </button>
                </div>
              </div>
            ))}
            <div className="mb-10 mt-10 flex w-full justify-center">
              <Pagination
                count={tickets.last_page}
                page={currentPage}
                onChange={handlePageChange}
              />
            </div>
          </div>

          <TicketComponent open={openTicket} onClose={handleCloseModal} />
        </>
      ) : (
        <>
          <div className="empty grid place-items-center pb-20">
            <img
              loading="lazy"
              src="/assets/images/empty.png"
              alt="Empty Wishlist"
            />
            <h3 className="px-4 text-lg font-bold 2xl:px-7 2xl:text-xl">
              {t("userProfile.tickets.emptyTicket")}
            </h3>
            <p className="pt-4 font-medium text-[#131C23]/40">
              {t("userProfile.tickets.message")}
            </p>
            <button
              className="mt-3 flex items-center justify-center rounded-full bg-[#FF913F] px-14 py-2 text-center text-white"
              onClick={() => navigate("/allEvents/large")}
            >
              <span className="px-1 font-medium">
                {t("userProfile.tickets.exploreMore")}
              </span>
              <img
                loading="lazy"
                src="/assets/svgs/common/white-arrow.svg"
                className="w-4"
                alt="Find Things"
              />
            </button>
          </div>
        </>
      )}
    </div>
  );
};
export default Ticket;
