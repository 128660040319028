import React, {useState} from "react";
import Person2Icon from "@mui/icons-material/Person2";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import LockIcon from "@mui/icons-material/Lock";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import "./style.css";
import CustomInput from "../../../common/Inputs";
import {Formik, Form, Field, ErrorMessage, useFormikContext} from "formik";
import * as Yup from "yup";
import {useAppDispatch} from "../../../../store/redux";
import {
    toggleVerificationModal,
    userSignUp,
} from "../../../../store/Auth/ActionAuth";
import {useSelector} from "react-redux";
import {RootState} from "../../../../store/store";
import PrimaryButton from "../../../PrimaryButton";
import {useTranslation} from "react-i18next";
import AuthTabs from "../AuthTabs";
import EventCard from "../../../EventCard";
import {Link} from "react-router-dom";
import {getTerms} from "../../../../store/TermsAndCondition/TermsAction";

type SignUpProps = {
    activeTab: "client" | "business";
    handleRegisterUser: () => void;
    onLoginCLick?: () => void;
    isClient: boolean;
};

const SignUpClient: React.FC<SignUpProps> = ({
                                                 activeTab,
                                                 handleRegisterUser,
                                                 onLoginCLick,
                                             }) => {
    const dispatch = useAppDispatch();
    const {t} = useTranslation();
    const clientTabName = t("clientTabName");
    const businessTabName = t("businessTabName");
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [termsChecked, setTermsChecked] = useState(false);

    const fieldsError = useSelector(
        (state: RootState) => state.authReducer?.errors,
    );
    const showVerificationModal = useSelector(
        (state: RootState) => state.authReducer?.showVerificationModal,
    );

    const [initialValues, setInitialValues] = useState({
        name: "",
        email: "",
        password: "",
        password_confirmation: "",
        phone_number: "",
        seo_code:localStorage.getItem('seoCode')?? null,
        country_code: "",
        is_client: true,
    });

    const validationSchema = Yup.object({
        name: Yup.string().required(t('nameRequired')),
        email: Yup.string().required(t('emailRequired')),
        phone_number: Yup.string().required(t('phoneRequired')),
        password: Yup.string()
            .required(t('passwordRequired'))
            .min(8, t('requiredCharacters'))
            .matches(
                /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[-.#?!@$%^&_*=()+]).{8,}$/,
                t('specialCharacter'),
            ),
        password_confirmation: Yup.string()
            .required(t('confirmationPassword'))
            .oneOf([Yup.ref("password"), ""], t('passwordMatch')),
    });

    type ApiError = {
        [key: string]: string[];
    };
    const formik = useFormikContext();
    const handleSignUpClick = async (values: typeof initialValues) => {
        const cleanedCountryCode = values.country_code.replace(/\D/g, '');
        let data = {
            ...values,
            phone_number: `+${cleanedCountryCode}${values.phone_number}`,
        };
        dispatch(userSignUp(data))
            .then(() => {
                handleRegisterUser();
                localStorage.setItem("otpVerified", "false");
                dispatch(toggleVerificationModal(true));
            })
            .catch(() => {
            });
    };
    const handleCloseModal = () => {
        // setShowVerificationModal(false);
    };

    return (

        <div className="max-h-[70vh] overflow-y-scroll">
            <AuthTabs activeTab={activeTab}/>
            <div className="py-3 text-center">
                <h3 className="text-2xl font-bold ">{t('welcome')}</h3>
                <p className="font-medium text-[#131C23]/40"> {t('logInOrSignUp')}</p>
            </div>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                    handleSignUpClick(values);
                }}
            >
                {({handleChange, values, errors, touched}) => (
                    <div>
                        <Form>
                            <div>
                                <CustomInput
                                    type="text"
                                    placeholder="Full Name"
                                    icon={<Person2Icon/>}
                                    value={values.name}
                                    name="name"
                                    label=""
                                    onChange={handleChange}
                                />
                                {errors.name && touched.name && (
                                    <div className="ml-2 text-[12px] text-red-600">
                                        {errors.name}
                                    </div>
                                )}
                            </div>
                            <div>
                                <CustomInput
                                    type="text"
                                    placeholder={t('mail')}
                                    icon={<EmailIcon/>}
                                    value={values.email}
                                    name="email"
                                    label=""
                                    onChange={handleChange}
                                />
                                {errors.email && touched.email && (
                                    <div className="ml-2 text-[12px] text-red-600">
                                        {errors.email}
                                    </div>
                                )}
                                {fieldsError["email"] && (
                                    <span className="my-3 ml-2 text-[12px] text-red-600">
                      {fieldsError["email"][0]}
                    </span>
                                )}
                            </div>
                            <div className="m-0 grid grid-cols-[4fr_8fr] gap-2  border-none p-0">
                                <CustomInput
                                    type="text"
                                    placeholder={t('code')}
                                    icon={<PhoneIcon/>}
                                    name="country_code"
                                    label=""
                                    onChange={handleChange}
                                />
                                <CustomInput
                                    type="number"
                                    placeholder={t('phoneNumber')}
                                    name="phone_number"
                                    label=""
                                    onChange={handleChange}
                                />
                            </div>
                            {fieldsError["phone_number"] && (
                                <span className="ml-2 mt-3 text-[12px]  text-red-600">
                    {fieldsError["phone_number"][0]}
                  </span>
                            )}
                            <ErrorMessage
                                name="phone_number"
                                component="div"
                                className="ml-2 text-[12px] text-red-600"
                            />

                            <div className="my-4 flex items-center rounded-lg bg-[#131C23]/5 p-2 text-[#131C23]/30">
                                <LockIcon className="mr-2 text-gray-400"/>
                                <Field
                                    type={showPassword ? "text" : "password"}
                                    name="password"
                                    placeholder={t('password')}
                                    className="w-full border-none bg-transparent text-gray-700 outline-none"
                                    onChange={handleChange}
                                />
                                {showPassword ? (
                                    <VisibilityOffIcon
                                        className="cursor-pointer text-gray-400"
                                        onClick={() => setShowPassword(false)}
                                    />
                                ) : (
                                    <VisibilityIcon
                                        className="cursor-pointer text-gray-400"
                                        onClick={() => setShowPassword(true)}
                                    />
                                )}
                            </div>
                            <ErrorMessage
                                name="password"
                                component="div"
                                className="ml-2 text-[12px] text-red-600"
                            />

                            <div className="my-4 flex items-center rounded-lg bg-[#131C23]/5 p-2 text-[#131C23]/30">
                                <LockIcon className="mr-2 text-gray-400"/>
                                <Field
                                    type={showConfirmPassword ? "text" : "password"}
                                    name="password_confirmation"
                                    placeholder={t('passwordConfirmation')}
                                    className="w-full border-none bg-transparent text-gray-700 outline-none"
                                    onChange={handleChange}
                                />
                                {showConfirmPassword ? (
                                    <VisibilityOffIcon
                                        className="cursor-pointer text-gray-400"
                                        onClick={() => setShowConfirmPassword(false)}
                                    />
                                ) : (
                                    <VisibilityIcon
                                        className="cursor-pointer text-gray-400"
                                        onClick={() => setShowConfirmPassword(true)}
                                    />
                                )}
                            </div>
                            <ErrorMessage
                                name="password_confirmation"
                                component="div"
                                className="ml-2 text-[12px] text-red-600"
                            />
                            <div className="flex items-center gap-1 text-md cursor-pointer">
                                <input
                                    type="checkbox"
                                    checked={termsChecked}
                                    onChange={() => setTermsChecked(!termsChecked)}
                                />
                                <p className="">Read and agreed to </p>
                                <Link to={'/terms-and-condition?type=client'} target={"_blank"} className="text-primary-orange cursor-pointer underline font-bold">
                                    terms and conditions
                                </Link>
                             </div>
                            <div className="py-4 text-center font-medium"> {t('alreadyHaveAccount')}
                                <span
                                    onClick={onLoginCLick}
                                    className="cursor-pointer px-2 text-[#FF913F]"
                                >
                    {t('signIn')}
                  </span>
                            </div>

                            <PrimaryButton className="gap-2" disabled={!termsChecked}>
                                {t('signUp')}
                                <img
                                    loading="lazy"
                                    src="/assets/svgs/common/white-arrow.svg"
                                    className="w-4"
                                />
                            </PrimaryButton>
                        </Form>
                    </div>
                )}
            </Formik>
            {/*<div className="grid grid-cols-3 items-center py-4">*/}
            {/*    <div className="border-b-[1px] border-[#131C23]/40"></div>*/}
            {/*    <p className="text-center text-[#131C23]/50">Or SignUp with</p>*/}
            {/*    <div className="border-b-[1px] border-[#131C23]/40"></div>*/}
            {/*</div>*/}

            {/*<div className="flex items-center justify-center">*/}
            {/*    <img*/}
            {/*        src="/assets/svgs/common/apple.svg"*/}
            {/*        className="m-3 rounded-full border-[1px] border-[#131C23]/20 p-2"*/}
            {/*        alt="Apple"*/}
            {/*    />*/}
            {/*    <img*/}
            {/*        src="/assets/svgs/common/google.svg"*/}
            {/*        className="m-3 rounded-full border-[1px] border-[#131C23]/20 p-2"*/}
            {/*        alt="Google"*/}
            {/*    />*/}
            {/*</div>*/}
        </div>

    );
};

export default SignUpClient;
