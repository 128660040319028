import LogoSvg from "../SvgComponents/LogoSvg";
import SearchSvg from "../SvgComponents/SearchSvg";
import HeartSvg from "../SvgComponents/HeartSvg";
import ShoppingSvg from "../SvgComponents/ShoppingSvg";
import React, {useEffect, useRef, useState} from "react";
import AuthComponent from "./component/AuthComponent";
import Currency from "./component/Currency";
import {useLocation, useNavigate} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../store/redux";
import {Avatar} from "@mui/material";
import {useSelector} from "react-redux";
import {RootState} from "../../store/store";
import {UserInfo} from "../../networking/auth/types";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import {
    setAuthModal,
    toggleVerificationModal,
} from "../../store/Auth/ActionAuth";
import {setLanguage} from "../../store/Language/LanguageSlice";
import {useTranslation} from "react-i18next";
import {getSearchEvents} from "../../store/events/ActionEvents";
import {getLanguagesAxios} from "../../networking/language/language";
import MobileMenu from "./MobileMenu";
import { fetchCartItemCount} from "../../store/Cart/ActionCart";

type THeader = {
    sticky?: boolean;
};
const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 900,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: 3,
};

interface Language {
    id: number;
    code: string;
    logo: string;
    title: string;
}

const getRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
};
export const Header = (props: THeader) => {
    const dispatch = useAppDispatch();
    const roleType = useSelector(
        (state: RootState) => state.authReducer?.userRole,
    );
    // const selectedLanguage = useSelector((state) => state.events.eventsReducer);
    const token = localStorage?.getItem("token");
    const [languages, setLanguages] = useState<Language[]>([]);
    const location = useLocation();
    const pathname = location.pathname;
    const divRef = useRef(null);
    const userInfo: UserInfo | null = useSelector(
        (state: RootState) => state.authReducer?.userInfo,
    );
    const auth = useAppSelector((state) => state.authReducer);
    const searchedEvents = useAppSelector(
        (state: RootState) => state.eventsReducer.searchEvents,
    );
    // const [openAuthModal, setOpenAuthModal] = useState(false);

    const openAuthModal: boolean = useSelector(
        (state: RootState) => state.authReducer?.openAuthModal,
    );
    const selectedLanguage = useAppSelector((state) => state.languageReducer.selectedLanguage);
    const displaySelectedLanguage = languages?.find(lang => lang.code === selectedLanguage);

    const [, setIsOutsideClick] = useState(false);
    const [showMobileMenu, setShowMobileMenu] = useState(false);
    const [isMobileScreen, setIsMobileScreen] = useState(false);
    const [showSearchModal, setShowSearchModal] = useState(false);
    const [inputSearchValue, setInputSearchValue] = useState("");
    const [displayCheckImage, setDisplayCheckImage] = useState<string>("");
    const {t, i18n} = useTranslation();
    const [showLanguageModal, setShowLanguageModal] = useState(false);
    const [clientToken, setClientToken] = useState('');
    const [languageColors, setLanguageColors] = useState<{ [key: number]: string }>({});
    // const [cartItemCount, setCartItemCount] = useState<number>(0);
    const cartItemCount = useAppSelector((state) => state.cartReducer.cartItemCount);
    const generateRandomColors = () => {
        const colors: { [key: number]: string } = {};
        languages.forEach((language, index) => {
            colors[index] = getRandomColor();
        });
        setLanguageColors(colors);
    };
    const openLanguageModal = () => {
        setShowLanguageModal(true);
    };

    const closeLanguageModal = () => {
        setShowLanguageModal(false);
    };
    const handleChangeInputSearch = (event: any) => {
        setInputSearchValue(event.target.value);
    };
    const toggleMobileMenu = () => {
        setShowMobileMenu(!showMobileMenu);
    };
    const handleOpenAuthModal = () => {
        dispatch(setAuthModal(true));
    };
    const navigate = useNavigate();
    const goToHomePage = () => {
        navigate("/");
    };
    const goToPayment = () => {
        navigate("/payment");
    };
    const goToWishlist = () => {
        navigate("/wishlist");
    };
    const handleLanguageChange = (newLanguage: string) => {
        i18n.changeLanguage(newLanguage);
        document.documentElement.setAttribute("lang", newLanguage)
        if (newLanguage === "ar") {
            document.documentElement.setAttribute("dir", "rtl")
        } else {
            document.documentElement.setAttribute("dir", "ltr")
        }
        dispatch(setLanguage(newLanguage));
        setDisplayCheckImage(newLanguage);
    };
    const handleNavigateUser = () => {
        if (localStorage.getItem("otpVerified") == "false") {
            dispatch(toggleVerificationModal(true));
        } else
            navigate(
                roleType?.id === 1 ? "/profile-settings" : "/business-profile-settings",
            );
    };

    function useOutsideAlerter(ref: any) {
        useEffect(() => {
            function handleClickOutside(event: any) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setShowSearchModal(false);
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);
    useEffect(() => {
        dispatch(fetchCartItemCount());
    }, [dispatch]);

    useEffect(() => {
        function handleClickOutside(event: any) {
            if (divRef.current) {
                setIsOutsideClick(true);
            } else {
                setIsOutsideClick(false);
            }
        }
        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (inputSearchValue !== "") {
            const timeoutId = setTimeout(() => {
                dispatch(getSearchEvents(inputSearchValue)).then((res) => {
                    setShowSearchModal(true);
                });
            }, 500);
            return () => clearTimeout(timeoutId);
        }
    }, [inputSearchValue]);

    useEffect(() => {
        const handleResize = () => {
            setIsMobileScreen(window.innerWidth < 768);
        };
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        setClientToken(localStorage.getItem('clientToken') as string)
        generateRandomColors();
        getLanguagesAxios()
            .then((data) => {
                setLanguages(prev => prev = data);
            })
            .catch((error) => {
            });
    }, []);

    return (
        <>
            <div className={"sticky left-0 right-0 top-0 z-50 text-white"}>
                <div className={"w-full" + (props.sticky && " relative")}>
                    {isMobileScreen ? (
                        <MobileMenu/>
                    ) : (
                        <div
                            className={`left-0 right-0 top-0 mx-auto bg-black  mb-5 ${
                                props.sticky && "absolute"
                            }`}
                        >
                            <div className="container grid grid-cols-1 gap-4 py-5 md:grid-cols-2 ">
                                <div
                                    className={
                                        "flex cursor-pointer items-center justify-center md:justify-start"
                                    }
                                >
                                    <LogoSvg className={"fill-white"} onClick={goToHomePage}/>
                                </div>
                                <div
                                    className="flex cursor-pointer items-center justify-end gap-5 max-md:grid max-md:grid-cols-3">
                                    <div>
                                        <div className=" flex items-center relative">
                                            <SearchSvg className={"h-full fill-white"}/>
                                            <input
                                                onChange={handleChangeInputSearch}
                                                className={
                                                    "w-[70px] bg-transparent pl-3 text-white/60 placeholder-white/60 focus-visible:outline-none"
                                                }
                                                placeholder={t('search')}
                                            />
                                        </div>
                                        {showSearchModal && (
                                            <div
                                                ref={wrapperRef}
                                                className="absolute max-h-[420px] w-[500px] overflow-y-scroll rounded-lg border-2 border-black/5 bg-[#f3f3f4]"
                                            >
                                                {searchedEvents.length > 0 ? (
                                                    <>
                                                        {" "}
                                                        <div className="flex flex-col gap-3 p-2">
                                                            {searchedEvents?.map((item) => (
                                                                <div
                                                                    onClick={() => {
                                                                        navigate(`/eventDetails/${item.id}`);
                                                                        setShowSearchModal(false);
                                                                    }}
                                                                    className="hover-card grid grid-cols-[4fr_8fr] gap-3 rounded-lg border bg-white p-2"
                                                                >
                                                                    <div>
                                                                        <img
                                                                            loading="lazy"
                                                                            className="h-[100px] w-[120px]"
                                                                            alt="No Image"
                                                                            src={item.image.image_path}
                                                                        />
                                                                    </div>
                                                                    <div>
                                                                        <p className="text-base font-bold text-black">
                                                                            {item.title}
                                                                        </p>
                                                                        <p className="mt-1 text-[12px] line-clamp-3 overflow-ellipsis font-medium text-primary-orange">
                                                                            {item.description}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>

                                                    </>
                                                ) : (
                                                    <>
                                                        <div
                                                            className="flex flex-col gap-3 rounded-lg border bg-white p-2">
                                                            <h3 className="text-lg text-primary-orange font-bold 2xl:px-7 2xl:text-xl">
                                                                {t('nothingFound')}
                                                            </h3>
                                                            <p className="pt-4 font-medium text-[#131C23]/40">
                                                                {t('searchSomethingElse')}
                                                            </p>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                    {/*<div className="flex">*/}
                                    {/*    <FormControl*/}
                                    {/*        fullWidth*/}
                                    {/*        sx={{m: 1, minWidth: 120, color: "white"}}*/}
                                    {/*    >*/}
                                    {/*        <Select*/}
                                    {/*            sx={{*/}
                                    {/*                color: "white",*/}
                                    {/*                "& .MuiSelect-icon": {*/}
                                    {/*                    color: "white",*/}
                                    {/*                },*/}
                                    {/*            }}*/}
                                    {/*            displayEmpty*/}
                                    {/*            inputProps={{"aria-label": "Without label"}}*/}
                                    {/*            value={selectedLanguage}*/}
                                    {/*            onChange={(e) => handleLanguageChange(e.target.value)}*/}
                                    {/*            IconComponent={KeyboardArrowDownIcon}*/}
                                    {/*        >*/}
                                    {/*            {languages?.map((language) => (*/}
                                    {/*                <MenuItem key={language.code} value={language.code}>*/}
                                    {/*                    <div className="gap-2 flex items-center">*/}
                                    {/*                        {*/}
                                    {/*                            language.logo &&*/}
                                    {/*                            <img src={language.logo} className="w-4 h-4"/>*/}
                                    {/*                        }*/}
                                    {/*                        {language.title}*/}
                                    {/*                    </div>*/}
                                    {/*                </MenuItem>*/}
                                    {/*            ))}*/}
                                    {/*        </Select>*/}
                                    {/*    </FormControl>*/}
                                    {/*</div>*/}
                                    <button onClick={openLanguageModal} className="flex items-center gap-1">
                                        {displaySelectedLanguage?.logo && (
                                            <img
                                                loading="lazy"
                                                src={displaySelectedLanguage.logo}
                                                alt="Language Logo"
                                                className="w-4"
                                            />
                                        )}
                                        <span className={displaySelectedLanguage?.logo ? 'ml-2' : ''}>
                                                {displaySelectedLanguage?.title}
                                            </span>
                                        <img
                                            loading="lazy"
                                            src="/assets/svgs/down-arrow.png"
                                            alt="Down Arrow"
                                            className="w-4 h-4"
                                        />
                                    </button>
                                    <Modal
                                        open={showLanguageModal}
                                        onClose={closeLanguageModal}
                                        aria-labelledby="language-modal-title"
                                        aria-describedby="language-modal-description"
                                    >
                                        <Box sx={style}>
                                            <span
                                                className="text-[#131C23] font-bold text-2xl mb-5">Select a Language</span>
                                            <div className="language-modal mt-4">
                                                <div className="grid grid-cols-3 modal-content gap-5">
                                                    {languages?.map((language) => (
                                                        <div
                                                            key={language.code}
                                                            onClick={() => {
                                                                handleLanguageChange(language.code);
                                                                closeLanguageModal();
                                                            }}
                                                            className={`flex items-center justify-between cursor-pointer rounded-md p-3 hover:bg-primary-orange/10 ${
                                                                selectedLanguage === language.code ? '' : ''
                                                            }`}
                                                        >
                                                            <div className="flex gap-2 items-center">
                                                                {language.logo &&
                                                                    <img src={language.logo} className="w-4 h-3"/>}
                                                                {language.title}
                                                            </div>
                                                            {displayCheckImage === language.code && (
                                                                <img
                                                                    loading="lazy"
                                                                    src="/assets/svgs/common/check.svg"
                                                                    className="ml-2 w-4 h-4"
                                                                    alt="Selected Check"
                                                                />
                                                            )}
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </Box>
                                    </Modal>
                                    <Currency/>
                                    <div className="flex">
                                        {(auth?.token && auth.userRole?.id === 1) && (
                                            <HeartSvg
                                                className={`hover-link ml-3 w-8 ${
                                                    pathname === "/wishlist"
                                                        ? "fill-primary-orange"
                                                        : "fill-white"
                                                }`}
                                                onClick={goToWishlist}
                                            />
                                        )}
                                        {(auth.userRole?.id !== 2 || !auth.userRole) && (
                                            <div className="relative">
                                                <ShoppingSvg
                                                    className={`hover-link ml-3 w-8 ${
                                                        pathname === "/payment"
                                                            ? "fill-primary-orange"
                                                            : "fill-white"
                                                    }`}
                                                    onClick={goToPayment}
                                                />
                                                <span
                                                    className="badge bg-primary-orange rounded-full absolute -top-0.5 -right-0.5 text-[10px] px-1"
                                                >
                                                   {cartItemCount}
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                    <div>
                                        {!token ? (
                                            <button
                                                className="px-3 py-2 font-medium"
                                                onClick={handleOpenAuthModal}
                                            >
                                                {t('signIn')}
                                            </button>
                                        ) : (
                                            <div className="flex items-center gap-2" onClick={handleNavigateUser}>
                                                <Avatar
                                                    className="cursor-pointer"
                                                    alt={userInfo?.name}
                                                    src={userInfo?.profile_pic as string}
                                                />
                                                <p>{userInfo?.name}</p>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <AuthComponent
                openModal={openAuthModal}
                onClose={() => dispatch(setAuthModal(false))}
            />
        </>
    );
};
export default Header;
