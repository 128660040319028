
import {TSvgComponent} from "./types";
import React from "react";
const LogoSvg : TSvgComponent = ({...props}) => {
    return (
    <svg width="144" height="40" viewBox="0 0 571 166" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <g clipPath="url(#clip0_1786_24551)">
            <path d="M250.2 32.0996V45.0996H267.3V53.2996H250.2V66.9996H269.5V75.4996H240V23.5996H269.5V32.0996H250.2Z"
                  fill="white"/>
            <path
                d="M309.302 75.5002L298.302 58.1002L288.302 75.5002H276.802L292.802 49.4002L276.602 23.7002H288.302L299.302 41.1002L309.202 23.7002H320.702L304.702 49.7002L321.002 75.5002H309.302Z"
                fill="white"/>
            <path
                d="M365.801 39.7002C365.801 42.5002 365.201 45.1002 363.901 47.5002C362.601 49.9002 360.601 51.9002 357.901 53.4002C355.201 54.9002 351.701 55.6002 347.501 55.6002H339.001V75.5002H328.801V23.7002H347.601C351.501 23.7002 354.901 24.4002 357.601 25.8002C360.401 27.2002 362.401 29.1002 363.801 31.5002C365.201 33.9002 365.901 36.6002 365.801 39.7002ZM347.101 47.2002C349.901 47.2002 352.001 46.6002 353.401 45.3002C354.801 43.9002 355.401 42.1002 355.401 39.7002C355.401 34.6002 352.601 32.1002 347.101 32.1002H339.001V47.2002H347.101Z"
                fill="white"/>
            <path d="M383.302 67.2002H400.102V75.4002H373.102V23.7002H383.302V67.2002Z" fill="white"/>
            <path
                d="M430.202 76C425.402 76 421.102 74.9 417.102 72.6C413.102 70.3 410.002 67.2 407.602 63.2C405.302 59.2 404.102 54.6 404.102 49.5C404.102 44.4 405.302 39.9 407.602 35.9C409.902 31.8 413.102 28.7 417.102 26.4C421.102 24.1 425.502 23 430.202 23C435.002 23 439.402 24.1 443.302 26.4C447.302 28.7 450.402 31.8 452.702 35.9C455.002 39.9 456.202 44.4 456.202 49.5C456.202 54.6 455.002 59.2 452.702 63.2C450.402 67.2 447.302 70.4 443.302 72.6C439.302 74.9 434.902 76 430.202 76ZM430.202 66.8C433.302 66.8 436.002 66.1 438.302 64.7C440.602 63.3 442.502 61.3 443.802 58.6C445.102 56 445.702 52.9 445.702 49.5C445.702 46.1 445.102 43 443.802 40.5C442.502 37.9 440.702 35.9 438.302 34.5C435.902 33.1 433.302 32.4 430.202 32.4C427.102 32.4 424.402 33.1 422.002 34.5C419.702 35.9 417.802 37.9 416.502 40.5C415.202 43.1 414.502 46.1 414.502 49.5C414.502 53 415.102 56 416.502 58.6C417.802 61.2 419.602 63.2 422.002 64.7C424.402 66.1 427.102 66.8 430.202 66.8Z"
                fill="white"/>
            <path
                d="M490.001 75.5002L478.801 55.3002H474.001V75.5002H463.801V23.7002H482.901C486.801 23.7002 490.201 24.4002 492.901 25.8002C495.701 27.2002 497.701 29.1002 499.101 31.5002C500.501 33.9002 501.201 36.6002 501.201 39.5002C501.201 42.9002 500.201 46.0002 498.301 48.8002C496.301 51.5002 493.501 53.4002 489.601 54.4002L501.801 75.4002L490.001 75.5002ZM474.001 47.5002H482.501C485.301 47.5002 487.301 46.8002 488.701 45.5002C490.101 44.1002 490.701 42.2002 490.701 39.8002C490.701 37.4002 490.001 35.6002 488.701 34.3002C487.301 32.9002 485.301 32.3002 482.501 32.3002H474.001V47.5002Z"
                fill="white"/>
            <path d="M520.7 32.0996V45.0996H537.8V53.2996H520.7V66.9996H540V75.4996H510.5V23.5996H540V32.0996H520.7Z"
                  fill="white"/>
            <g opacity="0.5">
                <path
                    d="M253.9 113C257.4 113 260.2 114.1 262.3 116.2C264.4 118.3 265.5 121.4 265.5 125.4V142.5H260.8V126.1C260.8 123.2 260.1 121 258.7 119.5C257.3 118 255.3 117.2 252.8 117.2C250.3 117.2 248.3 118 246.8 119.6C245.3 121.2 244.6 123.5 244.6 126.5V142.5H240V113.5H244.7V117.6C245.6 116.1 246.9 115 248.5 114.1C250.2 113.3 251.9 113 253.9 113Z"
                    fill="white"/>
                <path
                    d="M299.399 126.9C299.399 127.8 299.399 128.8 299.299 129.8H276.499C276.699 132.7 277.599 134.9 279.399 136.5C281.199 138.1 283.299 138.9 285.799 138.9C287.899 138.9 289.599 138.4 290.899 137.5C292.299 136.5 293.299 135.2 293.799 133.6H298.899C298.099 136.4 296.599 138.7 294.299 140.4C291.999 142.1 289.199 143 285.799 143C283.099 143 280.699 142.4 278.499 141.2C276.399 140 274.699 138.2 273.499 136C272.299 133.7 271.699 131.1 271.699 128.1C271.699 125.1 272.299 122.5 273.499 120.2C274.699 118 276.399 116.2 278.499 115C280.599 113.8 283.099 113.2 285.899 113.2C288.699 113.2 290.999 113.8 293.099 115C295.199 116.2 296.799 117.8 297.899 120C298.799 121.9 299.399 124.2 299.399 126.9ZM294.499 125.9C294.499 124.1 294.099 122.5 293.299 121.2C292.499 119.9 291.399 118.8 289.999 118.2C288.599 117.5 287.099 117.2 285.499 117.2C283.099 117.2 280.999 118 279.399 119.5C277.799 121 276.799 123.2 276.499 126H294.499V125.9Z"
                    fill="white"/>
                <path
                    d="M303.898 127.901C303.898 124.901 304.498 122.301 305.698 120.101C306.898 117.901 308.498 116.101 310.498 114.901C312.598 113.701 314.898 113.101 317.398 113.101C319.898 113.101 322.098 113.701 323.898 114.801C325.698 115.901 327.098 117.301 327.998 118.901V113.601H332.798V142.601H327.998V137.201C327.098 138.901 325.598 140.301 323.798 141.401C321.998 142.501 319.798 143.001 317.398 143.001C314.998 143.001 312.598 142.401 310.498 141.101C308.398 139.801 306.798 138.101 305.698 135.801C304.498 133.501 303.898 130.801 303.898 127.901ZM328.098 127.901C328.098 125.701 327.598 123.801 326.798 122.201C325.898 120.601 324.698 119.401 323.298 118.501C321.798 117.601 320.198 117.201 318.498 117.201C316.698 117.201 315.098 117.601 313.698 118.501C312.198 119.401 311.098 120.601 310.198 122.201C309.298 123.801 308.898 125.701 308.898 127.901C308.898 130.101 309.298 132.101 310.198 133.701C311.098 135.301 312.198 136.601 313.698 137.501C315.198 138.401 316.798 138.801 318.498 138.801C320.298 138.801 321.898 138.401 323.298 137.501C324.798 136.601 325.998 135.401 326.798 133.701C327.598 132.001 328.098 130.101 328.098 127.901Z"
                    fill="white"/>
                <path
                    d="M345.6 118.2C346.4 116.5 347.6 115.2 349.2 114.3C350.8 113.4 352.7 112.9 354.9 112.9V117.9H353.7C348.4 117.9 345.7 120.8 345.7 126.7V142.4H341V113.4H345.7V118.2H345.6Z"
                    fill="white"/>
                <path
                    d="M408.599 113C410.799 113 412.799 113.5 414.499 114.4C416.199 115.3 417.599 116.7 418.599 118.6C419.599 120.5 420.099 122.8 420.099 125.4V142.5H415.399V126.1C415.399 123.2 414.699 121 413.299 119.5C411.899 118 409.999 117.2 407.599 117.2C405.199 117.2 403.199 118 401.699 119.6C400.199 121.2 399.499 123.5 399.499 126.5V142.5H394.799V126.1C394.799 123.2 394.099 121 392.699 119.5C391.299 118 389.399 117.2 386.999 117.2C384.599 117.2 382.599 118 381.099 119.6C379.599 121.2 378.899 123.5 378.899 126.5V142.5H374.199V113.5H378.899V117.7C379.799 116.2 381.099 115 382.699 114.2C384.299 113.4 385.999 113 387.899 113C390.299 113 392.399 113.6 394.199 114.7C395.999 115.8 397.399 117.4 398.299 119.5C399.099 117.4 400.399 115.9 402.299 114.7C404.299 113.6 406.299 113 408.599 113Z"
                    fill="white"/>
                <path
                    d="M453.999 126.9C453.999 127.8 453.999 128.8 453.899 129.8H430.999C431.199 132.7 432.099 134.9 433.899 136.5C435.699 138.1 437.799 138.9 440.299 138.9C442.399 138.9 444.099 138.4 445.399 137.5C446.799 136.5 447.799 135.2 448.299 133.6H453.399C452.599 136.4 451.099 138.7 448.799 140.4C446.499 142.1 443.699 143 440.299 143C437.599 143 435.199 142.4 432.999 141.2C430.899 140 429.199 138.2 427.999 136C426.799 133.7 426.199 131.1 426.199 128.1C426.199 125.1 426.799 122.5 427.999 120.2C429.199 118 430.799 116.2 432.899 115C434.999 113.8 437.499 113.2 440.299 113.2C443.099 113.2 445.399 113.8 447.499 115C449.599 116.2 451.199 117.8 452.299 120C453.399 121.9 453.899 124.2 453.999 126.9ZM449.099 125.9C449.099 124.1 448.699 122.5 447.899 121.2C447.099 119.9 445.999 118.8 444.599 118.2C443.299 117.5 441.799 117.2 440.099 117.2C437.699 117.2 435.699 118 433.999 119.5C432.299 121 431.399 123.2 431.099 126H449.099V125.9Z"
                    fill="white"/>
            </g>
            <path
                d="M309.302 75.5002L298.302 58.1002L288.302 75.5002H276.802L292.802 49.4002L276.602 23.7002H288.302L299.302 41.1002L309.202 23.7002H320.702L304.702 49.7002L321.002 75.5002H309.302Z"
                fill="#F68936"/>
            <g opacity="0.3">
                <path d="M82.5 95.6V83L124.4 124.9L82.5 95.6Z" fill="white"/>
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M115.8 118.9C117.5 120.1 119.6 118 118.4 116.3L95.0999 83L124.5 41L83.5999 69.6C82.9999 70 82.0999 70 81.4999 69.6L49.1999 47.1C47.4999 45.9 45.3999 48 46.5999 49.7L69.1999 82C69.6999 82.6 69.6999 83.5 69.1999 84.1L46.5999 116.3C46.2999 116.7 46.1999 117.1 46.2999 117.5C46.3999 118.8 47.8999 119.8 49.1999 118.9L81.4999 96.3C82.0999 95.8 82.9999 95.8 83.5999 96.3L115.8 118.9Z"
                      fill="white"/>
                <path d="M40.6016 41L82.5016 83V70.3L40.6016 41Z" fill="white"/>
            </g>
            <path
                d="M93.1992 95.1002C93.2992 94.3002 93.9992 93.7002 94.6992 93.6002L144.599 84.8002C146.699 84.4002 146.699 81.5002 144.599 81.1002L94.6992 72.3002C93.8992 72.1002 93.2992 71.5002 93.1992 70.8002L84.2992 20.9002C83.8992 18.8002 80.9992 18.8002 80.5992 20.9002L71.7992 70.8002C71.5992 71.6002 70.9992 72.2002 70.2992 72.3002L20.3992 81.1002C18.2992 81.5002 18.2992 84.4002 20.3992 84.8002L70.2992 93.6002C71.0992 93.7002 71.6992 94.4002 71.7992 95.1002L80.5992 145C80.9992 147.1 83.8992 147.1 84.2992 145L93.1992 95.1002Z"
                fill="white"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M95.9016 69.5996L125.302 123.2C126.202 124.8 124.402 126.6 122.802 125.7L69.1016 96.2996L39.7016 42.6996C38.8016 41.0996 40.6016 39.2996 42.2016 40.1996L95.9016 69.5996Z"
                  fill="#F68936"/>
            <path
                d="M88.6008 75.9002C92.2008 79.5002 92.2008 85.4002 88.6008 89.0002C85.0008 92.6002 79.1008 92.6002 75.5008 89.0002C71.9008 85.4002 71.9008 79.5002 75.5008 75.9002C79.1008 72.3002 85.0008 72.3002 88.6008 75.9002Z"
                fill="#D56827"/>
            <path
                d="M86.3984 78.2004C88.7984 80.6004 88.7984 84.5004 86.3984 86.9004C83.9984 89.3004 80.0984 89.3004 77.6984 86.9004C75.2984 84.5004 75.2984 80.6004 77.6984 78.2004C80.0984 75.8004 83.9984 75.8004 86.3984 78.2004Z"
                fill="white"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M5.8 52.5C2.1 61.9 0 72.2 0 83C0 128.5 37 165.5 82.5 165.5C93.3 165.5 103.5 163.4 113 159.6L110.8 157.4C102 160.7 92.5 162.6 82.5 162.6C38.6 162.6 2.9 126.9 2.9 83C2.9 73.1 4.7 63.5 8.1 54.7L5.8 52.5ZM154.7 116.5C159.5 106.3 162.1 94.9 162.1 83C162.1 39.1 126.4 3.4 82.5 3.4C70.5 3.4 59.2 6.1 49 10.8L46.8 8.6C57.6 3.4 69.7 0.5 82.5 0.5C128 0.5 165 37.5 165 83C165 95.8 162.1 107.9 156.9 118.7L154.7 116.5Z"
                  fill="white"/>
            <path
                d="M558.401 24.2996C552.001 24.2996 546.801 19.0996 546.801 12.6996C546.801 6.29961 552.001 1.09961 558.401 1.09961C564.801 1.09961 570.001 6.29961 570.001 12.6996C570.001 19.0996 564.801 24.2996 558.401 24.2996ZM558.401 2.19961C552.601 2.19961 548.001 6.89961 548.001 12.5996C548.001 18.3996 552.701 22.9996 558.401 22.9996C564.201 22.9996 568.801 18.2996 568.801 12.5996C568.801 6.89961 564.101 2.19961 558.401 2.19961Z"
                fill="white"/>
            <path
                d="M558.399 0C551.399 0 545.699 5.7 545.699 12.7C545.699 19.7 551.399 25.4 558.399 25.4C565.399 25.4 570.999 19.6 570.999 12.7C570.999 5.8 565.399 0 558.399 0ZM567.799 12.6C567.799 17.8 563.599 22 558.399 22C553.199 22 548.999 17.8 548.999 12.6C548.999 7.4 553.199 3.2 558.399 3.2C563.599 3.2 567.799 7.4 567.799 12.6Z"
                fill="white"/>
            <path
                d="M558.2 11.3998C558.9 11.3998 559.4 11.2998 559.7 10.9998C560 10.7998 560.2 10.2998 560.2 9.7998C560.2 9.1998 560 8.7998 559.7 8.5998C559.4 8.3998 558.9 8.1998 558.2 8.1998H556.8V11.3998H558.2ZM556.8 13.5998V18.3998H553.5V5.7998H558.5C560.2 5.7998 561.4 6.0998 562.1 6.5998C562.9 7.1998 563.3 7.9998 563.3 9.1998C563.3 9.9998 563.1 10.6998 562.7 11.2998C562.3 11.7998 561.7 12.1998 560.9 12.4998C561.4 12.5998 561.7 12.7998 562.1 13.1998C562.5 13.5998 562.8 14.0998 563.2 14.7998L565 18.3998H561.5L560 15.2998C559.7 14.6998 559.4 14.1998 559.1 13.9998C558.8 13.7998 558.4 13.5998 557.8 13.5998H556.8Z" fill="white"/>
        </g>
        <defs>
            <clipPath id="clip0_1786_24551">
                <rect width="571" height="165.5" fill="white"/>
            </clipPath>
        </defs>
    </svg>
)
}
export default LogoSvg