import React, { useState } from "react";
import { Link } from "react-router-dom";
import Swiper from "react-id-swiper";
import "swiper/swiper-bundle.css";

interface HomePageTabComponentProps {
  titles?: { id: number; label: string, image_url: string }[];
  activeTab: number;
  handleTabClick: (title: { id: number; label: { id: number; title: string } }, tabId: number) => void;
}

const HomePageTabComponent = (props: HomePageTabComponentProps) => {
  const [swiper, setSwiper] = useState<any>(null);
  const [startIndex, setStartIndex] = useState(0);
  const categoriesPerSlide = window.innerWidth < 768 ? 3 : 6;
  const handleTabClick = (
    title: { id: number; label: { id: number; title: string } },
    tabId: number
  ) => {
    props.handleTabClick(title, tabId);
  };
  const swiperParams = {
    slidesPerView: window.innerWidth > 767 ? 6 : 1,
    spaceBetween: 20,
    loop: false,
    shouldSwiperUpdate: true,
    on: {
      init: (swiper: any) => {
        setSwiper(swiper);
      },
      slideChange: () => {
        if (swiper) {
          setStartIndex(swiper.activeIndex);
        }
      }
    }
  };

  const handleArrowClick = (direction: "left" | "right") => {
    const totalCategories = props.titles?.length ?? 0;
    const newIndex =
      direction === "left"
        ? (startIndex - categoriesPerSlide + totalCategories) % totalCategories
        : (startIndex + categoriesPerSlide) % totalCategories;

    if (swiper) {
      swiper.slideTo(newIndex);
      setStartIndex(newIndex);
    }
  };

  return (
    <div className="flex justify-center items-center mx-5 relative gap-10">
      <button onClick={() => handleArrowClick("left")}>
        <img src="/assets/svgs/eventDetails/left-arrow.svg" className="w-[400px] md:w-20" alt="Left Arrow" />
      </button>
      <div className="py-10 md:mt-0 overflow-x-auto overflow-y-clip text-white">
        <Swiper {...swiperParams}>
          {props.titles && props.titles.map((title, index) => {
            if (index === 0) {
              return <></>;
            }
            return (
              <div
                key={index}
                className="group transition-transform duration-500 rounded-3xl"
              >
                <Link to={`/allEvents/large?categoryId=${title.id}`} key={title.id} className="h-full">
                  <img src="/assets/svgs/common/explore-compass.svg"
                       className="top-0 left-0 absolute z-20 -translate-x-1/3 -translate-y-1/3 w-8 h-8 scale-0 origin-bottom-right group-hover:scale-100 duration-300"
                       alt="Explore Logo" />
                  <button
                    className={`bg-cover flex flex-col justify-center items-center gap-2 w-full h-40 relative text-sm rounded-3xl shadow-xl px-0 duration-300 ${
                      props.activeTab === startIndex + index
                        ? "text-white hover:bg-white"
                        : "hover:bg-primary-orange bg-black absolute"
                    }`}
                    onClick={() =>
                      handleTabClick(
                        { id: title.id, label: { id: title.id, title: title.label } },
                        startIndex + index
                      )
                    }
                  >
                    <div
                      className="h-px rounded-full flex justify-center bg-white w-[30px]"></div>
                    <span className="z-10 font-bold">
                                            <span
                                              className={`text-white group-hover:text-white duration-200 ${
                                                props.activeTab === startIndex + index && "group-hover:text-white"
                                              }`}
                                            >
                                                {title.label}
                                            </span>
                                        </span>
                    <div
                      className="border-2 border-black absolute inset-0 transform rotate-0 transition-transform group-hover:-rotate-[10deg] -z-10 rounded-3xl"></div>
                  </button>
                </Link>
              </div>
            );
          })}
        </Swiper>
      </div>
      <button onClick={() => handleArrowClick("right")}>
        <img src="/assets/svgs/eventDetails/right-arrow.svg" className="w-[400px] md:w-20" alt="Right Arrow" />
      </button>
    </div>
  );
};
export default HomePageTabComponent;