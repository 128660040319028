import { Achievements, TaskType, UserDashboardState, UserLevel } from "./types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const initialState: UserDashboardState = {
    isLoading: false,
    isMobile: false,
    showDialog: false,
    isOpen: false,
    gamificationData: {
        achievments: [],
        user_level: undefined,
        user_tasks: {
            all: [],
            done: [],
            progress: [],
        }
    },
    userHistory:{
        current_page:0,
        historyData:[],
        per_page:0,
        total:0,
        last_page:0
    }
}
export const UserDashboardSlice = createSlice({
    name: 'UserDashboardSlice',
    initialState,
    reducers: {
        setIsMobile(state, action: PayloadAction<boolean>) {
            state.isMobile = action.payload;
        },
        setIsOpen(state, action: PayloadAction<boolean>) {
            state.isOpen = action.payload;
        },
        setShowDialog(state, action: PayloadAction<boolean>) {
            state.showDialog = action.payload;
        },
        setAchievments(state, action: PayloadAction<Achievements[]>) {
            state.gamificationData.achievments = action?.payload;
        },
        setUserLevel(state, action: PayloadAction<UserLevel | undefined>) {
            state.gamificationData.user_level = action.payload;
        },
        setAllUserTask(state, action: PayloadAction<TaskType[]>) {
            state.gamificationData.user_tasks.all = action.payload;
        },
        setTasksDone(state, action: PayloadAction<TaskType[]>) {
            state.gamificationData.user_tasks.done = action.payload;
        },
        setTasksInProgress(state, action: PayloadAction<TaskType[]>) {
            state.gamificationData.user_tasks.progress = action.payload;
        },
        setHistoryData(state, action: PayloadAction<[]>) {
            state.userHistory.historyData = action.payload;
        },
        setUserHistory(state, action: PayloadAction<{ name: string; value: any }>) {
            state.userHistory = {
              ...state.userHistory,
              [action.payload.name]: action.payload.value,
            };
          },

    }
})
export default UserDashboardSlice.reducer;
