
import {TSvgComponent} from "./types";

const TokenSvg :TSvgComponent = ({...props}) => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g opacity="0.5">
                <path d="M9.99984 1.66667C5.39984 1.66667 1.6665 5.40001 1.6665 10C1.6665 14.6 5.39984 18.3333 9.99984 18.3333C14.5998 18.3333 18.3332 14.6 18.3332 10C18.3332 5.40001 14.5998 1.66667 9.99984 1.66667ZM11.2498 12.2917L10.9915 12.2083L10.8332 12.7083L9.99984 15.2083L7.9165 11.3083L7.83317 11.15L4.99984 10.2083L6.8165 9.30001L8.33317 8.54167L8.72484 8.36667L9.99984 4.37501L11.2915 7.20833L12.0832 8.95834L11.7415 9.13334L14.9998 10.2083L11.2498 12.2917Z" />
            </g>
        </svg>
    )
}
export default TokenSvg
