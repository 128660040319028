import {TSvgComponent} from "./types";

const SettingsSvg : TSvgComponent = ({...props}) => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g opacity="0.5">
                <path d="M16.7498 7.68333C15.2415 7.68333 14.6248 6.61666 15.3748 5.30833C15.8082 4.55 15.5498 3.58333 14.7915 3.15L13.3498 2.325C12.6915 1.93333 11.8415 2.16666 11.4498 2.825L11.3582 2.98333C10.6082 4.29166 9.37484 4.29166 8.6165 2.98333L8.52484 2.825C8.14984 2.16666 7.29984 1.93333 6.6415 2.325L5.19984 3.15C4.4415 3.58333 4.18317 4.55833 4.6165 5.31666C5.37484 6.61666 4.75817 7.68333 3.24984 7.68333C2.38317 7.68333 1.6665 8.39166 1.6665 9.26666V10.7333C1.6665 11.6 2.37484 12.3167 3.24984 12.3167C4.75817 12.3167 5.37484 13.3833 4.6165 14.6917C4.18317 15.45 4.4415 16.4167 5.19984 16.85L6.6415 17.675C7.29984 18.0667 8.14984 17.8333 8.5415 17.175L8.63317 17.0167C9.38317 15.7083 10.6165 15.7083 11.3748 17.0167L11.4665 17.175C11.8582 17.8333 12.7082 18.0667 13.3665 17.675L14.8082 16.85C15.5665 16.4167 15.8248 15.4417 15.3915 14.6917C14.6332 13.3833 15.2498 12.3167 16.7582 12.3167C17.6248 12.3167 18.3415 11.6083 18.3415 10.7333V9.26666C18.3332 8.4 17.6248 7.68333 16.7498 7.68333ZM9.99984 12.7083C8.50817 12.7083 7.2915 11.4917 7.2915 10C7.2915 8.50833 8.50817 7.29166 9.99984 7.29166C11.4915 7.29166 12.7082 8.50833 12.7082 10C12.7082 11.4917 11.4915 12.7083 9.99984 12.7083Z"/>
            </g>
        </svg>

    )
}
export default SettingsSvg
