import React, { useEffect } from "react";
import Header from "../../component/Header";
import { useAppDispatch, useAppSelector } from "../../store/redux";
import { RootState } from "../../store/store";
import { getTerms } from "../../store/TermsAndCondition/TermsAction";
import "./style.css";
import { useLocation } from "react-router-dom";
interface TermsAndConditionProps {
    type?: string;
}

const TermsAndCondition: React.FC<TermsAndConditionProps> = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const type = searchParams.get("type");
    const dispatch = useAppDispatch();
    const terms = useAppSelector((state: RootState) => state.termsReducer.termsAndCondition);

    useEffect(() => {
        console.log("terms",terms)
        if (type) {
            dispatch(getTerms(type));
        }
    }, [type, dispatch]);

    return (
        <div className="terms-and-condition bg-white">
            <Header />
            <div className="p-10" dangerouslySetInnerHTML={{ __html: terms }} />
        </div>
    );
};

export default TermsAndCondition;