export const partnersData =
    {
        id: 2,
        tag: "The activities can be quite different",
        title: 'What Makes Our Partners Special',
        slogan: 'We\'re putting together a selection of events.',
        activity: '',
        background_image: "/assets/images/pages/home/explore-bg.webp",
        image: "/assets/images/pages/home/special-partners.webp",
        button_text: "View",
        button_url: "/partnersRecommendations"
    };
