import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import TabComponent from "../../../../component/TabComponent";
import { getDocuments } from "../../../../store/BusinessDashboard/ActionBusinessDashboard";
import { TDocuments } from "../../../../store/BusinessDashboard/types";
import { scrollToTop } from "../../../../store/GlobalState/ActionGlobalState";
import { useAppDispatch, useAppSelector } from "../../../../store/redux";

export const BusinessDocument = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(0);
    const documents: any = useAppSelector(
    (state) => state.businessDashboardReducer.documents,
  );
  const [selectedTab, setSelectedTab] = useState(documents[activeTab]?.label);
  const handleTabClicked = (value: any, index: number) => {
    setActiveTab(index);
    let tab = value.label;
    setSelectedTab(tab.toLowerCase());
  };
  useEffect(() => {
    dispatch(scrollToTop);
    dispatch(getDocuments());
  }, []);
  return (
    <div>
       <div className="mb-5 mt-4 flex items-center gap-2">
        <p className={"text-xl font-bold text-black"}>{t("dashboardSideBar.sidebar.documents")}</p>
      </div>
        <div className="grid">
        <TabComponent
          titles={documents.map((tab:any,index:number) => ({
            id: index,
            label: tab.label,
          }))}
          activeTab={activeTab}
          handleTabClick={handleTabClicked}
        />
      </div>
      <div className="flex flex-col gap-3 mt-5">
        {
          <div className="grid grid-cols-1 mb-10 items-center shadow-md shadow-primary-orange/30">
            <div dangerouslySetInnerHTML={{ __html: documents[activeTab]?.information }} />
          </div>
        }
      </div>
    </div>
  );
};
