export const partnersData = [
    {
        id: "klev",
        image: "/assets/svgs/common/partners/klev.svg",
        path: "https://klevlb.com",
    },
    {
        id: "aws",
        image: "/assets/svgs/common/partners/aws.svg",
        path: "https://aws.amazon.com/",
    },
    {
        id: "turitop",
        image: "/assets/svgs/common/partners/turitop.svg",
        path: "https://turitop.com",
    },
];
