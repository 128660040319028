import LevelAccount from "./LevelAccount";
import LevelAchievements from "./LevelAchievements";
import { useEffect} from "react";
import YourTasks from "./YourTasks";
import { useAppDispatch } from "../../../../store/redux";
import { getUserGamifications } from "../../../../store/UserDashboard/ActionUserDashboard";
import { scrollToTop } from "../../../../store/GlobalState/ActionGlobalState";

const Gamification = () => {
    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(scrollToTop)
        dispatch(getUserGamifications())
    }, [])
    return (
        <>
            <div className={"grid xl:grid-cols-2 grid-cols-1 mb-3  gap-3"}>
                <LevelAccount />
                <LevelAchievements />
            </div>
            <div className="grid mb-[100px]">
                <YourTasks />
            </div>

        </>
    )
}
export default Gamification
