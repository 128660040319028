import axios from "axios";

export const CrowdfundingAPI = async (payload: any[]) => {
  try {
    const response = await axios.post("api/contributions/one-time", payload);
    return response.data;
  } catch (error) {
    console.error("Error:", error);

    throw error;
  }
};
export const stripePaymentAPI = async (contributionId: string, paymentIntent: string) => {
  try {
    const response = await axios.post(`api/contributions/${contributionId}/success?payment_intent=${paymentIntent}`);
    return response.data;
  } catch (error) {
    console.error("Error in paymentStripe API:", error);
    throw error;
  }
};

export const getContributions = async () => {
  try {
    const response = await axios.get("api/contributions");
    return response.data.data;
  } catch {
  }
};
export const subscriptionAPI = async (payload: any[]) => {
  try {
    const response = await axios.post(`api/contributions/subscribe`, payload);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const countriesAPI = async () => {
  try {
    const response = await axios.get("/api/countries");
    return response.data.data;
  } catch {

  }
};

export const referredAPI = async (payload: { full_name: string, email: string }) => {
  try {
    const response = await axios.post(`api/contributions/referred`, payload);
    return response.data;
  } catch {

  }
};