import React from "react";
import { wordsOfSupport } from "../../../constants/wordsOfSupport";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import moment from "moment/moment";
import { useTranslation } from "react-i18next";

interface WordsOfSupportProps {
  recentContributors: {
    id: number;
    name: string;
    amount: string;
    words_of_support: string;
    created_at: string;
    country_code:string;
  }[];
}

const WordsOfSupport: React.FC<WordsOfSupportProps> = ({ recentContributors }) => {

  const { t } = useTranslation();
  let maxSlideHeight = 0;

  const calculateMaxHeight = (height: number) => {
    maxSlideHeight = Math.max(maxSlideHeight, height);
  };
  const settings = {
    dots: false,
    infinite: recentContributors.length > 5,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    nextArrow: recentContributors.length > 0 ?<img src="/assets/svgs/next-arrow.svg" alt="Next Arrow" /> : undefined,
    prevArrow: recentContributors.length > 0 ? <img src="/assets/svgs/prev-arrow.svg" alt="Previous Arrow" /> : undefined,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <div className="">
      <div className="md:pt-20 pb-10">
        <h1 className="font-bold text-3xl gap-2 flex items-center max-md:justify-center">
          {t("crowdfunding.wordsOfSupportTitle")}
          <div className="border-b-[3px] border-black w-[60px] mt-2 rounded-2xl"></div>
        </h1>
        <p className="text-primary-orange font-bold max-md:text-center">{t("crowdfunding.wordsTag")}</p>
      </div>
      <div className="md:pb-10 md:mt-0 text-white max-md:mx-8">
        <Slider {...settings}
                beforeChange={(current, next) => calculateMaxHeight(document.getElementById(`slide-${next}`)?.clientHeight || 0)}
        >
          {
            recentContributors.length === 0 ? (
              <p className="text-primary-orange font-bold">No contributors yet</p>
            ) : (
              recentContributors.map((support) => (
                  <div key={support.id}
                       className="slide px-2 cursor-pointer group transition-transform duration-500 rounded-3xl"
                  >
                    <div
                      className="bg-[#F6F6F6] w-full h-[270px] overflow-hidden hover:bg-gradient-to-r hover:from-[#FFB63F] hover:to-primary-orange rounded-xl text-black flex flex-col items-center md:px-4 py-8 relative">
                      <div className="flex justify-between">
                        <p
                          className="absolute top-0 right-0 mt-2 mr-2 text-[#9A9A9A] font-light text-xs group-hover:text-white px-3 pt-1">{moment(support.created_at).fromNow()}</p>
                        <p
                          className="absolute top-0 left-0 mt-2 mr-2 text-[#9A9A9A] font-light text-xs group-hover:text-white px-3 pt-1">{support.country_code}</p>
                      </div>
                      <img
                        src="/assets/svgs/donation.svg"
                        className="w-10 h-10 absolute top-5 left-0 right-0 m-auto visible group-hover:invisible mt-5"
                      />
                      <img
                        src="/assets/svgs/donation-hover.svg"
                        className="w-10 h-10 absolute top-5 left-0 right-0 m-auto invisible group-hover:visible mt-5"
                      />
                      <div className="my-4 flex flex-col items-center justify-center">
                        <p
                          className="text-md text-center pt-10 font-bold group-hover:text-white duration-200">{support.name}</p>
                        <div
                          className="bg-primary-orange/20 text-primary-orange group-hover:text-white group-hover:bg-white/20 px-8 py-2 rounded-xl font-bold mt-2 text-center">£{support.amount}</div>
                      </div>
                      <div
                        className="h-[350px] flex flex-col items-center relative scrollable-content"
                        style={{ overflowX: "hidden", overflowY: "auto" }}>
                        <p
                          className="px-3 text-center font-200 text-sm group-hover:text-white">{support.words_of_support}</p>
                      </div>
                    </div>
                  </div>
                )
              )
            )
          }
        </Slider>
      </div>
    </div>
  );
};

export default WordsOfSupport;
