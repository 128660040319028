import React, {useEffect, useState} from "react";
import Header from "../../component/Header";
import {IPartners} from "../../networking/partners/types";
import {fetchPartnersAxios} from "../../networking/partners/fetchPartnersAxios";
import ImagesSlider from "./imagesSlider";
import {useTranslation} from "react-i18next";

const PartnersRecommendations = () => {
    const [partners, setPartners] = useState<IPartners[] | null>(null);
    const [loading, setLoading] = useState(true);
    const {t} = useTranslation();
    useEffect(() => {
        fetchPartnersAxios()
            .then((data) => {
                setPartners(data);
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
            });
    }, []);

    return (
        <>
            <Header/>
            <div className="container py-10">
                <h2 className="text-center mb-10">{t('partners.title')}</h2>
                <div className="shadow-2xl rounded-xl">
                    {partners?.map((partner, index) => {
                        return (
                            <>
                                <div className="grid md:grid-cols-[1fr_2fr] gap-10 p-10 my-4" key={index}>
                                    <ImagesSlider images={partner.images.map((image) => image.image)}/>
                                    <div className="">
                                        <h2 className="text-[#FF913F] text-3xl">{partner.name}</h2>
                                        <div dangerouslySetInnerHTML={{__html: partner.description}}
                                             className="[&_p]:mb-3 font-medium text-[#131C23]/70"/>
                                    </div>
                                </div>
                            </>
                        );
                    })}
                </div>
                <div className="flex mt-10">
                    <img className={"w-full"}
                         src={"/assets/images/pages/Dashboard/UserProfile/mobile-coming-soon.png"}/>
                </div>
            </div>
        </>
    );
};

export default PartnersRecommendations;
