import LogoSvg from "../SvgComponents/LogoSvg";
import SearchSvg from "../SvgComponents/SearchSvg";
import HeartSvg from "../SvgComponents/HeartSvg";
import ShoppingSvg from "../SvgComponents/ShoppingSvg";
import {useEffect, useRef, useState} from "react";
import AuthComponent from "./component/AuthComponent";
import Currency from "./component/Currency";
import {useLocation, useNavigate} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../store/redux";
import {Avatar} from "@mui/material";
import {useSelector} from "react-redux";
import {RootState} from "../../store/store";
import {UserInfo} from "../../networking/auth/types";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import {AppBar, IconButton, Toolbar, Typography} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import {
    setAuthModal,
    toggleVerificationModal,
} from "../../store/Auth/ActionAuth";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import {setLanguage} from "../../store/Language/LanguageSlice";
import {useTranslation} from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import {getSearchEvents} from "../../store/events/ActionEvents";
import {getLanguagesAxios} from "../../networking/language/language";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {fetchCartItemCount} from "../../store/Cart/ActionCart";

type THeader = {
    sticky?: boolean;
};
const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 900,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: 3,
};

const headerStyle = {
    background: 'black',
}

interface Language {
    id: number;
    code: string;
    logo: string;
    title: string;
}

const getRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
};
export const MobileMenu = (props: THeader) => {
    const dispatch = useAppDispatch();
    const roleType = useSelector(
        (state: RootState) => state.authReducer?.userRole,
    );
    // const selectedLanguage = useSelector((state) => state.events.eventsReducer);
    const token = localStorage?.getItem("token");
    const [languages, setLanguages] = useState<Language[]>([]);
    const location = useLocation();
    const pathname = location.pathname;
    const divRef = useRef(null);
    const userInfo: UserInfo | null = useSelector(
        (state: RootState) => state.authReducer?.userInfo,
    );
    const auth = useAppSelector((state) => state.authReducer);
    const searchedEvents = useAppSelector(
        (state: RootState) => state.eventsReducer.searchEvents,
    );
    // const [openAuthModal, setOpenAuthModal] = useState(false);

    const openAuthModal: boolean = useSelector(
        (state: RootState) => state.authReducer?.openAuthModal,
    );
    const cartItemCount = useAppSelector((state) => state.cartReducer.cartItemCount);

    const selectedLanguage = useAppSelector((state) => state.languageReducer.selectedLanguage);
    const displaySelectedLanguage = languages.find(lang => lang.code === selectedLanguage);

    const [, setIsOutsideClick] = useState(false);
    const [showMobileMenu, setShowMobileMenu] = useState(false);
    const [, setIsMobileScreen] = useState(false);
    const [showSearchModal, setShowSearchModal] = useState(false);
    const [inputSearchValue, setInputSearchValue] = useState("");
    const [, setDisplayCheckImage] = useState<string>("");
    const {t, i18n} = useTranslation();
    const [, setShowLanguageModal] = useState(false);
    const [, setClientToken] = useState('');
    const [, setLanguageColors] = useState<{ [key: number]: string }>({});
    const generateRandomColors = () => {
        const colors: { [key: number]: string } = {};
        languages.forEach((language, index) => {
            colors[index] = getRandomColor();
        });
        setLanguageColors(colors);
    };
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const handleMenuToggle = () => {
        setIsMenuOpen(!isMenuOpen);
    };
    const openLanguageModal = () => {
        setShowLanguageModal(true);
    };

    const closeLanguageModal = () => {
        setShowLanguageModal(false);
    };
    const handleChangeInputSearch = (event: any) => {
        setInputSearchValue(event.target.value);
    };
    const toggleMobileMenu = () => {
        setShowMobileMenu(!showMobileMenu);
    };
    const handleOpenAuthModal = () => {
        dispatch(setAuthModal(true));
    };
    const navigate = useNavigate();
    const goToHomePage = () => {
        navigate("/");
    };
    const goToPayment = () => {
        navigate("/payment");
    };
    const goToWishlist = () => {
        navigate("/wishlist");
    };
    const handleLanguageChange = (newLanguage: string) => {
        i18n.changeLanguage(newLanguage);
        document.documentElement.setAttribute("lang", newLanguage)
        if (newLanguage === "ar") {
            document.documentElement.setAttribute("dir", "rtl")
        } else {
            document.documentElement.setAttribute("dir", "ltr")
        }
        dispatch(setLanguage(newLanguage));
        setDisplayCheckImage(newLanguage);
    };
    const handleNavigateUser = () => {
        if (localStorage.getItem("otpVerified") == "false") {
            dispatch(toggleVerificationModal(true));
        } else
            navigate(
                roleType?.id === 1 ? "/profile-settings" : "/business-profile-settings",
            );
    };

    function useOutsideAlerter(ref: any) {
        useEffect(() => {
            function handleClickOutside(event: any) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setShowSearchModal(false);
                }
            }

            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

    useEffect(() => {
        function handleClickOutside(event: any) {
            if (divRef.current) {
                setIsOutsideClick(true);

            } else {
                setIsOutsideClick(false);
            }
        }

        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (inputSearchValue !== "") {
            const timeoutId = setTimeout(() => {
                dispatch(getSearchEvents(inputSearchValue)).then((res) => {
                    setShowSearchModal(true);
                });
            }, 500);
            return () => clearTimeout(timeoutId);
        }
    }, [inputSearchValue]);

    useEffect(() => {
        const handleResize = () => {
            setIsMobileScreen(window.innerWidth < 768);
        };
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);
    useEffect(() => {
        setClientToken(localStorage.getItem('clientToken') as string)
        generateRandomColors();
        getLanguagesAxios()
            .then((data) => {
                setLanguages(prev => prev = data);
            })
            .catch((error) => {
            });
    }, []);
    useEffect(() => {
        dispatch(fetchCartItemCount());
    }, [dispatch]);

    return (
        <>
            <div
                className="[&_header]:!bg-black [&_header]:!flex [&_header]:!flex-row [&_header]:!items-center [&_header]:!justify-between">
                <AppBar position="static">
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            onClick={handleMenuToggle}
                        >
                            <MenuIcon/>
                        </IconButton>
                        <Typography variant="h6"></Typography>
                    </Toolbar>
                    <LogoSvg
                        className="fill-white"
                        onClick={goToHomePage}
                    />
                    <div className="flex">
                        <div className="flex items-center relative mx-3">
                            <SearchSvg className={"h-full fill-white"}/>
                            <input
                                onChange={handleChangeInputSearch}
                                className={
                                    "w-[80px] bg-transparent pl-3 text-white/60 placeholder-white/60 focus-visible:outline-none"
                                }
                                placeholder={t('search')}
                            />
                        </div>
                        {showSearchModal && (
                            <div
                                ref={wrapperRef}
                                className="absolute max-h-[320px] top-12 overflow-y-scroll rounded-lg right-0 border-2 border-black/5 bg-[#f3f3f4]"
                            >
                                {searchedEvents.length > 0 ? (
                                    <>
                                        {" "}
                                        <div className="flex flex-col gap-3 p-2">
                                            {searchedEvents?.map((item) => (
                                                <div
                                                    onClick={() =>
                                                        navigate(`/eventDetails/${item.id}`)
                                                    }
                                                    className="hover-card grid grid-cols-[4fr_8fr] gap-3 rounded-lg border bg-white p-2"
                                                >
                                                    <div>
                                                        <img
                                                            loading="lazy"
                                                            className="h-[100px] w-[120px]"
                                                            alt="No Image"
                                                            src={item.image.image_path}
                                                        />
                                                    </div>
                                                    <div>
                                                        <p className="text-base font-bold text-black">
                                                            {item.title}
                                                        </p>
                                                        <p className="mt-1 text-[12px] line-clamp-3 overflow-ellipsis font-medium text-primary-orange">
                                                            {item.description}
                                                        </p>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                        {" "}
                                    </>
                                ) : (
                                    <>
                                        {" "}
                                        <div
                                            className="flex flex-col gap-3 rounded-lg border bg-white p-2">
                                            <h3 className="text-lg text-primary-orange font-bold 2xl:px-7 2xl:text-xl">
                                                {t('nothingFound')}
                                            </h3>
                                            <p className="pt-4 font-medium text-[#131C23]/40">
                                                {t('searchSomethingElse')}
                                            </p>
                                        </div>
                                    </>
                                )}
                            </div>
                        )}
                    </div>
                </AppBar>
                <div
                    className={`fixed inset-0 bg-gray-800 bg-opacity-75 text-white w-50 ${isMenuOpen ? "translate-x-0" : "-translate-x-full"}`}
                    onClick={handleMenuToggle}>
                    <div className=" bg-black w-3/5 h-full flex flex-col">
                        <button className="text-white flex p-5" onClick={handleMenuToggle}>
                            <CloseIcon/>
                        </button>
                        <div className="px-5">
                            <div className="">
                                {token &&
                                    <div>
                                        <div className="flex items-center gap-2">
                                            <Avatar
                                                className="cursor-pointer"
                                                onClick={handleNavigateUser}
                                                alt={userInfo?.name}
                                                src={userInfo?.profile_pic as string}
                                            />
                                            <div>Hello,</div>
                                            <p onClick={handleNavigateUser}>{userInfo?.name}</p>
                                        </div>
                                        <div
                                            className="h-px rounded-full flex justify-center bg-white w-full my-8 opacity-50"></div>
                                    </div>
                                }
                            </div>
                            {(auth?.token && auth.userRole?.id === 1) && (
                                <div className="flex items-center py-4 text-lg">
                                    <HeartSvg
                                        className={`hover-link w-8 ${
                                            pathname === "/wishlist"
                                                ? "fill-primary-orange"
                                                : "fill-white"
                                        }`}
                                        onClick={goToWishlist}
                                    />
                                    <p>Wishlist</p>
                                </div>
                            )}
                            <div className="flex items-center py-4 text-lg">
                                {(auth.userRole?.id !== 2 || !auth.userRole) && (
                                    <div className="flex items-center gap-2" onClick={goToPayment}>
                                        <ShoppingSvg
                                            className={`hover-link w-10 ${
                                                pathname === "/payment"
                                                    ? "fill-primary-orange"
                                                    : "fill-white"
                                            }`}

                                        />
                                        Cart
                                        <span
                                            className="bg-primary-orange text-[10px] rounded-lg px-2 aspect-square"
                                        >
                                                   {cartItemCount}
                                        </span>
                                    </div>
                                )}
                            </div>
                            <FormControl
                                fullWidth
                                sx={{m: 1, minWidth: 120, color: "white"}}
                                className="my-20"
                            >
                                <Select
                                    sx={{
                                        color: "white",
                                        "& .MuiSelect-icon": {
                                            color: "white",
                                        },
                                    }}
                                    displayEmpty
                                    inputProps={{"aria-label": "Without label"}}
                                    value={selectedLanguage}
                                    onChange={(e) => handleLanguageChange(e.target.value)}
                                    IconComponent={KeyboardArrowDownIcon}
                                >
                                    {languages?.map((language) => (
                                        <MenuItem key={language.code} value={language.code}>
                                            <div className="flex items-center gap-2">
                                                <img src={language.logo} className="w-4 h-4"/>
                                                <h2 className="text-[#8C8C8C] font-bold text-sm">  {language.title}</h2>
                                            </div>
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <div className="py-4 px-2">
                                <Currency/>
                            </div>
                        </div>
                        {
                            !token && (
                                <div className="pt-[100%]">
                                    <div
                                        className="m-10 bg-gradient-to-r from-primary-orange/90 to-primary-orange rounded-[30px] p-5 bottom-0">
                                        <img src="/assets/svgs/common/loginIcon.svg"/>
                                        <button
                                            className="py-2 font-bold md:text-lg text-sm"
                                            onClick={handleOpenAuthModal}
                                        >
                                            {t('signIn')} / {t('signUp')}
                                        </button>
                                        <p className="font-medium text-xs">{t('logInQuote')}</p>
                                    </div>
                                </div>

                            )
                        }
                    </div>
                </div>
            </div>
        </>
    );
};
export default MobileMenu;
