import {useEffect, useState} from "react";
import TabComponent from "../../../component/TabComponent";
import {Link, useNavigate} from "react-router-dom";
import CreateWishlist from "./CreateWishlist";
import {useAppDispatch, useAppSelector} from "../../../store/redux";
import {useSelector} from "react-redux";
import {deleteCategory, getWishlistsCategories} from "../../../store/Wishlist/ActionWishlist";
import {RootState} from "../../../store/store";
import {CategoryType, IEventUserType} from "../../../store/Wishlist/types";
import EventCard from "../../../component/EventCard";
import {IEventsData} from "../../../networking/events/types";
import AddEventCategory from "./AddEventCategory";
import {setAllEventsCategories, setWishlistCategories} from "../../../store/Wishlist/WishlistSlice";
import {scrollToTop} from "../../../store/GlobalState/ActionGlobalState";
import {useTranslation} from "react-i18next";

const WishList = () => {
    const dispatch = useAppDispatch();
    const [selectedCategoryId, setSelectedCategoryId] = useState<number | null>(null);
    const {categories, all} = useSelector((state: RootState) => state.wishlistReducer);
    const [activeTab, setActiveTab] = useState(0)
    const auth = useAppSelector(state => state.authReducer);
    const {t} = useTranslation()
    const categoriesAll: CategoryType[] = useSelector((state: RootState) =>
        state.wishlistReducer.categories.map((category, index) => ({
            ...category,
            label: category.title,
        }))
    );
    const tabCategories = [
        {id: 0, label: "All Categories"},
        ...categoriesAll.map((category) => ({
            id: category.id,
            label: category.title,
        })),
    ];
    const handleTabClicked = (title: { id: number, label: string }, tabId: number) => {
        setActiveTab(tabId);
        setSelectedCategoryId(title.id);
    };
    const navigate = useNavigate();
    const handleFindThingsClick = () => {
        navigate("/allEvents/large");
    };
    const handleRemoveFromCategory = (eventId: number) => {
        const updatedAllEvents = all.filter((event) => event.id !== eventId);
        const updatedCategories = categories.map((category) => ({
            ...category,
            user_liked_events: category.user_liked_events.filter(
                (eventType) => eventType.event.id !== eventId
            ),
        }));
        dispatch(setAllEventsCategories(updatedAllEvents));
        dispatch(setWishlistCategories(updatedCategories));
    };
    const handleDeleteCategory = (categoryId: number | null) => {
        if (categoryId !== null) {
            dispatch(deleteCategory(categoryId));
            setSelectedCategoryId(null);
            setActiveTab(0);
            dispatch(getWishlistsCategories());
        }
    };
    useEffect(() => {
        dispatch(scrollToTop);
    }, [])

    useEffect(() => {
        dispatch(getWishlistsCategories());
    }, [auth.token, dispatch]);

    return (
        <>
            <div className="container">
                <div className="md:flex md:justify-between pt-10">
                    <h3 className="2xl:text-xl text-lg font-bold">Your Wishlist</h3>
                    <div className="flex justify-between gap-2 pt-5 md:pt-0">
                        {
                            activeTab !== 0 && <button
                                className="px-3 py-2 border-2 border-[#FF913F] rounded-full font-medium"
                                onClick={() => handleDeleteCategory(selectedCategoryId)}
                            >
                                Delete Category
                            </button>
                        }

                        <CreateWishlist/>
                        <div>
                            {selectedCategoryId !== null && selectedCategoryId !== 0 && (
                                <AddEventCategory allEvents={all} selectedCategoryId={selectedCategoryId}
                                                  categories={categories}/>
                            )}
                        </div>
                    </div>
                </div>
                <div className="my-10">
                    <div className="flex justify-between border-b-2">
                        <TabComponent titles={tabCategories} activeTab={activeTab} handleTabClick={handleTabClicked}/>
                    </div>
                    {selectedCategoryId === null || selectedCategoryId === 0 ? (
                        <div className="md:grid md:grid-cols-4 gap-10 my-10">
                            {all.map((event: IEventsData) => (
                                <Link to={`/eventDetails/${event.id}`} key={event.id}>
                                    <EventCard key={event.id} event={event}/>
                                </Link>
                            ))}
                        </div>
                    ) : selectedCategoryId && selectedCategoryId !== 0 && categories.find(category => category.id === selectedCategoryId)?.user_liked_events.length === 0 ? (
                        <div className="h-[50vh] flex justify-center items-center">
                            <div className="text-center text-2xl text-[#FF913F] font-bold">
                                {t('events')}
                            </div>
                        </div>
                    ) : (
                        <div className="md:grid md:grid-cols-4 gap-10">
                            {categories
                                .find((category) => category.id === selectedCategoryId)
                                ?.user_liked_events.map((eventType: IEventUserType) => (
                                    <Link to={`/eventDetails/${eventType.event.id}`} key={eventType.event.id}>
                                        <EventCard
                                            key={eventType.event.id}
                                            event={eventType.event}
                                            removeFromCategory={() => handleRemoveFromCategory(eventType.event.id)}
                                        />
                                    </Link>
                                ))}
                        </div>
                    )}
                </div>
                {auth.token && selectedCategoryId === null && all.length === 0 ? (
                    <div className="grid place-items-center pb-20 empty">
                        <img loading="lazy" src="/assets/images/empty.png" alt="Empty Wishlist"/>
                        <h3 className="2xl:text-xl text-lg font-bold 2xl:px-7 px-4">Your Wishlist is Empty</h3>
                        <p className="text-[#131C23]/40 font-medium pt-4">Save activities to your wishlist by
                            clicking on the heart icon.</p>
                        <button
                            className="items-center flex justify-center rounded-full bg-[#FF913F] text-white text-center px-14 py-2 mt-3"
                            onClick={handleFindThingsClick}
                        >
                            <span className="px-1 font-medium">Explore for more</span>
                            <img loading="lazy" src="/assets/svgs/common/white-arrow.svg" className="w-4" alt="Find Things"/>
                        </button>
                    </div>
                ) : null}
                {!auth.token ? (
                    <div className="grid place-items-center pb-20 empty">
                        <p className="text-[#131C23]/60 font-medium pt-4 text-xl">You need to Log in to see your
                            wishlist.</p>
                    </div>
                ) : null}
            </div>
        </>
    )
}
export default WishList;