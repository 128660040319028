import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import LockIcon from '@mui/icons-material/Lock';
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store/store";
import {useTranslation} from "react-i18next";
const LevelAchievements = () => {
    const userLevelAchievements = useSelector((state: RootState) => state.userDashboardReducer?.gamificationData?.achievments);
    const isActive = true
    const {t}=useTranslation();
    return (
        <div className={"bg-[#f3f3f4] w-full  rounded-xl px-4 py-4"}>
            <div className="flex gap-2">
                <p className={"font-bold text-[20px]"}>{t('userProfile.gamification.levelAchievements.title')}</p>
                <img loading="lazy" src="/assets/svgs/common/info-circle.svg" className="ml-3 " />
            </div>
            <div className={"grid md:grid-cols-3 grid-cols-2 gap-4 mt-5"}>
                {
                    userLevelAchievements?.map((level, index) => (
                        level.is_achieved ? (
                            <div key={index ?? null} className={"grid py-3 px-1 relative flex-col  bg-white h-full rounded-[30px] justify-center text-center items-center"}>
                                <CheckCircleIcon sx={{ color: '#52E0AB' }} className={"absolute -top-2 right-0"} />
                                <img className='w-full' src={level.image} />
                                <p className={" text-[#898d91] text-sm"}>{level.name}: <b className={"text-black"}>{level.description}</b></p>
                            </div>
                        ) : (
                            <div key={index ?? null} className={"grid py-3 px-[0.5px] relative bg-white opacity-40 rounded-[30px] justify-center text-center items-center"}>
                                <LockIcon sx={{ color: 'black' }} className={"absolute -top-2 right-0"} />
                                <img loading="lazy" className='w-full' src={level.image} />
                                <p className={" text-[#898d91] text-sm"}>{level.name}: <b className={"text-black"}>{level.description}</b></p>
                            </div>
                        )


                    ))
                }
            </div>
        </div>
    )
}
export default LevelAchievements
