import moment from "moment";
import React, { useEffect, useState } from "react";
import { Calendar } from "react-multi-date-picker";
import { getHistoryEvents } from "../../../../../store/BusinessDashboard/ActionBusinessDashboard";
import { useAppDispatch } from "../../../../../store/redux";

export const EventSection = () => {
  const [selectedDate, setSelectedDate] = useState("");
  const [events,setEvents] = useState([]);
  const dispatch = useAppDispatch()
  useEffect(() => {
    const date = new Date();
    setSelectedDate(moment(date).format("YYYY-MM-DD"));

  }, []);
  useEffect(() => {
    if(selectedDate)
    {
      dispatch(getHistoryEvents(selectedDate)).then((res)=>{
        setEvents(res);
       })
    }
   
  }, [selectedDate]);
  return (
    <div className="rounded-xl border p-5">
      <div className="mb-2 flex items-center">
        <h3 className="text-xl font-bold">Event Selection</h3>
      </div>
      <div className="grid grid-cols-1 gap-3 xl:grid-cols-2">
        <Calendar
          className="!z-0"
          value={selectedDate}
          onChange={(event: any) => {
            setSelectedDate(moment(new Date(event)).format('YYYY-MM-DD'))
          }}
        />
        <div
          style={{ boxShadow: "0 0 5px #FF9C52" }}
          className="max-h-[350px] overflow-y-scroll rounded-xl"
        >
         {events?.length===0 ? <>
         <p className="text-primary-orange font-medium p-5">No Events Found</p>
         </> :
         <>
         {events.map((item:any)=>(
          <div className="grid grid-cols-[3fr_9fr] gap-2 m-3 p-2 border border-primary-orange rounded-lg">
            <img className="w-[75px] h-[75px] rounded-lg" src={item?.image} />
            <div>
              <p className="text-primary-orange font-medium">{item.title}</p>
              <div className="flex gap-3 items-center mt-2">
              <p>Ticket Sold: x{item.total}</p>
              <p className="border px-3 py-1 text-sm border-black rounded-full w-fit ">{item.time}</p>
              </div>
            </div>
          </div>
         ))}
         </>} 
        </div>
      </div>
    </div>
  );
};
