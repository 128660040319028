import {TSvgComponent} from "./types";

const LightningSvg: TSvgComponent = ({...props}) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M9.83333 1.33335C9.83333 1.11302 9.68911 0.918655 9.47823 0.854806C9.26735 0.790957 9.03953 0.872673 8.91731 1.056L3.58398 9.056C3.48169 9.20943 3.47215 9.4067 3.55916 9.56928C3.64617 9.73186 3.8156 9.83335 4 9.83335H6.83333V14.6667C6.83333 14.887 6.97756 15.0814 7.18844 15.1452C7.39932 15.2091 7.62714 15.1274 7.74936 14.944L13.0827 6.94404C13.185 6.79061 13.1945 6.59333 13.1075 6.43076C13.0205 6.26818 12.8511 6.16669 12.6667 6.16669H9.83333V1.33335Z"
                />
        </svg>
    )
}
export default LightningSvg
