import React, {useEffect, useState} from "react";
import OrderConfirmation from "../OrderConfirmation";
import {useSelector} from "react-redux";
import {fetchCartItemCount, getCartItems} from "../../../../store/Cart/ActionCart";
import {RootState} from "../../../../store/store";
import {useAppDispatch} from "../../../../store/redux";
import {TAges} from "../../../../store/Cart/types";
import {deleteEventFromCart} from '../../../../store/Cart/ActionCart'
import moment from "moment";
import {useTranslation} from "react-i18next";
import {scrollToTop} from "../../../../store/GlobalState/ActionGlobalState";
import {useNavigate} from "react-router-dom";

interface CartItem {
    id: number;
    total: number;
    expires_at: string;
    service_fees:number;
    price_without_commission:number;
}

const CartItems = () => {
    const dispatch = useAppDispatch();
    const {t} = useTranslation();
    const navigate = useNavigate()
    const selectedCurrency = useSelector((state: RootState) => state.currenciesReducer.selectedCurrency);
    const cartItem = useSelector((state: RootState) => state.cartReducer.cartItem);
    const cartItemsData = cartItem.data || [];
    const [selectedItems, setSelectedItems] = useState<CartItem[]>([]);
    const [selectedTotal, setSelectedTotal] = useState(0);
    const[totalPriceWithoutFees,selectedTotalPriceWithoutFees]=useState(0);
    const [totalFees, selectedTotalFees] = useState(0);
    const [timers, setTimers] = useState<{ [key: number]: string }>({});

    useEffect(() => {
        dispatch(getCartItems());
    }, [dispatch]);
    useEffect(() => {
        dispatch(scrollToTop);
    }, [])
    useEffect(() => {
        setSelectedTotal(calculateTotal());
        selectedTotalFees(calculateTotalFees());
        selectedTotalPriceWithoutFees(calculatePriceWithoutCommission());
        
    }, [selectedItems]);

    const calculateTotal = () => {
        let total = 0;
        selectedItems.forEach((item) => {
            total += item.total;
        });
        return total;
    };
    const calculateTotalFees = () => {
        let total = 0;
        selectedItems.forEach((item) => {
            total += item.service_fees;
        });
        return total;
    };
    const calculatePriceWithoutCommission = () => {
        let total = 0;
        selectedItems.forEach((item) => {
            total += item.price_without_commission;
        });
        return total;
    };
    const handleDeleteEventFromCart = (eventId: number) => {
        dispatch(deleteEventFromCart(eventId));
        dispatch(fetchCartItemCount());
    };
    function calculateHoldSpot(expires_at: string, itemId: number) {
        const currentTime = moment();
        const expirationTime = moment(expires_at);
        const duration = moment.duration(expirationTime.diff(currentTime));

        if (duration.asSeconds() <= 0) {
            setTimers((prevTimers) => ({
                ...prevTimers,
                [itemId]: "0 minutes 0 seconds",
            }));
            // delete the item from the cart
            handleDeleteEventFromCart(itemId);
        } else {
            const minutes = duration.minutes();
            const seconds = duration.seconds();
            const holdSpotTime = `${minutes} minutes ${seconds} seconds`;
            setTimers((prevTimers) => ({
                ...prevTimers,
                [itemId]: holdSpotTime,
            }));
            return holdSpotTime;
        }
    }
    const handleCardClick = (item: CartItem) => {
        const isSelected = selectedItems.some(
            (selectedItem) => selectedItem.id === item.id
        );
        if (isSelected) {
            setSelectedItems(selectedItems.filter((selectedItem) => selectedItem.id !== item.id));
        } else {
            setSelectedItems([...selectedItems, item]);
        }
    };

    useEffect(() => {
        const updateHoldSpot = () => {
            cartItemsData?.forEach((item: any) => {
                const holdSpotTime = calculateHoldSpot(item.expires_at, item.id);
                if (holdSpotTime === "0 minutes 0 seconds") {
                    handleDeleteEventFromCart(item.id);
                }
            });
        };
        updateHoldSpot();
        const intervalId = setInterval(updateHoldSpot, 1000);
        return () => {
            clearInterval(intervalId);
        };
    }, [cartItemsData]);

    return (
        <div className="container mt-10">
            <div className="flex items-center">
                <h3 className="text-[#131C23] font-bold text-xl"> {t('shopCart.shoppingCart')} </h3>
            </div>
            <div className="xl:grid xl:grid-cols-[3fr_1fr] py-10">
                <div className="">
                    {
                        cartItemsData.length > 0 ? cartItemsData?.map((item: any) => (
                            <article
                                key={item.id}
                                className={`my-10 md:grid md:grid-cols-[1fr_4fr] md:my-5 rounded-xl bg-white shadow-md cursor-pointer hover:bg-[#FF913F]/5 ${
                                    selectedItems.some((selectedItem) => selectedItem.id === item.id) ? "clicked" : ""
                                }`}
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleCardClick(item);
                                }}                            >
                                <div className="relative">
                                    {
                                        item.type && (
                                            <div className="absolute top-0 left-0 px-3 pt-4">
                                                <button
                                                    className="backdrop-blur-xl text-[#131C23] m-2 px-5 py-1 rounded-full text-xs font-medium bg-white">{item.type}</button>
                                            </div>
                                        )
                                    }
                                    <img loading="lazy" src={item.event.images?.[0]?.image_path} alt="event"
                                         className="max-md:max-h-[200px] h-full w-full object-cover object-center rounded-xl"/>
                                </div>
                                <div className="details p-5 rounded-r-3xl">
                                    <div className="flex items-center justify-between">
                                        {item.event.location && (
                                            <div className="flex items-center">
                                                <img loading="lazy" src="/assets/svgs/events/location.svg" alt="icon"
                                                     className="w-5 h-auto"/>
                                                <p className="text-sm">{item.event.location}</p>
                                            </div>
                                        )}
                                        <div>
                                            <button onClick={() => handleDeleteEventFromCart(item.id)}>
                                                <img loading="lazy" src="/assets/svgs/common/trash.svg"/>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="md:flex justify-between">
                                        <p className="text-[#131C23] font-bold text-xl pb-1">{item.event.title}</p>
                                        <div
                                            className="rounded-full bg-[#FF585F]/20 px-5 flex items-center py-2 text-xs w-1/2 md:w-fit justify-center space-x-1">
                                            <p className="text-[#FF585F] hidden md:block">{t('shopCart.holdSpot')}: </p>
                                            <span className="font-bold text-[#FF585F]">
                                                 {timers[item.id]}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="flex text-md pt-3">
                                        {item.reviews?.length > 0 ? (
                                            <div className="flex space-x-2">
                                                <span className="text-black font-bold text-md">
                                                        {item.reviews.length}+
                                                </span>
                                                <span className="text-[#888D90] px-2 text-md">{t('reviews')}</span>
                                                <img loading="lazy" src="/assets/svgs/events/star.svg" alt="review"/>
                                                <span className="text-black font-bold text-md">
                                                  {/*{item.reviews[0].stars}*/}
                                                </span>
                                            </div>
                                        ) : (
                                            <p>{t('noReviews')}</p>
                                        )}
                                    </div>
                                    <div className="md:flex items-center justify-between pt-2">
                                        <div className="flex text-sm py-1 md:py-0">
                                            <img loading="lazy" src="/assets/svgs/common/durations.svg"/>
                                            <span className="text-[#888D90] px-1">{t('duration')}</span>
                                            <p className="font-bold text-[#131C23]">{item.event.duration} {t('hours')}</p>
                                        </div>
                                        <div className="flex text-sm md:px-1 py-1 md:py-0">
                                            <img loading="lazy" src="/assets/svgs/common/language.svg"/>
                                            <span className="text-[#888D90] px-1">{t('language')}</span>
                                            <p className="font-bold text-[#131C23]">{item.event.language}</p>
                                        </div>
                                        <div className="flex text-sm md:px-1 py-1 md:py-0">
                                            <img loading="lazy" src="/assets/svgs/common/durations.svg"/>
                                            <span className="text-[#888D90] px-1">{t('visitingTime')}:</span>
                                            <p className="font-bold text-[#131C23]"> {moment(item.time, "HH:mm:ss").format("HH:mm A")}</p>
                                        </div>
                                    </div>
                                    <div className="md:flex grid grid-cols-2 items-baseline gap-2 md:items-center md:space-x-5 space-y-2 md:space-y-0 text-sm my-2">
                                        {
                                            item?.event_age.map((event: TAges) => (
                                                <div
                                                    className="flex items-center justify-between px-3 py-2 bg-[#131C23]/10 rounded-lg text-sm">
                                                    <img loading="lazy" src="/assets/svgs/common/user.svg" className="w-4"/>
                                                    <p className="text-[#888D90] px-2">{event.age?.label}</p>
                                                    <p className="font-bold text-[#131C23]">{event.quantity} </p>
                                                </div>
                                            ))}
                                        <div className="flex items-center justify-between text-sm px-3 py-2 bg-[#131C23]/10 rounded-lg">
                                            <img loading="lazy" src="/assets/svgs/common/date.svg" className="w-4"/>
                                            <p className="text-[#888D90] px-2">{t('date')}:</p>
                                            <p className="font-bold text-[#131C23]">{item.date} </p>
                                        </div>
                                    </div>
                                    <div
                                        className="flex text-[#131C23] rounded-xl p-2 justify-between border-2 border-[#131C23]/10 mt-4">
                                        <p className="font-base text-base text-[#131C23]/60">
                                            {t('total')}:
                                        </p>
                                        <span
                                            className="text-black text-base font-bold"> {selectedCurrency.symbol} {(item.price_without_commission * selectedCurrency.exchange_rate).toFixed(2)} </span>
                                    </div>
                                </div>
                            </article>
                        )) : <>
                            <div className="grid place-items-center pb-20 empty">
                                <img loading="lazy" src="/assets/images/empty.png" alt="Empty data"/>
                                <h3 className="2xl:text-xl text-lg font-bold 2xl:px-7 px-4"> {t('shopCart.shoppingCartEmpty')}</h3>
                                <p className="text-[#131C23]/40 font-medium pt-4">{t('shopCart.fillYourCart')}</p>
                                <button
                                    className="items-center flex justify-center rounded-full bg-[#FF913F] text-white text-center px-14 py-2 mt-3"
                                    onClick={() => navigate('/allEvents/large')}
                                >
                                    <span className="px-1 font-medium">{t('exploreMore')}</span>
                                    <img loading="lazy" src="/assets/svgs/common/white-arrow.svg" className="w-4" alt="Find Things"/>
                                </button>
                            </div>
                        </>
                    }
                </div>
                <div>
                    <OrderConfirmation priceWithoutFees={totalPriceWithoutFees} fees={totalFees} selectedTotal={selectedTotal} selectedItems={selectedItems}/>
                </div>
            </div>
        </div>
    )
}
export default CartItems;