import {
    SignIn,
    SignUpAPI,
    getInterestAPI,
    getNotficationsAPI,
    logoutUserAPI,
    resendOTPAPI,
    setInterestUserAPI,
    toggleNotificationAPI,
    updatePasswordAPI,
    updateUserProfileAPI,
    verifyEmailAPI,
    forgotPasswordEmailAPI,
    checkOtpAPI,
    createNewPasswordAPI,
    GenerateGuestTokenAPI,
    loginWithGoogleAPI,
    loginWithAppleAPI
} from "../../networking/auth/auth";
import {UserData} from "../../networking/auth/types";
import {
    setSnackBarMessage,
    setSnackBarType,
    toggleShowButtonLoader,
    toggleShowSnackBar
} from "../GlobalState/ActionGlobalState";
import {GlobalStateSLice} from "../GlobalState/GlobalStateSlice";
import {AppDispatch} from "../store";
import {authSlice} from "./AuthSlice";
import {UpatePassword, UserLogin} from "./types";

// import {fetchGoogleMapEvents} from "../../networking/events/events";

export const userSignUp = (loginPayload: UserData) => async (dispatch: AppDispatch) => {
    try {
        dispatch(toggleShowButtonLoader(true))
        const response = await SignUpAPI(loginPayload);
        dispatch(authSlice.actions.setToken(response.data.token ?? null));
        dispatch(authSlice.actions.setUserData(response.data?.user ?? null));
        dispatch(authSlice.actions.setUserRole(response.data?.role ?? null));
        localStorage.setItem('token', response.data?.token ?? '')
        localStorage.setItem('userInfo', JSON.stringify(response.data?.user) ?? '')
        localStorage.setItem('userRole', JSON.stringify(response.data?.role) ?? '')
        dispatch(authSlice.actions.setErrors({}));
    } catch (error: any) {
        dispatch(authSlice.actions.setErrors(error?.response?.data.errors));
        throw error
    } finally {
        dispatch(toggleShowButtonLoader(false))
    }
};

export const getInterests = () => async (dispatch: AppDispatch) => {
    try {
        const response = await getInterestAPI();
        dispatch(authSlice.actions.setInterests(response));
    } catch (error: any) {
     throw error
    } finally {
    }
};

export const GenerateGuestToken = () => async (dispatch: AppDispatch) => {
    try {
        const response = await GenerateGuestTokenAPI();
        dispatch(GlobalStateSLice.actions.setSnackBarType('success'))
        dispatch(GlobalStateSLice.actions.toggleShowSnackBar(true))
        dispatch(GlobalStateSLice.actions.setSnackBarMessage('You are now logged in as guest'))
        return response
    } catch (error: any) {
     throw error
    } finally {
    }
};

export const setInterestUser= (payload:number[]) => async (dispatch: AppDispatch) => {
    try {
        const response = await setInterestUserAPI(payload);
        dispatch(authSlice.actions.setInterests(response));
    } catch (error: any) {
     throw error
    } finally {
    }
};

export const userLogin = (loginPayload: UserLogin) => async (dispatch: AppDispatch) => {
    try {
        dispatch(toggleShowButtonLoader(true))
       const response = await SignIn(loginPayload);
        if (response.user.email_verified_at == null) {
            dispatch(authSlice.actions.setToken(response?.token ?? null));
            dispatch(authSlice.actions.setVerificationModal(true));
            dispatch(authSlice.actions.setUserData(response?.user ?? null));
            localStorage.setItem('token', response?.token ?? '')
            localStorage.setItem('userInfo', JSON.stringify(response?.user) ?? '')
            localStorage?.removeItem('clientToken')
        } else {
            dispatch(authSlice.actions.setToken(response?.token ?? null));
            dispatch(authSlice.actions.setUserData(response?.user ?? null));
            dispatch(authSlice.actions.setUserRole(response?.role ?? null));
            localStorage.setItem('token', response?.token ?? '')
            localStorage.setItem('userInfo', JSON.stringify(response?.user) ?? '')
            localStorage.setItem('userRole', JSON.stringify(response?.role) ?? '')
            localStorage?.removeItem('clientToken')
        }

    } catch (error: any) {
        dispatch(toggleShowSnackBar(true))
        dispatch(setSnackBarType("error"));
        dispatch(
            setSnackBarMessage(error?.response?.data.message ?? error?.message),
        );
        throw error
    } finally {
        dispatch(toggleShowButtonLoader(false))
    }
};

export const loginWithGoogle = (data:{token:string,email:string,id:string,name:string}) => async (dispatch: AppDispatch) => {
    try {
        dispatch(toggleShowButtonLoader(true));
        const { token, user ,role } = await loginWithGoogleAPI(data);

        dispatch(authSlice.actions.setToken(token));
        dispatch(authSlice.actions.setUserData(user));
        dispatch(authSlice.actions.setUserRole(role));

        localStorage.setItem('token', token);
        localStorage.setItem('userInfo', JSON.stringify(user));
        localStorage.setItem('userRole', JSON.stringify(role));
        localStorage?.removeItem('clientToken')
    } catch (error:any) {
        dispatch(authSlice.actions.setErrors(error.toString()));
    } finally {
        dispatch(toggleShowButtonLoader(false));
    }
};
export const loginWithApple = (data:{token:string,id:string, email?: string, name?: string}) => async (dispatch: AppDispatch) => {
    try {
        dispatch(toggleShowButtonLoader(true));
        const { token, user ,role } = await loginWithAppleAPI(data);

        dispatch(authSlice.actions.setToken(token));
        dispatch(authSlice.actions.setUserData(user));
        dispatch(authSlice.actions.setUserRole(role));

        localStorage.setItem('token', token);
        localStorage.setItem('userInfo', JSON.stringify(user));
        localStorage.setItem('userRole', JSON.stringify(role));
        localStorage?.removeItem('clientToken')
    } catch (error:any) {
        dispatch(authSlice.actions.setErrors(error.toString()));
    } finally {
        dispatch(toggleShowButtonLoader(false));
    }
};
export const verifyEmail = (otp: string) => async (dispatch: AppDispatch) => {
    try {
        dispatch(toggleShowButtonLoader(true))
        const response = await verifyEmailAPI(otp);
    } catch (error) {
        throw error
    } finally {
        dispatch(toggleShowButtonLoader(false))
    }
};
export const resendOTP = () => async (dispatch: AppDispatch) => {
    try {
        const response = await resendOTPAPI();
    } catch (error) {
        throw error
    } finally {
    }
};
export const logoutUser = () => async (dispatch: AppDispatch) => {
    try {
        const response = await logoutUserAPI();
        dispatch(authSlice.actions.setToken(null));
        dispatch(authSlice.actions.setUserData(null));
        dispatch(authSlice.actions.setUserRole(null));
        localStorage.removeItem('token')
        localStorage.removeItem('userInfo')
        localStorage.removeItem('userRole')

    } catch (error) {
        throw (error)
    } finally {
    }
};
export const updateUserProfile = (updateProfileData: any) => async (dispatch: AppDispatch) => {
    try {
        dispatch(toggleShowButtonLoader(true))
        const formData = new FormData()
        Object.keys(updateProfileData).map((key) => {
            if (key === 'profile_pic' && typeof updateProfileData[key] === 'string') {

            } else formData.append(key, updateProfileData[key])
        })
        const response = await updateUserProfileAPI(formData);
        localStorage.setItem('userInfo', JSON.stringify(response) ?? '')
        dispatch(GlobalStateSLice.actions.toggleShowSnackBar(true))
        dispatch(GlobalStateSLice.actions.setSnackBarMessage('Profile Updated Successfully'))
        dispatch(authSlice.actions.setUserData(response ?? null));

    } catch (error: any) {
        dispatch(GlobalStateSLice.actions.toggleShowSnackBar(true))
        dispatch(GlobalStateSLice.actions.setSnackBarType('error'))
        console.log(error,'error')
        dispatch(GlobalStateSLice.actions.setSnackBarMessage(error.response.data.message))
    } finally {
        dispatch(toggleShowButtonLoader(false))
    }
}
export const updateUserPassword = (updatePassword: UpatePassword) => async (dispatch: AppDispatch) => {
    try {

        dispatch(toggleShowButtonLoader(true))
        const response = await updatePasswordAPI(updatePassword);
        dispatch(GlobalStateSLice.actions.toggleShowSnackBar(true))
        dispatch(GlobalStateSLice.actions.setSnackBarMessage('Password Updated Successfully'))
    } catch (error) {
        throw error
    } finally {
        dispatch(toggleShowButtonLoader(false))
    }
}
export const getNotficationList = () => async (dispatch: AppDispatch) => {
    try {
        const response = await getNotficationsAPI();
        dispatch(authSlice.actions.setNotificationList(response))
    } catch (error) {
        throw error
    } finally {

    }
}
export const toggleNotification = (notificationId: number) => async (dispatch: AppDispatch) => {
    try {
        const response = await toggleNotificationAPI(notificationId);
    } catch (error) {
        throw error
    } finally {

    }
}
export const toggleVerificationModal = (boolean: boolean) => async (dispatch: AppDispatch) => {
    try {
        dispatch(authSlice.actions.setVerificationModal(boolean))
    } catch (error) {
        throw error
    } finally {

    }
}
export const toggleVerificationModalPassword = (boolean: boolean) => async (dispatch: AppDispatch) => {
    try {
        dispatch(authSlice.actions.setVerificationModalPassword(boolean))
    } catch (error) {
        throw error
    } finally {

    }
}

export const setAuthModal = (openAuth: boolean) => async (dispatch: AppDispatch) => {
    try {
        dispatch(authSlice.actions.setOpenAuthModal(openAuth))
    } catch (error) {
        throw error
    } finally {

    }
}
export const setActiveTab = (activeTab: string) => async (dispatch: AppDispatch) => {
    try {
        dispatch(authSlice.actions.setActiveTab(activeTab))
    } catch (error) {
        throw error
    } finally {

    }
}

export const setShowSignUpContent = (showSignUp: boolean) => async (dispatch: AppDispatch) => {
    try {
        dispatch(authSlice.actions.setSignUpContent(showSignUp))
    } catch (error) {
        throw error
    } finally {

    }
}
export const setShowSignInContent = (showSignIn: boolean) => async (dispatch: AppDispatch) => {
    try {
        dispatch(authSlice.actions.setShowSignInContent(true))
    } catch (error) {
        throw error
    }
}
export const setShowSendEmailRequest = (showSendEmailRequest: boolean) => async (dispatch: AppDispatch) => {
    try {
        dispatch(authSlice.actions.setShowSendEmailContent(showSendEmailRequest))
    } catch (error) {
        throw error
    } finally {

    }
}

export const setShowForgotPasswordContent = (showForgotPassword: boolean) => async (dispatch: AppDispatch) => {
    try {
        dispatch(authSlice.actions.setForgotPasswordContent(showForgotPassword))
    } catch (error) {
        throw error
    } finally {

    }
}
export const setShowVerificationEmailContent = (showVerification: boolean) => async (dispatch: AppDispatch) => {
    try {
        dispatch(authSlice.actions.setVerificationModal(showVerification))
    } catch (error) {
        throw error
    } finally {

    }
}
export const setShowVerificationPassword = (showVerification: boolean) => async (dispatch: AppDispatch) => {
    try {
        dispatch(authSlice.actions.setVerificationModalPassword(showVerification))
    } catch (error) {
        throw error
    } finally {

    }
}
export const setShowResetPassword = (showResetPassword: boolean) => async (dispatch: AppDispatch) => {
    try {
        dispatch(authSlice.actions.setShowResetPasswordModal(showResetPassword))
    } catch (error) {
        throw error
    } finally {

    }
}

export const forgotPasswordEmail = (email: string) => async (dispatch: AppDispatch) => {
    try {
        await forgotPasswordEmailAPI(email);
    } catch (error) {
        throw error
    } finally {
        dispatch(toggleShowButtonLoader(false))
    }
};

export const checkOtp = (otp: string, email: string) => async (dispatch: AppDispatch) => {
    try {
        dispatch(toggleShowButtonLoader(true))
        await checkOtpAPI(otp, email);
    } catch (error) {
        throw error
    } finally {
        dispatch(toggleShowButtonLoader(false))
    }
};

export const createNewPassword = (password: string,password_confirmation:string, email: string,otp:string) => async (dispatch: AppDispatch) => {
    try {
        dispatch(toggleShowButtonLoader(true))
        await createNewPasswordAPI(password,password_confirmation, email,otp);
    } catch (error) {
        throw error
    } finally {
        dispatch(toggleShowButtonLoader(false))
    }
};
