
import {useSelector} from "react-redux";
import {RootState} from "../../../store/store";
import SignUpClient from "./SignUp/SignUpClient";
import SignUpBusiness from "./SignUp/SignUpBusiness";
import LogIn from "./LogIn";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import {useAppDispatch} from "../../../store/redux";
import {setShowForgotPasswordContent, setShowSignUpContent} from "../../../store/Auth/ActionAuth";
const style = {
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: 6,
    width: 500
};
const AuthComponent = ({onClose, openModal}: { onClose: () => void, openModal: boolean }) => {
    const isLoggingIn = useSelector((state: RootState) => state.authReducer.isLoggingIn);
    const role = useSelector((state: RootState) => state.authReducer.role);
    const showSignUpContent = useSelector((state: RootState) => state.authReducer.showSignUpContent);
    // const [activeTab, setActiveTab] = useState<"client" | "business">("client");
    // const [showSignUpContent, setShowSignUpContent] = useState(false);
    const activeTab = useSelector((state: RootState) => state.authReducer.activeTab);

    const dispatch = useAppDispatch()
    const handleClose = () => {
        onClose();
    };

    return (
        <Modal
            open={openModal}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                {isLoggingIn ? (
                    <LogIn
                        activeTab={activeTab}
                        onClose={onClose}
                        role={role}
                        isLoggingIn={isLoggingIn}
                        open={true}
                        onSignUpClick={() => dispatch(setShowSignUpContent(true))}
                        onForgotPasswordClick={() => dispatch(setShowForgotPasswordContent(true))}
                    />
                ) : (
                    showSignUpContent ? (
                        activeTab === "client" ? (
                            <SignUpClient onLoginCLick={() => dispatch(setShowSignUpContent(false))}
                                          handleRegisterUser={handleClose} activeTab={activeTab} isClient={true}/>
                        ) : (
                            <SignUpBusiness onLoginCLick={() => dispatch(setShowSignUpContent(false))}
                                            handleRegisterUser={handleClose} activeTab={activeTab} isClient={false}/>
                        )
                    ) : (
                        <LogIn
                            activeTab={activeTab}
                            onClose={onClose}
                            role={role}
                            isLoggingIn={isLoggingIn}
                            open={true}
                            onSignUpClick={() => setShowSignUpContent(true)}
                            onForgotPasswordClick={() => dispatch(setShowForgotPasswordContent(true))}
                        />
                    )
                )}
            </Box>
        </Modal>
    );
};
export default AuthComponent;
