import { BrowserRouter, Routes, Route } from "react-router-dom";
import Unauthorized from "../pages/ErrorHandeling/401";
import PageNotFound from "../pages/ErrorHandeling/404";
import TooManyRequests from "../pages/ErrorHandeling/429";
import HomePage from "../pages/Home";
import Footer from "../component/Footer/index";
import ProfileSettings from "../pages/Dashboard/UserProfile/ProfileSettings";
import Layout from "../pages/Dashboard/Layout";
import History from "../pages/Dashboard/UserProfile/History";
import EventDetails from "../pages/EventDetails/index"
import Gamification from "../pages/Dashboard/UserProfile/Gamification";
import Token from "../pages/Dashboard/UserProfile/Token";
import RefferalProgram from "../pages/Dashboard/UserProfile/RefferalProgram";
import Ticket from "../pages/Dashboard/UserProfile/Ticket";
// import SignIn from '../pages/Authtentication/AuthenticationClient/SignIn/index'
import SeeAllEvents from "../pages/AllEvents/SeeAllEvents";
import Wishlist from "../pages/Dashboard/Wishlist";
import ProfileOverview from "../pages/Dashboard/BusinessProfile/ProfileOverview";
import Payment from "../pages/Payment";
import PartnersRecommendations from "../pages/PartnersRecommendations";
import BusinessProfileSettings from "../pages/Dashboard/BusinessProfile/BusinessProfileSettings";
import EventCreation from "../pages/Dashboard/BusinessProfile/EventCreation";
import EventList from "../pages/Dashboard/BusinessProfile/EventList";
import CartItems from "../pages/Payment/ShoppingCart/CartItems";
import Checkout from "../pages/Payment/Checkout";
import GuestCheckout from "../pages/Payment/GuestCheckout";
import { BusinessRoute, ClientRoute } from "./ProtectedRoutes";
import TicketConfirmation from "../pages/Dashboard/UserProfile/Ticket/TicketConfirmation";
import Maintenance from "../pages/ErrorHandeling/503";
import TermsAndCondition from "../pages/TermsAndCondition";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import AboutUs from "../pages/AboutUs"
import SeeAllPromotionalEvents from "../pages/AllEvents/SeeAllPromotionalEvents";
import { BusinessNotification } from "../pages/Dashboard/BusinessProfile/BusinessNotification";
import { BusinessDocument } from "../pages/Dashboard/BusinessProfile/BusinessDocument";
import OurMission from "../pages/Home/components/OurMission/OurMission";
import {useSelector} from "react-redux";
import {RootState} from "../store/store";
import React from "react";
import Crowdfunding from "../pages/Crowdfunding";
function AppRouter() {
  const translations = useSelector((state: RootState) => (state.termsReducer as any).translations);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="401" element={<Unauthorized />} />
        <Route path="503" element={<Maintenance />} />
        <Route path="429" element={<TooManyRequests />} />

        <Route path="/" element={<HomePage />} />
        <Route path="/crowdfunding" element={<Crowdfunding />}/>
        <Route path="/allEvents/:eventType/:categoryId?" element={<SeeAllEvents filters />} />
        <Route path="/allPromotionalEvents" element={<SeeAllPromotionalEvents filters />} />

        <Route
          path="/eventDetails/:eventId/:pageNumber?"
          element={<EventDetails />}
        />

        <Route
          path="/partnersRecommendations"
          element={<PartnersRecommendations />}
        />
         <Route
          path="/ticket-confirmation"
          element={<TicketConfirmation />}
        />
        <Route
          path="/terms-and-condition/"
          element={<TermsAndCondition />}
        />
         <Route
          path="/privacy-policy"
          element={<PrivacyPolicy />}
        />
        <Route
            path="/our-mission"
            element={<OurMission/>}
        />
        <Route
            path="/aboutUs"
            element={<AboutUs />}
        />
          <Route element={<Payment />}>
            <Route path="/guest-checkout" element={<GuestCheckout />} />
               <Route path="/payment" element={<CartItems />} />
            <Route path="/checkout" element={<Checkout />} />
          </Route>
        <Route element={<ClientRoute />}>
          <Route element={<Layout type="userDashboard" />}>
            <Route path="/profile-settings" element={<ProfileSettings />} />
            <Route path="/history" element={<History />} />
            <Route path="/gamification" element={<Gamification />} />
            <Route path="/token" element={<Token />} />
            <Route path="/referral-program" element={<RefferalProgram />} />
            <Route path="/tickets" element={<Ticket />} />
            {/* Business Profile*/}
          </Route>
          <Route element={<Payment />}>
            <Route path="/wishlist" element={<Wishlist />} />
            <Route path="/payment" element={<CartItems />} />
            <Route path="/checkout" element={<Checkout />} />
            <Route path="/guest-checkout" element={<GuestCheckout />} />
          </Route>
        </Route>
        <Route element={<BusinessRoute />}>
          <Route element={<Layout type="businessDashboard" />}>
            <Route path="/profile-overview" element={<ProfileOverview />} />
            <Route
              path="/business-profile-settings"
              element={<BusinessProfileSettings />}
            />
            <Route path="/event-creation" element={<EventCreation />} />
            <Route path="/update-event/:eventId" element={<EventCreation />} />
            <Route path="/event-list" element={<EventList />} />
            <Route path="/notifications" element={<BusinessNotification />} />
            <Route path="/documents" element={<BusinessDocument />} />
          </Route>
        </Route>
        <Route path="*" element={<PageNotFound />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default AppRouter;
