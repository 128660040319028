import React, {useEffect, useState} from "react";
import Box from '@mui/material/Box';
import Rating from '@mui/material/Rating';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import {useAppDispatch, useAppSelector} from "../../../store/redux";
import {
    setCurrentEventId,
    setCurrentReviewPage,
    setEventDetails,
    setReviews
} from "../../../store/EventDetails/EventDetailsSlice";
import Pagination from '@mui/material/Pagination';
import {format} from 'date-fns';
import {createEventReview, getEventDetails} from "../../../store/EventDetails/ActionEventDetails";
import {TEventReview} from "../../../store/EventDetails/types";
import {useNavigate, useParams} from "react-router-dom";
import {getEventDetailsAxios} from "../../../networking/eventDetails/eventDetails";
import {likeReview} from "../../../store/EventDetails/ActionEventDetails";
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import {useTranslation} from "react-i18next";

interface AllReviewsProps {
    currentEventId: number;
    hanldePaginationChange : (page:number)=> void;
    pageNumber :number;
}

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: 3,
};
const AllReviews: React.FC<AllReviewsProps> = ({currentEventId,...props}) => {

    const dispatch = useAppDispatch();
    const auth = useAppSelector(state => state.authReducer);
    const {reviews} = useAppSelector(state => state.eventDetailsReducer);
    const [isReviewModalOpen, setIsReviewModalOpen] = useState(false);
    const [reviewData, setReviewData] = useState<Partial<TEventReview>>({
        review: "",
        stars: "",
    });
    const {t} = useTranslation();

    const [reviewError, setReviewError] = useState<string | null>(null);
    const [starsError, setStarsError] = useState<string | null>(null);

    const isUserLoggedIn = !!auth.token;
    const userName = auth.userInfo?.name || "";
    const profilePic = auth.userInfo?.profile_pic || "";
    const handleLikeReview = async (eventId: number, reviewId: number, liked: boolean) => {
        if (!isUserLoggedIn) {
            return;
        }
        await dispatch(likeReview(eventId, reviewId));
        const updatedReviews = reviews.data.map((review) => {
            if (review.id === reviewId) {
                return {
                    ...review,
                    liked_by_user: liked,
                    likes_count: liked ? review.likes_count + 1 : review.likes_count - 1
                };
            }
            return review;
        });
        if (reviews && reviews.data) {
            dispatch(setReviews({...reviews, data: updatedReviews}));
        }
    };
    const handleOpen = () => setIsReviewModalOpen(true);
    const handleClose = () => {
        setIsReviewModalOpen(false);
        setReviewError(null);
        setStarsError(null);
    }
    const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
        props.hanldePaginationChange(page)
        // fetchReviews(currentEventId, page);
    };
    const handleSubmitReview = async () => {
        if (!reviewData?.review) {
            setReviewError(t('requiredReview'));
        } else {
            setReviewError(null);
        }
        if (!reviewData?.stars) {
            setStarsError(t('requiredRating'));
        } else {
            setStarsError(null);
        }
        if (reviewData?.review && reviewData?.stars) {
            await dispatch(createEventReview(currentEventId, reviewData)).then(()=>{
                dispatch(getEventDetails(currentEventId,1))
                setReviewData({
                    review: "",
                    stars: "",
                });
                setIsReviewModalOpen(false);
            }).catch(()=>{

            })

        }
    };
    return (
        <>
            {
                reviews && reviews.data && reviews.data.length > 0 ? (
                    reviews.data.map((review, index) => {
                        const ratingValue = parseFloat(review.stars);
                        const createdAtDate = new Date(review.created_at);
                        const formattedDate = format(createdAtDate, 'dd MMM yyyy');
                        return (
                            <div className="md:grid grid-cols-[1fr_9fr] flex flex-wrap py-10 gap-5">
                                <img loading="lazy" src={profilePic}
                                     className="rounded-full max-md:h-14 max-md:w-14 aspect-square object-cover"/>
                                <div className="">
                                    <div className="md:flex items-center justify-between">
                                        <div className="md:flex items-center justify-between">
                                            <h3 className="text-[#131C23] font-medium text-xl mr-2">{review.user.name}</h3>
                                            {/*<h3 className="text-[#131C23] font-medium text-xl mb-2 mr-2">{review.name}</h3>*/}
                                            <Box
                                                sx={{
                                                    '& > legend': {mt: 2},
                                                }}
                                            >
                                                <Rating name="read-only" value={ratingValue} readOnly precision={0.5}/>
                                            </Box>
                                        </div>
                                        <div className="flex items-center gap-5">
                                            <p className="text-[#131C23]/50 font-medium">{formattedDate}</p>
                                            <div className="flex">
                                                <div className="flex">
                                                    {review.liked_by_user ? (
                                                        <img
                                                            src="/assets/svgs/eventDetails/liked.svg"
                                                            alt="liked"
                                                            onClick={() => handleLikeReview(currentEventId, review.id, false)}
                                                        />
                                                    ) : (
                                                        <img
                                                        loading="lazy"
                                                            src="/assets/svgs/eventDetails/unliked.svg"
                                                            alt="unliked"
                                                            onClick={() => handleLikeReview(currentEventId, review.id, true)}
                                                        />
                                                    )}
                                                    <p className="text-[#131C23] font-bold pl-2">{review.likes_count}</p>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    {/*<p className="text-[#131C23]/50 font-medium">{review.location}</p>*/}
                                    <p className="font-medium pt-2">
                                        {review.review}
                                    </p>
                                </div>
                            </div>
                        );
                    })
                ) : (
                    <p>{t('noReviews')}</p>
                )
            }
            {isUserLoggedIn && (
                <div className="flex justify-end">
                    <button
                        className="px-3 py-2 border-2 border-[#FF913F] hover:bg-[#FF913F] hover:text-white rounded-full font-medium"
                        onClick={handleOpen}
                    >
                        {t('writeReview')}
                    </button>
                </div>
            )}
            <Modal
                open={isReviewModalOpen}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        <span className="text-[#FF913F] font-bold">{t('writeReview')} </span>
                        <IconButton
                            edge="end"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                            sx={{
                                position: 'absolute',
                                top: '1.5rem',
                                right: '1rem',
                            }}
                        >
                            <CloseIcon/>
                        </IconButton>
                    </Typography>
                    <Typography id="modal-modal-description" sx={{mt: 2}}>
                        <div>
                            <input type="text" id="first_name" readOnly
                                   className="bg-gray-50 border border-[#FF913F] text-gray-900 text-sm rounded-lg focus:ring-[#FF913F] focus:border-[#FF913F] block w-full p-2.5 dark:border-[#FF913F] dark:placeholder-gray-400 dark:focus:ring-[#FF913F] dark:focus:border-[#FF913F]"
                                   placeholder={t('fullName')} value={userName}
                            />
                            <div className="py-5">
                                <textarea id="message"
                                          className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-[#FF913F] focus:ring-blue-500 focus:border-blue-500 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                          placeholder={t('thoughts')}
                                          onChange={(e) => setReviewData({...reviewData, review: e.target.value})}
                                          required value={reviewData?.review || ""}></textarea>
                                {reviewError && <p className="text-red-500">{reviewError}</p>}
                            </div>
                            <p className="py-3 font-medium">{t('rate')}</p>
                            <Box
                                sx={{
                                    '& > legend': {mt: 2},
                                }}
                            >
                                <Rating
                                    name="simple-controlled"
                                    value={parseFloat(reviewData.stars || "0")}
                                    onChange={(event, newValue) => {
                                        setReviewData({...reviewData, stars: newValue?.toString()});
                                    }}
                                />
                            </Box>
                            {starsError && <p className="text-red-500">{starsError}</p>}
                            <div className="flex justify-end">
                                <button
                                    className="border border-[#FF913F] hover:bg-[#FF913F] hover:text-white px-5 py-1 rounded-full font-bold flex justify-end"
                                    onClick={handleSubmitReview}>{t('submitReview')}
                                </button>
                            </div>
                        </div>
                    </Typography>
                </Box>
            </Modal>
            <div className="flex">
                {reviews && reviews.data && reviews.data.length > 0 && reviews.last_page ? (
                    <Pagination
                        count={reviews.last_page}
                        page={props.pageNumber}
                        onChange={handlePageChange}
                    />
                ) : null}
            </div>
        </>
    )
}
export default AllReviews;