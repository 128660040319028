import {TSvgComponent} from "./types";

const AwardSvg : TSvgComponent= ({...props}) => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g opacity="0.5">
                <path d="M14.8415 4.57499V5.19166L11.8915 3.48332C10.7749 2.84166 9.21654 2.84166 8.1082 3.48332L5.1582 5.19999V4.57499C5.1582 2.69999 6.1832 1.66666 8.0582 1.66666H11.9415C13.8165 1.66666 14.8415 2.69999 14.8415 4.57499Z" />
                <path d="M14.8665 6.64165L14.7498 6.58332L13.6165 5.93332L11.2665 4.57498C10.5498 4.15832 9.44984 4.15832 8.73317 4.57498L6.38317 5.92498L5.24984 6.59165L5.09984 6.66665C3.6415 7.64998 3.5415 7.83332 3.5415 9.40832V13.175C3.5415 14.75 3.6415 14.9333 5.13317 15.9416L8.73317 18.0166C9.0915 18.2333 9.5415 18.325 9.99984 18.325C10.4498 18.325 10.9082 18.225 11.2665 18.0166L14.8998 15.9166C16.3665 14.9333 16.4582 14.7583 16.4582 13.175V9.40832C16.4582 7.83332 16.3582 7.64998 14.8665 6.64165ZM12.3248 11.25L11.8165 11.875C11.7332 11.9666 11.6748 12.1416 11.6832 12.2666L11.7332 13.0666C11.7665 13.5583 11.4165 13.8083 10.9582 13.6333L10.2165 13.3333C10.0998 13.2916 9.90817 13.2916 9.7915 13.3333L9.04984 13.625C8.5915 13.8083 8.2415 13.55 8.27484 13.0583L8.32484 12.2583C8.33317 12.1333 8.27484 11.9583 8.1915 11.8666L7.67484 11.25C7.35817 10.875 7.49984 10.4583 7.97484 10.3333L8.74984 10.1333C8.87484 10.1 9.0165 9.98331 9.08317 9.88331L9.5165 9.21665C9.78317 8.79998 10.2082 8.79998 10.4832 9.21665L10.9165 9.88331C10.9832 9.99165 11.1332 10.1 11.2498 10.1333L12.0248 10.3333C12.4998 10.4583 12.6415 10.875 12.3248 11.25Z" />
            </g>
        </svg>

    )
}
export default AwardSvg
