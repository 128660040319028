import axios from "axios";
import {IPartners} from "./types";
export const fetchPartnersAxios = async () => {
    try {
        const response = await axios.get<{data: { partners: IPartners[]}}>(
            '/api/partners'
        );
        return response.data.data.partners

    } catch (error) {
        console.error("Error:", error);
        return null
    }
};