import axios from "axios";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../store/redux";
import { useQuery } from "react-query";
import { TLaravelObject, TLaravelResponse, TTranslations } from "../../types/laravel";
import { useLocation, useNavigate } from "react-router-dom";
import { exploreNearMeData } from "../../constants/home/exploreNearMeData";
import {
  fetchGoogleMapEventsAction,
  getAllExclusiveEvents,
  getCategories,
  getHomeEvents
} from "../../store/events/ActionEvents";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { useEffect, useRef, useState } from "react";
import { partnersData } from "../../constants/home/partnersData";
import {
  getInterests, resendOTP,
  setActiveTab,
  setAuthModal, setInterestUser,
  setShowSignUpContent,
  toggleVerificationModal,
  verifyEmail
} from "../../store/Auth/ActionAuth";
import { IEventsData } from "../../networking/events/types";
import { filterTabData } from "../../constants/googleMaps/filterTabData";
import usePlacesAutocomplete, { getGeocode, getLatLng } from "use-places-autocomplete";
import { ITypes } from "../../networking/filterByType/types";
import { useJsApiLoader } from "@react-google-maps/api";
import { fetchTypesAxios } from "../../networking/filterByType/filterByType";
import { guestPayment, PaymentSuccess } from "../../store/Cart/ActionCart";
import { setSnackBarMessage, setSnackBarType, toggleShowSnackBar } from "../../store/GlobalState/ActionGlobalState";

type THomeInfo = [{
  type: string;
  content: string
  translations: TTranslations
} & TLaravelObject]

const filterParams = {
  selectedCheckboxes: [],
  minPrice: "",
  maxPrice: "",
  selectedDuration: "",
  selectedStar: ""
};
export const useBanner = () => {
  const { t } = useTranslation();
  const [isMobileScreen, setIsMobileScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileScreen(window.innerWidth < 768);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return { t, isMobileScreen };
};
export const SetupLanguage = () => {
  const { t } = useTranslation();
  const selectedLanguage = useAppSelector((state) => state.languageReducer.selectedLanguage);
  const { data, isLoading } = useGetHomeInformationAPI();
  return { t, data, selectedLanguage, isLoading };
};
export const useGetHomeData = () => {

  const dispatch = useAppDispatch();
  const urlParams = useLocation();
  const { t } = useTranslation();

  const DefaultLocation = { lat: 10, lng: 106 };
  const paymentIntent = new URLSearchParams(urlParams.search).get("payment_intent");
  const clientCode = new URLSearchParams(urlParams.search).get("code");
  const seoCode = new URLSearchParams(urlParams.search).get("seoCode");

  const showVerificationModal = useSelector((state: RootState) => state.authReducer?.showVerificationModal);
  const { googleMapMarkers } = useAppSelector((state) => state.eventsReducer);

  const [loading, setLoading] = useState(true);
  const [pageAlreadyLoaded, setPageAlreadyLoaded] = useState(false);
  const [defaultLocation, setDefaultLocation] = useState(DefaultLocation);

  useEffect(() => {
    dispatch(fetchGoogleMapEventsAction(defaultLocation.lat, defaultLocation.lng, filterParams));
    getCurrentLocation();
    if (document.readyState === "complete") {
      document.body.style.overflowY = "auto";
      setPageAlreadyLoaded(true);
    } else {
      window.onload = () => {
        setLoading((prev) => (prev = false));
      };
    }
  }, []);
  const getCurrentLocation = () => {
    const successCallback = (position: any) => {
      setDefaultLocation({
        lat: position.coords.latitude,
        lng: position.coords.longitude
      });
    };
    const errorCallback = (error: any) => {
      return null;
    };
    navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
  };

  useEffect(() => {
    if (seoCode) localStorage.setItem("seoCode", seoCode);
    if (!paymentIntent) {
      return;
    }
    const payment = clientCode ? guestPayment(clientCode, paymentIntent) : PaymentSuccess(paymentIntent);
    dispatch(payment).then(removeAllQueryParams).catch((error: any) => {
      dispatch(toggleShowSnackBar(true));
      dispatch(setSnackBarType("error"));
      dispatch(
        setSnackBarMessage(error?.response?.data.message ?? error?.message)
      );
      removeAllQueryParams();
    });
  }, [urlParams]);
  const removeAllQueryParams = () => {
    const url = new URL(window.location.href);
    url.search = "";
    window.history.replaceState({}, "", url.toString());
  };
  const handleCloseVerificationModal = () => {
    dispatch(toggleVerificationModal(false));
  };

  return {
    t,
    showVerificationModal,
    googleMapMarkers,
    loading,
    pageAlreadyLoaded,
    defaultLocation,
    handleCloseVerificationModal
  };
};

export const useGetImportantInformation = () => {
  const { t } = SetupLanguage();
  return { t, ...getDataByType("important_information") };
};
export const useGetNews = () => {
  const { t } = SetupLanguage();
  return { t, ...getDataByType("section_news") };
};
export const findDataByType = (data: any, type: any) => {
  return data.find((item: any) => item.type === type);

};
export const getDataByType = (type: string) => {
  const { data, selectedLanguage, isLoading } = SetupLanguage();
  let translatedContent;
  if (data?.success) {
    const dataItem = findDataByType(data.data as any, type);
    const translation = dataItem && dataItem.translations[selectedLanguage];
    translatedContent = translation && translation.content;
  }
  return { translatedContent, isLoading };
};
export const useGetHomeInformationAPI = () => {
  const { data, isLoading } = useQuery({
    queryKey: ["home", "important-information"], queryFn: async () => {
      const res = await axios.get<TLaravelResponse<THomeInfo>>("/api/home-information");
      return res.data;
    }
  });
  return { data, isLoading };
};
export const useGoogleMaps = (defaultLocation: { lat: number; lng: number }) => {

  const dispatch = useAppDispatch();
  const { t, selectedLanguage } = SetupLanguage();
  const [isMobileScreen, setIsMobileScreen] = useState(false);
  const mapRef = useRef<google.maps.Map | null>(null);
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const transparentImage =
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8/wAA/wAB/3B0wAAAAASUVORK5CYII=";
  const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAP_KEY;

  if (!googleMapsApiKey) {
    throw new Error("Google Maps API key is not defined in your environment variables.");
  }
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: googleMapsApiKey,
    libraries: ["places"]
  });
  const {
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions
  } = usePlacesAutocomplete({
    callbackName: "GOOGLE_MAP"
  });

  const selectedCurrency = useSelector((state: RootState) => state.currenciesReducer.selectedCurrency);

  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([null, null]);
  const [isCalendarOpen, setCalendarOpen] = useState(false);
  const [formattedDateRange, setFormattedDateRange] = useState<string>("");
  const [selectedMarker, setSelectedMarker] = useState<IEventsData | null>(null);
  const [, setEventTypes] = useState<ITypes[]>([]);
  const [minPrice, setMinPrice] = useState<string>("");
  const [maxPrice, setMaxPrice] = useState<string>("");
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [selectedStar, setSelectedStar] = useState<string>("");
  const [selectedCategory, setSelectedCategory] = useState<string>("");
  const [enteredCountry] = useState<string>("");

  const googleMapDateFormat = (date: Date): string => {
    const options: Intl.DateTimeFormatOptions = { month: "short", day: "numeric" };
    const formattedDate = new Intl.DateTimeFormat("en-US", options).format(date);
    return formattedDate;
  };
  const openCalendar = () => {
    setCalendarOpen(true);
  };
  const closeCalendar = () => {
    setCalendarOpen(false);
  };
  const handleCountrySelect = async (address: string) => {
    setValue(address, false);
    clearSuggestions();
    try {
      const results = await getGeocode({ address });
      const { lat, lng } = await getLatLng(results[0]);
      const countryComponent = results[0].address_components.find((component) =>
        component.types.includes("country")
      );
      if (countryComponent) {
        const country = countryComponent.long_name;
        const countryCenter = await getCountryCenter(country);
        if (countryCenter) {
          // const { lat: countryLat, lng: countryLng } = countryCenter;
          // when the user is searching for a city or country make the center of the map on his coordinates
          mapRef.current?.setCenter({ lat, lng });
          mapRef.current?.setZoom(10);
        }
      }
      dispatch(fetchGoogleMapEventsAction(lat, lng, filterParams));
    } catch (error) {
      return null;
    }
  };

  async function getCountryCenter(country: string): Promise<{ lat: number; lng: number } | null> {
    try {
      const results = await getGeocode({ address: country });
      const { lat, lng } = await getLatLng(results[0]);
      return { lat, lng };
    } catch (error) {
      return null;
    }
  }

  const handleMapCenterChange = () => {
    if (mapRef.current) {
      google.maps.event.addListener(mapRef.current, "center_changed", () => {
        const newCenter = mapRef.current?.getCenter();
        if (newCenter) {
          const lat = newCenter.lat();
          const lng = newCenter.lng();
          if (timerRef.current) {
            clearTimeout(timerRef.current);
          }
          timerRef.current = setTimeout(() => {
            dispatch(fetchGoogleMapEventsAction(lat, lng, filterParams));
          }, 1500);
        }
        const zoomLevel = isMobileScreen ? 8 : 14;
        mapRef.current?.setZoom(zoomLevel);
      });
    }
  };
  const handleMinPriceChange = (event: any) => {
    setMinPrice(event.target.value);
  };
  const handleMaxPriceChange = (event: any) => {
    setMaxPrice(event.target.value);
  };
  const handleDateChange = (dates: [Date | null, Date | null]) => {
    setDateRange(dates);
    if (dates[0] && dates[1]) {
      const formattedStartDate = googleMapDateFormat(dates[0]);
      const formattedEndDate = googleMapDateFormat(dates[1]);
      setFormattedDateRange(`${formattedStartDate} - ${formattedEndDate}`);
    }
  };
  const handleSearchEventsMap = () => {
    let formattedDate = "";
    let formattedStartDate = "";
    let formattedEndDate = "";
    if (dateRange[0] instanceof Date && dateRange[1] instanceof Date) {
      const startDate = dateRange[0];
      const endDate = dateRange[1];
      formattedStartDate = `${startDate.getFullYear()}-${(startDate.getMonth() + 1).toString().padStart(2, "0")}-${startDate.getDate().toString().padStart(2, "0")}`;
      formattedEndDate = `${endDate.getFullYear()}-${(endDate.getMonth() + 1).toString().padStart(2, "0")}-${endDate.getDate().toString().padStart(2, "0")}`;
    }
    if (selectedDate instanceof Date) {
      const year = selectedDate.getFullYear();
      const month = (selectedDate.getMonth() + 1).toString().padStart(2, "0");
      const date = selectedDate.getDate().toString().padStart(2, "0");
      formattedDate = `${year}-${month}-${date}`;
    }
    const filterParams = {
      selectedDate: formattedDate,
      start_date: formattedStartDate,
      end_date: formattedEndDate,
      selectedCategory,
      maxPrice,
      selectedStar,
      enteredCountry
    };
    dispatch(fetchGoogleMapEventsAction(defaultLocation.lat, defaultLocation.lng, filterParams));
  };
  const handleResetFilters = () => {
    setMaxPrice("");
    setSelectedCategory("");
    setSelectedStar("");
    setSelectedDate(null);
  };

  useEffect(() => {
    handleMapCenterChange();
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, []);
  useEffect(() => {
    dispatch(getCategories());
    // dispatch(getInterests());
    fetchTypesAxios()
      .then((types) => {
        if (types) {
          setEventTypes(types.types);
        }
      });
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileScreen(window.innerWidth < 768);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return {
    t,
    selectedCurrency,
    selectedLanguage,
    status,
    value,
    setValue,
    dateRange,
    data,
    isCalendarOpen,
    formattedDateRange,
    selectedMarker,
    setSelectedMarker,
    maxPrice,
    minPrice,
    openCalendar,
    closeCalendar,
    transparentImage,
    isLoaded,
    handleCountrySelect,
    handleMaxPriceChange,
    handleMinPriceChange,
    handleDateChange,
    handleResetFilters,
    handleSearchEventsMap,
    handleMapCenterChange,
    mapRef
  };
};
export const useEvents = () => {
  const { t, selectedLanguage } = SetupLanguage();
  const large_events = useSelector((state: RootState) => state.eventsReducer.large_events);
  const large_events_categories = useSelector((state: RootState) => state.eventsReducer.categories);

  const [tabTitlesData, setTabTitlesData] = useState<{ id: number, label: string, image_url: string }[]>([]);
  const [activeTab, setActiveTab] = useState(0);
  const [selectedCategoryId] = useState<number | null>(null);
  const [events, setEvents] = useState<IEventsData[]>(large_events);
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  const [checkboxValues, setCheckboxValues] = useState(filterTabData.map(() => false));
  const handleCategoryClick = (
    { id, label }: { id: number; label: { id: number; title: string } },
    tabId: number
  ) => {
    setActiveTab(tabId);
    if (tabId === 0) {
      setEvents(large_events);
    }
  };
  const handleMinPriceChange = (event: any) => {
    setMinPrice(event.target.value);
  };
  const handleMaxPriceChange = (event: any) => {
    setMaxPrice(event.target.value);
  };
  const handleCheckedTypes = (index: number) => {
    const updatedCheckboxValues = [...checkboxValues];
    updatedCheckboxValues[index] = !updatedCheckboxValues[index];
    setCheckboxValues(updatedCheckboxValues);
  };
  const handleResetSelectedOptions = () => {
    setMinPrice("");
    setMaxPrice("");
    setCheckboxValues(filterTabData.map(() => false));
  };
  useEffect(() => {
    setEvents(large_events);
  }, [large_events]);

  useEffect(() => {
    getCategories();
  }, []);

  useEffect(() => {
    setTabTitlesData(prev => prev = [
      { id: 0, label: t("allEvents"), image_url: "" },
      ...(large_events_categories?.map(category => ({
        id: category.id,
        label: category.translations ? category.translations?.[selectedLanguage].title : category.title,
        image_url: category.image_url
      })))
    ]);
  }, [large_events_categories, selectedLanguage]);

  return {
    tabTitlesData,
    activeTab,
    selectedCategoryId,
    events,
    minPrice,
    maxPrice,
    checkboxValues,
    large_events,
    handleCategoryClick,
    handleMinPriceChange,
    handleMaxPriceChange,
    handleCheckedTypes,
    handleResetSelectedOptions
  };
};
export const useExploreNearMeBanner = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { image, button_url } = exploreNearMeData;
  const auth = useAppSelector(state => state.authReducer);
  const searchData = {
    type_id: [],
    status: "",
    min_price: 0,
    max_price: 1000,
    duration: "",
    all_stars: ""
  };
  const handleButtonClick = async () => {
    try {
      const eventType = "exclusive";
        await dispatch(getAllExclusiveEvents(eventType, searchData));
      navigate(button_url);
    } catch (error) {
      return null;
    }
  };
  return { t, handleButtonClick, image };
};
export const usePromotionEvents = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const allPromotionalEvents = useSelector(
    (state: RootState) => state.eventsReducer.promotional_events
  );
  const swiperParams = {
    slidesPerView: window.innerWidth > 767 ? (window.innerWidth > 1200 ? 3 : 2) : 1,
    spaceBetween: 20
  };
  const [selectedCategoryId] = useState<number | null>(null);

  useEffect(() => {
    dispatch(getHomeEvents());
  }, []);

  const promotionalEvents = allPromotionalEvents.filter((event) =>
    selectedCategoryId === null || event.category_id === selectedCategoryId
  );
  return { t, promotionalEvents, swiperParams, selectedCategoryId };
};
export const usePartners = () => {
  const navigate = useNavigate();
  const { activity, image, button_url } = partnersData;
  const { t } = useTranslation();
  const handleButtonClick = () => {
    navigate(button_url);
  };
  return { t, activity, image, handleButtonClick };
};
export const useBecomeClient = () => {
  const { t } = useTranslation();
  return { t };
};
export const useBecomePartner = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const handleButtonClick = async () => {
    dispatch(setActiveTab("business"));
    dispatch(setAuthModal(true));
    dispatch(setShowSignUpContent(true));
  };
  return { t, handleButtonClick };
};
export const useVerificationEmail = (open: boolean) => {

  const dispatch = useAppDispatch();

  const userInfo = useSelector((state: RootState) => state.authReducer?.userInfo);
  const userRole = useSelector((state: RootState) => state.authReducer?.userRole);

  const inputRefs = useRef<Array<HTMLInputElement | null>>([]);

  const [verificationCode, setVerificationCode] = useState(Array(5).fill(""));
  const [resendTimer, setResendTimer] = useState(59);
  const [showCongratulationsModal, setShowCongratulationsModal] = useState(false);
  const [showInterestModal, setShowInterestModal] = useState(false);
  const regex = /^\d*$/;
  const handleVerificationCodeChange = (value: string, index: number) => {
    if (regex.test(value)) {
      const updatedCode = [...verificationCode];
      updatedCode[index] = value;
      setVerificationCode(updatedCode);
      if (value && index < inputRefs.current.length - 1) {
        inputRefs.current[index + 1]?.focus();
      }
    }
  };
  const handleOpenVerificationModal = () => {
    dispatch(verifyEmail(verificationCode.join(""))).then(() => {
      localStorage.setItem("otpVerified", "true");
      dispatch(toggleVerificationModal(false));
      dispatch(getInterests());
      setShowCongratulationsModal(true);
    });
  };
  const handleResendCode = () => {
    dispatch(resendOTP()).then((res) => {
      setResendTimer(59);
    });
  };
  const closeCongratulationModal = () => {
    setShowCongratulationsModal(false);
    if (userRole?.id !== 1) {
      return;
    }
    setShowInterestModal(true);
  };
  const closeUserInterest = (value: number[]) => {
    dispatch(setInterestUser(value));
    setShowInterestModal(false);
  };

  useEffect(() => {
    inputRefs.current[0]?.focus();
  }, []);

  useEffect(() => {
    if (resendTimer > 0 && open) {
      const interval = setInterval(() => {
        setResendTimer(prevTimer => prevTimer - 1);
      }, 1000);
      return () => clearInterval(interval);
    } else if (resendTimer === 0) {
    }
  }, [resendTimer, open]);
  return {
    userInfo,
    userRole,
    resendTimer,
    showCongratulationsModal,
    showInterestModal,
    verificationCode,
    inputRefs,
    handleVerificationCodeChange,
    handleOpenVerificationModal,
    handleResendCode, closeCongratulationModal, closeUserInterest
  };
};
export const useUserInterest = () => {

  const interests = useSelector((state: RootState) => state.authReducer.interests);

  const [interestSelected, setInterestSelected] = useState<number[]>([]);
  const handleSelectInterest = (id: number) => {
    let array = [...interestSelected];
    if (array.includes(id)) {
      let index = array.findIndex(item => item === id);
      array.splice(index, 1);
      setInterestSelected(array);
    } else {
      array.push(id);
      setInterestSelected(array);
    }
  };
  return { interests, interestSelected, handleSelectInterest };
};
