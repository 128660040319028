import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store/store";
import { useAppDispatch } from "../../../../../store/redux";
import {
  generateStripeAccount,
  successConnectionStripe,
} from "../../../../../store/BusinessDashboard/ActionBusinessDashboard";
import PrimaryButton from "../../../../../component/PrimaryButton";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

const StripeAccount = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const urlParams = useLocation();
  const stripeSuccess = new URLSearchParams(urlParams.search).get("stripe");
  const connectedToStripe = useSelector(
    (state: RootState) => state.businessDashboardReducer?.connectedToStripe,
  );

  useEffect(() => {
    if (stripeSuccess === "success") {
      dispatch(successConnectionStripe());
    }
  }, [urlParams]);
  const handleRegisterAccount = () => {
    dispatch(generateStripeAccount())
      .then((data) => {
        window.location.href = data;
      })
      .catch(() => {});
  };

  return (
    <div className={"h-full w-full rounded-xl bg-[#f3f3f4]"}>
      <div className="my-3 px-4">
        <div className="flex items-center gap-3">
          <p className={"text-xl font-bold"}>
            {t("businessProfile.setting.paymentInformation.stripeAccount")}
          </p>
          <div className="rounded-full bg-[#FF585F]/5 px-4 py-1 text-sm text-[#FF585F]">
            <span className="font-medium">
              {t("businessProfile.setting.paymentInformation.stripeMessage")}
            </span>
          </div>
        </div>

        <PrimaryButton
          disabled={connectedToStripe === 1}
          onClick={handleRegisterAccount}
          className={`${
            connectedToStripe === 1 ? "text-primary-orange" : "text-white"
          } mt-5 w-fit  rounded-full  
          border-none  px-4 py-2`}
        >
          {connectedToStripe === null
            ? t("businessProfile.setting.paymentInformation.registerStripe")
            : connectedToStripe === 0
            ? t("businessProfile.setting.paymentInformation.stripeNotAvailable")
            : t(
                "businessProfile.setting.paymentInformation.alreadyConnectedToStripe",
              )}
        </PrimaryButton>
      </div>
    </div>
  );
};
export default StripeAccount;
