import { Alert, Snackbar } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { useAppDispatch } from "../../store/redux";
import {toggleShowSnackBar } from "../../store/GlobalState/ActionGlobalState";

const SnackBar = () => {
    const dispatch = useAppDispatch()
    const [state, setState] = useState({
        vertical: 'top',
        horizontal: 'center',
    });
    const { vertical, horizontal, } = state;
    const snackBarState = useSelector(
        (state: RootState) => state.globalStateReducer
    );
    useEffect(() => {
        setTimeout(() => {
            dispatch(toggleShowSnackBar(false))
        }, 3000)
    }, [snackBarState.showSnackBar])
    return (
        <div style={{ width: 500 }}>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={snackBarState.showSnackBar}
                autoHideDuration={3000}
                key={vertical + horizontal}
            >
                <Alert severity={snackBarState.severity}>{snackBarState.snackBarMessage}</Alert>
                </Snackbar>
        </div>
    );

}
export default SnackBar