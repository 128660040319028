import { historyHeader } from "../../../../constants/dashboard/History";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../../store/redux";
import { PaymentSuccess } from "../../../../store/Cart/ActionCart";
import { editHistoryReservedTicket, getUserHistory } from "../../../../store/UserDashboard/ActionUserDashboard";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import { FormControl, MenuItem, Pagination, Rating, Select, SelectChangeEvent, Tooltip } from "@mui/material";
import { scrollToTop } from "../../../../store/GlobalState/ActionGlobalState";
import { useTranslation } from "react-i18next";
import { Calendar, CalendarRef, DatePickerRef } from "react-multi-date-picker";
import { DateObject } from "react-multi-date-picker";
import CheckIcon from "@mui/icons-material/Check";

interface Event {
  reserved_date: string;
  reserved_time: string;
  working_days: string[];
  start_time: string;
  end_time: string;
  id: string;
  title: string;
  images: { image_path: string }[];
}

interface SelectedTimes {
  [eventId: string]: string;
}

const History = () => {
  const urlParams = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const calendarRef = useRef<CalendarRef>();
  const datepickerRef = useRef<DatePickerRef>();

  const paymentIntent = new URLSearchParams(urlParams.search).get(
    "payment_intent"
  );
  const userHistory = useSelector(
    (state: RootState) => state.userDashboardReducer?.userHistory
  );
  const currency = useSelector(
    (state: RootState) => state.currenciesReducer?.selectedCurrency
  );
  const dispatch = useAppDispatch();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [activeTab, setActiveTab] = useState(1);

  const [isCalendarOpen, setIsCalendarOpen] = useState<boolean>(false);
  const [openCalendarEventId, setOpenCalendarEventId] = useState<string | null>(null);
  const [eventDates, setEventDates] = useState<{ [key: string]: Date }>({});
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [selectedDates, setSelectedDates] = useState<{ [eventId: string]: Date | null }>({});
  const [selectedTimes, setSelectedTimes] = useState<{ [key: string]: string }>({});
  const [editMode, setEditMode] = useState(false);
  const [reservedDate, setReservedDate] = useState("");
  const [reservedTime, setReservedTime] = useState("");
  const [editingEventId, setEditingEventId] = useState<string | null>(null);
  const [editModes, setEditModes] = useState<Record<string, boolean>>({});

  const handleTabClicked = (value: number) => {
    setActiveTab(value);
  };
  const removeAllQueryParams = () => {
    const url = new URL(window.location.href);
    url.search = ""; // Clear all query parameters
    window.history.replaceState({}, "", url.toString());
  };

  useEffect(() => {
    dispatch(getUserHistory(currentPage));
  }, [dispatch, currentPage]);

  const getFormattedWorkingDays = (event: Event): Date[] => {
    return event.working_days.map(day => new Date(day));
  };
  const toggleCalendar = (eventId: string) => {
    setOpenCalendarEventId(prev => prev === eventId ? null : eventId);
  };
  const handleDateChange = (dateObject: DateObject | DateObject[] | null, eventId: string) => {
    if (dateObject) {
      const newDate = dateObject instanceof DateObject ? new Date(dateObject.year, +dateObject.month - 1, dateObject.day) : null;
      if (newDate) {
        setSelectedDates(prev => ({
          ...prev,
          [eventId]: newDate
        }));
      }
    }
  };
  const handlePageChange = (event: React.ChangeEvent<unknown>, page: number): void => {
    setCurrentPage(page);
    dispatch(getUserHistory(page));
  };

  function formatDate(isoDateString: any) {
    const date = new Date(isoDateString);
    return date.toLocaleDateString(undefined, { year: "numeric", month: "long", day: "numeric" });
  }

  function getTimeSlots(startTime: string, endTime: string) {
    const today = new Date().toDateString();
    const start = new Date(`${today} ${startTime}`);
    const end = new Date(`${today} ${endTime}`);

    if (end < start) {
      end.setDate(end.getDate() + 1);
    }

    const slots = [];
    let current = new Date(start);
    while (current <= end) {
      slots.push(current.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit", hour12: true }));
      current.setHours(current.getHours() + 2);
    }
    return slots;
  }

  const handleTimeChange = (event: SelectChangeEvent<string>, eventId: string) => {
    const newTime = event.target.value;
    setSelectedTimes(prev => ({
      ...prev,
      [eventId]: newTime
    }));
  };

  const handleEditClick = (eventId: string) => {
    setEditModes(prevModes => ({
      ...prevModes,
      [eventId]: !prevModes[eventId]
    }));
  };

  function convertTo24Hour(timeStr: string): string {
    const [time, modifier] = timeStr.split(' ');
    let [hours, minutes] = time.split(':');

    if (hours === '12') {
      hours = '00';
    }

    if (modifier === 'PM' && hours !== '12') {
      hours = (parseInt(hours, 10) + 12).toString();
    }

    return `${hours}:${minutes}`;
  }

  const handleDoneClick = (reservation: any) => {
    setEditModes(prevModes => ({
      ...prevModes,
      [reservation.event.id]: false
    }));
    const selectedDate = selectedDates[reservation.event.id];
    const selectedTime = selectedTimes[reservation.event.id];

    if (selectedDate && selectedTime) {
      const date = selectedDate.toISOString().split('T')[0];
      const time = convertTo24Hour(selectedTime);
      const reservedTicketId = reservation.id;

      dispatch(editHistoryReservedTicket(reservedTicketId, date, time));
    } else {
      console.error("Both date and time must be selected.");
    }
  };

  return (
    <>
      {userHistory?.historyData?.length > 0 ? (
        <>
          <div className="overflow-x-scroll">
            <div>
              <div
                className={
                  " grid min-w-[800px] grid-cols-[1fr_3fr_5fr_3fr_3fr_2.5fr] gap-4 rounded-xl bg-[#f3f3f4] px-5 py-3"
                }
              >
                {historyHeader?.map((item, index) => (
                  <p key={index} className={"text-[#9fa3a5] "}>
                    {t(`userProfile.history.historyHeader.${item.id}`)}
                  </p>
                ))}
              </div>
              {userHistory?.historyData?.map((item: any, index: number) => (
                <div key={index}>
                  <div
                    className={
                      "mt-3 grid min-w-[800px]  grid-cols-[1fr_3fr_5fr_3fr_3fr_2.5fr_0.5fr] items-center gap-4 px-5 py-3"
                    }
                  >
                    <img
                      loading="lazy"
                      onClick={() => navigate(`/eventDetails/${item.event.id}`)}
                      className={"h-10 w-[50px] cursor-pointer rounded-lg"}
                      src={item.event?.images[0]?.image_path}
                    />
                    <Tooltip
                      arrow
                      placement="bottom-end"
                      title={item.event.title}
                    >
                      <p
                        className={
                          "text-sx w-full cursor-pointer truncate font-bold"
                        }
                      >
                        {item.event.title}
                      </p>
                    </Tooltip>

                    <div className="flex w-fit items-center rounded-full bg-[#F3F3F4] px-3 py-1">
                      <img
                        loading="lazy"
                        src="/assets/svgs/events/location.svg"
                        alt="icon"
                        className="h-auto w-4"
                      />
                      <Tooltip
                        arrow
                        placement="bottom-end"
                        title={
                          item.event.location +
                          (item.event.address ? ` ${item.event.address}` : "")
                        }
                      >
                        <span className="text-sx ml-2 cursor-pointer">
                          {item.event.location}{" "}
                          {item.event.address ?? `, ${item.event.address}`}
                        </span>
                      </Tooltip>
                    </div>
                    {
                      editModes[item.event.id] && (
                        <>
                          <div>
                            <div className="flex items-center gap-2">
                              <p>{selectedDates[item.event.id] ? formatDate(selectedDates[item.event.id]!.toISOString()) : formatDate(item.reserved_date)}</p>
                              <img
                                src="/assets/svgs/orange-calendar.svg"
                                alt="Toggle Calendar"
                                onClick={() => toggleCalendar(item.event.id)}
                                style={{ cursor: "pointer" }}
                              />
                            </div>
                            {openCalendarEventId === item.event.id && (
                              <div className="absolute z-40">
                                <Calendar
                                  ref={calendarRef}
                                  value={selectedDates[item.event.id] ? selectedDates[item.event.id] : new Date(item.reserved_date)}
                                  onChange={(dateObject) => handleDateChange(dateObject, item.event.id)}
                                  mapDays={({ date }) => {
                                    const formattedWorkingDays = getFormattedWorkingDays(item.event);
                                    const dateString = date.format("YYYY-MM-DD");
                                    return formattedWorkingDays.some(d => d.toISOString().split("T")[0] === dateString)
                                      ? { className: "highlighted", disabled: false }
                                      : { disabled: true };
                                  }}
                                />
                              </div>
                            )}
                          </div>
                          <div className="">
                            <Select
                              value={selectedTimes[item.event.id] || item.reserved_time}
                              onChange={(event) => handleTimeChange(event, item.event.id)}
                              displayEmpty
                            >
                              <MenuItem value={item.reserved_time}>{item.reserved_time}</MenuItem>
                              {getTimeSlots(item.event.start_time, item.event.end_time).map((time, idx) => (
                                <MenuItem key={idx} value={time}>{time}</MenuItem>
                              ))}
                            </Select>
                          </div>
                        </>
                      )
                    }
                    {!editModes[item.event.id] && (
                      <div className="flex items-center gap-2">
                        <p>{selectedDates[item.event.id] ? formatDate(selectedDates[item.event.id]!.toISOString()) : formatDate(item.reserved_date)}</p>
                      </div>
                    )}

                    {!editModes[item.event.id] && (
                      <div className="">
                        <p>{item.reserved_time}</p>
                      </div>
                    )}

                    {/*<div className="text-md flex items-center">*/}
                    {/*  {item?.reviews_avg_stars ? (*/}
                    {/*    <>*/}
                    {/*      <Rating*/}
                    {/*        size="small"*/}
                    {/*        name="read-only"*/}
                    {/*        value={item.reviews_avg_stars}*/}
                    {/*        readOnly*/}
                    {/*        precision={0.5}*/}
                    {/*      />*/}
                    {/*      {Number(item.reviews_avg_stars).toFixed(2)}*/}
                    {/*    </>*/}
                    {/*  ) : (*/}
                    {/*    "No ratings yet"*/}
                    {/*  )}*/}
                    {/*</div>*/}

                    {/*  <div className="grid w-full grid-cols-2 items-center text-center">*/}
                    {/*    /!* <div*/}
                    {/*  style={{ whiteSpace: "nowrap" }}*/}
                    {/*  className="flex w-fit items-center gap-3  rounded-full bg-[#F3F3F4] px-3 py-1 text-sm font-medium text-[#898D91]"*/}
                    {/*>*/}
                    {/*  <WatchLaterIcon*/}
                    {/*    sx={{ fill: "#FF913F", width: "15px", height: "15px" }}*/}
                    {/*  />*/}
                    {/*  {item.event.duration}*/}
                    {/*</div> *!/*/}
                    {/*    {item?.event.types?.map((element: any, index: number) => (*/}
                    {/*      <div*/}
                    {/*        key={index}*/}
                    {/*        style={{ whiteSpace: "nowrap" }}*/}
                    {/*        className="my-1 flex w-fit items-center rounded-full bg-[#F3F3F4] px-3 py-1 text-sm font-medium text-[#898D91]"*/}
                    {/*      >*/}
                    {/*        {element.title}*/}
                    {/*      </div>*/}
                    {/*    ))}*/}
                    {/*  </div>*/}
                    <div className="flex w-full items-center ">
                      <div className="rounded-full bg-[#F3F3F4] px-3 py-1">
                        {currency.symbol}{" "}
                        {(item.paid_amount * currency.exchange_rate).toFixed(2)}
                      </div>
                    </div>
                    {
                      editModes[item.event.id] && (
                        <CheckIcon onClick={() => handleDoneClick(item)} className="text-primary-orange font-bold cursor-pointer" />
                      )
                    }
                    {
                      !editModes[item.event.id] && (
                        <img
                          alt="edit-icon"
                          src="/assets/svgs/dashboard/userProfile/edit-icon.svg"
                          className="cursor-pointer"
                          onClick={() => handleEditClick(item.event.id)}
                        />
                      )
                    }
                  </div>
                  <div className="mt-3 min-w-[800px]">
                    <div className="h-px w-full bg-[#d0d2d3] bg-opacity-50" />
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="mb-10 mt-10 flex w-full justify-center">
            <Pagination
              count={userHistory.last_page}
              page={currentPage}
              onChange={handlePageChange}
            />
          </div>
        </>
      ) : (
        <>
          <div className="empty grid place-items-center pb-20">
            <img
              loading="lazy"
              src="/assets/images/empty.png"
              alt="Empty Wishlist"
            />
            <h3 className="px-4 text-lg font-bold 2xl:px-7 2xl:text-xl">
              Your History is Empty
            </h3>
            <p className="pt-4 font-medium text-[#131C23]/40">
              Ready to embark on a journey through time?
            </p>
            <button
              className="mt-3 flex items-center justify-center rounded-full bg-[#FF913F] px-14 py-2 text-center text-white"
              onClick={() => navigate("/allEvents/large")}
            >
              <span className="px-1 font-medium">Explore for more</span>
              <img
                loading="lazy"
                src="/assets/svgs/common/white-arrow.svg"
                className="w-4"
                alt="Find Things"
              />
            </button>
          </div>
        </>
      )}
    </>
  );
};
export default History;
