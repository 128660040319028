import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { prices } from "../../../../../constants/contribution";
import StripeDonation from "../../stripe/StripeDonation";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../store/store";
import { CrowfundingPayment, subscription } from "../../../../../store/Crowdfunding/ActionCrowdfunding";
import { countriesAPI, getContributions } from "../../../../../networking/crowdfunding/crowdfunding";
import { useTranslation } from "react-i18next";
import { FormControl } from "@mui/material";
import Select from "react-select";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

interface RecentContributorsProps {
  recentContributors: {
    id: number;
    name: string;
    amount: string;
    words_of_support: string;
    created_at: string;
  }[];
}

interface Country {
  name: string;
  code: string;
}

export const Contribution: React.FC<RecentContributorsProps> = ({ recentContributors }) => {

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState("monthly");
  const [selectedPrice, setSelectedPrice] = useState<number | null>(null);
  const [customAmount, setCustomAmount] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [country, setCountry] = useState({ name: "", code: "" });
  const [countries, setCountries] = useState<Country[]>([]);
  const [wordsOfSupport, setWordsOfSupport] = useState<string>("");
  const [isContributionSubmitted, setIsContributionSubmitted] = useState<boolean>(false);
  const isLoading = useSelector((state: RootState) => state.crowdfundingReducer.isLoading);
  const clientSecretKey = useSelector((state: RootState) => state.crowdfundingReducer.clientSecretKey);
  const [goal, setGoal] = useState("");
  const [remaining, setRemaining] = useState("");
  const [totalContributors, setTotalContributors] = useState("");
  const [raised, setRaised] = useState("");
  const [percentage, setPercentage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const validationSchema = Yup.object({
    email: Yup.string().email("Invalid email address").required("Email is Required")
  });
  const [anonymousName, setAnonymousName] = useState<boolean>(false);

  const handlePriceSelection = (price: number) => {
    setSelectedPrice(price);
    setCustomAmount(price.toString());
  };
  const handleCustomAmountChange = (amount: string) => {
    setSelectedPrice(null);
    setCustomAmount(amount);
  };
  const handleTabChange = (tab: string) => {
    setActiveTab(tab);
  };
  const handleContributeNow = async (formValues: any) => {
    try {
      const requestData = {
        amount: selectedPrice ? selectedPrice.toString() : customAmount,
        is_subscribed: activeTab === "monthly",
        words_of_support: wordsOfSupport,
        name: anonymousName ? "anonymous" : name,
        email: formValues.email,
        country: country.name,
        country_code: country.code
      };

      if (activeTab === "monthly") {
        const response = await dispatch(subscription(requestData) as any);
        if (response && response.success && response.data && response.data.client_secret) {
          setIsContributionSubmitted(true);
        }
        return response;
      } else {
        const response = await dispatch(CrowfundingPayment(requestData) as any);
        if (response && response.success && response.data && response.data.client_secret) {
          setIsContributionSubmitted(true);
        } else {
          console.error("Error: Client Secret is not available in the API response.");
        }
      }
    } catch (error) {
      console.log("inn in errorrr");
    }
  };
  const handleShareButtonClick = () => {
    const currentURL = window.location.href;
    navigator.clipboard.writeText(currentURL)
      .then(() => {
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
        }, 3000);
      })
      .catch((error) => {
        console.error("Failed to copy URL to clipboard:", error);
      });
  };
  const getContributionsData = async () => {
    try {
      const data = await getContributions();
      if (data) {
        setGoal(data.goal);
        setRemaining(data.remaining);
        setTotalContributors(data.total_contributors);
        setRaised(data.raised);
        setPercentage(data.percentage);
      }
    } catch (error) {
      console.error("Error fetching contribution data:", error);
    }
  };
  const getCountries = async () => {
    try {
      const data = await countriesAPI();
      if (data) {
        setCountries(data);
      }
    } catch (error) {

    }
  };
  useEffect(() => {
    getContributionsData();
    getCountries();
  }, []);
  return (
    <section>
      <div className="bg-white rounded-xl text-[#1B1B1B] md:p-6 max-md:py-4 max-md:px-3">
        <h2 className="text-4xl font-bold max-md:text-center max-md:text-2xl">
          £{raised}
        </h2>
        <div className="w-full bg-[#EAECF0] rounded-full h-2.5 mt-1">
          <div className="bg-gradient-to-l from-[#FFB63F] to-[#FF8A3F] h-2.5 rounded-full"
               style={{ width: `${parseFloat(percentage) * 100}%` }}></div>
        </div>
        <div className="grid grid-cols-3 justify-between md:gap-6 mt-5">
          <p className="text-[#667085] text-sm">{t("crowdfunding.goal")}</p>
          <div></div>
          <p className="text-[#667085] text-sm">{t("crowdfunding.remaining")}</p>
        </div>
        <div className="md:grid grid-cols-3 max-md:flex max-md:justify-between md:gap-6">
          <p className="max-md:text-[16px] max-md:text-center md:gap-3 font-bold">£{goal}</p>
          <p className="font-regular"> Raised of</p>
          <p className="max-md:text-[16px] font-bold">£{remaining}</p>
        </div>
        <p className="border-[1px] border-[#DCDCDC]/60 my-3"></p>
        <div className="md:grid md:grid-cols-2 gap-2 items-center">
          <div className="max-md:flex max-md:justify-between md:gap-6 max-md:gap-5">
            <p className="font-bold text-[20px] max-md:text-[15px]"> {t("crowdfunding.helpedRaised")}</p>
            <p className="font-light md:pt-2 text-lg max-md:text-[15px]">❤️ {totalContributors} Contributors</p>
          </div>
          <Link to="">
            <button
              className="bg-primary-orange text-white rounded-full md:px-14 max-md:px-10 py-3 mt-5 flex items-center justify-center gap-1 max-md:w-full hover:bg-black"
              onClick={handleShareButtonClick}
            >
              {t("crowdfunding.share")}
              <img src="/assets/svgs/common/white-arrow.svg" />
            </button>
          </Link>
          {showAlert && (
            <div className="fixed bottom-0 right-0 mb-4 mr-4 p-4 bg-primary-orange text-white rounded">
              Link copied!
            </div>
          )}
        </div>
      </div>

      {isContributionSubmitted ? (
        <StripeDonation />
      ) : (
        <Formik initialValues={{ email: "" }}
                validationSchema={validationSchema}
                onSubmit={async (values, { setSubmitting }) => {
                  await handleContributeNow(values);
                  setSubmitting(false);
                }}>
          {({ isSubmitting }) => (
            <Form>
              <div className="bg-white rounded-xl text-[#1B1B1B] p-6 mt-4">
                <div className="pb-5">
                  <h2 className="text-lg md:text-2xl font-bold">
                    {t("crowdfunding.contribution")}
                  </h2>
                  <p className="text-black/40"> {t("crowdfunding.contributionText")}</p>
                </div>
                <div
                  className="grid grid-cols-2 text-center mb-4 text-md text-[#131C23]/50 border-b-2 border-[#131C23]/10">
                  <button
                    className={`cursor-pointer ${
                      activeTab === "monthly" ? "text-[#131C23] font-bold" : ""
                    }`}
                    onClick={() => handleTabChange("monthly")}
                  >
                    Monthly
                    {activeTab === "monthly" && (
                      <div
                        className={`cursor-pointer mt-1 ${
                          activeTab === "monthly"
                            ? "text-[#131C23] font-bold h-1 w-full bg-[#FF913F] rounded-t-[50px]"
                            : "h-1 w-full bg-transparent rounded-t-2xl"
                        }`}
                      ></div>
                    )}
                  </button>
                  <button
                    className={`cursor-pointer ${
                      activeTab === "oneTime" ? "text-[#131C23] font-bold" : ""
                    }`}
                    onClick={() => handleTabChange("oneTime")}
                  >
                    One Time
                    {activeTab === "oneTime" && (
                      <div
                        className={`cursor-pointer mt-1 ${
                          activeTab === "oneTime"
                            ? "text-[#131C23] font-bold h-1 w-full bg-[#FF913F] rounded-t-[50px]"
                            : "h-1 w-full bg-transparent rounded-t-2xl"
                        }`}
                      ></div>
                    )}
                  </button>
                </div>
                <div className="grid md:grid-cols-3 max-md:grid-cols-2 gap-3">
                  {prices.map((price) => (
                    <label key={price} className="block relative">
                      <input
                        type="checkbox"
                        value={price}
                        checked={price === selectedPrice}
                        onChange={() => handlePriceSelection(price)}
                        className="absolute opacity-0"
                      />
                      <p
                        className={`bg-[#131C23]/10 rounded-lg text-black p-2 text-center cursor-pointer ${
                          price === selectedPrice ? "bg-primary-orange" : ""
                        }`}
                      >
                        £ {price}
                      </p>
                    </label>
                  ))}
                </div>
                <div className="text-black pt-4">
                  <p className="font-bold text-primary-orange">{t("crowdfunding.customAmount")}</p>
                  <input
                    type="number"
                    placeholder={t("crowdfunding.enterAmount")}
                    value={customAmount}
                    onChange={(e) => handleCustomAmountChange(e.target.value)}
                    className="border-2 border-[#E3E2E2]/30 placeholder:text-black text-sm w-full rounded-xl p-2 font-light"
                  />
                </div>
                {
                  !anonymousName && (
                    <div className="text-black pt-4">
                      <p className="font-bold"> {t("crowdfunding.fullName")}</p>
                      <input type="text" placeholder={t("crowdfunding.fullName")} value={name}
                             onChange={(e) => setName(e.target.value)}
                             className="border-2 border-[#E3E2E2]/30 w-full text-sm rounded-xl p-2 font-extralight my-2 placeholder:text-black" />
                    </div>
                  )
                }
                <div className="flex items-center pt-2">
                  <input type="checkbox" id="anonymousName" checked={anonymousName}
                         onChange={() => setAnonymousName(!anonymousName)} />
                  <label htmlFor="anonymousName" className="ml-2">Contribute anonymously</label>
                </div>
                {/*<p className="font-bold">{t("crowdfunding.email")}</p>*/}
                {/*<input type="email" placeholder={t("crowdfunding.enterEmail")} value={email}*/}
                {/*       onChange={(e) => setEmail(e.target.value)}*/}
                {/*       className="border-2 border-[#E3E2E2]/30 w-full rounded-xl text-sm p-2 font-light my-2 placeholder:text-black" />*/}
                <div className="text-black pt-4">
                  <p className="font-bold">{t("crowdfunding.email")}</p>
                  <Field
                    name="email"
                    type="email"
                    placeholder={t("crowdfunding.enterEmail")}
                    className="border-2 border-[#E3E2E2]/30 w-full rounded-xl text-sm p-2 font-light my-2 placeholder:text-black"
                  />
                  <ErrorMessage name="email" component="div" className="text-red-500" />
                </div>

                <p className="font-bold">{t("crowdfunding.country")}</p>
                <div className="my-3 [&_#react-select-2-live-region+div>div]:rounded-full">
                  {/*<FormControl*/}
                  {/*  // sx={{*/}
                  {/*  //   border: "1px solid #E3E2E2",*/}
                  {/*  //   borderRadius: "10px",*/}
                  {/*  //   backgroundColor: "transparent",*/}
                  {/*  //   paddingLeft: "10px"*/}
                  {/*  // }}*/}
                  {/*  className="w-fit"*/}
                  {/*  variant="standard"*/}
                  {/*  fullWidth*/}
                  {/*>*/}
                  {/*<Select*/}
                  {/*  labelId="country-select-label"*/}
                  {/*  id="country-select"*/}
                  {/*  value={country.code}*/}
                  {/*  onChange={(e:any) => {*/}
                  {/*    const selectedCode = e.target.value;*/}
                  {/*    const selectedCountry = countries.find((country: Country) => country.code === selectedCode);*/}
                  {/*    setCountry({*/}
                  {/*      name: selectedCountry ? selectedCountry.name : "",*/}
                  {/*      code: selectedCode*/}
                  {/*    });*/}
                  {/*  }}*/}
                  {/*  displayEmpty*/}
                  {/*  inputProps={{ "aria-label": "Without label" }}*/}
                  {/*>*/}
                  {/*  <MenuItem value="" disabled>*/}
                  {/*    {t("crowdfunding.enterCountry")}*/}
                  {/*  </MenuItem>*/}
                  {/*  {countries.map((country: any) => (*/}
                  {/*    <MenuItem key={country.code} value={country.code}>*/}
                  {/*      {country.name}*/}
                  {/*    </MenuItem>*/}
                  {/*  ))}*/}
                  {/*</Select>*/}
                  <Select
                    value={{ label: country.name, value: country.code }}
                    onChange={(selectedOption) => {
                      if (selectedOption) {
                        setCountry({
                          name: selectedOption.label,
                          code: selectedOption.value
                        });
                      }
                    }}
                    placeholder={t("crowdfunding.country")}
                    options={[
                      // { label: "Enter Country", value: "" },
                      ...countries.map((country) => ({
                        label: country.name,
                        value: country.code
                      }))
                    ]}
                  />
                  {/*</FormControl>*/}
                </div>
                <p className="font-bold">{t("crowdfunding.wordsOfSupport")} </p>
                <textarea rows={4} cols={4} placeholder={t("crowdfunding.shareWords")} value={wordsOfSupport}
                          onChange={(e) => setWordsOfSupport(e.target.value)}
                          className="border-2 border-[#E3E2E2]/30 w-full rounded-xl p-2 text-sm font-light my-2 placeholder:text-black" />
                {/*<button*/}
                {/*  className="bg-primary-orange text-white rounded-full md:px-14 max-md:px-10 py-3 my-5 flex items-center justify-center gap-1 w-full hover:bg-black"*/}
                {/*  onClick={handleContributeNow}*/}
                {/*>*/}
                {/*  {t("crowdfunding.contributeNow")}*/}
                {/*  <img src="/assets/svgs/common/white-arrow.svg" />*/}
                {/*</button>*/}

                <button type="submit" disabled={isSubmitting}
                        className="bg-primary-orange text-white rounded-full md:px-14 max-md:px-10 py-3 my-5 flex items-center justify-center gap-1 w-full hover:bg-black">
                  {t("crowdfunding.contributeNow")}
                  <img src="/assets/svgs/common/white-arrow.svg" />
                </button>
              </div>
            </Form>
          )}
        </Formik>
      )}
      <div className="bg-white rounded-xl p-4 mt-4 text-[#1B1B1B] max-md:my-5 md:hidden max-md:block">
        <h1 className="text-lg font-bold gap-2 flex items-center">
          {t("crowdfunding.recentContributors")}
        </h1>
        <div className="grid md:grid-cols-3 max-md:grid-cols-2 gap-4 mt-4">
          {
            recentContributors.length === 0 ? (
              <p className="text-primary-orange font-bold">No contributors yet</p>
            ) : (
              recentContributors.map((recentContributor) => (
                <div key={recentContributor.name}>
                  <p className="text-[#667085]">{recentContributor.name}</p>
                  <p>❤️ £ {recentContributor.amount}</p>
                </div>
              ))
            )
          }
        </div>
      </div>
    </section>
  );
};
export default Contribution;
