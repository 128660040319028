import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const OurMissionSummary = () => {
  const { t } = useTranslation();

  return (
    <main className="md:my-10 max-md:mt-20 md:pt-10">
      <div className="mt-5 flex items-center rounded-b-xl rounded-t-3xl t md:p-5">
        <div className="container place-items-center">
          <div className="md:grid md:grid-cols-2 max-md:text-center">
            <img src="/assets/images/mission-1.png" className="max-md:block md:hidden mb-5" />
            <div className="md:py-5 max-md:pb-5 text-black md:mr-10">
              <h1 className="text-xl font-bold md:text-3xl gap-2 flex items-center max-md:justify-center">
                {t("missionTitle")}
                <div className="border-b-[3px] border-black w-[60px] mt-2 rounded-2xl"></div>
              </h1>
              <p className="text-primary-orange font-bold"> {t("story")}</p>
              <p className="text-lg text-black opacity-80 max-md:text-center">
                <h3 className="pt-8">
                  {t("missionText")}
                </h3>
              </p>
            </div>
            <img src="/assets/images/mission-1.png" className="md:block max-md:hidden" />
          </div>
          <div className="md:grid md:grid-cols-2 gap-5 md:pt-10">
            <img src="/assets/images/mission-2.png" className="md:block max-md:hidden" />
            <img src="/assets/images/mission-2-mobile.png" className="md:hidden max-md:block" />
            <h3 className="pt-8 mx-md:text-center">
              {t("missionSecondText")}
            </h3>
          </div>
          <div
            className="md:grid md:grid-cols-[2fr_1fr_1fr] bg-[#141D24] rounded-2xl text-white relative place-items-center p-10 my-14">
            <div>
              <h1
                className="text-xl font-bold md:text-3xl gap-2 flex items-center max-md:justify-center max-md:text-center">
                {t("missionSecondTitle")}
              </h1>
              <h3 className="pt-8 max-md:text-center">
                {t("missionThirdText")}
              </h3>
            </div>
            <img src="/assets/images/mission-3-1.png"
                 className="rounded-2xl transform border-2 -rotate-12 max-w-[300px] max-h-[300px] translate-y-10 max-md:hidden md:block" />
            <img src="/assets/images/mission-3.png"
                 className="rounded-2xl max-w-[300px] border-2 max-h-[300px] rotate-12 -translate-y-10 max-md:hidden md:block" />
            <img src="/assets/images/mission-3.png"
                 className="rounded-2xl max-w-[250px] border-2 max-h-[250px] rotate-12 translate-y-10 max-md:block md:hidden" />
            <img src="/assets/images/mission-3-1.png"
                 className="rounded-2xl transform border-2 -rotate-12 max-w-[250px] max-h-[250px] translate-y-20 max-md:block md:hidden" />
          </div>
          <div className="md:grid md:grid-cols-2 place-items-center gap-5">
            <img src="/assets/images/mission-4.png" className="md:block max-md:hidden" />
            <div>
              <h3 className="pt-8 max-md:text-center">
                {t("missionFourthText")}
                <span className="font-bold">{t("missionSlogan")}</span>
              </h3>
              <img src="/assets/images/mission-4.png" className="md:hidden max-md:block" />
              <div className="max-md:flex max-md:justify-center mt-10">
                <Link to="/crowdfunding">
                  <button
                    className="bg-[#93DBDF] text-black rounded-full md:px-5 max-md:px-10 py-3 font-bold flex items-center justify-center gap-1">
                    <span className="font-bold">{t("missionButton")}</span> {t("missionButtonText")}
                    <img src="/assets/svgs/home/arrow-black.svg" />
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <div style={{ backgroundImage: `url(/assets/images/mission-bg.png)` }}
               className="md:bg-contain xl:aspect-[16/3] flex flex-col justify-center xl:bg-center bg-origin-border bg-no-repeat text-white text-center p-20 mt-20 max-md:hidden">
            <h3 className="font-light gap-2 text-xl flex items-center max-md:justify-center md:px-24">
              {t("missionStory")}
            </h3>
            <p className="md:text-3xl font-bold">{t("missionStorySlogan")}</p>
          </div>
          <div
            className="bg-gradient-to-r from-[#FFB63F] to-primary-orange bg-primary-orange max-md:flex max-md:flex-col items-center my-20 rounded-[30px] text-white text-center md:p-14 max-md:pl-14 max-md:py-8 max-md:pr-[3.9rem] mt-20 md:hidden">
            <img src="/assets/svgs/slogan.svg" className="w-20 h-20"/>
            <p className="font-light gap-2 text-lg flex items-center max-md:justify-center pt-2">
              {t("missionSecondStory")}
            </p>
            <p className="font-bold text-2xl">{t("missionSecondSlogan")}</p>
          </div>
        </div>
      </div>
    </main>
  );
};
export default OurMissionSummary;
