import React, { useEffect, useState } from "react";
import { historyHeader } from "../../../../../constants/dashboard/History";
import { Pagination, Rating, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { scrollToTop } from "../../../../../store/GlobalState/ActionGlobalState";
import { useAppDispatch } from "../../../../../store/redux";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store/store";
import { getPaidEvents } from "../../../../../store/BusinessDashboard/ActionBusinessDashboard";
import { useTranslation } from "react-i18next";
import { Calendar } from "react-multi-date-picker";
import { TEventAge, TEventFee } from "../../../../../store/EventDetails/types";

interface HistoryProps {
  workingDays: string[];
}

const History  = () => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(1);
  const navigate = useNavigate();
  const userHistory = useSelector(
    (state: RootState) => state.businessDashboardReducer.businessHistory,
  );
  const currency = useSelector(
    (state: RootState) => state.currenciesReducer?.selectedCurrency,
  );
  const [currentPage, setCurrentPage] = useState<number>(1);


  const dispatch = useAppDispatch();
  const handleTabClicked = (value: number) => {
    setActiveTab(value);
  };
  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    page: number,
  ) => {
    setCurrentPage(page);
  };
  useEffect(() => {
    dispatch(scrollToTop);
    dispatch(getPaidEvents(currentPage));
  }, [currentPage]);
  return (
    <div>
      <div className="mb-5 mt-10 flex items-center gap-2">
        <p className={"text-xl font-bold text-black"}>
          {t("businessProfile.overview.history.title")}
        </p>
      </div>
      <div className="overflow-x-scroll">
        <div
          className={
            " grid w-full grid-cols-[1fr_3fr_5fr_3fr_3fr_2.5fr] gap-4 rounded-xl bg-[#f3f3f4] px-5 py-3"
          }
        >
          {historyHeader?.map((item, index) => (
            <p className={"text-[#9fa3a5] "}>
              {t(`businessProfile.overview.history.historyHeader.${item.id}`)}
            </p>
          ))}
        </div>
        {userHistory?.historyData?.map((item: any) => (
          <>
            <div
              className={
                "mt-3 grid  w-full grid-cols-[1fr_3fr_5fr_3fr_3fr_2.5fr] items-center gap-4 px-5 py-3"
              }
            >
              <img
                loading="lazy"
                onClick={() => navigate(`/eventDetails/${item.event.id}`)}
                className={"h-10 w-[50px] cursor-pointer rounded-lg"}
                src={item.event?.images[0]?.image_path}
              />
              <Tooltip arrow placement="bottom-end" title={item.event.title}>
                <p
                  className={"text-sx w-full cursor-pointer truncate font-bold"}
                >
                  {item.event.title}
                </p>
              </Tooltip>

              <div className="flex w-fit items-center rounded-full bg-[#F3F3F4] px-3 py-1">
                <img
                  loading="lazy"
                  src="/assets/svgs/events/location.svg"
                  alt="icon"
                  className="h-auto w-4"
                />
                <Tooltip
                  arrow
                  placement="bottom-end"
                  title={
                    item.event.location +
                    (item.event.address ? ` ${item.event.address}` : "")
                  }
                >
                  <span className="text-sx ml-2 cursor-pointer">
                    {item.event.location}{" "}
                    {item.event.address ?? `, ${item.event.address}`}
                  </span>
                </Tooltip>
              </div>
              <div className="text-md flex items-center">
                {item?.reviews_avg_stars ? (
                  <>
                    <Rating
                      size="small"
                      name="read-only"
                      value={item.reviews_avg_stars}
                      readOnly
                      precision={0.5}
                    />
                    {Number(item.reviews_avg_stars).toFixed(2)}
                  </>
                ) : (
                  "No ratings yet"
                )}
              </div>

              <div className="grid w-full grid-cols-2 items-center text-center">
                {/* <div
                    style={{ whiteSpace: "nowrap" }}
                    className="flex w-fit items-center gap-3  rounded-full bg-[#F3F3F4] px-3 py-1 text-sm font-medium text-[#898D91]"
                  >
                    <WatchLaterIcon
                      sx={{ fill: "#FF913F", width: "15px", height: "15px" }}
                    />
                    {item.event.duration}
                  </div> */}
                {item?.event.types?.map((element: any) => (
                  <div
                    style={{ whiteSpace: "nowrap" }}
                    className="my-1 flex w-fit items-center rounded-full bg-[#F3F3F4] px-3 py-1 text-sm font-medium text-[#898D91]"
                  >
                    {element.title}
                  </div>
                ))}
              </div>
              <div className="flex w-full items-center ">
                <div className="rounded-full bg-[#F3F3F4] px-3 py-1">
                  {currency.symbol}
                  {(item.paid_amount * currency.exchange_rate).toFixed(2)}
                </div>
              </div>
            </div>
            <div className="mt-3 min-w-[800px]">
              <div className="h-px w-full bg-[#d0d2d3] bg-opacity-50" />
            </div>
          </>
        ))}
      </div>

      <div className="mb-10 mt-10 flex w-full justify-center">
        <Pagination
          count={userHistory.last_page}
          page={currentPage}
          onChange={handlePageChange}
        />
      </div>
    </div>
  );
};
export default History;
