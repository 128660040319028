import {TSvgComponent} from "./types";

const SearchSvg : TSvgComponent = ({...props}) =>{
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path fillRule="evenodd" clipRule="evenodd" d="M2.29161 9.1667C2.29161 5.36974 5.36965 2.2917 9.16661 2.2917C12.9636 2.2917 16.0416 5.36974 16.0416 9.1667C16.0416 12.9637 12.9636 16.0417 9.16661 16.0417C5.36965 16.0417 2.29161 12.9637 2.29161 9.1667ZM9.16661 1.0417C4.67929 1.0417 1.04161 4.67938 1.04161 9.1667C1.04161 13.654 4.67929 17.2917 9.16661 17.2917C11.1853 17.2917 13.032 16.5555 14.453 15.337L17.058 17.942C17.3021 18.1861 17.6978 18.1861 17.9419 17.942C18.186 17.6979 18.186 17.3022 17.9419 17.0581L15.3369 14.4531C16.5554 13.0321 17.2916 11.1854 17.2916 9.1667C17.2916 4.67938 13.6539 1.0417 9.16661 1.0417Z"/>
        </svg>
    )
}
export default SearchSvg
