import UserDetails from  './UserDetails'
import ChangePassword from "./ChangePassword";
import Notifications from "./Notifications";
import PaymentDetails from './PaymentDetails';
import { useAppDispatch } from '../../../../store/redux';
import { useEffect } from 'react';
import { scrollToTop } from '../../../../store/GlobalState/ActionGlobalState';
import StripeAccount from './StripeAccount';
const BusinessProfileSettings = () => {
    const dispatch = useAppDispatch()

    useEffect(()=>{
        dispatch(scrollToTop);
    },[])
    return (
        <div className={"mb-4"}>
            <div className={"grid xl:grid-cols-3 grid-cols-1 gap-4 mb-4"}>
                <UserDetails/>
                <ChangePassword/>
                <Notifications/>
            </div>
            <div className='flex flex-col gap-y-10 mt-10'>
                <StripeAccount/>
                <PaymentDetails />
            </div>
            <div className="flex mt-10">
                <img loading="lazy" className={"w-full"} src={"/assets/images/pages/Dashboard/UserProfile/mobile-coming-soon.png"} />
            </div>
        </div>

)
}
export default BusinessProfileSettings
