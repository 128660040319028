import {useEffect} from "react";
import Toggle from "../../../../../component/Inputs/Toggle";
import {useAppDispatch} from "../../../../../store/redux";
import {getNotficationList, toggleNotification} from "../../../../../store/Auth/ActionAuth";
import {useSelector} from "react-redux";
import {RootState} from "../../../../../store/store";
import {authSlice} from "../../../../../store/Auth/AuthSlice";
import {useTranslation} from "react-i18next";

const Notifications = () => {
    const dispatch = useAppDispatch();
    const {t} = useTranslation();
    const notificationList = useSelector((state: RootState) => state.authReducer?.notifications);
    const handleUpdateNotification = (id: number, index: number) => {

        const updatedData = notificationList.map((item) => {
            if (item.id === id) {
                // Create a new object with the existing properties and a toggled status
                return {
                    ...item,
                    status: !item.status,
                };
            }
            return item; // Return other items as is
        });
        dispatch(authSlice.actions.setNotificationList(updatedData))
        dispatch(toggleNotification(id)).then((res) => {

        }).catch((err) => {
            console.log(err)
        })

    }

    useEffect(() => {
        dispatch(getNotficationList())
    }, [])

    return (
        <div className={"rounded-xl relative  bg-white border-[#d0d2d3] border "}>
            <div className="container-fluid  w-full h-full  px-4 pt-3 pb-3">
                <div className="flex">
                    <p className={"font-bold text-xl"}>{t('businessProfile.setting.notification.title')}</p>
                </div>
                {
                    notificationList.map((item, index) => (
                        <div key={index}>
                            <p className={"text-[13px] mt-3"}>{item?.description}</p>
                            <div className="flex mt-5">
                                <div className={"basis-1/2 font-bold"}>
                                    {item?.name}
                                </div>
                                <div className="basis-1/2  text-end">
                                    <Toggle handleToggle={(e) => handleUpdateNotification(item.id, index)}
                                            checked={item.status}/>
                                </div>
                            </div>
                            <div className="flex mt-3">
                                <div className="bg-[#d0d2d3] w-full h-px bg-opacity-50"/>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>

    )
}
export default Notifications
