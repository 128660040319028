import { userProfileSideBar } from "../../constants/dashboard/UserProfileSideBar";
import { businessProfileSideBar } from "../../constants/dashboard/BusinessProfileSideBar";
import { useEffect, useState } from "react";
import { TSvgComponent } from "../SvgComponents/types";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store/redux";
import { handleIsOpen } from "../../store/UserDashboard/ActionUserDashboard";
import MenuIcon from "@mui/icons-material/Menu";
import { GenerateGuestToken, logoutUser } from "../../store/Auth/ActionAuth";
import { useTranslation } from "react-i18next";

export type TSideBar = {
  id: string;
  path: string;
  title: string;
  svg: TSvgComponent;
  isActive: boolean;
}[];
type props = {
  type?: string;
};
const DashboardSideBar = (props: props) => {
  const { isMobile, isOpen } = useAppSelector(
    (state) => state.userDashboardReducer,
  );
  const [sideBarItems, setSideBarItems] = useState<TSideBar>([]);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  useEffect(() => {
    let sideBarItems = [];
    if (props.type === "userDashboard") sideBarItems = userProfileSideBar;
    else sideBarItems = businessProfileSideBar;
    setSideBarItems(
      sideBarItems.map((item) => {
        return {
          ...item,
          isActive: location.pathname === item.path,
        };
      }),
    );
  }, [location.pathname]);
  const changeSideMenu = (index: number) => {
    setSideBarItems(
      sideBarItems.map((item, itemIndex) => {
        return {
          ...item,
          isActive: index === itemIndex,
        };
      }),
    );
    if (isMobile) {
      dispatch(handleIsOpen(!isOpen));
    }
    navigate(sideBarItems[index].path);
  };
  const closeMenu = () => {
    dispatch(handleIsOpen(!isOpen));
  };
  const handleLogout = () => {
    dispatch(logoutUser()).then(() => {
      dispatch(GenerateGuestToken()).then((res) => {
        localStorage.setItem('clientToken', res);
        navigate("/");  
    });
     
    });
  };
  return (
    <div
      className={
        "fixed z-50 mb-3  rounded-3xl bg-white shadow-[0px_0px_100px_0px_rgba(0,0,0,0.05)] max-h-[90vh] max-md:inset-0 md:relative md:z-0"
      }
    >
      <div className="container-fluid  mt-4">
        <div className="flex px-7 md:px-0">
          {isMobile && (
            <MenuIcon
              className={"cursor-pointer"}
              onClick={() => closeMenu()}
            />
          )}
          <p className="px-4 text-lg font-bold 2xl:px-7 2xl:text-xl ">
            {t("dashboardSideBar.title")}
          </p>
        </div>
        <div>
          {sideBarItems.map((item, index) => {
            return (
              <div
                key={index}
                onClick={() => changeSideMenu(index)}
                className={
                  "relative mt-5 flex cursor-pointer items-center px-7 py-2 " +
                  (item.isActive &&
                    "before:absolute before:left-0 before:h-full before:w-1 before:rounded-r-xl before:bg-primary-orange")
                }
              >
                {item.svg({
                  className: item.isActive
                    ? "fill-primary-orange"
                    : "fill-primary-gray",
                })}
                <p
                  className={
                    "color-[#131C23] px-4 " + (item.isActive && "font-bold")
                  }
                >
                  {t(`dashboardSideBar.sidebar.${item.id}`)}
                </p>
              </div>
            );
          })}
        </div>
        <div className="absolute bottom-0 mb-10 flex w-full px-4">
          <button
            onClick={handleLogout}
            className={
              "w-full rounded-full border-2 border-[#FF3F4D] bg-[#FF3F4D22] p-3 font-bold"
            }
          >
            {" "}
            {t("dashboardSideBar.logout")}
          </button>
        </div>
      </div>
    </div>
  );
};
export default DashboardSideBar;
