import React, { useEffect } from "react";
import Header from "../../../../component/Header";
import "../style.css";
import StaticBanner from "../../../../component/StaticBanner";
import OurMissionSummary from "./components/OurMissionSummary";
import { missionBanner } from "../../../../constants/bannerData";

const OurMission = () => {
  return (
    <div className="">
      <Header />
      <StaticBanner data={missionBanner}/>
      <OurMissionSummary />
    </div>
  );
};

export default OurMission;