
import {TSvgComponent} from "./types";

const NotificationSvg: TSvgComponent = ({...props}) => {
    return (
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M21.5479 19.9852V20.9852H3.54788V19.9852L5.54788 17.9852V11.9852C5.54788 8.88517 7.57788 6.15517 10.5479 5.27517C10.5479 5.17517 10.5479 5.08517 10.5479 4.98517C10.5479 4.45474 10.7586 3.94603 11.1337 3.57095C11.5087 3.19588 12.0174 2.98517 12.5479 2.98517C13.0783 2.98517 13.587 3.19588 13.9621 3.57095C14.3372 3.94603 14.5479 4.45474 14.5479 4.98517C14.5479 5.08517 14.5479 5.17517 14.5479 5.27517C17.5179 6.15517 19.5479 8.88517 19.5479 11.9852V17.9852L21.5479 19.9852ZM14.5479 21.9852C14.5479 22.5156 14.3372 23.0243 13.9621 23.3994C13.587 23.7745 13.0783 23.9852 12.5479 23.9852C12.0174 23.9852 11.5087 23.7745 11.1337 23.3994C10.7586 23.0243 10.5479 22.5156 10.5479 21.9852" fill="grey"/>
        </svg>
        
    )
}
export default NotificationSvg

