import React from "react";
import { useTranslation } from "react-i18next";

interface RecentContributorsProps {
  recentContributors: {
    id: number;
    name: string;
    amount: string;
    words_of_support: string;
    created_at: string;
  }[];
}

export const VulnerableCommunities: React.FC<RecentContributorsProps> = ({ recentContributors }) => {
  const { t } = useTranslation();

  return (
    <section>
      <div className="flex justify-between">
        <h1 className="text-3xl text-white max-md:text-center">
          {t("crowdfunding.hereToSupport")}
          <br />
          {t("crowdfunding.vulnerable")}
        </h1>
        <img src="/assets/images/heart.png" className="w-14 h-14 max-md:hidden"/>
      </div>
      <div className="text-[#B1B1B1] text-md py-5 max-md:text-center">
        <p className="">
          {t("crowdfunding.supportDescription_1")}
        </p>
        <p className="pt-4">
          {t("crowdfunding.supportDescription_2")}
        </p>
        <p className="pt-4">
          {t('crowdfunding.supportDescription_3')}
        </p>
        <p className="pt-4">
          {t('crowdfunding.supportDescription_4')}
        </p>
        <p className="pt-4">
          {t('crowdfunding.supportDescription_5')}
        </p>
        <p className="pt-4">
          {t('crowdfunding.supportDescription_6')}
        </p>
        <p className="pt-4">
          {t('crowdfunding.supportDescription_7')}
        </p>
      </div>
      <img src="/assets/images/vulnerable.png" className="my-5 w-full"/>
      <div className="bg-white rounded-xl p-4 mt-4 text-[#1B1B1B] max-md:my-5 max-md:hidden">
        <h1 className="text-lg font-bold gap-2 flex items-center">
          {t("crowdfunding.recentContributors")}
        </h1>
        <div className="grid md:grid-cols-3 max-md:grid-cols-2 gap-4 mt-4">
          {
            recentContributors.length === 0 ? (
              <p className="text-primary-orange font-bold">No contributors yet</p>
            ) : (
              recentContributors.map((recentContributor) => (
                <div key={recentContributor.name}>
                  <p className="text-[#667085]">{recentContributor.name}</p>
                  <p>❤️ £ {recentContributor.amount}</p>
                </div>
              ))
            )
          }
        </div>
      </div>
    </section>
  );
};
export default VulnerableCommunities;