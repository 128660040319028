export const exploreNearMeData =
    {
        id: 1,
        title: 'Explore Near Me\n' + 'Exclusive',
        slogan: 'We\'re putting together a selection of events.',
        activity: 'The activities can be quite different',
        background_image: "/assets/images/pages/home/explore-bg.png",
        image: "/assets/images/pages/home/explore-nearme.webp",
        button_text: "View Selection",
        button_url: "/allEvents/exclusive"
    };