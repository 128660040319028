import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {TLanguage} from "./types";
export const initialState: TLanguage = {
    selectedLanguage: 'en'
}

export const languageSlice = createSlice({
    name: 'language',
    initialState,
    reducers: {
        setLanguage(state,action: PayloadAction<string>) {
            state.selectedLanguage = action.payload;
        },
    }
})
export const { setLanguage } = languageSlice.actions;
export default languageSlice.reducer;
