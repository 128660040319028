

import {TSvgComponent} from "./types";

const DocumentSvg: TSvgComponent = ({...props}) => {
    return (
        <svg width="25" height="24" viewBox="0 0 25 24"  fill="#131C23" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.8288 9H19.3288L13.8288 3.5V9ZM6.82875 2H14.8288L20.8288 8V20C20.8288 20.5304 20.618 21.0391 20.243 21.4142C19.8679 21.7893 19.3592 22 18.8288 22H6.82875C5.71875 22 4.82875 21.1 4.82875 20V4C4.82875 2.89 5.71875 2 6.82875 2ZM15.8288 18V16H6.82875V18H15.8288ZM18.8288 14V12H6.82875V14H18.8288Z" fill="grey"/>
        </svg>        
    )
}
export default DocumentSvg

