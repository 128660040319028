import React, { useEffect, useState } from "react";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import PersonIcon from "@mui/icons-material/Person";
import EventIcon from "@mui/icons-material/Event";
import PrimaryButton from "../../../../../component/PrimaryButton";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../../../store/redux";
import { getTicket, submitTicket } from "../../../../../store/Cart/ActionCart";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store/store";
import { useTranslation } from "react-i18next";

const TicketConfirmation = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const urlParams = useLocation();
  const code = new URLSearchParams(urlParams.search).get("code");
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const reservedTickets = useSelector((state: RootState) => state.cartReducer.singleTicket);

  const navigate = useNavigate();
  const handleSubmitTicket = () => {
    if (code !== null) dispatch(submitTicket(code)).then(() => {
      setButtonDisabled(true);
    }).catch(() => {
    });
  };
  useEffect(() => {
    if (code !== null) dispatch(getTicket(code)).then((res) => {
      setButtonDisabled(res.scanned === 1 && true);
    }).catch(() => {
    });
  }, []);
  useEffect(() => {
    console.log("reservedTickets", reservedTickets);
  }, [reservedTickets]);

  return (
    <div className="min-h-[calc(100vh-350px)] flex w-full justify-center items-center">
      <div className="bg-white my-10">
        <div className="grid grid-cols-2 px-5">
          <p className="text-xl font-bold">{t("userProfile.tickets.ticket")}</p>
        </div>
        <div className="mt-4 grid h-[400px] w-full px-2">
          <div className="rounded-2xl bg-[#131c23] w-full ">
            <div className=" px-5 py-5 ">
              <p className="text-primary-orange flex items-center">
                Operator:
                <img loading="lazy" className="w-5 h-5 rounded-full ml-2"
                     src={reservedTickets?.ticket?.age?.event_age?.business?.profile_pic} />
                <b className="text-white ml-2">
                  {reservedTickets?.ticket?.age?.event_age?.business.name}
                </b>
              </p>
              <p className="text-primary-orange">
                {t("userProfile.tickets.phoneNumber")}
                <b className="ms-4 text-white">
                  {reservedTickets?.ticket?.age?.event_age?.business?.phone_number}
                </b>
              </p>
              <p className="mt-4 text-2xl font-bold text-white">
                {reservedTickets?.ticket?.age?.event?.title}
              </p>
              <div className="flex items-center mt-4 gap-5">
                <div className="flex items-center justify-center rounded-lg bg-[#172739] px-3 py-3">
                  <FmdGoodIcon style={{ color: "#3f8cff" }} />
                </div>
                <div>
                  <b className="text-white">{t("userProfile.tickets.address")}</b>
                  <p
                    className="text-white">{reservedTickets?.ticket?.age?.event?.address}</p>
                </div>
              </div>
              <div className="mt-4 grid grid-cols-[1fr_9fr] gap-5">
                {/*<div className="flex items-center justify-center rounded-lg bg-[#172739] px-3 py-3">*/}
                {/*    <FmdGoodIcon style={{ color: "#3f8cff" }} />*/}
                {/*</div>*/}
                <div className="flex items-center justify-center rounded-lg bg-[#271f39] px-3 py-3">
                  <PersonIcon style={{ color: "#db3fff" }} />
                </div>
                <div>
                  <b className="text-white">{t("userProfile.tickets.people")}</b>
                  <p className="text-white">{reservedTickets?.ticket?.age?.event_age?.label}</p>
                </div>

                <div className="flex items-center justify-center rounded-lg bg-[#1b2d2f] px-3 py-3">
                  <EventIcon style={{ color: "#65cc93" }} />
                </div>
                <div>
                  <b className="text-white"> {t("userProfile.tickets.date")}</b>
                  <p className="text-white">{reservedTickets?.ticket?.date}</p>
                </div>
              </div>
              <div className="grid mt-3 px-3 py-3 rounded-xl bg-[#2a2826]">
                <PrimaryButton
                  disabled={buttonDisabled}
                  onClick={handleSubmitTicket}>{t("userProfile.tickets.checkIn")}</PrimaryButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TicketConfirmation;
