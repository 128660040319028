import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TCart, TCartItem, TEventData, TReservedTicket, TUserData, TUserTicketData } from './types';
const userInfo = JSON?.parse(localStorage.getItem("userInfo") as string) ?? null
type TInitial = {
    isLoading: boolean,
    cart: TCart,
    cartItem: TCartItem
    checkoutState: {
        userData: TUserData,
        errors: any,
        paymentSelection: string,
        clientSecretKey: string,
        clientCode: string
    }
    selectedCart:TCart[];
    userTickets: TUserTicketData;
    reservedTickets:TReservedTicket[];
    singleTicket: TReservedTicket
    cartItemCount:number;
}
export const initialState: TInitial = {
    isLoading: false,
    cart: {} as TCart,
    cartItem: {
        data: [],
        selectedCart:[],
        cartTotal: 0,
        message: "",
        success: false,
    },
    checkoutState: {
        userData: {
            name: userInfo?.name,
            email: userInfo?.email,
            phone_number: userInfo?.phone_number,
            your_wish: '',
            total_price: 0,
            initial_total_price: 0
        },
        errors: {},
        paymentSelection: '1',
        clientSecretKey: '',
        clientCode: '',
    },
    selectedCart:[],
    userTickets:{} as TUserTicketData,
    reservedTickets: [] as TReservedTicket[],
    singleTicket: {} as TReservedTicket,
    cartItemCount:0 as number,
}
const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        setLoading(state, action: PayloadAction<boolean>) {
            state.isLoading = action.payload;
        },
        addToCartItem(state, action: PayloadAction<TCart>) {
            state.cart = action.payload;
        },
        removeFromCart(state, action: PayloadAction<number>) {
            state.cartItem.data = state.cartItem.data.filter(
                (item) => item.id !== action.payload
            );
        },
        setCartItem(state, action: PayloadAction<TEventData[]>) {
            state.cartItem.data = action.payload;
        },
        setSelectedCartItem(state, action: PayloadAction<TEventData[]>) {
            state.cartItem.selectedCart = action.payload;
        },
        setUserData(state, action: PayloadAction<{ name: string; value: any }>) {
            state.checkoutState.userData = {
                ...state.checkoutState.userData,
                [action.payload.name]: action.payload.value,
            };
        },
        setUserTicket(state, action: PayloadAction<{ name: string; value: any }>) {
            state.userTickets = {
                ...state.userTickets,
                [action.payload.name]: action.payload.value,
            };
        },
        setReservedTickets(state, action: PayloadAction<TReservedTicket[]>) {
            state.reservedTickets = action.payload;
        },
        setSingleTicket(state, action: PayloadAction<TReservedTicket>) {
            state.singleTicket = action.payload;
        },
        setErrors(state, action: PayloadAction<Object | null>) {
            state.checkoutState.errors = action.payload;
        },
        setPaymentSelection(state, action: PayloadAction<string>) {
            state.checkoutState.paymentSelection = action.payload;
        },
        setClientSecretKey(state, action: PayloadAction<string>) {
            state.checkoutState.clientSecretKey = action.payload;
        },
        setCartTotal(state, action: PayloadAction<number>) {
            state.cartItem.cartTotal = action.payload;
        },
        setSelectedCart(state, action: PayloadAction<TCart[]>) {
            state.selectedCart = action.payload;
        },
        setClientCode(state, action: PayloadAction<string>) {
            state.checkoutState.clientCode = action.payload;
        },
        setCartItemCount(state, action: PayloadAction<number>) {
            state.cartItemCount = action.payload;
        }
        // removeEventFromCart(state, action: PayloadAction<number>) {
        //     state.cartItem.data = state.cartItem.data.filter(
        //         (item) => item.event.id !== action.payload
        //     );
        // },
    },
});


export const { setLoading, addToCartItem, removeFromCart,setSelectedCart, setCartItem,setSelectedCartItem, setUserData, setPaymentSelection, setClientSecretKey, setCartTotal,setClientCode,setUserTicket,setReservedTickets,setSingleTicket,setCartItemCount} = cartSlice.actions;

export default cartSlice.reducer;
