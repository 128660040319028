import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {useState, useEffect} from "react";
import {useAppDispatch, useAppSelector} from "../../../../store/redux";
import {getCurrencies} from "../../../../store/Currencies/ActionCurrencies";
import {setSelectedCurrency} from "../../../../store/Currencies/CurrenciesSlice";
import {TCurrency} from "../../../../store/Currencies/types";

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 900,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: 3
};

const getRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
};
const Currency = () => {
    const dispatch = useAppDispatch();
    const [open, setOpen] = useState(false);
    const [, setSelectedCurrencyIndex] = useState(localStorage.getItem("selectedCurrencyIndex") || -1);
    const [, setCurrencyColors] = useState<{ [key: number]: string }>({});
    const [selectedCurrencySymbol, setSelectedCurrencySymbol] = useState('');
    const {currencies,} = useAppSelector(state => state.currenciesReducer);
    const [currencySelected, setCurrencySelected] = useState<any>({})
    const [isMobileScreen, setIsMobileScreen] = useState(false);

    const handleOpen = (e:any) => {
        setOpen(true);
        generateRandomColors();
    };
    const handleClose = () => {
        setOpen(false);
        setSelectedCurrencyIndex(-1);
    };
    const handleCurrencyClick = (currency: TCurrency) => {
        setCurrencySelected(currency)
        setSelectedCurrencySymbol(`${currency?.abbreviation} - ${currency?.symbol}`);
        localStorage.setItem('selectedCurrency', JSON.stringify(currency));
        localStorage.setItem('selectedCurrencySymbol', selectedCurrencySymbol);
        dispatch(setSelectedCurrency(currency));
        setOpen(false)
    };

    const generateRandomColors = () => {
        const colors: { [key: number]: string } = {};
        currencies.forEach((currency, index) => {
            colors[index] = getRandomColor();
        });
        setCurrencyColors(colors);
    };
    useEffect(() => {
        dispatch(getCurrencies());
        generateRandomColors();
        if (localStorage.getItem('selectedCurrency')) {
            const currencySelected = JSON?.parse(localStorage.getItem('selectedCurrency') ?? '')
            setCurrencySelected(JSON?.parse(localStorage.getItem('selectedCurrency') ?? ''))
            setSelectedCurrencySymbol(`${currencySelected?.abbreviation} - ${currencySelected?.symbol}`);
        } else {
            setCurrencySelected({
                id: 1,
                abbreviation: "USD",
                exchange_rate: 1.00,
                name: "Us Dollar",
                symbol: '$',
            })
            localStorage.setItem('selectedCurrency', JSON.stringify({
                id: 1,
                abbreviation: "USD",
                exchange_rate: 1.00,
                name: "Us Dollar",
                symbol: '$',
            }))
        }
    }, []);
    useEffect(() => {
        const handleResize = () => {
            setIsMobileScreen(window.innerWidth < 768);
        };
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);
    return (
        <>
            <div className="flex gap-2">
                {
                    isMobileScreen &&(
                        <img src="/assets/svgs/common/currency-logo.svg" className="w-5 h-5"/>
                    )
                }
                <button className="text-sm flex items-center" onClick={handleOpen}>
                    {selectedCurrencySymbol || "USD-$"}
                    <img loading="lazy" src="/assets/svgs/down-arrow.png" className="w-4 h-4"/>
                </button>
            </div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        <span className="text-[#131C23] font-bold text-2xl max-md:hidden">Select Currency</span>
                    </Typography>
                    <div className="grid md:grid-cols-3 grid-cols-2 gap-5">
                        {currencies?.map((currency) => {
                            return (
                                <div
                                    key={currency.id}
                                    className={`flex items-center justify-between cursor-pointer p-4 duration-200 hover:bg-primary-orange/10 rounded-lg ${
                                        currency.id.toString() === localStorage.getItem('selectedCurrency')
                                            ? "bg-[#131C23]/10 rounded-xl"
                                            : ""
                                    }`}
                                    onClick={() => handleCurrencyClick(currency)}
                                >
                                    <div className="flex items-center gap-2">
                                        <div className="flex gap-2 items-center">
                                            {currency.logo && <img src={currency.logo}/>}
                                        </div>
                                        <div>
                                            <h2 className="text-black font-bold text-sm">{currency.name}</h2>
                                            <p className="text-[#131C2388] text-sm">
                                                {currency.abbreviation} - {currency.symbol}
                                            </p>
                                        </div>
                                    </div>
                                    <div>{currency.id === currencySelected.id &&
                                        <img loading="lazy" src="/assets/svgs/common/check.svg"/>}</div>
                                </div>
                            )
                        })}
                    </div>
                </Box>
            </Modal>
        </>
    )
}
export default Currency;