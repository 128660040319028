import React from "react";
import VulnerableCommunities from "./components/VulnerableCommunities";
import Contribution from "./components/Contribution";

interface RecentContributorsProps {
  recentContributors: {
    id: number;
    name: string;
    amount: string;
    words_of_support: string;
    created_at: string;
  }[];
}

export const SupportCommunities: React.FC<RecentContributorsProps> = ({ recentContributors }) => {
  return (
    <main className="bg-[#141D24] rounded-3xl p-8 my-10">
      <div className="md:grid md:grid-cols-[2fr_1fr] gap-8">
        <VulnerableCommunities recentContributors={recentContributors} />
        <Contribution recentContributors={recentContributors}/>
      </div>
    </main>
  );
};
export default SupportCommunities;