import {Link} from "react-router-dom";
import {useGetImportantInformation} from "../hooks";
import React from "react";

export const ImportantInformation = () => {
    const {t, translatedContent} = useGetImportantInformation();
    return (
        <section id="important-information" className="container md:my-10 max-md:mt-20 md:pt-10">
            <div
                className="mt-5 flex items-center rounded-b-xl rounded-t-3xl t md:p-5">
                <div className="container place-items-center">
                    <div className="md:grid md:grid-cols-2 max-md:text-center">
                        <div className="md:py-5 max-md:pb-5 text-black md:mr-10">
                            <h1 className="text-xl font-bold md:text-3xl gap-2 flex items-center max-md:justify-center">
                                {t('importantInformation')}
                                <div className="border-b-[3px] border-black w-[60px] mt-2 rounded-2xl"></div>
                            </h1>
                            <p className="text-primary-orange font-bold">{t('message')} </p>
                            <p className="text-lg text-black opacity-80 max-md:text-center">
                                <h3 className="italic pt-8">{translatedContent}</h3>
                                <p className="text-primary-orange font-bold">{t("author")}</p>
                            </p>
                            <div className="md:flex md:justify-end items-center text-center mt-5">
                                <div className="max-md:flex max-md:justify-center max-md:mt-10">
                                    <Link to="our-mission">
                                        <button
                                            className="bg-[#93DBDF] text-black rounded-full md:px-5 max-md:px-10 py-3 font-bold flex items-center justify-center gap-1">
                                            {t('ourMission')}
                                            <img src="/assets/svgs/home/arrow-black.svg"/>
                                        </button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div
                            className="bg-[url(/public/assets/images/pages/home/video-bg.png)] lg:bg-contain bg-center bg-no-repeat grid justify-center place-content-center max-h-full max-w-full max-md:hidden">
                            <div className="">
                                <iframe width="200%" height="100%"
                                        src="https://www.youtube.com/embed/26zOFiqCC4w?si=xRYXSXVYPEssMEzP"
                                        title="YouTube video player"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        allowFullScreen className="rounded-2xl"></iframe>
                            </div>
                            <p className="text-black font-bold md:text-md italic pt-3 px-3">{t('videoText')}
                                <span> {t('ordinary')}</span></p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
export default ImportantInformation;