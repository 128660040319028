import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {TCurrency} from "./types";

type TInitial = {
    isLoading:boolean,
    currencies:TCurrency[],
    selectedCurrency:TCurrency,
}
export const initialState: TInitial = {
    isLoading: false,
    currencies:[],
    selectedCurrency: {} as TCurrency,
}

export const currenciesSlice = createSlice({
    name: 'currencies',
    initialState,
    reducers: {
        setLoading(state, action: PayloadAction<boolean>) {
            state.isLoading = action.payload;
        },
        setCurrencies(state, action: PayloadAction<TCurrency[]>) {
            state.currencies = action.payload;
        },
        setSelectedCurrency(state, action: PayloadAction<TCurrency>) {
            state.selectedCurrency = action.payload
        },
    },
});
export const { setLoading, setCurrencies, setSelectedCurrency } = currenciesSlice.actions;

export default currenciesSlice.reducer;
