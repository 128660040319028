import Events from "../../component/events/index";
import Banner from "./components/Banner";
import React, {lazy, Suspense} from 'react';
import {Link} from "react-router-dom";
import Header from "../../component/Header";
// import Partners from "./components/Partners";
import VerificationEmail from "../../component/Header/component/SignUp/VerificationEmail";
import PageLoader from "../../component/PageLoader";
import ImportantInformation from "./components/ImportantInformation";
import {useGetHomeData} from "./hooks";
import Marquee from "./components/Marquee";
import { useGetNews } from "./hooks";

const GoogleMapsComponent = lazy(() =>
    import('./components/GoogleMaps/GoogleMapsComponent'));

const ExploreNearMe = lazy(() => import('./components/ExploreNearMe/ExploreNearMe'))
const PromotionEvents = lazy(() => import("./components/PromotionEvents"))
const Partners = lazy(() => import('./components/Partners'))
const BecomeOurClient = lazy(() => import('./components/BecomeOurClient'))
const BecomeOurPartner = lazy(() => import('./components/BecomeOurPartner'))

const HomePage = () => {
    const {
        t,
        showVerificationModal,
        googleMapMarkers,
        loading,
        pageAlreadyLoaded,
        defaultLocation,
        handleCloseVerificationModal
    } = useGetHomeData();

    const { translatedContent, isLoading } = useGetNews();

    return (
        <main id="home-page">
            {!pageAlreadyLoaded && <PageLoader loading={loading}/>}
            <Header sticky/>
            <Banner/>
            <ImportantInformation/>
            {/*<Marquee id="home" data={translatedContent}/>*/}
            <section className="container flex flex-col ">
                <div className="md:mt-30 mt-10 items-center md:flex md:justify-between lg:mt-[80px]">
                    <div className="flex items-center max-md:justify-center">
                        <h2 className="text-3xl md:text-4xl mt-20 md:mt-0 flex gap-2 items-center">
                            {t("allEvents")}
                            <div className="border-b-[3px] border-black w-[60px] mt-2 rounded-2xl"></div>
                        </h2>
                    </div>
                    <div className="flex pt-4 md:pt-0 max-md:justify-center max-md:hidden">
                        <Link
                            to="/allEvents/large"
                            className="mr-2 text-center text-base font-medium"
                        >
                            <div className="flex cursor-pointer rounded-full border-2 border-[#FF913F] px-5 py-2">
                                {t("seeAll")}
                                <img
                                    loading="lazy"
                                    src="/assets/svgs/common/black-arrow.svg"
                                    className="pl-2" alt=""
                                />
                            </div>
                        </Link>
                    </div>
                </div>
                <Events filterTabs={true}/>
                <div className="flex pt-4 md:pt-0 max-md:justify-center md:hidden">
                    <Link
                        to="/allEvents/large"
                        className="mr-2 text-center text-base font-medium"
                    >
                        <div className="flex cursor-pointer rounded-full border-2 border-[#FF913F] px-20 my-10 py-2">
                            {t("seeAll")}
                            <img
                                loading="lazy"
                                src="/assets/svgs/common/black-arrow.svg"
                                className="pl-2" alt=""
                            />
                        </div>
                    </Link>
                </div>
            </section>
            <Suspense fallback={<PageLoader loading={true}/>}>
                <GoogleMapsComponent
                    markers={googleMapMarkers}
                    defaultLocation={defaultLocation}
                />
                <ExploreNearMe/>
                <PromotionEvents filterTabs={true}/>
                <Partners/>
                <BecomeOurClient/>
                <BecomeOurPartner/>
            </Suspense>

            <VerificationEmail
                onClose={handleCloseVerificationModal}
                open={showVerificationModal}
            />

        </main>
    );
};
export default HomePage;