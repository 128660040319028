import axios from "axios";
import { IGamificationData } from "./types";


export const getUserGamificationAPI = async (): Promise<IGamificationData | null> => {
    try {
        const response = await axios.get('/api/users/profile/gamification');
        let data = response.data.data
        return {
            achievments: data.achievements,
            user_level: data.user_level,
            user_tasks: {
                all: data.user_tasks.done.concat(data.user_tasks.progress),
                done: data.user_tasks.done,
                progress: data.user_tasks.progress,
            }
        };
    } catch (error) {
        throw error;
    }
};
