import {Box, Modal} from "@mui/material";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import PersonIcon from "@mui/icons-material/Person";
import EventIcon from "@mui/icons-material/Event";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {useSelector} from "react-redux";
import {RootState} from "../../../../../store/store";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

type TModal = {
    open: boolean;
    onClose: () => void;
};

const TicketComponent = ({open, onClose}: TModal) => {
    const {t} = useTranslation();
    const reservedTickets = useSelector(
        (state: RootState) => state.cartReducer.reservedTickets,
    );
    const [ticketIndex, setTicketIndex] = useState(0);
    const style = {
        position: "absolute" as "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 900,
        bgcolor: "background.paper",
        boxShadow: 24,
        pt: 2,
        pb:7,
        borderRadius: 6,
    };

    const handleArrowClick = (type: string) => {
        if (type === 'back') {
            setTicketIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
        } else {
            setTicketIndex((prevIndex) => (prevIndex < reservedTickets.length - 1 ? prevIndex + 1 : prevIndex));
        }
    };
    useEffect(() => {
        setTicketIndex(0)
    }, [reservedTickets])

    useEffect(()=>{
        console.log("reservedTickets",reservedTickets)
    },[reservedTickets])
    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <div>
                    <div className="grid grid-cols-2 px-5">
                        <p className="text-xl font-bold">{t('userProfile.tickets.yourTicket')}</p>
                        <div className="flex justify-end gap-4">
                            <p className="text-[#131C2388] flex items-center gap-3">
                                <button onClick={() => handleArrowClick('back')}><ArrowBackIcon
                                    className="!fill-primary-orange cursor-pointer"/></button>
                                {t('userProfile.tickets.ticket')} : {ticketIndex + 1} of {reservedTickets.length}
                                <div onClick={() => handleArrowClick('next')}><ArrowForwardIcon
                                    className="!fill-primary-orange cursor-pointer"/></div>
                            </p>
                        </div>
                    </div>
                    <div className="mt-4 grid px-2 bg-[#131c23]">
                        <div className="grid grid-cols-[2fr_1fr] rounded-2xl bg-[#131c23]">
                            <div className=" border-r-4 border-dashed border-[#42494f] px-5 py-5 ">
                                <p className="text-primary-orange flex items-center">
                                    Operator:
                                    <img loading="lazy" className="w-5 h-5 rounded-full ml-2"
                                         src={reservedTickets[ticketIndex]?.ticket?.age.event_age.business.profile_pic} />
                                    <b className="text-white ml-2">
                                        {reservedTickets[ticketIndex]?.ticket?.age?.event_age?.business.name}
                                    </b>
                                </p>
                                <p className="text-primary-orange">
                                    {t("userProfile.tickets.phoneNumber")}:
                                    <b className="ms-4 text-white">
                                        {reservedTickets[ticketIndex]?.ticket?.age?.event_age?.business?.phone_number}
                                    </b>
                                </p>
                                <p className="mt-4 text-2xl font-bold text-white">
                                    {reservedTickets[ticketIndex]?.ticket?.age.event.title}
                                </p>
                                <div className="mt-4 grid grid-cols-[1fr_9fr] gap-5">
                                    <div className="flex items-center justify-center rounded-lg bg-[#172739] px-3 py-3">
                                        <FmdGoodIcon style={{ color: "#3f8cff" }} />
                                    </div>
                                    <div>
                                        <b className="text-white">{t("userProfile.tickets.address")}</b>
                                        <p
                                          className="text-white">{reservedTickets[ticketIndex]?.ticket?.age?.event?.address}</p>
                                    </div>

                                    <div className="flex items-center justify-center rounded-lg bg-[#271f39] px-3 py-3">
                                        <PersonIcon style={{ color: "#db3fff" }} />
                                    </div>
                                    <div>
                                        <b className="text-white">{t("userProfile.tickets.people")}</b>
                                        <p
                                          className="text-white">{reservedTickets[ticketIndex]?.ticket.age?.event_age?.label}</p>
                                    </div>

                                    <div className="flex items-center justify-center rounded-lg bg-[#1b2d2f] px-3 py-3">
                                        <EventIcon style={{ color: "#65cc93" }} />
                                    </div>
                                    <div>
                                        <b className="text-white">{t("userProfile.tickets.date")}</b>
                                        <p className="text-white">{reservedTickets[ticketIndex]?.ticket?.date}</p>
                                    </div>
                                </div>
                                {/* <div className="grid grid-cols-2 mt-3 px-3 py-3 rounded-xl bg-[#2a2826]">
                                    <div className="text-white">
                                        Price
                                    </div>
                                    <div className="flex justify-end">
                                        <b className="text-[#ff913f] text-lg">$212.00</b>
                                    </div>
                                </div> */}
                            </div>
                            <div className="grid items-center justify-center rounded-2xl bg-[#131c23]">
                                <img loading="lazy" src={reservedTickets[ticketIndex]?.ticket?.qr_code} />
                            </div>
                        </div>
                        <p className="text-primary-orange py-3 px-3 font-bold">
                                Thank you for using Explore near me Ltd , By purchasing this Ticket you have
                                contributed a portion of tickets cost towards Explore near me Ltd Mission in
                                Helping Orphans
                        </p>
                    </div>
                </div>
            </Box>
        </Modal>
    );
};
export default TicketComponent;
