import axios from "axios";
import {TCurrency} from "../../store/Currencies/types";
export const getCurrenciesAxios = async () => {
    try {
        const response = await axios.get<{data: { currencies: TCurrency[]}}>(
            '/api/currency'
        );
        return response.data.data.currencies

    } catch (error) {
        console.error("Error:", error);
        return null
    }
};