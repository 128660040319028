import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React from "react";
import {useAppDispatch} from "../../../../store/redux";
import {IEventsData} from "../../../../networking/events/types";
import {addEventToCategory, getWishlistsCategories} from "../../../../store/Wishlist/ActionWishlist";
import {CategoryType} from "../../../../store/Wishlist/types";

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: 3,
};

const AddEventCategory = ({allEvents, selectedCategoryId, categories}: {
    allEvents: IEventsData[];
    selectedCategoryId: number | null;
    categories: CategoryType[]
}) => {
    const dispatch = useAppDispatch();
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleEventClick = (category: number | null, event_id: number) => {
        if (category !== null) {
            dispatch(addEventToCategory(category, event_id));
            handleClose();
            dispatch(getWishlistsCategories());
        } else {
            console.error("selectedCategoryId is null.");
        }
    };
    const eventsNotInCategory = allEvents.filter((event) => {
        const category = categories.find((c) => c.id === selectedCategoryId);
        if (category) {
            return !category.user_liked_events.some((eventType) => eventType.event.id === event.id);
        }
        return true;
    });

    return (
        <>
            <div className="flex justify-end">
                <button
                    className="border-2 border-[#FF913F] rounded-full font-medium px-3 py-2"
                    onClick={handleOpen}
                >
                    Add Event
                </button>
            </div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" className={"text-center"}>
                        <span className="text-[#131C23] font-bold text-2xl">Add Event To Category </span>
                        <p className="text-sm text-[#131C23]/40 font-bold">Your Liked Events</p>
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        {eventsNotInCategory.length === 0 ? (
                            <p className="text-primary-orange font-bold text-center text-xl">
                                You don't have any events to add to this category.
                            </p>
                        ) : (
                            eventsNotInCategory.map((event: IEventsData) => (
                                <div
                                    className="rounded-md flex px-2 my-3 py-3 bg-primary-orange/10 cursor-pointer group hover:bg-[#FF913F] hover:text-white transition duration-200"
                                    key={event.id}
                                    onClick={() => handleEventClick(selectedCategoryId, event.pivot.id)}
                                >
                                    <div className="before:content-['\2022'] before:text-[#FF913F] group-hover:before:text-white duration-200 before:mr-2"></div>
                                    <div className="text-sm text-[#131C2388]">
                                        <h4 className="text-[#FF913F] group-hover:text-white duration-200">{event.title}</h4>
                                        <p>Location: {event.location}</p>
                                        <p>Price: {event.display_price}</p>
                                    </div>
                                </div>
                            ))
                        )}
                    </Typography>
                </Box>
            </Modal>
        </>
    )
}

export default AddEventCategory;