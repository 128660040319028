import Header from "../../../component/Header";
import DashboardSideBar from "../../../component/DashboardSideBar";
import {Outlet} from "react-router-dom";
import {useEffect} from "react";
import {useAppDispatch, useAppSelector} from "../../../store/redux";
import {handleIsOpen, handleMobile} from "../../../store/UserDashboard/ActionUserDashboard";
import MenuIcon from "@mui/icons-material/Menu";

type props = {
    type ?:string
}
const Layout = (props:props) => {
    const {isMobile , isOpen} = useAppSelector(state => state.userDashboardReducer);
    const dispatch = useAppDispatch()

    useEffect(() => {
        const handleResize = () => {
            dispatch(handleMobile(window.innerWidth < 768))
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const toggleSidebar = () => {
        dispatch(handleIsOpen(!isOpen))
    };
    return (
        <div>
            <Header/>
            <div className="container mt-10">
                <div className="grid md:grid-cols-[1fr_4.5fr] gird-cols-1 md:gap-10 ">
                    {
                        isMobile && (
                            <div className="flex cursor-pointer gap-3 font-bold"  onClick={() => toggleSidebar()}>
                                <MenuIcon className={"mb-2 h-10 "}  />
                                <p>Menu</p>
                            </div>
                        )
                    }
                    {
                        (!isMobile || isOpen) && <DashboardSideBar type={props.type} />
                    }

                    {
                        (
                            (!isOpen || !isMobile) &&
                            <div className="content min-h-screen">
                                <Outlet/>
                            </div>
                        )

                    }
                </div>
            </div>
        </div>
    )
}
export default Layout
