import axios from "axios";

export const createWishlistAxios = async (wishlistTitle: string) => {
    try {
        const response = await axios.post(`/api/wishlists/categories`, {title: wishlistTitle});
        return response.data.data;
    } catch (error) {
        console.error("Error:", error);
        return null;
    }
};
export const getWishlistsAxios = async () => {
    try {
        const response = await axios.get(
            '/api/wishlists'
        );
        return {
            all: response.data.data.all,
            categories: response.data.data.categories,
        };
    } catch (error) {
        console.error("Error:", error);
        return null
    }
};
export const addEventToCategoryAxios = async (category: number, likedEvent: number) => {
    try {
        const response = await axios.post(
            `/api/wishlists/categories/${category}/toggle-event/${likedEvent}`
        );
        return {
            all: response.data.data.all,
            categories: response.data.data.categories,
        };
    } catch (error) {
        console.error("Error:", error);
        return null
    }
};
export const deleteCategoryAxios = async (category: number) => {
    try {
        const response = await axios.delete(
            `/api/wishlists/categories/${category}`
        );
        return {
            categories: response.data.data.categories,
        };
    } catch (error) {
        console.error("Error:", error);
        return null
    }
};