import axios from "axios";
import {TEventDetails, TEventReview} from "../../store/EventDetails/types";

export const getEventDetailsAxios = async (eventId: number , page:number) => {
    try {
        const response = await axios.get<{ data: TEventDetails }>(
            `/api/events/${eventId}/show/?page=${page}`
        );
        return response.data.data;
    } catch (error) {
        console.error("Error:", error);
        return null;
    }
};
export const createReviewAxios = async(eventId: number, reviewData: TEventReview) => {
    try {
        const response = await axios.post(`/api/events/${eventId}/review`, reviewData);
        return response.data.data;

    } catch (error) {
        console.error("Error:", error);
        return null;
    }
};
export const likeEventReviewAxios = async(eventId: number,reviewId:number) => {
    try {
        const response = await axios.post(`/api/events/${eventId}/review/${reviewId}/like`);
        return response.data.data;
    } catch (error) {
        console.error("Error:", error);
        return null;
    }
};
export const suggestedEventsAxios = async(eventId: number) => {
    try {
        const response = await axios.get(`/api/events/${eventId}/suggested`);
        return response.data.data;
    } catch (error) {
        console.error("Error:", error);
        return null;
    }
};