import {createSlice, PayloadAction} from "@reduxjs/toolkit";
export const initialState = {
    about:"",
}
export const AboutSlice = createSlice({
    name: 'about',
    initialState,
    reducers: {
        setAbout(state,action: PayloadAction<string>) {
            state.about = action.payload;
        },
    }
})
export const {
    setAbout,
} = AboutSlice.actions;

export default AboutSlice.reducer;
