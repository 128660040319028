import {AppDispatch} from "../store";
import {getCurrenciesAxios} from "../../networking/currency/currency";
import {setCurrencies, setSelectedCurrency} from "./CurrenciesSlice";

export const getCurrencies = () => async (dispatch: AppDispatch) => {
    try {
        const currencies = await getCurrenciesAxios();
        if (currencies) {
            if(localStorage?.getItem('selectedCurrency'))
            {
                dispatch(setSelectedCurrency(JSON.parse(localStorage?.getItem("selectedCurrency") ?? "")))
            }
            dispatch(setCurrencies(currencies));
        }
    } catch (error) {
        console.error("Error:", error);
    }
};