import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { referredAPI } from "../../../networking/crowdfunding/crowdfunding";
import { setSnackBarMessage } from "../../../store/GlobalState/ActionGlobalState";
import { useAppDispatch } from "../../../store/redux";

export const PartOfJourney = () => {
  const dispatch =useAppDispatch();
  const { t } = useTranslation();
  const [full_name, setName] = useState("");
  const [email, setEmail] = useState("");
  const handleNameChange = (e: any) => {
    setName(e.target.value);
  };
  const handleEmailChange = (e: any) => {
    setEmail(e.target.value);
  };
  const handleSubscribe = async () => {
    try {
      const payload = { full_name, email };
      await referredAPI(payload);
      setName("");
      setEmail("");
      dispatch(setSnackBarMessage("The email is sent"));
    } catch {

    }
  };
  return (
    <div className="bg-white md:grid md:grid-cols-2 md:mb-20 gap-32 place-items-center">
      <img src="/assets/images/donate.png" className="max-md:block md:hidden w-full h-full" />
      <div className="md:mt-8">
        <h1 className="font-bold text-3xl gap-2 flex items-center max-md:justify-center max-md:text-center">
          {t("crowdfunding.partOfJourney")}
          <div className="border-b-[3px] border-black w-[60px] mt-2 rounded-2xl max-md:hidden"></div>
        </h1>
        <p className="text-primary-orange font-bold max-md:text-center">  {t("crowdfunding.partJourneyTag")}</p>
        <div className="text-lg text-black opacity-80 max-md:text-center">
          <h3 className="md:pt-8 max-md:pt-4 max-md:text-sm">
            {t("crowdfunding.journeyDescription")}
          </h3>
        </div>
        <div className="bg-[#F8F8F8] rounded-2xl p-8 mt-5">
          <div className="relative">
            <img
              src="/assets/svgs/user.svg"
              alt="Email Icon"
              className="absolute h-5 w-5 text-gray-400 top-1/2 transform -translate-y-1/2 left-3"
            />
            <input
              type="text"
              placeholder={t('crowdfunding.fullName')}
              value={full_name}
              onChange={handleNameChange}
              className="pl-10 w-full bg-white border border-[#E3E2E2] rounded-lg p-3 text-black placeholder:text-black placeholder:font-light"
            />
          </div>
          <div className="relative my-5">
            <img
              src="/assets/svgs/email.svg"
              alt="Email Icon"
              className="absolute h-5 w-5 text-gray-400 top-1/2 transform -translate-y-1/2 left-3"
            />
            <input
              type="text"
              placeholder={t('crowdfunding.enterEmail')}
              value={email}
              onChange={handleEmailChange}
              className="pl-10 w-full bg-white border border-[#E3E2E2] rounded-lg p-3 text-black placeholder:text-black placeholder:font-light"
            />
          </div>
          <button
            className="bg-primary-orange text-white w-full rounded-full md:px-14 max-md:px-10 py-3 my-5 flex items-center justify-center gap-1"
            onClick={handleSubscribe}
          >
            {t("crowdfunding.subscribe")}
            <img src="/assets/svgs/common/white-arrow.svg" alt="arrow" />
          </button>
        </div>
      </div>
      <img src="/assets/images/donate.png" className="md:block max-md:hidden w-full h-full" />
    </div>
  );
};
export default PartOfJourney;