import { AppDispatch } from "../store";
import {
  setSnackBarMessage,
  setSnackBarType,
  toggleShowButtonLoader,
  toggleShowSnackBar
} from "../GlobalState/ActionGlobalState";

import { CrowdfundingAPI, stripePaymentAPI, subscriptionAPI } from "../../networking/crowdfunding/crowdfunding";
import { setClientSecretKey, setContributionSubmitted, setSubscription } from "./CrowdfundingSlice";
import { useEffect } from "react";

export const CrowfundingPayment =
  (payload: any) => async (dispatch: AppDispatch) => {
    try {
      dispatch(toggleShowButtonLoader(true));
      const response = await CrowdfundingAPI(payload);

      dispatch(setClientSecretKey(response?.data.client_secret));
      localStorage.setItem("contribution_id", response?.data.contribution_id ?? "");
      dispatch(setContributionSubmitted(true));
      return response;
    } catch (error) {
      throw error;
    } finally {
      dispatch(toggleShowButtonLoader(false));
    }
  };

export const stripePayment =
  (payload: string, payment_intent: string) =>
    async (dispatch: AppDispatch) => {
      try {
        const response = await stripePaymentAPI(payload, payment_intent);
        dispatch(toggleShowSnackBar(true));
        dispatch(setSnackBarMessage("Payment Success"));
        return response;
      } catch (error: any) {
        return error;
      }
    };

export const subscription = (payload: any) => async (dispatch: AppDispatch) => {
  try {
    dispatch(toggleShowButtonLoader(true));
    const response = await subscriptionAPI(payload)
    // if(response.response.status === 422){
    //   throw new Error(response.response.data.message);
    // }
    if(response?.response?.status === 422){
      throw new Error(response.response.data.message);
    }
    dispatch(setClientSecretKey(response?.data.client_secret));
    localStorage.setItem("contribution_id", response?.data.contribution_id ?? "");
    dispatch(setSubscription(true));
    dispatch(toggleShowButtonLoader(false));
    return response;
  } catch (error:any) {
    console.log("error", error)
    dispatch(toggleShowSnackBar(true));
    dispatch(setSnackBarType("error"));
    dispatch(setSnackBarMessage("An Error has occurred"));
  }
};

