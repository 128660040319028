import { TextareaAutosize } from "@mui/material";
import EventCardInformation from "../../../../../component/EventCardInformation";
import BoltIcon from "@mui/icons-material/Bolt";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useAppDispatch } from "../../../../../store/redux";
import { setCreateEventData } from "../../../../../store/BusinessDashboard/ActionBusinessDashboard";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store/store";
import { useTranslation } from "react-i18next";
import { BusinessDashboardSlice } from "../../../../../store/BusinessDashboard/BusinessDashboardSlice";
const AboutEvents = () => {
  const { t } = useTranslation();
  const fieldsError = useSelector(
    (state: RootState) => state.businessDashboardReducer?.errors,
  );
  const dispatch = useAppDispatch();
  const eventData = useSelector(
    (state: RootState) => state.businessDashboardReducer?.createEventData,
  );

  const handleRemoveErrorKey = (name: string[]) => {
    let errorfields = { ...fieldsError };
    name?.map((item) => {
      delete errorfields[item];
    });
    dispatch(BusinessDashboardSlice.actions.setErrors(errorfields));
  };
  return (
    <div className={"mb-4 mt-4 rounded-lg"}>
      <p className="px-5 py-3 text-xl font-bold">
        {t("businessProfile.eventCreation.aboutEvents.title")}
      </p>
      <TextareaAutosize
        className="w-full  rounded-lg bg-[#f3f3f4] p-5 focus-visible:border-[#131C23]"
        placeholder={t("businessProfile.eventCreation.aboutEvents.description")}
        value={eventData["description"]}
        onChange={(e) => {
          dispatch(setCreateEventData("description", e.target.value));
          handleRemoveErrorKey(["description"]);
        }}
        minRows={4}
        maxRows={6}
      />
      {fieldsError["description"] && (
        <span className="ml-5 text-[12px] text-red-600">
          {fieldsError["description"][0]}
        </span>
      )}
      <div className="mt-5 grid grid-cols-1 gap-3 lg:grid-cols-3">
        <div>
          <EventCardInformation
            name="highlights"
            icon={<BoltIcon sx={{ fill: "#FF873F" }} />}
            title={t("highlights")}
          />
          {fieldsError["highlights"] && (
            <span className="ml-5 text-[12px] text-red-600">
              {fieldsError["highlights"][0]}
            </span>
          )}
        </div>
        <div>
          <EventCardInformation
            name="includes"
            icon={<CheckCircleIcon sx={{ fill: "green" }} />}
            title={t("includes")}
          />
          {fieldsError["includes"] && (
            <span className="ml-5 text-[12px] text-red-600">
              {fieldsError["includes"][0]}
            </span>
          )}
        </div>
        <div>
          <EventCardInformation
            name="important_information"
            icon={<CheckCircleIcon sx={{ fill: "blue" }} />}
            title={t("information")}
          />
          {fieldsError["important_information"] && (
            <span className="ml-5 text-[12px] text-red-600">
              {fieldsError["important_information"][0]}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};
export default AboutEvents;
