export const EventListHeader = [
    {
        id:"image",
        label:"Image",
    },
    {
        id:"title",
        label:"Title",
    },
    {
        id:"location",
        label:"Location",
    },
    {
        id:"rating",
        label:"Rating",
    },
    {
        id:"tags",
        label:"Tags",
    }
];
