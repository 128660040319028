import { toggleBackDrop } from "../GlobalState/ActionGlobalState";
import { AppDispatch } from "../store";
import {getAboutUsAPI} from "../../networking/aboutUs/aboutUs";
import {setAbout} from "../AboutUs/AboutUsSlice"
export const getAboutUs = () => async (dispatch: AppDispatch) => {
    try {
        dispatch(toggleBackDrop(true));
        const response = await getAboutUsAPI();
        if (response) {
            dispatch(setAbout(response));
        } else {
        }

    } catch {
    } finally {
        dispatch(toggleBackDrop(false));
    }
};
export const getReview=()=>async(dispatch:AppDispatch)=>{
try {
    const response =await getAboutUsAPI();
    if(response){
        dispatch(setAbout(response))
    }
    else{}
}
catch{

}
}