import {useEffect} from "react";
import Header from "../../component/Header";
import {useAppDispatch, useAppSelector} from "../../store/redux";
import {RootState} from "../../store/store";
import "./style.css";
import {getAboutUs} from "../../store/AboutUs/ActionAboutUs";

const AboutUs = () => {
    const dispatch = useAppDispatch();
    const about = useAppSelector((state: RootState) => state.aboutReducer)
    useEffect(() => {
        dispatch(getAboutUs());
    }, []);

    return <div className="terms-and-condition">
        <Header/>
        <div className="p-10" dangerouslySetInnerHTML={{__html: about.about}}/>
    </div>
};

export default AboutUs;
