import {useState} from 'react';
import {filterDropdownData} from "../../constants/filterDropdownData"

const DropdownFilter = () => {
    const [isOpen, setIsOpen] = useState(false);
    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    return (
        <>
            {filterDropdownData.map((data,index) => (
                <div className="relative" key={index}>
                    <button
                        type="button"
                        onClick={toggleDropdown}
                        className="flex items-center text-sm font-medium text-gray-700 focus:outline-none max-md:bg-[#131C23]/10 max-md:w-full max-md:rounded-full max-md:p-2 max-md:justify-between max-md:mb-5"
                    >{data.title}
                        <svg
                            className="ml-1 h-5 w-5"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            aria-hidden="true"
                        >
                            <path
                                fillRule="evenodd"
                                d="M6.293 6.293a1 1 0 011.414 0L10 8.586l2.293-2.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                                // clipRule="evenodd"
                            />
                        </svg>
                    </button>

                    {isOpen && (
                        <div
                            className="origin-top-right absolute right-0 mt-2 w-[130px] rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-10">
                            <div className="py-1" role="menu" aria-orientation="vertical"
                                 aria-labelledby="options-menu">

                                {data.options.map((option, optionIndex) => (
                                    <a
                                        key={optionIndex}
                                        href="#"
                                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                                        role="menuitem"
                                    >
                                        {option}
                                    </a>

                                ))}
                            </div>
                        </div>
                    )}
                </div>
            ))}
        </>
    );
};

export default DropdownFilter;
