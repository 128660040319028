import {TSvgComponent} from "./types";

export const ShoppingSvg : TSvgComponent = ({...props}) =>{
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path fillRule="evenodd" clipRule="evenodd" d="M7.36178 4.3851C7.64026 3.18556 8.71574 2.29169 10 2.29169C11.2843 2.29169 12.3597 3.18556 12.6382 4.3851C12.2547 4.37493 11.8139 4.37497 11.2903 4.37501L11.1815 4.37502H8.81854L8.70966 4.37501C8.1861 4.37497 7.74528 4.37493 7.36178 4.3851ZM6.04166 5.79951V6.66669C6.04166 7.01186 6.32149 7.29169 6.66666 7.29169C7.01184 7.29169 7.29166 7.01186 7.29166 6.66669V5.63801C7.67711 5.62595 8.16522 5.62502 8.81854 5.62502H11.1815C11.8348 5.62502 12.3229 5.62595 12.7083 5.63801V6.66669C12.7083 7.01186 12.9882 7.29169 13.3333 7.29169C13.6785 7.29169 13.9583 7.01186 13.9583 6.66669V5.79951C15.3364 6.2007 16.4337 7.2544 16.8875 8.62256C17.0545 9.12593 17.1036 9.75435 17.2082 11.2429C17.327 12.9326 17.3715 13.6485 17.2543 14.2167C16.9239 15.8194 15.7267 17.1037 14.1511 17.5457C13.5925 17.7024 12.8753 17.7084 11.1815 17.7084H8.81854C7.12473 17.7084 6.40745 17.7024 5.84888 17.5457C4.27328 17.1037 3.07607 15.8194 2.74567 14.2167C2.62854 13.6485 2.67295 12.9326 2.79175 11.2429C2.89642 9.75435 2.94547 9.12593 3.11245 8.62256C3.56629 7.2544 4.66358 6.2007 6.04166 5.79951ZM6.07237 4.50455C6.31616 2.55228 7.98167 1.04169 10 1.04169C12.0183 1.04169 13.6838 2.55228 13.9276 4.50455C13.9955 4.51822 14.0619 4.53333 14.1272 4.55003C15.9835 5.0242 17.4708 6.41057 18.074 8.229C18.2969 8.9009 18.3529 9.69899 18.4475 11.0466L18.4552 11.1553L18.464 11.2807C18.5715 12.8078 18.635 13.7101 18.4786 14.4691C18.0538 16.5297 16.5145 18.181 14.4887 18.7493C13.7426 18.9586 12.8381 18.9585 11.3072 18.9584L11.1815 18.9584H8.81854L8.6928 18.9584C7.16191 18.9585 6.25741 18.9586 5.51125 18.7493C3.48548 18.181 1.94622 16.5297 1.52142 14.4691C1.36495 13.7101 1.42848 12.8078 1.536 11.2807L1.54483 11.1553L1.55246 11.0466C1.6471 9.699 1.70314 8.9009 1.92602 8.229C2.52923 6.41057 4.0165 5.0242 5.87276 4.55003C5.93813 4.53333 6.00454 4.51822 6.07237 4.50455Z"/>
        </svg>

    )
}
export default ShoppingSvg
