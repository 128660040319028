import { getUserGamificationAPI } from "../../networking/UserDashboard/gamification";
import { editHistoryReservedTicketAPI, getUserHistoryAPI } from "../../networking/UserDashboard/history";
import { toggleBackDrop } from "../GlobalState/ActionGlobalState";
import { AppDispatch } from "../store";
import { UserDashboardSlice } from "./UserDashboardSlice";

export const handleMobile = (value: boolean) => async (dispatch: AppDispatch) => {
  try {
    dispatch(UserDashboardSlice.actions.setIsMobile(value));
  } catch (e) {
  }
};

export const handleIsOpen = (value: boolean) => async (dispatch: AppDispatch) => {
  try {
    dispatch(UserDashboardSlice.actions.setIsOpen(value));
  } catch (e) {
  }
};

export const getUserGamifications = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(toggleBackDrop(true));
    const response = await getUserGamificationAPI();
    dispatch(UserDashboardSlice.actions.setAchievments(response?.achievments ?? []));
    dispatch(UserDashboardSlice.actions.setUserLevel(response?.user_level));
    dispatch(UserDashboardSlice.actions.setAllUserTask(response?.user_tasks.all ?? []));
    dispatch(UserDashboardSlice.actions.setTasksDone(response?.user_tasks.done ?? []));
    dispatch(UserDashboardSlice.actions.setTasksInProgress(response?.user_tasks.progress ?? []));
  } catch (e) {
  } finally {
    dispatch(toggleBackDrop(false));
  }
};

export const getUserHistory = (payload: number) => async (dispatch: AppDispatch) => {
  try {
    dispatch(toggleBackDrop(true));
    const response = await getUserHistoryAPI(payload);

    // dispatch(UserDashboardSlice.actions.setHistoryData(response.historyData))
    dispatch(UserDashboardSlice.actions.setUserHistory({ name: "historyData", value: response.data }));
    dispatch(UserDashboardSlice.actions.setUserHistory({ name: "current_page", value: response.current_page }));
    dispatch(UserDashboardSlice.actions.setUserHistory({ name: "per_page", value: response.per_page }));
    dispatch(UserDashboardSlice.actions.setUserHistory({ name: "total", value: response.total }));
    dispatch(UserDashboardSlice.actions.setUserHistory({ name: "last_page", value: response.last_page }));
  } catch (e) {
  } finally {
    dispatch(toggleBackDrop(false));
  }
};


export const handleModalDialog = (value: boolean) => async (dispatch: AppDispatch) => {
  try {
    dispatch(UserDashboardSlice.actions.setShowDialog(value));
  } catch (e) {
  }
};

export const editHistoryReservedTicket = (reservedTicketId: string, date:string, time:string) => async (dispatch: AppDispatch) => {
  try {
    const response = await editHistoryReservedTicketAPI(reservedTicketId, date, time);
    console.log("reservedTicketId",reservedTicketId)

    return response.data.data
  } catch (error) {
    console.error("Failed to update ticket:", error);
  }
}


