import {paymentSystemsData} from "../../../constants/footer/paymentSystems";
import {useTranslation} from "react-i18next";
import {useEffect} from "react";

export const ContactUs = () => {
    const {t} = useTranslation()

    // useEffect(() => {
    //     // const widget = document.getElementById('tp-widget-wrapper');
    //     // if (widget) {
    //     //     widget.style.textAlign = 'start';
    //     // }
    // }, []);

    return (
        <section id="footer-payment-systems">
            <h3 className="font-medium text-md text-white mb-2 max-md:text-center">
                {t('footer.payment')}
            </h3>
            <div className="flex flex-wrap max-md:justify-center gap-4 pt-4">
                {paymentSystemsData.map((paymentSystem) => {
                    return (
                        <li
                            key={`footer_payment_${paymentSystem.id}`}
                            className="cursor-pointer hover:translate-x-1 hover:-translate-y-1 transition-transform duration-500 uppercase list-none"
                        >
                            <a href={paymentSystem.path} target="_blank" rel="noopener noreferrer"
                               className="w-1/2 h-1/2">
                                <img loading="lazy" src={paymentSystem.image} key={`social_${paymentSystem.id}`}
                                     alt={`Payment ${paymentSystem.id}`}/>
                            </a>
                        </li>
                    );
                })}
            </div>
        </section>
    );
};
export default ContactUs;
