import React from "react";
import { useBanner } from "../pages/Home/hooks";

interface MissionBanner {
  firstBackground: string;
  secondBackground:string;
  title:string;
  secondTitle?:string;
}

interface StaticBannerProps {
  data: MissionBanner;
}

const StaticBanner: React.FC<StaticBannerProps> = ({ data }) => {
  const { t, isMobileScreen } = useBanner();
  return (
    <section className="container relative md:pt-20 max-md:pt-20">
      {isMobileScreen ? (
        <div className="relative w-full h-[50vh] banner-border">
          <img src={data.firstBackground} alt="" className="w-full rounded-xl -rotate-3 " />
          <img src="/assets/images/logo.png" className="absolute top-0 right-10 -translate-y-1/3 z-30 w-40 h-40" />
        </div>
      ) : (
        <div className="relative w-full h-[50vh] banner-border mt-10">
          <img src={data.secondBackground} alt="" className="w-full rounded-xl -rotate-3 h-[65vh]" />
          <img src="/assets/images/logo.png" className="absolute top-0 right-20 -translate-y-1/2 z-30" />
        </div>
      )}
      <h2
        className="w-max md:-translate-y-1 max-md:-translate-y-4 md:translate-x-16 max-md:translate-x-8 absolute z-10 rounded-lg md:rounded-xl bg-primary-orange px-5 py-3 text-2xl max-md:text-[15px] font-bold text-white">
        {data.title} <br/>
        {data.secondTitle}
      </h2>
    </section>
  );
};
export default StaticBanner;