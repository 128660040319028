import LockIcon from '@mui/icons-material/Lock';
import { UpatePassword } from '../../../../../store/Auth/types';
import { useState } from 'react';
import CustomInput from '../../../../../component/common/Inputs';
import { useAppDispatch } from '../../../../../store/redux';
import { updateUserPassword } from '../../../../../store/Auth/ActionAuth';
import { Formik, Form, ErrorMessage, useFormikContext } from 'formik';
import * as Yup from 'yup';
import {useTranslation} from "react-i18next";
const ChangePassword = () => {
    const dispatch = useAppDispatch()
    const {t}=useTranslation();
    const formik = useFormikContext()
    const [isFieldEmpty, setIsFieldEmpty] = useState<Boolean>(true)
    const [updatePassword, setUpdatePassword] = useState<UpatePassword>({
        current_password: "",
        password: "",
        password_confirmation: ""
    })
    const [showErrorPassword, setShowErrorPassword] = useState<Boolean>(false)
    const [showResetFields,setShowResetFields] =useState<Boolean>(false)

    const validationSchema = Yup.object({
        current_password: Yup.string().required(t('businessProfile.setting.changePassword.requiredCurrentPassword')),
        password: Yup.string()
            .required(t('businessProfile.setting.changePassword.required'))
            .min(8, t('businessProfile.setting.changePassword.requiredCharacters'))
            .matches(
             /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[-.#?!@$%^&_*=()+]).{8,}$/,
                t('businessProfile.setting.changePassword.specialCharacter')
            ),
        password_confirmation: Yup.string()
            .required(t('businessProfile.setting.changePassword.confirmationPassword'))
            .oneOf([Yup.ref('password'), ''], t('businessProfile.setting.changePassword.passwordMatch'))
    });


    const handleChangePassword = (val: UpatePassword) => {
        dispatch(updateUserPassword(val)).then((res) => {
            alert('updated')
        }).catch((err) => setShowErrorPassword(true))
    }

    const checkFieldsIsEmpty = (values: UpatePassword) => {
        for (const key in values) {
            if (values[key].trim() === "") {
                return true;

            }
        }
        return false;
    }
    const handleChangeState = (event: React.ChangeEvent<HTMLInputElement>, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => {
        if (event && event.target) {
            const { name, value } = event.target;
            if (name === "current_password") setShowErrorPassword(false)
            setFieldValue(name, value);
        }
    };
    return (
        <div className={"grid rounded-xl w-full h-full bg-[#f3f3f4]"}>
        <div className="px-4 my-3">

            <Formik
                initialValues={updatePassword}
                validationSchema={validationSchema}
                onSubmit={(values) => { handleChangePassword(values) }}
            >
                {({ setFieldValue, values, errors, touched }) => (
                    <Form className='h-full flex flex-col'>
                        <div>
                            <p className={"font-bold text-xl"}>{t("businessProfile.setting.changePassword.title")}</p>
                            <div>
                                <div className="mt-5">
                                    <CustomInput
                                        inputBgColor="bg-white"
                                        name="current_password"
                                        type="text"
                                        value={values.current_password}
                                        placeholder={t("businessProfile.setting.changePassword.currentPassword")}
                                        onChange={(event) => handleChangeState(event, setFieldValue)}
                                        icon={<LockIcon sx={{ color: "#d0d2d3" }} />}
                                    />
                                    <ErrorMessage name="current_password" component="div" className="text-red-500 text-xs px-3" />
                                    {showErrorPassword && <div className='text-xs px-3 text-red-500'>{t("businessProfile.setting.changePassword.wrongPassword")}</div>}
                                    <span onClick={()=>setShowResetFields(true)}  className='text-xs px-3 text-[#3F87FF] font-medium cursor-pointer'>{t("businessProfile.setting.changePassword.resetPassword")}</span>

                                </div>

                            </div>
                        </div>
                        {showResetFields &&    <div>
                            <div className="mt-5">
                                <CustomInput
                                    inputBgColor="bg-white"
                                    name="password"
                                    type="text"
                                    value={values.password}
                                    placeholder={t("businessProfile.setting.changePassword.newPassword")}
                                    onChange={(event) => handleChangeState(event, setFieldValue)}

                                />
                                <ErrorMessage name="password" component="div" className="text-red-500 text-xs px-3" />
                            </div>
                            <div className="mt-5">
                                <CustomInput
                                    inputBgColor="bg-white"
                                    name="password_confirmation"
                                    type="text"
                                    value={values.password_confirmation}
                                    placeholder={t("businessProfile.setting.changePassword.confirmNewPassword")}
                                    onChange={(event) => handleChangeState(event, setFieldValue)}


                                />
                                <ErrorMessage name="password_confirmation" component="div" className="text-red-500 text-xs px-3" />
                            </div>
                            <button disabled={checkFieldsIsEmpty(values)} type="submit" className={`border-none  ${checkFieldsIsEmpty(values) ? 'bg-[#d0d2d3]' : 'bg-primary-orange'}  text-white rounded-full px-4 py-3 w-full`}>{t("businessProfile.setting.changePassword.savePassword")}</button>
                        </div>}
                     
                    </Form>
                )}
            </Formik>

        </div>

    </div>

    )
}
export default ChangePassword
