import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store/store";
import { AdditionalInformation } from "../../../../../store/BusinessDashboard/types";
import CustomInput from "../../../../../component/common/Inputs";
import { FormControl, MenuItem, Select } from "@mui/material";
import { getCountries } from "../../../../../store/GlobalState/ActionGlobalState";
import { useAppDispatch } from "../../../../../store/redux";
import {
  addAdditionalInformation,
  checkStripeConnection,
  getAdditionalInformation,
} from "../../../../../store/BusinessDashboard/ActionBusinessDashboard";
import PhoneInput from "react-phone-number-input";
import PrimaryButton from "../../../../../component/PrimaryButton";
import { useTranslation } from "react-i18next";

const PaymentDetails = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const countires = useSelector(
    (state: RootState) => state.globalStateReducer?.country,
  );
  const banks_belong_to = [
    "A manufacturer or supplier of goods",
    "A logistics company",
    "A provider of accounting or tax-related service",
    "A provider of advertising or marketing services",
    "A provider of legal or compilance services",
    "A contractor, employee, or business partner",
    "me or my company",
  ];

  const [paymentDisabled, setPaymentDisabled] = useState<
    number | boolean | null
  >(null);
  const [AdditionalInformation, setAdditionalInformation] =
    useState<AdditionalInformation>({
      bank_country: " ",
      bank_account_currency: "",
      phone_number: "",
      email: "",
      street_and_number: "",
      more_address_detail: "",
      city: "",
      zip_code: "",
      country: " ",
      bank_belongs_to: " ",
      bank_name: "",
      account_holder_name: "",
      account_number: "",
      sort_code: "",
      swift_or_bic: "",
    });

  const handleChangeInput = (event: any) => {
    setAdditionalInformation((prevPayload) => ({
      ...prevPayload,
      [event?.target?.name]: event?.target?.value,
    }));
  };

  const hasNonEmptyKey = (obj: any) => {
    for (var key in obj) {
      if (obj[key] !== null && obj[key] !== undefined && obj[key] !== "") {
        return true;
      }
    }
    return false;
  };

  const checkFieldsIsEmpty = (values: any) => {
    for (const key in values) {
      if (values[key]?.trim() === "") {
        return true;
      }
    }
    return false;
  };

  const handleAddAdditionalInfo = () => {
    dispatch(addAdditionalInformation(AdditionalInformation))
      .then((res) => {
        setPaymentDisabled(true);
      })
      .catch(() => {});
  };

  const handleChangeDropList = (name: string, value: string) => {
    setAdditionalInformation((prevPayload) => ({
      ...prevPayload,
      [name]: value,
    }));
  };

  useEffect(() => {
    dispatch(getCountries());
    dispatch(getAdditionalInformation()).then((res) => {
      if (hasNonEmptyKey(res)) {
        setAdditionalInformation(res);
      }
      dispatch(checkStripeConnection())
        .then((res) => {
          setPaymentDisabled(res);
        })
        .catch(() => {});
    });
  }, []);
  if (paymentDisabled === 0 || !checkFieldsIsEmpty(AdditionalInformation)) {
    return (
      <div className={"h-full w-full rounded-xl bg-[#f3f3f4]"}>
        <div className="my-3 px-4">
          <div>
            <div className="flex items-center gap-3">
              {/* <input
                id={`stripe-not-allowed`}
                type="checkbox"
                value={1}
                checked={!paymentDisabled}
                name="bordered-checkbox"
                onChange={() => setPaymentDisabled(!paymentDisabled)}
                className=" checbox-input rounded border-gray-300 bg-gray-300 text-white accent-[#FF913F] focus:ring-transparent"
              /> */}
              <p className={"text-xl font-bold"}>
                {t("businessProfile.setting.paymentInformation.title")}
              </p>
              <div className="rounded-full bg-[#FF585F]/5 px-4 py-1 text-sm text-[#FF585F]">
                <span className="font-medium">
                  {t("businessProfile.eventCreation.basicInfo.required")}
                </span>
              </div>
            </div>

            <div className="mt-3 grid grid-cols-1 items-center gap-4 lg:grid-cols-3">
              <div className="flex items-center gap-2 rounded-xl bg-white px-2 py-1">
                <FormControl
                  sx={{ border: "none" }}
                  className="w-fit"
                  variant="standard"
                  fullWidth
                >
                  <Select
                    sx={{
                      border: "none",
                      color: "rgb(19 28 35 / 0.5)",
                      width: "100%",
                      paddingLeft: "7px",
                    }}
                    className=" border-none"
                    id={"chart_component"}
                    placeholder=""
                    value={AdditionalInformation.bank_country}
                    disableUnderline
                  >
                    <MenuItem disabled value={" "}>
                      {t(
                        "businessProfile.setting.paymentInformation.bankCountry",
                      )}
                    </MenuItem>
                    {countires.map((item, index) => (
                      <MenuItem
                        onClick={() =>
                          handleChangeDropList("bank_country", item.code)
                        }
                        key={index}
                        value={item.code}
                      >
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="">
                <CustomInput
                  wrapperClassName="my-0"
                  inputBgColor="bg-white"
                  name="bank_account_currency"
                  type="text"
                  value={AdditionalInformation.bank_account_currency}
                  placeholder={t(
                    "businessProfile.setting.paymentInformation.bankCurrency",
                  )}
                  onChange={handleChangeInput}
                />
              </div>
              <div
                className={"flex h-10 justify-center rounded-lg bg-white px-3"}
              >
                <PhoneInput
                  initialValueFormat="national"
                  placeholder={t(
                    "businessProfile.setting.paymentInformation.phoneNumber",
                  )}
                  value={AdditionalInformation["phone_number"]}
                  className="w-full"
                  onChange={(val) =>
                    setAdditionalInformation((prevPayload) => ({
                      ...prevPayload,
                      phone_number: val?.toString() ?? "",
                    }))
                  }
                />
              </div>
              {/* <div>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  sx={{
                    width: "100%",
                    background: "white",
                    padding: "0px 12px",
                  }}
                  className="flex h-[40px] justify-center rounded-lg"
                  format={"D-M-YYYY"}
                  slotProps={{ textField: { placeholder: "Date Of Birth" } }}
                  onChange={(newValue: Dayjs | null) => {
                    const formattedDate = dayjs(newValue).format("D-M-YYYY");
                    setAdditionalInformation((prevPayload) => ({
                      ...prevPayload,
                      dateOfBirth: formattedDate,
                    }));
                  }}
                />
              </LocalizationProvider>
            </div> */}
              <div className="">
                <CustomInput
                  wrapperClassName="my-0"
                  inputBgColor="bg-white"
                  name="email"
                  type="text"
                  value={AdditionalInformation.email}
                  placeholder={t(
                    "businessProfile.setting.paymentInformation.email",
                  )}
                  onChange={handleChangeInput}
                />
              </div>
              <div className="">
                <CustomInput
                  wrapperClassName="my-0"
                  inputBgColor="bg-white"
                  name="street_and_number"
                  type="text"
                  value={AdditionalInformation.street_and_number}
                  placeholder={t(
                    "businessProfile.setting.paymentInformation.streetNumber",
                  )}
                  onChange={handleChangeInput}
                />
              </div>
              <div className="">
                <CustomInput
                  wrapperClassName="my-0"
                  inputBgColor="bg-white"
                  name="more_address_detail"
                  type="text"
                  value={AdditionalInformation.more_address_detail}
                  placeholder={t(
                    "businessProfile.setting.paymentInformation.address",
                  )}
                  onChange={handleChangeInput}
                />
              </div>
              <div className="">
                <CustomInput
                  wrapperClassName="my-0"
                  inputBgColor="bg-white"
                  name="city"
                  type="text"
                  value={AdditionalInformation.city}
                  placeholder={t(
                    "businessProfile.setting.paymentInformation.city",
                  )}
                  onChange={handleChangeInput}
                />
              </div>
              <div className="">
                <CustomInput
                  wrapperClassName="my-0"
                  inputBgColor="bg-white"
                  name="zip_code"
                  type="text"
                  value={AdditionalInformation.zip_code}
                  placeholder={t(
                    "businessProfile.setting.paymentInformation.zipCode",
                  )}
                  onChange={handleChangeInput}
                />
              </div>
              <div className="flex items-center gap-2 rounded-xl bg-white px-2 py-1">
                <FormControl
                  sx={{ border: "none" }}
                  className="w-fit"
                  variant="standard"
                  fullWidth
                >
                  <Select
                    sx={{
                      border: "none",
                      color: "rgb(19 28 35 / 0.5)",
                      width: "100%",
                      paddingLeft: "7px",
                    }}
                    className=" border-none"
                    id={"chart_component"}
                    placeholder=""
                    value={AdditionalInformation.country}
                    disableUnderline
                  >
                    <MenuItem disabled value={" "}>
                      {t("businessProfile.setting.paymentInformation.country")}
                    </MenuItem>
                    {countires.map((item, index) => (
                      <MenuItem
                        onClick={() =>
                          handleChangeDropList("country", item.code)
                        }
                        key={index}
                        value={item.code}
                      >
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="my-2 flex items-center gap-2 rounded-xl bg-white px-2 py-1">
                <FormControl
                  sx={{ border: "none" }}
                  className="w-fit"
                  variant="standard"
                  fullWidth
                >
                  <Select
                    sx={{
                      border: "none",
                      color: "rgb(19 28 35 / 0.5)",
                      width: "100%",
                      paddingLeft: "7px",
                    }}
                    className=" border-none"
                    id={"chart_component"}
                    placeholder=""
                    value={AdditionalInformation.bank_belongs_to}
                    disableUnderline
                  >
                    <MenuItem disabled value={" "}>
                      {t(
                        "businessProfile.setting.paymentInformation.bankAccountBelongs",
                      )}
                    </MenuItem>
                    {banks_belong_to.map((item, index) => (
                      <MenuItem
                        onClick={() =>
                          handleChangeDropList("bank_belongs_to", item)
                        }
                        key={index}
                        value={item}
                      >
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="">
                <CustomInput
                  wrapperClassName="my-0"
                  inputBgColor="bg-white"
                  name="bank_name"
                  type="text"
                  value={AdditionalInformation.bank_name}
                  placeholder={t(
                    "businessProfile.setting.paymentInformation.bankName",
                  )}
                  onChange={handleChangeInput}
                />
              </div>
              <div className="">
                <CustomInput
                  wrapperClassName="my-0"
                  inputBgColor="bg-white"
                  name="account_holder_name"
                  type="text"
                  value={AdditionalInformation.account_holder_name}
                  placeholder={t(
                    "businessProfile.setting.paymentInformation.accountHolder",
                  )}
                  onChange={handleChangeInput}
                />
              </div>
              <div className="">
                <CustomInput
                  wrapperClassName="my-0"
                  inputBgColor="bg-white"
                  name="account_number"
                  type="text"
                  value={AdditionalInformation.account_number}
                  placeholder={t(
                    "businessProfile.setting.paymentInformation.accountNumber",
                  )}
                  onChange={handleChangeInput}
                />
              </div>
              <div className="">
                <CustomInput
                  wrapperClassName="my-0"
                  inputBgColor="bg-white"
                  name="sort_code"
                  type="text"
                  value={AdditionalInformation.sort_code}
                  placeholder={t(
                    "businessProfile.setting.paymentInformation.sortCode",
                  )}
                  onChange={handleChangeInput}
                />
              </div>
              <div className="">
                <CustomInput
                  wrapperClassName="my-0"
                  inputBgColor="bg-white"
                  name="swift_or_bic"
                  type="text"
                  value={AdditionalInformation.swift_or_bic}
                  placeholder={t(
                    "businessProfile.setting.paymentInformation.swift_or_bic",
                  )}
                  onChange={handleChangeInput}
                />
              </div>
            </div>
          </div>
          <PrimaryButton
            onClick={handleAddAdditionalInfo}
            className={`mt-5 w-full  rounded-full border-none  lg:w-[25%]  ${
              checkFieldsIsEmpty(AdditionalInformation)
                ? "bg-[#d0d2d3]"
                : "bg-primary-orange"
            }  px-4 py-2 text-white`}
          >
            {t("businessProfile.setting.paymentInformation.registerPayment")}
          </PrimaryButton>
        </div>
      </div>
    );
  }
};
export default PaymentDetails;
