import OverviewSvg from "../../component/SvgComponents/OverviewSvg";
import SettingsSvg from "../../component/SvgComponents/SettingsSvg";
import EventCreationSvg from "../../component/SvgComponents/EventCreationSvg";
import ClipboardSvg from "../../component/SvgComponents/ClipboardSvg";
import NotificationSvg from "../../component/SvgComponents/NotificationSvg";
import DocumentSvg from "../../component/SvgComponents/DocumentSvg";
export const businessProfileSideBar = [
    {
        id: "overview",
        path: "/profile-overview",
        title: "Overview",
        svg: OverviewSvg,
        isActive:false
    },
    {
        id: "settings",
        path: "/business-profile-settings",
        title: "Settings",
        svg: SettingsSvg,
        isActive:false
    },
    {
        id: "eventList",
        path: "/event-list",
        title: "Event List",
        svg: ClipboardSvg,
        isActive:false
    },
    {
        id: "eventCreation",
        path: "/event-creation",
        title: "Event Creation",
        svg: EventCreationSvg,
        isActive:false
    },
    {
        id: "notifications",
        path: "/notifications",
        title: "Notification",
        svg: NotificationSvg,
        isActive:false
    },
    {
        id: "documents",
        path: "/documents",
        title: "Documents",
        svg: DocumentSvg,
        isActive:false
    },
    
];
