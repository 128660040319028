import React, {useEffect, useState} from 'react';
import EventCard from '../../component/EventCard';
import {useAppSelector} from "../../store/redux";
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';
import {Link} from 'react-router-dom';
import {useSelector} from "react-redux";
import {RootState} from "../../store/store";
import {useAppDispatch} from "../../store/redux";
import {filterAllEventsData} from "../../constants/filterAllEventsData";
import TabComponent from "../../component/TabComponent";
import DropdownTab from "../../component/DropdownFilter";
import SearchButton from "../../component/SearchButton";
import {fetchTypesAxios} from "../../networking/filterByType/filterByType";
import {ITypes} from "../../networking/filterByType/types";
import {filterDropdownData} from "../../constants/filterDropdownData";
import {TEventsFilters} from "../../store/events/types";
import {scrollToTop} from '../../store/GlobalState/ActionGlobalState';
import {IEventsData} from "../../networking/events/types";
import Header from "../../component/Header/index"
import {useTranslation} from "react-i18next";
import {setAuthModal} from "../../store/Auth/ActionAuth";
import {getAllPromotionalEvents} from "../../store/events/ActionEvents";
import Pagination from "@mui/material/Pagination";
import { eventsSlice } from "../../store/events/EventsSlice";
import Spinner from "./Spinner";

const SeeAllPromotionalEvents = ({filters = true}: { filters?: boolean }) => {
    const dispatch = useAppDispatch();
    const {t} = useTranslation()
    const auth = useAppSelector(state => state.authReducer);
    const large_events_categories = useSelector((state: RootState) => state.eventsReducer.large_events_categories);
    const exclusive_events_categories = useSelector((state: RootState) => state.eventsReducer.exclusive_events_categories);
    const [, setSelectedFilters] = useState(
        filterAllEventsData.map(() => false)
    );
    const {eventType} = useParams();
    // const [events, setEvents] = useState<IEventsData[]>()
    const events=useAppSelector(state =>state.eventsReducer.promotional_events)
    const categoryEvents = useAppSelector(state => state.eventsReducer.large_events_categories);
    const exclusiveCategoryEvents = useAppSelector(state => state.eventsReducer.exclusive_events_categories);
    const [, setActiveTab] = useState(0);
    const [maxPrice, setMaxPrice] = useState<number | null>(null);
    const [, setSelectedCategoryId] = useState<number | null>(null);
    const [checkboxValues, setCheckboxValues] = useState<boolean[]>([]);
    const [tabTitlesData, setTabTitlesData] = useState<{ id: number, label: string }[]>([]);
    const selectedLanguage = useAppSelector(state => state.languageReducer.selectedLanguage);
    const [searchParams] = useSearchParams();
    const [categoryId, setCategoryId] = useState(searchParams.get("categoryId") || "0");
    const [loadEvents, setLoadEvents] = useState(8);
    const [hideNextButton, setHideNextButton] = useState(false);
    const [isLoading,setIsLoading] =useState(false)
    const [isDurationOpen, setIsDurationOpen] = useState(false);
    const [isStarOpen, setIsStarOpen] = useState(false);
    const [selectedDuration, setSelectedDuration] = useState('');
    const [selectedStar, setSelectedStar] = useState('');
    const [selectedCheckboxes, setSelectedCheckboxes] = useState<string[]>([]);
    const [eventTypes, setEventTypes] = useState<ITypes[]>([]);
    const [isNoEventsFound, setIsNoEventsFound] = useState(false);
    const [last_page, setLastPage] = useState<number | null>(null);
    const [searchData, setSearchData] = useState<TEventsFilters>({
        type_id: selectedCheckboxes.map((title) => {
            const selectedType = eventTypes.find((type) => type.title === title);
            return selectedType ? selectedType.id : 0;
        }),
        min_price: 0,
        max_price: maxPrice,
        duration: '',
        all_stars: '',
        status: '',
    });
    const promotionalEvents = useAppSelector(state => state.eventsReducer.promotional_events)
    const showSignInContent = useAppSelector(state => state.authReducer.openAuthModal)

    const lastPage = useAppSelector(state => state.eventsReducer.last_page);
    // const [currentPage, setCurrentPage] = useState(1);
    const {currentPage: currentPageParam} = useParams();
    const initialPage = parseInt(sessionStorage.getItem('currentPage') || '1');
    const [currentPage, setCurrentPage] = useState(initialPage);
    const currentPageFromURL = currentPageParam ? parseInt(currentPageParam) : 1;

    useEffect(() => {
        setCurrentPage(currentPageFromURL);
    }, [currentPageFromURL]);

    useEffect(() => {
        if (selectedLanguage === "en") {
            //append array based on the event type to get the categories
            const arrayToAppend = eventType === "large" ?
                (large_events_categories?.map(category => ({
                    id: category.id,
                    label: category.title,
                    categoryId: category.id
                })) ?? [])
                :
                (exclusive_events_categories?.map(category => ({
                    id: category.id,
                    label: category.title,
                    categoryId: category.id
                })) ?? []);

            setTabTitlesData(prev => prev = [
                {id: 0, label: t('allEvents')},
                ...(arrayToAppend || [])
            ])

        } else {
            //append array based on event type to get the categories
            const arrayTranslateToAppend = eventType === "large" ?
                (large_events_categories?.map(category => ({
                    id: category.id,
                    label: category.translations ? category.translations?.[selectedLanguage].title : category.title,
                    categoryId: category.id
                })) ?? [])
                :
                (exclusive_events_categories?.map(category => ({
                    id: category.id,
                    label: category.translations ? category.translations?.[selectedLanguage].title : category.title,
                    categoryId: category.id
                })) ?? []);

            setTabTitlesData(prev => prev = [
                {id: 0, label: t('allEvents')},
                ...(arrayTranslateToAppend || [])
            ])
        }
        // setEvents((prev) => eventType === "large" ? allEvents : (eventType === "exclusive" ? exclusiveEvents : promotionalEvents));
    }, [large_events_categories, selectedLanguage])
    const handleTabClick = (
        {categoryId, id, label}: { categoryId: number; id: number; label: string },
        tabId: number
    ) => {
        setActiveTab(tabId);
        setSelectedCategoryId(tabId !== 0 ? id : 0);
        if (tabId !== 0) {
            const selectedCategory = eventType === "large"
                ? categoryEvents.find((category) => category.id === id)
                : exclusiveCategoryEvents.find((category) => category.id === id);

            if (selectedCategory) {
                dispatch(eventsSlice.actions.setAllPromotionalEvents(selectedCategory.events));
                // setEvents(selectedCategory.events);
            }

            setCategoryId(String(tabId))
        } else {
            setCategoryId(String(0))
            dispatch(eventsSlice.actions.setAllPromotionalEvents(tabId === 0 ? promotionalEvents || [] : []));
            // setEvents(tabId === 0 ? promotionalEvents || [] : []);
        }
    };
    const handleCheckboxChange = (index: number) => {
        const updatedCheckboxValues = [...checkboxValues];
        updatedCheckboxValues[index] = !updatedCheckboxValues[index];
        setCheckboxValues(updatedCheckboxValues);

        const selectedEventType = eventTypes[index];

        setSelectedCheckboxes((prevSelectedCheckboxes) => {
            if (prevSelectedCheckboxes.includes(selectedEventType.title)) {
                return prevSelectedCheckboxes.filter((item) => item !== selectedEventType.title);
            } else {
                return [...prevSelectedCheckboxes, selectedEventType.title];
            }
        });
    };
    const handleMinPriceChange = (event: any) => {
        setSearchData((prevSearchData) => ({
            ...prevSearchData,
            min_price: event.target.value,
        }));
    };
    const handleMaxPriceChange = (event: any) => {
        setSearchData((prevSearchData) => ({
            ...prevSearchData,
            max_price: event.target.value,
        }));
    };
    const handleResetClick = () => {
        if (eventType === "large") {
            const emptyFilters: TEventsFilters = {
                type_id: [],
                status: '',
                min_price: 0,
                max_price: null,
                duration: '',
                all_stars: '',
            };
        }
        setSelectedFilters(filterAllEventsData.map(() => false));
        setMaxPrice(null);
        setSelectedCategoryId(null);
        setCheckboxValues([]);
        setIsDurationOpen(false);
        setIsStarOpen(false);
        setSelectedDuration('');
        setSelectedStar('');
        setSelectedCheckboxes([]);
        setSearchData({
            type_id: [],
            min_price: 0,
            max_price: null,
            duration: '',
            all_stars: '',
            status: '',
        });
    };
    const toggleDropdown = () => {
        setIsDurationOpen(!isDurationOpen);
        setIsStarOpen(false);
    };
    const toggleSecondDropdown = () => {
        setIsStarOpen(!isStarOpen);
        setIsDurationOpen(false);
    };
    const handleDurationSelect = (option: string) => {
        setSearchData((prevSearchData) => ({
            ...prevSearchData,
            duration: option,
        }));
        setSelectedDuration(option);
        setIsDurationOpen(false);
    };
    const handleStarSelect = (option: string) => {
        setSearchData((prevSearchData) => ({
            ...prevSearchData,
            all_stars: option,
        }));
        setSelectedStar(option);
        setIsStarOpen(false);
    };
    const handleSearchClick = () => {
        if (auth.token !== null) {
            const updatedSearchData: TEventsFilters = {
                type_id: selectedCheckboxes.map((title) => {
                    const selectedType = eventTypes.find((type) => type.title === title);
                    return selectedType ? selectedType.id : 0;
                }),
                min_price: parseFloat(searchData.min_price?.toString() || '0'),
                max_price: parseFloat(searchData.max_price?.toString() || '0'),
                duration: selectedDuration,
                all_stars: selectedStar,
                status: '',
            };
            dispatch(getAllPromotionalEvents(updatedSearchData, true, 1));
            const noEventsFound = events?.length === 0;
            setIsNoEventsFound(noEventsFound);
        }
    };
    const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
        setCurrentPage(page);
        dispatch(getAllPromotionalEvents(searchData, true, page));
        sessionStorage.setItem('currentPage', page.toString());
    };
    useEffect(() => {
        const storedPage = sessionStorage.getItem('currentPage');
        if (storedPage !== null) {
            const newPage = parseInt(storedPage);
            setCurrentPage(newPage);
        }
    }, [currentPage]);

    useEffect(() => {
        dispatch(scrollToTop);
        fetchTypesAxios()
            .then((data) => {
                if (data) {
                    setMaxPrice(data.max_price);
                    setSearchData((prevSearchData) => ({
                        ...prevSearchData,
                        max_price: data.max_price,
                    }));
                    setEventTypes(data.types);
                }
            })
            .catch((error) => {
            });
    }, []);
    //
    // useEffect(() => {
    //     const categoryIdFromUrl = categoryId ? parseInt(categoryId, 10) : null;
    //
    //     if (categoryIdFromUrl !== null && !isNaN(categoryIdFromUrl)) {
    //         const eventsPerPage = 8;
    //         const start = (currentPage - 1) * eventsPerPage;
    //         const end = start + eventsPerPage;
    //         // setEvents(promotionalEvents.slice(start, end));
    //         // dispatch(eventsSlice.actions.setAllPromotionalEvents(promotionalEvents.slice(start, end)));
    //
    //     }
    // }, [eventType, categoryId, promotionalEvents, currentPage]);

    // useEffect(() => {
    //     dispatch(getAllPromotionalEvents(searchData, true, 1));
    // }, [eventType, searchData]);
    useEffect(() => {
        setIsLoading(true);
        dispatch(getAllPromotionalEvents(searchData, true, currentPage))
          .then(() => setIsLoading(false))
          .catch(() => setIsLoading(false));
    }, [eventType, searchData, currentPage]);

    useEffect(() => {
        if (promotionalEvents && promotionalEvents.length > 0) {
            setHideNextButton(last_page === null);
        }
    }, [promotionalEvents, last_page]);

    return (
      <main id="all-events">
          <Header />
          <div className="container">
              <div className="">
                  {eventType === 'large' || eventType === 'exclusive' ? (
                    <TabComponent
                      titles={tabTitlesData}
                      activeTab={categoryId ? parseInt(categoryId) : 0}
                      handleTabClick={handleTabClick}
                    />
                  ) : null}
                  {filters && <div>
                      <div className="md:flex py-2 items-center justify-between">
                          {eventTypes.map((item, index) => (
                            <div key={index} className="flex items-center checkbox-container md:mb-0 mb-3">
                                <input
                                  id={`bordered-checkbox-${index}`}
                                  type="checkbox"
                                  value=""
                                  name="bordered-checkbox"
                                  className="w-4 h-4 border-gray-300 bg-gray-300 rounded accent-[#FF913F] focus:ring-transparent text-white checbox-input"
                                  checked={checkboxValues[index]}
                                  onChange={() => handleCheckboxChange(index)}
                                />
                                <label
                                  htmlFor={`bordered-checkbox-${index}`}
                                  className="w-full py-2 ml-2 font-medium text-[#131C23] text-xs checkbox-label custom-checkbox"
                                >
                                    {/*{item.title}*/}
                                    {item.translations && item.translations[selectedLanguage] ? item.translations[selectedLanguage].title : item.title}
                                </label>
                            </div>
                          ))}
                          <div className="flex items-center md:justify-center mb-4 md:mb-0">
                              <label htmlFor="price"
                                     className="block text-sm font-medium text-gray-900">{t('minPrice')}:</label>
                              <input
                                type="number"
                                id="price"
                                value={searchData.min_price === null ? '' : searchData.min_price.toString()}
                                onChange={handleMinPriceChange}
                                className="bg-gray-200 mx-3 text-gray-900 text-sm rounded-lgblock w-1/2 p-1.5 focus:outline-[#FF913F] rounded-lg dark:border-gray-600 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                              />
                          </div>
                          <div className="flex items-center md:justify-center mb-4 md:mb-0">
                              <label htmlFor="price"
                                     className="block text-sm font-medium text-gray-900">{t('maxPrice')}:</label>
                              <input
                                type="number"
                                id="price"
                                value={searchData.max_price === null ? '' : searchData.max_price.toString()}
                                onChange={handleMaxPriceChange}
                                className="bg-gray-200 mx-3 text-gray-900 text-sm rounded-lg focus:outline-[#FF913F] focus:ring-blue-500 focus:border-red-500 focus-visible:border-blue-500 block w-1/2 p-1.5 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                              />
                          </div>
                          <div className="hidden md:flex md:justify-between items-center">
                              <div className="hidden md:flex md:justify-between items-center">
                                  <div className="relative">
                                      <button
                                        type="button"
                                        onClick={toggleDropdown}
                                        className="flex items-center text-sm font-medium text-gray-700 focus:outline-none"
                                      >
                                          {selectedDuration || 'Duration'}
                                          <svg
                                            className="ml-1 h-5 w-5"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                            aria-hidden="true"
                                          >
                                              <path
                                                fillRule="evenodd"
                                                d="M6.293 6.293a1 1 0 011.414 0L10 8.586l2.293-2.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                                                // clipRule="evenodd"
                                              />
                                          </svg>
                                      </button>
                                      {isDurationOpen && (
                                        <div
                                          className="origin-top-right absolute right-0 mt-2 w-[130px] rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-10"
                                          role="menu"
                                          aria-orientation="vertical"
                                          aria-labelledby="options-menu"
                                        >
                                            <div className="py-1">
                                                {filterDropdownData[0].options.map((option, index) => (
                                                  <a
                                                    key={index}
                                                    onClick={() => handleDurationSelect(option)}
                                                    href="#"
                                                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                                                    role="menuitem"
                                                  >
                                                      {option}
                                                  </a>
                                                ))}
                                            </div>
                                        </div>
                                      )}
                                  </div>
                                  <div className="relative">
                                      <button
                                        type="button"
                                        onClick={toggleSecondDropdown}
                                        className="flex items-center text-sm font-medium text-gray-700 focus:outline-none"
                                      >
                                          {selectedStar || 'All Star'}
                                          <svg
                                            className="ml-1 h-5 w-5"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                            aria-hidden="true"
                                          >
                                              <path
                                                fillRule="evenodd"
                                                d="M6.293 6.293a1 1 0 011.414 0L10 8.586l2.293-2.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                                                // clipRule="evenodd"
                                              />
                                          </svg>
                                      </button>

                                      {isStarOpen && (
                                        <div
                                          className="origin-top-right absolute right-0 mt-2 w-[130px] rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-10"
                                          role="menu"
                                          aria-orientation="vertical"
                                          aria-labelledby="options-menu"
                                        >
                                            <div className="py-1">
                                                {filterDropdownData[1].options.map((option, index) => (
                                                  <a
                                                    key={index}
                                                    onClick={() => handleStarSelect(option)}
                                                    href="#"
                                                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                                                    role="menuitem"
                                                  >
                                                      {option}
                                                  </a>
                                                ))}
                                            </div>
                                        </div>
                                      )}
                                  </div>
                                  <SearchButton onClick={handleSearchClick} />
                                  <button className="px-3" onClick={handleResetClick}>
                                      <img src="/assets/svgs/common/reset.svg" alt="reset" />
                                  </button>
                              </div>
                          </div>
                      </div>
                      <div className="grid grid-cols-2 md:hidden place-content-between">
                          <DropdownTab />
                          <div className="flex justify-between py-4">
                              <SearchButton onClick={handleSearchClick} />
                              <button className="ml-5" onClick={handleResetClick}><img
                                src="/assets/svgs/common/reset.svg" alt="reset" /></button>
                          </div>
                      </div>
                  </div>}
              </div>
              {isLoading ? (
                <div className="flex justify-center items-center h-[50vh]">
                    <Spinner />
                </div>
              ) : (
                <div>
                    {categoryId && events && events.length > 0 ? (
                      <div className="grid md:grid-cols-3 lg:grid-cols-4 gap-4 min-h-[calc(100vh-500px)] my-10">
                          {events && events?.map((event) => (
                            <Link to={`/eventDetails/${event.id}`} key={event.id}>
                                <EventCard filterTabs={true} filters={true} event={event} />
                            </Link>
                          ))}
                      </div>
                    ) : (
                      <div className="flex justify-center items-center">
                          <div className="text-center text-2xl text-[#FF913F] font-bold py-10">
                              <img src="/assets/images/empty.png" alt="Empty Wishlist" />
                              {t('noEvents')}
                          </div>
                      </div>
                    )}
                </div>
              )}
          </div>
          {isNoEventsFound && (
            <div className="h-[50vh] flex justify-center items-center">
                <div className="text-center text-2xl text-[#FF913F] font-bold">
                    {t('noEventsWithYourFilters')}
                </div>
            </div>
          )}
          <div className="mb-10 flex justify-center">
              {lastPage && (
                <Pagination
                  count={lastPage}
                  page={currentPage}
                  onChange={handlePageChange}
                  variant="outlined"
                  shape="rounded"
                  color="primary"
                />
              )}
          </div>
      </main>
    );
};
export default SeeAllPromotionalEvents;
