import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store/store";
import { useAppDispatch } from "../../../../../store/redux";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CustomInput from "../../../../../component/common/Inputs";
import { Divider, Tooltip } from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {
  SplitPaymentHandler,
  setTotalPrice,
} from "../../../../../store/Cart/ActionCart";
import { Elements } from "@stripe/react-stripe-js";
import StripeForm from "../StripeForm";
import { loadStripe } from "@stripe/stripe-js";
import { useNavigate } from "react-router-dom";
import PrimaryButton from "../../../../../component/PrimaryButton";
import { useTranslation } from "react-i18next";

type props = {
  handleChangeSection: () => void;
};
const key = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY as string;
const clientRedirection =  process.env.REACT_APP_CLIENT_REDIRECTION as string
const stripePromise = loadStripe(key);
const SeperatePayment = (props: props) => {
  const events = useSelector(
    (state: RootState) => state.cartReducer.cartItem.selectedCart,
  );
  const [eventsSelected, setEventsSelected] = useState<any>([]);
  const [showAddParticipantError, setShowAddParticipantError] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const clientKey = useSelector(
    (state: RootState) => state.cartReducer.checkoutState.clientSecretKey,
  );
  const dispatch = useAppDispatch();
  const currency = useSelector(
    (state: RootState) => state.currenciesReducer?.selectedCurrency,
  );
  const appearance = {
    variables: {
      padding: "0 15px",
      colorPrimary: "#0570de",
      colorText: "#30313d",
      colorDanger: "#df1b41",
      spacingUnit: "3px",
      borderRadius: "15px",
      spacingGridRow: "15px",
    },
    rules: {
      ".Tab": {
        border: "1px solid #E0E6EB",
        boxShadow:
          "0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(18, 42, 66, 0.02)",
      },
      ".p-PaymentDetails": { backgroundColor: "red" },
      ".Tab:hover": {
        color: "var(--colorText)",
      },
      ".Tab--selected": {
        borderColor: "#FF913F",
        boxShadow:
          "0px 1px 1px #FF913F, 0px 3px 6px #FF913F, 0 0 0 2px #FF913F",
      },
      ".Tab--selected:focus": {
        borderColor: "#FF913F",
        boxShadow:
          "0px 1px 1px #FF913F, 0px 3px 6px #FF913F, 0 0 0 2px #FF913F",
      },
      ".Tab:focus": {
        borderColor: "#FF913F",
        boxShadow:
          "0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02), 0px 0px 6px #ff913f",
      },

      ".Input:focus": {
        borderColor: "#FF913F",
        boxShadow:
          "0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02), 0px 0px 6px #ff913f",
      },
      ".Input--invalid": {
        boxShadow:
          "0 1px 1px 0 rgba(0, 0, 0, 0.07), 0 0 0 2px var(--colorDanger)",
      },
      ".CheckboxInput--checked": {
        backgroundColor: "#FF913F",
        borderColor: "#FF913F",
      },
      ".CheckboxInput:focus": {
        boxShadow:
          "0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02), 0px 0px 6px #ff913f",
        borderColor: "#FF913F",
      },
      ".DropdownItem:active": {
        backgroundColor: "red",
      },
      ".Input::selection": {
        backgroundColor: "red",
      },
      ".Menu": {
        backgroundColor: "red",
      },
    },
  };
  const options = {
    // passing the client secret obtained from the server
    clientSecret: clientKey,
    appearance: appearance,
  };
  const totalPrice = useSelector(
    (state: RootState) =>
      state.cartReducer?.checkoutState?.userData.initial_total_price,
  );
  const finalPrice = useSelector(
    (state: RootState) =>
      state.cartReducer?.checkoutState?.userData.total_price,
  );
  const [participants, setParticipants] = useState<any>([]);
  const [showPayment, setShowPayment] = useState<boolean>(false);
  const handleAddUser = () => {
    let array = [...participants];
    let object = {
      user_name: "",
      user_email: "",
      events: [],
    };
    array.push(object);
    setParticipants(array);
  };

  const handleRemoveParticipants = (index: any) => {
    // Create a copy of the participants array and eventsSelected array
    const updatedParticipants = [...participants];
    const updatedEventsSelected = [...eventsSelected];
    // Iterate through the events of the participant being removed
    updatedParticipants[index].events?.forEach((userEventItem: any) => {
      userEventItem?.ages?.forEach((ageItem: any) => {
        updatedEventsSelected.forEach((eventItem: any) => {
          // Find the corresponding age group in eventsSelected
          const ageGroup = eventItem.event_age.find(
            (element: any) => element.id === ageItem.id,
          );
          if (ageGroup) {
            // Update the remainingQuantity using the current value and ageItem.qty
            ageGroup.remainingQuantity += ageItem.qty;
          }
        });
      });
    });

    // Remove the participant at the specified index
    updatedParticipants.splice(index, 1);
    // Update the state with the new participants array and eventsSelected array
    setParticipants(updatedParticipants);
    // Calculate the final total price
    const finalTotalPrice = calculateTotalPrice(updatedParticipants);
    // Dispatch the action to update the total price
    dispatch(setTotalPrice(totalPrice - Number(finalTotalPrice)));
  };
  const calculateTotalQuantity = (ageId: number, index: number) => {
    let totalQuantity = 0;
  
    if (participants.length > 0) {
      participants?.forEach((item: any, participantIndex: number) => {
        if (participantIndex !== index) {
          item.events?.forEach((eventItem: any) => {
            eventItem.ages?.forEach((ageItem: any) => {
              if (ageItem.id === ageId && typeof ageItem.qty === 'number') {
                totalQuantity += ageItem.qty;
              }
            });
          });
        }
      });
    }
  
    return Number(totalQuantity);
  }; 
  const calculateTotalFeesQuantity = (feesId: number, index: number) => {
    let totalQuantity = 0;
  
    if (participants.length > 0) {
      participants?.forEach((item: any, participantIndex: number) => {
        if (participantIndex !== index) {
          item.events?.forEach((eventItem: any) => {
            eventItem.fees?.forEach((feesItem: any) => {
              if (feesItem.id === feesId && typeof feesItem.qty === 'number') {
                totalQuantity += feesItem.qty;
              }
            });
          });
        }
      });
    }
  
    return Number(totalQuantity);
  };
  const handleChangeQuantity = (
    eventId: number,
    ageId: number,
    price: number,
    value: string,
    index: number,
    eventIndex: number,
    ageIndex: number,
  ) => {
    // Create copies of state variables
    let updatedParticipants = [...participants];
    let events = [...eventsSelected];
  
    // Calculate total quantity for the specified age
    let totalQuantity = calculateTotalQuantity(ageId, index);
  
    // Ensure quantityVal is within a valid range and handle empty string
    let quantityVal =
      value === ""
        ? ""
        : Math.min(
            Math.max(Number(value), 0),
            events[eventIndex].event_age[ageIndex].remainingQuantity,
          );
  
    // Update the remaining quantity for the event and age immediately
    events[eventIndex].event_age[ageIndex].remainingQuantity =
      events[eventIndex].event_age[ageIndex].quantity - (totalQuantity + (typeof quantityVal === 'number' ?quantityVal : 0));
  
    // Update or create participant's information
    if (index >= 0 && index < updatedParticipants.length) {
      let participant = updatedParticipants[index];
      let eventIndexInParticipant = participant.events.findIndex(
        (eventItem: any) => eventItem.id === eventId,
      );
  
      if (eventIndexInParticipant === -1) {
        // If the event doesn't exist for this participant, create a new event object
        let newEvent = {
          id: eventId,
          ages: [
            {
              id: ageId,
              qty: quantityVal,
              price: price,
            },
          ],
        };
  
        participant.events.push(newEvent);
      } else {
        let agesArray = participant.events[eventIndexInParticipant].ages || [];
        let ageIndexInParticipant = agesArray.findIndex((ageItem: any) => ageItem.id === ageId);
  
        if (ageIndexInParticipant === -1) {
          // If the age doesn't exist, create a new age object
          let newAge = {
            id: ageId,
            qty: quantityVal,
            price: price,
          };
          agesArray.push(newAge);
        } else {
          // If both the event and age exist, update the quantity
          agesArray[ageIndexInParticipant].qty = quantityVal === 0 ? 0 : quantityVal;
        }
  
        participant.events[eventIndexInParticipant] = {
          ...participant.events[eventIndexInParticipant],
          ages: agesArray,
        };
      }
  
      // Update the updatedParticipants array in state
      updatedParticipants[index] = participant;
      setParticipants(updatedParticipants);
      setEventsSelected(events);
      updateTotalPrice();
    }
  }; 
  const handleChangeFeesQuantity = (
    eventId: number,
    feesId: number,
    price: number,
    value: string,
    index: number,
    eventIndex: number,
    feesIndex: number,
  ) => {
    // Create copies of state variables
    let updatedParticipants = [...participants];
    let events = [...eventsSelected];
  
    // Calculate total quantity for the specified fees
    let totalQuantity = calculateTotalFeesQuantity(feesId, index);
  
    // Calculate the remaining quantity for the event and fees
    const remainingQuantity = events[eventIndex].event_fees[feesIndex].remainingQuantity;
  
    // Ensure quantityVal is within a valid range and handle empty string
    let quantityVal =
      value === ""
        ? ""
        : Math.min(
            Math.max(Number(value), 0),
            remainingQuantity // Ensure quantityVal does not exceed remaining quantity
          );
  
    // Update the remaining quantity for the event and fees immediately
    events[eventIndex].event_fees[feesIndex].remainingQuantity =
      events[eventIndex].event_fees[feesIndex].quantity - (totalQuantity + (typeof quantityVal === 'number' ?quantityVal : 0));
  
    // Update the participant's information
    if (index >= 0 && index < updatedParticipants.length) {
      let participant = updatedParticipants[index];
      let eventIndexInParticipant = participant.events.findIndex(
        (eventItem: any) => eventItem.id === eventId,
      );
  
      if (eventIndexInParticipant === -1) {
        // If the event doesn't exist for this participant, create a new event object
        let newEvent = {
          id: eventId,
          fees: [
            {
              id: feesId,
              qty: quantityVal,
              price: price,
            },
          ],
        };
  
        participant.events.push(newEvent);
      } else {
        let feesArray = participant.events[eventIndexInParticipant]?.fees || [];
        let feeIndexInParticipant = feesArray.findIndex((feeItem: any) => feeItem.id === feesId);
        if (feeIndexInParticipant === -1) {
          // If the fees doesn't exist, create a new fees object
          let newFees = {
            id: feesId,
            qty: quantityVal,
            price: price,
          };
          feesArray.push(newFees);
        } else {
          // If both the event and fees exist, update the quantity
          feesArray[feeIndexInParticipant].qty = quantityVal === 0 ? 0 : quantityVal;
        }
  
        participant.events[eventIndexInParticipant] = {
          ...participant.events[eventIndexInParticipant],
          fees: feesArray,
        };
      }
  
      // Update the updatedParticipants array in state
      updatedParticipants[index] = participant;
      setParticipants(updatedParticipants);
      setEventsSelected(events);
      updateTotalPrice();
    }
  };
  
  
  const calculateTotalPrice = (users: any[]) => {
    console.log(users);
    return users?.reduce((total: any, user: any) => {
      const userTotal = user?.events?.reduce((eventTotal: any, event: any) => {
        return (
          eventTotal +
          event.ages?.reduce((ageTotal: any, age: any) => {
            const qty = age.qty || 0; // Handle potentially undefined qty
            const price = age.price || 0; // Handle potentially undefined price
            return ageTotal + qty * price;
          }, 0)
        );
      }, 0);
      return total + userTotal;
    }, 0);
  }; 
  const calculateTotalFeesPrice = (users: any[]) => {
    console.log(users);
    return users?.reduce((total: any, user: any) => {
      const userTotal = user?.events?.reduce((eventTotal: any, event: any) => {
        return (
          eventTotal +
          event.fees?.reduce((feeTotal: any, fee: any) => {
            const qty = fee.qty || 0; // Handle potentially undefined qty
            const price = fee.price || 0; // Handle potentially undefined price
            return feeTotal + qty * price;
          }, 0)
        );
      }, 0);
      return total + userTotal;
    }, 0);
  };
  const updateTotalPrice = () => {
    const finalTotalPrice = calculateTotalPrice(participants);
    const finalFeesPrice = calculateTotalFeesPrice(participants);
  
    if (finalFeesPrice && isNaN(finalTotalPrice)) {
      dispatch(setTotalPrice(totalPrice - Number(finalFeesPrice)));
    } else if (isNaN(finalFeesPrice) && finalTotalPrice) {
      dispatch(setTotalPrice(totalPrice - Number(finalTotalPrice)));
    } else if (!isNaN(finalFeesPrice) && !isNaN(finalTotalPrice)) {
      dispatch(
        setTotalPrice(
          totalPrice - (Number(finalTotalPrice) + Number(finalFeesPrice)),
        ),
      );
    } else {
      dispatch(setTotalPrice(totalPrice));
    }
  };
  const handleChangeUserInfo = (name: string, value: string, index: number) => {
    let participant = [...participants];
    participant[index][name] = value;
    setParticipants(participant);
  };
  const handlePay = () => {
    if (!handleCheckFields() && participants.length > 0) {
      const filteredData = participants.map((user:any) => ({
        ...user,
        events: user.events.map((event:any) => ({
          ...event,
          ages: event?.ages?.filter((age:any) => age.qty !== ""),
          fees: event?.fees?.filter((fee:any) => fee.qty !== "")
        }))
      })).filter((user:any) => user.events.some((event:any) => event.ages.length > 0 || event.fees.length > 0));
      dispatch(SplitPaymentHandler({ users: filteredData }))
        .then((res) => {
          setShowPayment(true);
        })
        .catch(() => {
          console.log("error");
        });
    }
  };

  // Regular expression for a simple email format
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const handleCheckFields = () => {
    let array = [...participants];
    let updated = false; // Initialize a flag to track if any changes were made

    if (array.length > 0) {
      array = array?.map((item) => {
        let isUserNameEmpty = item.user_name === "";
        let isUserEmailEmpty = item.user_email === "";
        let isEventsEmpty = item.events?.length === 0;
        let isEmailValid = emailRegex.test(item.user_email);

        if (isUserNameEmpty && isUserEmailEmpty && isEventsEmpty) {
          updated = true; // Set the flag to true if a change is made
          return {
            ...item,
            user_name_field: true,
            user_email_field: true,
            events_fields: true,
          };
        } else if (isUserNameEmpty && (isUserEmailEmpty || !isEmailValid)) {
          updated = true; // Set the flag to true if a change is made
          return {
            ...item,
            user_name_field: true,
            user_email_field: true,
          };
        } else if (isUserNameEmpty) {
          updated = true; // Set the flag to true if a change is made
          return {
            ...item,
            user_name_field: true,
          };
        } else if (isUserEmailEmpty || !isEmailValid) {
          updated = true; // Set the flag to true if a change is made
          return {
            ...item,
            user_email_field: true,
          };
        } else if (isEventsEmpty) {
          updated = true; // Set the flag to true if a change is made
          return {
            ...item,
            events_fields: true,
          };
        }
        return item;
      });
      setParticipants(array);

      return updated;
    } else {
      setShowAddParticipantError(true);
    }
  };

  useEffect(() => {
    let data = events.map((item) => ({
      ...item,
      event_age: item.event_age.map((itemAge) => ({
        ...itemAge,
        remainingQuantity: itemAge.quantity,
      })),
      event_fees: item.event_fees.map((itemFees) => ({
        ...itemFees,
        remainingQuantity: itemFees.quantity,
      })),
    }));
    setEventsSelected(data);
  }, [events]);

  return (
    <>
      {showPayment && clientKey !== "" ? (
        <div className="p-5">
          {" "}
          <Elements stripe={stripePromise} options={options}>
            <div className="stripe-form">
              <StripeForm navigateURL={clientRedirection} />
            </div>
          </Elements>{" "}
        </div>
      ) : (
        <div className="p-5">
          <p className="text-lg font-bold">
            {t("checkout.separatePayment.participants")}
          </p>
          <div className=" max-h-[275px] overflow-y-scroll lg:max-h-[calc(100vh-360px)]">
            {participants.length > 0 ? (
              participants.map((item: any, index: any) => (
                <div key={index}>
                  <div className="mt-3 flex  w-full justify-between">
                    <p className="text-sm font-medium text-primary-orange">
                      Participant {index + 1}
                    </p>
                    <DeleteForeverIcon
                      onClick={() => handleRemoveParticipants(index)}
                      sx={{
                        fill: "#FF585F",
                        width: "20px",
                        height: "20px",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                  <CustomInput
                    inputBgColor=" bg-[#131C23]/5"
                    name={"user_name"}
                    type="text"
                    onChange={(e) =>
                      handleChangeUserInfo(e.target.name, e.target.value, index)
                    }
                    placeholder={t("checkout.separatePayment.userName")}
                  />
                  {participants[index].user_name_field && (
                    <span className="ml-2 text-sm text-red-600">
                      Username required
                    </span>
                  )}
                  <CustomInput
                    inputBgColor=" bg-[#131C23]/5"
                    onChange={(e) =>
                      handleChangeUserInfo(e.target.name, e.target.value, index)
                    }
                    name={"user_email"}
                    type="text"
                    placeholder={"Enter user email"}
                  />
                  {participants[index].user_email_field && (
                    <span className="ml-2 text-sm text-red-600">
                      {t("checkout.separatePayment.validEmail")}
                    </span>
                  )}
                  <div className={"flex flex-col gap-5"}>
                    {eventsSelected.map(
                      (eventItem: any, eventIndex: number) => (
                        <>
                          <div className="flex w-full flex-col items-center gap-5 md:flex-row">
                            <Tooltip
                              arrow
                              placement="bottom-end"
                              title={eventItem.event.title}
                            >
                              <img
                                className="h-[full] w-[full] rounded-none md:h-[60px] md:w-[60px] md:rounded-full "
                                src={eventItem.event.images[0].image_path}
                              />
                            </Tooltip>
                            <div className="flex flex-col gap-3">
                              <div className="flex w-full flex-col">
                                <p className="text-lg font-medium">
                                  Event Ages:
                                </p>
                                {eventItem.event_age.map(
                                  (ageItem: any, ageIndex: number) => (
                                    <>
                                      <div className="flex flex-col items-start  gap-2 md:flex-row md:items-center md:gap-5 ">
                                        <div className=" flex flex-col min-w-[230px]">
                                          <p className="text-primary-orange">
                                            x{ageItem.quantity},{" "}
                                            {ageItem.age.label} (
                                            {ageItem?.remainingQuantity}{" "}
                                            remaining)
                                          </p>
                                          <p className="">
                                            Single Ticket Price:
                                            {currency.symbol}
                                            {(
                                              ageItem.initial_price *
                                              currency.exchange_rate
                                            ).toFixed(2)}
                                          </p>
                                        </div>
                                        <CustomInput
                                          value={
                                            participants[index].events
                                              ?.find(
                                                (element: any) =>
                                                  element.id === eventItem.id,
                                              )
                                              ?.ages?.find(
                                                (ageElemet: any) =>
                                                  ageElemet.id === ageItem.id,
                                              )?.qty ?? ""
                                          }
                                          //   disabled={
                                          //     participants[index].events
                                          //       .find((element: any) => element.id === eventItem.id)
                                          //       ?.ages.find((ageElemet: any) => ageElemet.id === ageItem.id)
                                          //       ?.remainingQuantity === 0
                                          //   }
                                          max={ageItem?.remainingQuantity}
                                          onChange={(e: any) =>
                                            handleChangeQuantity(
                                              eventItem.id,
                                              ageItem.id,
                                              ageItem.initial_price,
                                              e.target.value,
                                              index,
                                              eventIndex,
                                              ageIndex,
                                            )
                                          }
                                          inputBgColor=" bg-[#131C23]/5"
                                          name={"quantity"}
                                          type="number"
                                          placeholder={t(
                                            "checkout.separatePayment.insertQuantity",
                                          )}
                                        />
                                      </div>
                                    </>
                                  ),
                                )}
                              </div>
                              <div className="flex w-full flex-col">
                                {eventItem.event_fees.length > 0 && (
                                  <>
                                    <p className="text-lg font-medium">
                                      Event Fees:
                                    </p>
                                    {eventItem.event_fees.map(
                                      (feeItem: any, feesIndex: number) => (
                                        <>
                                          <div className="flex flex-col items-start  gap-2 md:flex-row md:items-center md:gap-5 ">
                                            <div className=" flex flex-col min-w-[230px]">
                                              <p className="text-primary-orange">
                                                x{feeItem.quantity},{" "}
                                                {feeItem.fee.title} (
                                                {feeItem?.remainingQuantity}{" "}
                                                remaining)
                                              </p>
                                              <p className="">
                                                Event Fee Price:
                                                {currency.symbol}
                                                {(
                                                  feeItem.initial_price *
                                                  currency.exchange_rate
                                                ).toFixed(2)}
                                              </p>
                                            </div>
                                            <CustomInput
                                              value={
                                                participants[index].events
                                                  ?.find(
                                                    (element: any) =>
                                                      element.id ===
                                                      eventItem.id,
                                                  )
                                                  ?.fees?.find(
                                                    (feeElement: any) =>
                                                      feeElement.id ===
                                                      feeItem.id,
                                                  )?.qty ?? ""
                                              }
                                              //   disabled={
                                              //     participants[index].events
                                              //       .find((element: any) => element.id === eventItem.id)
                                              //       ?.ages.find((ageElemet: any) => ageElemet.id === ageItem.id)
                                              //       ?.remainingQuantity === 0
                                              //   }
                                              max={feeItem?.remainingQuantity}
                                              onChange={(e: any) =>
                                                handleChangeFeesQuantity(
                                                  eventItem.id,
                                                  feeItem.id,
                                                  feeItem.initial_price,
                                                  e.target.value,
                                                  index,
                                                  eventIndex,
                                                  feesIndex,
                                                )
                                              }
                                              inputBgColor=" bg-[#131C23]/5"
                                              name={"quantity"}
                                              type="number"
                                              placeholder={t(
                                                "checkout.separatePayment.insertQuantity",
                                              )}
                                            />
                                          </div>
                                        </>
                                      ),
                                    )}
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                          <Divider sx={{ borderBottomWidth: "2px" }} />
                        </>
                      ),
                    )}
                    {participants[index].events_fields && (
                      <span className="ml-2 text-sm text-red-600">
                        {t("checkout.separatePayment.selectEvent")}
                      </span>
                    )}
                  </div>
                </div>
              ))
            ) : (
              <>
                <div className="flex w-full flex-col gap-5 bg-white p-4 md:flex-row">
                  <div className="flex w-fit items-center rounded-xl bg-[#FF4D3F]/10 px-8 py-5">
                    <img
                      className="h-[50px] w-[50px]"
                      src={"/assets/svgs/events/red-book-icon.svg"}
                    />
                  </div>
                  <div>
                    <p className="text-lg font-bold">
                      {" "}
                      {t("checkout.separatePayment.payment")}
                    </p>
                    <p>{t("checkout.separatePayment.splitPayment")}</p>
                  </div>
                </div>
                {showAddParticipantError && (
                  <span className="ml-2 text-sm text-red-600">
                    {t("checkout.separatePayment.youNeedToSplit")}
                    <a
                      onClick={props.handleChangeSection}
                      className="cursor-pointer text-[#1976d2] underline"
                    >
                      {t("checkout.separatePayment.changePayment")}
                    </a>
                  </span>
                )}
              </>
            )}
          </div>
          <div className="flex flex-col justify-between md:flex-row md:gap-10">
            <button
              onClick={handleAddUser}
              className="mt-4 flex w-full  items-center justify-center gap-2 rounded-full border-2 border-primary-orange py-2 text-sm"
            >
              {participants.length > 0 ? "Add More" : "Add Participants"}
              <AddCircleIcon sx={{ width: "20px", height: "20px" }} />
            </button>
            <PrimaryButton
              onClick={handlePay}
              className="mt-4 text-sm text-white"
            >
              {finalPrice === 0
                ? "Continue"
                : `Pay ${(finalPrice * currency.exchange_rate).toFixed(2)} ${
                    currency.symbol
                  }`}
              <img
                src="/assets/svgs/common/white-arrow.svg"
                className="h-[20px] w-[20xp]"
                alt="Arrow"
              />
            </PrimaryButton>
          </div>
        </div>
      )}
    </>
  );
};
export default SeperatePayment;
