import Header from "../../component/Header";
import {Outlet} from "react-router-dom";

const Payment = () =>{
    return(
        <>
            <Header/>
            <Outlet/>
        </>
    )
}
export default Payment;