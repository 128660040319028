import {useBanner} from "../hooks";
import React, { useEffect, useState } from "react";
import axios from "axios";
export const Banner = () => {
 const  {t, isMobileScreen} =useBanner();
const [bannerImage,setBannerImage] =useState();
 const getHomeBanner = async()=>{
   try{
      const response =await axios.get('api/home-banner')
     setBannerImage(response.data.data)
   }
   catch {

   }
 }
  useEffect(() => {
    getHomeBanner();
  }, []);
    return (
        <section className="container relative md:pt-40 max-md:pt-20 max-md:px-10">
            {isMobileScreen ? (
              <div className="relative w-full h-[50vh] banner-border">
                <img src={bannerImage} alt="" className="w-full rounded-xl -rotate-3 h-full"/>
                <img src="/assets/images/logo.png" className="absolute top-0 right-10 -translate-y-1/3 z-30 w-20 h-20"/>
              </div>
            ) : (
              <div className="relative w-full h-[50vh] banner-border mt-10">
                  <img src={bannerImage} alt="" className="w-full rounded-xl -rotate-3 h-[65vh] object-cover"/>
                <img src="/assets/images/logo.png" className="absolute top-0 right-20 -translate-y-1/2 z-30"/>
              </div>
            )}
          <h2
            className="w-max md:-translate-y-1 max-md:-translate-y-4 md:translate-x-16 max-md:translate-x-10 absolute z-10 rounded-lg md:rounded-xl bg-primary-orange px-5 py-3 text-2xl max-md:text-xl font-bold text-white">
            {t("escape")}
          </h2>
        </section>
    );
};

export default Banner;
