import {partnersData} from "../../../constants/footer/partners";
import {useTranslation} from "react-i18next";

export const Partners = () => {
    const {t} =useTranslation()

    return (
        <section id="footer-partners" className="max-md:text-center">
            <h2 className="mb-2 font-medium text-lg text-white tracking-wider">
                {t('footer.partners')}
            </h2>
            <div className="flex flex-wrap max-md:justify-center gap-4">
                {partnersData.map((partners) => {
                    return (
                        <li
                            key={`footer_partners_${partners.id}`}
                            className="w-max cursor-pointer hover:translate-x-1 hover:-translate-y-1 transition-transform duration-500 uppercase list-none"
                        >
                            <a href={partners.path} target="_blank" rel="noopener noreferrer" className="bg-white grid px-3 py-1 rounded-full h-max">
                                <img loading="lazy" src={partners.image} key={`social_${partners.id}`} alt={"partners-" + partners.id}/>
                            </a>
                        </li>
                    );
                })}
            </div>
        </section>
    );
};

export default Partners;
