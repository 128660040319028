
import {useTranslation} from "react-i18next";

//todo : add refferals table
const RefferalProgram = () => {
    const {t} =useTranslation();
  return (
    <>
      {/* <div className="grid xl:grid-cols-2 grid-cols-1 gap-5">
                <RefferalCode />
                <BecomeRefferal />
            </div>
            <UserRefferals /> */}

      <div className="empty grid place-items-center pb-20">
        <img loading="lazy" src="/assets/images/empty.png" alt="Empty Wishlist" />
        <h3 className="px-4 text-primary-orange text-lg font-bold 2xl:px-7 2xl:text-xl">
            {t('userProfile.referralProgram.comingSoon')}
        </h3>
      </div>
    </>
  );
};
export default RefferalProgram;
