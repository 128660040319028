import { AlertColor } from "@mui/material";
import { AppDispatch } from "../store";
import { GlobalStateSLice } from "./GlobalStateSlice";
import { getCountriesAPI } from "../../networking/GlobalEndPoints/globalEndPoints";


export const toggleLoading =
  (loaderValue: boolean) => async (dispatch: AppDispatch) => {
    try {
      dispatch(GlobalStateSLice.actions.toggleLoading(loaderValue));
    } catch {}
  };
export const toggleShowSnackBar =
  (loaderValue: boolean) => async (dispatch: AppDispatch) => {
    try {
      dispatch(GlobalStateSLice.actions.toggleShowSnackBar(loaderValue));
    } catch {}
  };

export const toggleShowButtonLoader =
  (loaderValue: boolean) => async (dispatch: AppDispatch) => {
    try {
      dispatch(GlobalStateSLice.actions.toggleButtonLoader(loaderValue));
    } catch {}
  };
export const toggleBackDrop =
  (loaderValue: boolean) => async (dispatch: AppDispatch) => {
    try {
      dispatch(GlobalStateSLice.actions.toggleBackDrop(loaderValue));
    } catch {}
  };
export const setSnackBarMessage =
  (message: string) => async (dispatch: AppDispatch) => {
    try {
      dispatch(GlobalStateSLice.actions.setSnackBarMessage(message));
    } catch {}
  };

export const setSnackBarType =
  (type: AlertColor) => async (dispatch: AppDispatch) => {
    try {
      dispatch(GlobalStateSLice.actions.setSnackBarType(type));
    } catch {}
  };
export const getCountries = () => async (dispatch: AppDispatch) => {
  try {
    const response = await getCountriesAPI();
    dispatch(GlobalStateSLice.actions.setCountries(response));
  } catch {}
};

export const scrollToTop = () => {
  window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
};

