import React, { useEffect, useState } from "react";
import Header from "../../component/Header";
import HighestAmbition from "./components/HighestAmbition";
import SupportCommunities from "./components/SupportCommunities";
import { crowdfundingBanner } from "../../constants/bannerData";
import StaticBanner from "../../component/StaticBanner";
import PartOfJourney from "./components/PartOfJourney";
import WordsOfSupport from "./components/WordsOfSupport";
import { PaymentSuccess } from "../../store/Cart/ActionCart";
import { setSnackBarMessage, setSnackBarType, toggleShowSnackBar } from "../../store/GlobalState/ActionGlobalState";
import { useLocation } from "react-router-dom";
import { useAppDispatch } from "../../store/redux";
import { stripePayment } from "../../store/Crowdfunding/ActionCrowdfunding";
import { getContributions } from "../../networking/crowdfunding/crowdfunding";

interface Support {
  id: number;
  name: string;
  amount: string;
  words_of_support: string;
  created_at:string;
  country_code:string;
}
const Crowdfunding = () => {

  const dispatch = useAppDispatch();
  const urlParams = useLocation();
  const paymentIntent = new URLSearchParams(urlParams.search).get("payment_intent");

  useEffect(() => {
    const contributionId = localStorage.getItem("contribution_id") || "";

    if (!paymentIntent) {
      return;
    }
    const payment =  stripePayment(contributionId, paymentIntent);
    dispatch(payment).then((response) => {
      dispatch(toggleShowSnackBar(true));
      dispatch(setSnackBarType("success"));
      dispatch(setSnackBarMessage("Payment Success"));
      removeAllQueryParams();
    }).catch((error: any) => {
      dispatch(toggleShowSnackBar(true));
      dispatch(setSnackBarType("error"));
      dispatch(setSnackBarMessage(error?.response?.data.message ?? error?.message));
      removeAllQueryParams();
    });
  }, [urlParams]);

  const removeAllQueryParams = () => {
    const url = new URL(window.location.href);
    url.search = "";
    window.history.replaceState({}, "", url.toString());
  };
  const [goal, setGoal] = useState("");
  const [raised, setRaised] = useState("");
  const [recentContributors, setRecentContributors] =useState<Support[]>([]);
  const [sliderContributors, setSliderContributors] =useState<Support[]>([]);
  const getContributionsData = async () => {
    try {
      const data = await getContributions();
      if (data) {
        setGoal(data.goal);
        setRaised(data.raised);
        setRecentContributors(data.recent_contributors);
        setSliderContributors(data.slider_contributors);
      }
    } catch (error) {
      console.error("Error fetching contribution data:", error);
    }
  };
  useEffect(() => {
    getContributionsData();
  }, []);

  return (
    <div className="bg-white">
      <Header />
      <div className="container bg-white">
        <StaticBanner data={crowdfundingBanner} />
        <HighestAmbition goal={goal} raised={raised}/>
        <SupportCommunities recentContributors={recentContributors}/>
        <WordsOfSupport recentContributors={sliderContributors} />
        <PartOfJourney />
      </div>
    </div>
  );
};
export default Crowdfunding;