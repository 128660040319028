import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GlobalState, ICountry } from "./type";
import { AlertColor } from "@mui/material";

export const initialState: GlobalState = {
    isLoading: false,
    showBackDrop:false,
    showSnackBar: false,
    showButtonLoader:false,
    snackBarMessage: '',
    severity: 'success',
    country:[]
}

export const GlobalStateSLice = createSlice({
    name: 'GlobalState',
    initialState,
    reducers: {
        // todo : add your reducers to change and manipulate the state
        setSnackBarMessage(state, action: PayloadAction<string>) {
            state.snackBarMessage = action.payload;
        },
        toggleLoading(state, action: PayloadAction<boolean>) {
            state.isLoading = action.payload;
        },
        toggleShowSnackBar(state, action: PayloadAction<boolean>) {
            state.showSnackBar = action.payload;
        },
        toggleBackDrop(state, action: PayloadAction<boolean>) {
            state.showBackDrop = action.payload;
        },
        toggleButtonLoader(state, action: PayloadAction<boolean>) {
            state.showButtonLoader = action.payload;
        },
        setSnackBarType(state, action: PayloadAction<AlertColor>) {
            state.severity = action.payload;
        },
        setCountries(state, action: PayloadAction<ICountry[]>) {
            state.country = action.payload;
        },
    }
})

export default GlobalStateSLice.reducer;
