import { useEffect, useState } from "react";
import CustomInput from "../../../../../component/common/Inputs";
import CloseIcon from "@mui/icons-material/Close";
import { TextareaAutosize } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store/store";
import { setCreateEventData } from "../../../../../store/BusinessDashboard/ActionBusinessDashboard";
import { useAppDispatch, useAppSelector } from "../../../../../store/redux";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { BusinessDashboardSlice } from "../../../../../store/BusinessDashboard/BusinessDashboardSlice";

type IFAQData = {
  question?: string;
  answer?: string;
};
const FAQS = () => {
  const params = useParams();
  const { t } = useTranslation();
  const [showInput, setShowInput] = useState(false);
  const [faqList, setFaqList] = useState<IFAQData[]>([]);
  const [userInterest, setUserInterest] = useState<number[]>([]);
  const dispatch = useAppDispatch();
  const fieldsError = useSelector(
    (state: RootState) => state.businessDashboardReducer?.errors,
  );
  const userInterestType = useSelector(
    (state: RootState) => state.businessDashboardReducer?.eventData?.interests,
  );
  const isLoading = useSelector(
    (state: RootState) => state.globalStateReducer.isLoading,
  );
  const eventData = useSelector(
    (state: RootState) => state.businessDashboardReducer?.createEventData,
  );

  const handleRemoveErrorKey = (name: string[]) => {
    let errorfields = { ...fieldsError };
    name?.map((item) => {
      delete errorfields[item];
    });
    dispatch(BusinessDashboardSlice.actions.setErrors(errorfields));
  };
  const handleAddToList = (event: any) => {
    let array = faqList.filter(
      (item) => item.question !== "" && item.answer !== "",
    );
    setFaqList(array);
    dispatch(setCreateEventData("faqs", array));
    handleRemoveErrorKey(["faqs"]);
    setShowInput(false);
  };
  const handleAddFAQ = () => {
    setFaqList((prevValue) => [...prevValue, { question: "", answer: "" }]);
    setShowInput(true);
  };
  const handleChangeInput = (event: any, index: number) => {
    let data = [...faqList];
    data[index] = { ...data[index], [event.target.name]: event.target.value };
    setFaqList(data);
  };
  const handleDeleteItem = (index: number) => {
    let array = [...faqList];
    array.splice(index, 1);
    if (array.length === 0) setShowInput(false);
    setFaqList(array);
    dispatch(setCreateEventData("faqs", array));
  };
  const selectedLanguage = useAppSelector(
    (state) => state.languageReducer.selectedLanguage,
  );

  const handleCheckBox = (event: any) => {
    let array = [...eventData["interests"]];
    let val = Number(event.target.value);
    if (array.includes(val)) {
      const index = array.indexOf(val);
      array.splice(index, 1);
    } else {
      array.push(val);
    }
    setUserInterest(array);
    dispatch(setCreateEventData("interests", array));
    handleRemoveErrorKey(["interests"]);
  };
  useEffect(() => {
    if (params.eventId) {
      setFaqList(
        eventData["faqs"].map((item) => ({
          question: item.question,
          answer: item.answer,
        })),
      );
    } else {
      setFaqList(eventData["faqs"] ?? []);
    }
  }, [isLoading, params]);
  return (
    <>
      <div className="mt-5 grid grid-cols-[4fr_8fr] items-center rounded-md bg-[#f3f3f4] px-5 py-2">
        <div className="text-sm">
          {t("businessProfile.eventCreation.faqs.userInterests")}:
        </div>
        <div className="overflow-x-scroll pb-2">
          <div className="grid grid-flow-col grid-cols-[repeat(auto-fill,minmax(250px,1fr))] grid-rows-3 gap-x-6 gap-y-2">
            {userInterestType.map((item, index) => (
              <div key={index} className="checkbox-container flex items-center">
                <input
                  id={`bordered-interest-checkbox-${index}`}
                  type="checkbox"
                  value={item.id}
                  checked={eventData["interests"].includes(item.id)}
                  name="bordered-interest-checkbox"
                  onChange={handleCheckBox}
                  className=" checbox-input rounded border-gray-300 bg-gray-300 text-white accent-[#FF913F] focus:ring-transparent"
                />
                <label
                  htmlFor={`bordered-interest-checkbox-${index}`}
                  className="checkboxdivstom-checkbox ml-2 w-max text-xs font-medium capitalize text-[#131C23]"
                >
                  {item.translations && item.translations[selectedLanguage]
                    ? item.translations[selectedLanguage].name
                    : item.name}
                </label>
              </div>
            ))}
          </div>
        </div>
      </div>
      {fieldsError["interests"] && (
        <span className="ml-5 text-[12px] text-red-600">
          {fieldsError["interests"][0]}
        </span>
      )}
      <div className={"mb-11 mt-3"}>
        <div className=" border-b">
          <div className="flex w-full px-5 py-3">
            <p className="text-xl font-bold">
              {" "}
              {t("businessProfile.eventCreation.faqs.title")}
            </p>
          </div>
        </div>
        {!showInput ? (
          <div>
            {faqList.length > 0 ? (
              <ul>
                {faqList.map((item, index) => (
                  <div
                    key={index}
                    className="mt-4 rounded-lg bg-[#131C23]/5 px-5 py-5"
                  >
                    <div className="flex w-full items-center justify-between">
                      <p className="mb-5 font-medium">{item.question}</p>
                      <span
                        onClick={() => handleDeleteItem(index)}
                        className="cursor-pointer text-sm text-red-600"
                      >
                        {t("businessProfile.eventCreation.faqs.delete")}
                      </span>
                    </div>

                    <p className=" text-sm">{item.answer}</p>
                  </div>
                ))}
              </ul>
            ) : (
              <p className="ml-5 mt-3 text-sm font-medium">
                {t("businessProfile.eventCreation.faqs.noAvailableFaqs")}
              </p>
            )}
          </div>
        ) : (
          <>
            {faqList.map((item, index) => (
              <div className="flex items-center gap-3">
                <div className=" mt-4 w-full rounded-lg bg-[#f3f3f4] px-5 py-3">
                  <div className="flex w-full items-center justify-between">
                    <CustomInput
                      inputBgColor=" bg-[#131C23]/5 mb-5"
                      name={"question"}
                      value={item.question}
                      type="text"
                      placeholder={t(
                        "businessProfile.eventCreation.faqs.enterQuestion",
                      )}
                      onChange={(e) => handleChangeInput(e, index)}
                    />
                    <span
                      onClick={() => handleDeleteItem(index)}
                      className="cursor-pointer text-sm text-red-600"
                    >
                      {t("businessProfile.eventCreation.faqs.delete")}
                    </span>
                  </div>

                  <TextareaAutosize
                    className="w-full  rounded-lg bg-[#131C23]/5 p-5 focus-visible:border-[#131C23]"
                    placeholder={t(
                      "businessProfile.eventCreation.faqs.description",
                    )}
                    value={item.answer}
                    name="answer"
                    minRows={4}
                    maxRows={6}
                    maxLength={1000}
                    onChange={(e) => handleChangeInput(e, index)}
                  />
                </div>

                {/* <CloseIcon onClick={() => handleDeleteItem(index)} className="cursor-pointer" sx={{ fill: 'red', width: '18px', height: '18px' }} /> */}
              </div>
            ))}
            <div className="mt-3 flex items-center justify-end gap-3">
              <span
                onClick={handleAddToList}
                className="cursor-pointer rounded-full border border-emerald-600 px-3 py-1 text-sm text-emerald-600"
              >
                {t("businessProfile.eventCreation.faqs.save")}
              </span>
            </div>
          </>
        )}
        <div className="w-full py-3 text-end ">
          <span
            onClick={handleAddFAQ}
            className="cursor-pointer rounded-full border border-[#3F87FF] px-3 py-1 text-sm font-medium text-[#3F87FF]"
          >
            + {t("businessProfile.eventCreation.faqs.addFaq")}
          </span>
        </div>

        {fieldsError["faqs"] && (
          <span className="ml-5 text-[12px] text-red-600">
            {fieldsError["faqs"][0]}
          </span>
        )}
      </div>
    </>
  );
};
export default FAQS;
