import React, { useState, useEffect } from 'react';
import './style.css';

interface ImagesSliderProps {
    images: string[];
}

function ImagesSlider({ images }: ImagesSliderProps) {
    const [showPopup, setShowPopup] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [selectedImage, setSelectedImage] = useState<string | null>(
        images.length > 0 ? images[0] : null
    );

    useEffect(() => {
        if (images.length > 0) {
            setSelectedImage(images[0]);
        }
    }, [images]);

    const handleThumbnailClick = (imageUrl: string) => {
        setSelectedImage(imageUrl);
    };

    const openPopup = (src: string, index: number) => {
        setSelectedImage(src);
        setCurrentIndex(index);
        setShowPopup(true);
    };
    const closePopup = () => {
        setShowPopup(false);
    };
    const showNextImage = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation()
        const nextIndex = (currentIndex + 1) % images.length;
        setSelectedImage(images[nextIndex]);
        setCurrentIndex(nextIndex);
    };
    const showPreviousImage = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation()
        const previousIndex = (currentIndex - 1 + images.length) % images.length;
        setSelectedImage(images[previousIndex]);
        setCurrentIndex(previousIndex);
    };
    return (
        <div className="grid grid-rows-4 grid-cols-3 justify-between h-max">
            <div className="col-span-3 flex gap-5 items-center justify-center">
                {images.map((imageUrl, index) => (
                    <img
                        key={index}
                        src={imageUrl}
                        alt={`${index}`}
                        className={`cursor-pointer rounded-xl aspect-square w-[70px] h-[70px] shadow-2xl ${
                            selectedImage === imageUrl ? 'selected border-2 border-[#FF913F]' : ''
                        }`}
                        onClick={() => handleThumbnailClick(imageUrl)}
                    />
                ))}
            </div>
            <div
                className="row-start-1 row-span-3 col-span-3 h-[300px] w-full"
                onClick={() => selectedImage && openPopup(selectedImage, currentIndex)}
            >
                {selectedImage && (
                    <img
                        src={selectedImage}
                        className={`h-full w-full object-cover rounded-3xl shadow-2xl border-2 border-[#FF913F] ${
                            selectedImage ? 'selected-image' : ''
                        }`}
                    />
                )}
            </div>
            {showPopup && (
                <div className="fixed inset-0 grid place-items-center z-[60]" onClick={closePopup}>
                    <div className="relative w-full h-full grid place-items-center bg-black bg-opacity-60">
                        <div
                            className="p-4 rounded-lg max-w-2xl overflow-hidden">
                            <img src={selectedImage ?? ''} alt=""
                                 className="md:w-[600px] md:h-[500px] w-full h-full object-cover z-50 rounded-2xl shadow-md"/>
                        </div>
                        <button onClick={showPreviousImage} className="absolute left-[25%]">
                            <img src="/assets/svgs/eventDetails/left-arrow.svg" className="w-10 h-10"/>
                        </button>
                        <button onClick={showNextImage} className="absolute right-[25%]"><img
                            src="/assets/svgs/eventDetails/right-arrow.svg" className="w-10 h-10"/>
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
}
export default ImagesSlider;