import {socialData} from "../../../constants/footer/social";
import {useTranslation} from "react-i18next";

export const ContactUs = () => {
    const {t} = useTranslation()
    return (
        <section id="footer-contactUs">
            <div className="text-white max-md:text-center">
                <div className="pb-3">
                    <h3 className="font-medium text-lg mb-2"> {t('footer.contactUs.title')}</h3>
                    <div className="flex items-center max-md:justify-center">
                        <a
                            href="mailto:tripsupport@explorenearme.co.uk"
                            className="w-max flex items-center hover:translate-x-0.5 pr-4 mb-6 hover:-translate-y-0.5 transition-transform text-md"
                        >
                            <img
                                loading="lazy"
                                className="max-w-[15px]"
                                src="/assets/svgs/common/email.svg"
                                alt="Email"
                            />
                            <p className="ml-2 text-md font-light">tripsupport@explorenearme.co.uk</p>
                        </a>
                    </div>
                </div>
                <div className="pb-3">
                    <h3 className="font-medium text-md mb-2"> {t('footer.contactUs.contactSeo')}</h3>
                    <div className="flex items-center max-md:justify-center">
                        <a
                            href="mailto:HQ@explorenearme.co.uk"
                            className="w-max flex items-center hover:translate-x-0.5 pr-4 mb-6 hover:-translate-y-0.5 transition-transform text-md"
                        >
                            <img
                                loading="lazy"
                                className="max-w-[15px]"
                                src="/assets/svgs/common/email.svg"
                                alt="Email"
                            />
                            <p className="ml-2 text-sm font-light">HQ@explorenearme.co.uk</p>
                        </a>
                    </div>
                </div>
                <div className="pb-3">
                    <h3 className="font-medium text-md mb-2"> {t('footer.contactUs.socialMedia')}</h3>
                    <div className="flex items-center max-md:justify-center gap-4">
                        {socialData.map((social) => {
                            return (
                                <li
                                    key={`footer_social_${social.id}`}
                                    className="max-w-[15px] cursor-pointer hover:translate-x-1 hover:-translate-y-1 transition-transform duration-500 uppercase list-none"
                                >
                                    <a href={social.path} target="_blank" rel="noopener noreferrer">
                                        <img loading="lazy" src={social.image} key={`social_${social.id}`} alt=""/>
                                    </a>
                                </li>
                            );
                        })}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ContactUs;
