import LightningSvg from "../../../../../component/SvgComponents/LightningSvg";
import MedalSvg from "../../../../../component/SvgComponents/MedalSvg";
import { LinearProgress, linearProgressClasses, styled } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store/store";
import {useTranslation} from "react-i18next";
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 15,
    borderRadius: 10,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: '#ff913f44',
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: theme.palette.mode === 'light' ? '#FF913F' : 'red',
    },
}));

const LevelAccount = () => {
    const userLevelGamification = useSelector((state: RootState) => state.userDashboardReducer?.gamificationData?.user_level);
    const {t} =useTranslation();

    return (
        <div className={"bg-[#ff913f22] w-full rounded-xl px-4 py-4"}>
            <div className="flex gap-2">
                <p className={"font-bold text-[20px]"}>{t('userProfile.gamification.levelAccount.title')}</p>
                <img loading="lazy" src="/assets/svgs/common/info-circle.svg" className="ml-3" />
            </div>

            <div
                className={"bg-[url(/public/assets/images/pages/Dashboard/UserProfile/level-background.png)] mt-5 rounded-[30px] bg-cover "}>
                <div className={"grid sm:grid-cols-[1fr_2fr] grid-cols-1 gap-4 px-1  py-1"}>
                    <div className={"flex bg-white h-full rounded-[30px] justify-center text-center items-center"}>
                        <img loading="lazy" src={"/assets/images/pages/Dashboard/UserProfile/currentLevel.png"} />
                    </div>
                    <div className={"gird grid-cols-1  px-3 py-3"}>
                        <div className={"border w-fit items-center flex text-center px-3 py-1.5 border-[#ff913f] bg-[#ff913f33] rounded-full text-[#ff913f]"}>
                            <MedalSvg fill={"#ff913f"} />
                            <p className={"ml-2 text-sm"}>{t('userProfile.gamification.levelAccount.JuniorAccount')}</p>
                        </div>
                        <div className=" text-white flex ">
                            <p className={"text-[40px] justify-self-end"}>{userLevelGamification?.current_level}</p>
                            <p className={"mt-6 ml-2 text-[#898d91]"}>/</p>
                            <p className={"mt-6 text-[#898d91] "}>{userLevelGamification?.next_level_at}</p>
                        </div>
                        <div className="grid grid-cols-[1fr_3fr_1fr] text-center items-center gap-3">
                            <div className={"text-[#ff913f66]"}>
                                Exp: <b className={"text-[#ff913f]"}>{userLevelGamification?.current_exp}</b>
                            </div>
                            <BorderLinearProgress variant={"determinate"} value={userLevelGamification?.current_exp} />
                            <div className={"text-[#ff913f66]"}>
                                Exp: <b className={"text-[#ff913f]"}>{userLevelGamification?.next_level_at}</b>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="grid grid-cols-2 mt-7">
                <div className="grid grid-cols-[1fr_10fr] gap-4 items-center">
                    <LightningSvg className={"fill-[#ff913f] bg-white rounded-full py-1 px-1 h-7 w-7"} />
                    <div>
                        <p className={"font-bold text-[16px]"}>{t('userProfile.gamification.levelAccount.levelOne.label')}</p>
                    </div>
                </div>
                <div className="grid grid-cols-[1fr_10fr] items-center gap-4">
                    <LightningSvg className={"fill-[#ff913f] bg-white rounded-full py-1 px-1 h-7 w-7"} />
                    <div>
                        <p className={"font-bold text-[16px]"}>{t('userProfile.gamification.levelAccount.levelTwo.label')}</p>
                    </div>
                </div>
            </div>
            <div className="grid grid-cols-2 mt-3">
                <div className={"text-md ml-10 text-[#898987]"}>
                    {t('userProfile.gamification.levelAccount.levelOne.description')} <b className={"text-[#ff913f]"}>  {t('userProfile.gamification.levelAccount.levelOne.tokens')}</b>
                </div>
                <div className={"text-md  text-[#898987] ml-10"}>
                    {t('userProfile.gamification.levelAccount.levelTwo.description')} <b className={"text-[#ff913f]"}>{t('userProfile.gamification.levelAccount.levelTwo.tokens')}</b>
                </div>
            </div>
        </div>
    )
}
export default LevelAccount
