import axios from "axios";

export const getUserHistoryAPI = async (payload:number): Promise<any> => {
    try {
        const response = await axios.get(`api/users/profile/history?page=${payload}`);
        return response.data.data

    } catch (error) {
        throw error;
    }
};

export const editHistoryReservedTicketAPI = async (reservedTicketId: string, date: string, time: string): Promise<any> => {
    try {
        const response = await axios.post(`/api/users/profile/history/${reservedTicketId}/update`, {
            reserved_date: date,
            reserved_time: time
        });
        return response.data.data;
    } catch (error) {
        throw error;
    }
};
