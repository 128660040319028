import {
  getOurMissionAPI,
  getPrivacyPolicyAPI,
  getTermsAndConditionAPI
} from "../../networking/termsAndCondition/termsAndCondition";
import { toggleBackDrop } from "../GlobalState/ActionGlobalState";
import { AppDispatch } from "../store";
import { termsSLice } from "./TermsSlice";

export const getTerms = (type: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(toggleBackDrop(true));
    const response = await getTermsAndConditionAPI(type);
    dispatch(termsSLice.actions.setTerms(response.terms));
  } catch {
  } finally {
    dispatch(toggleBackDrop(false));
  }
};
export const getPrivacyPolicy = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(toggleBackDrop(true));
    const response = await getPrivacyPolicyAPI();
    dispatch(termsSLice.actions.setPrivacy(response.privacy));
  } catch {
  } finally {
    dispatch(toggleBackDrop(false));
  }
};

export const getOurMission = (selectedLanguage: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(toggleBackDrop(true));
    const response = await getOurMissionAPI(selectedLanguage);
    if (typeof response === 'object' && response?.translations?.[selectedLanguage].information) {
      dispatch(termsSLice.actions.setOurMission(response.translations?.[selectedLanguage].information));
    } else {
      console.error("Invalid response from API");
    }
  } catch {
    // Handle the error appropriately
  } finally {
    dispatch(toggleBackDrop(false));
  }
};