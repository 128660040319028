import { Navigate, Outlet } from "react-router-dom";

export const RequiresAuthentication = () => {
    const isLoggedIn = !!localStorage.getItem("token");
    const handleNavigateUser = () => {
        return <Navigate to="/401" replace />;
    };
    return isLoggedIn ? <Outlet /> : handleNavigateUser();
};


export const ClientRoute = () => {
    const isLoggedIn = !!localStorage.getItem("token");
    const role = JSON.parse(localStorage.getItem('userRole') as string)
    const handleNavigateUser = () => {
        return <Navigate to="/" replace />;
    };
    return (isLoggedIn && role.id===1) ? <Outlet /> : handleNavigateUser();
};

export const BusinessRoute = () => {
    const isLoggedIn = !!localStorage.getItem("token");
    const role = JSON.parse(localStorage.getItem('userRole') as string)
    const handleNavigateUser = () => {
        return <Navigate to="/" replace />;
    };
    return (isLoggedIn && role.id===2) ? <Outlet /> : handleNavigateUser();
};