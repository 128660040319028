import { Dayjs } from "dayjs";

export const divideTimeIntoSlots = (
    start_Time: string | Dayjs | null | Date,
     end_Time: string | Dayjs | null | Date,
     slotDuration: any,
   ) => {
     const slots = [];
     let currentTime = new Date(start_Time as string);
     let endTime = new Date(end_Time as string);
     // Check if endTime is earlier than currentTime, indicating it's on the next day
     if (endTime < currentTime) {
       endTime.setDate(endTime.getDate() + 1);
     }
   
     while (currentTime < endTime) {
       let slotEndTime = new Date(
         currentTime.getTime() + slotDuration * 60 * 1000,
       ); // Convert duration to milliseconds
   
       // Ensure that slotEndTime doesn't go beyond endTime
       if (slotEndTime > endTime) {
         slotEndTime = new Date(endTime);
       }
   
       slots.push({
         start: currentTime,
         end: slotEndTime,
       });
   
       currentTime = slotEndTime;
     }
   
     return slots;
   };
   

   export const formatTime = (value: string,showPeriod:boolean) => {
     const selectedDate = new Date(value);
     const hours = selectedDate?.getHours().toString().padStart(2, "0");
     const minutes = selectedDate.getMinutes().toString().padStart(2, "0");
     const seconds = selectedDate.getSeconds().toString().padStart(2, "0");
     const period = Number(hours) >= 12 ? "PM" : "AM";
     const formattedTime = showPeriod? `${hours}:${minutes} ${period}` : `${hours}:${minutes}`;;
     return formattedTime;
   };