import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {RootState} from "../../../../store/store";
import {useNavigate} from "react-router-dom";
import {useAppDispatch} from "../../../../store/redux";
import {selectedCart} from "../../../../store/Cart/ActionCart";
import {useTranslation} from "react-i18next";

interface OrderConfirmationProps {
    selectedTotal: number;
    selectedItems: any[];
    fees: number;
    priceWithoutFees: number;
}

const OrderConfirmation: React.FC<OrderConfirmationProps> = ({
                                                                 selectedTotal,
                                                                 selectedItems,
                                                                 fees,
                                                                 priceWithoutFees,
                                                             }) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const {t} = useTranslation();
    const selectedCurrency = useSelector(
        (state: RootState) => state.currenciesReducer.selectedCurrency,
    );
    const cartItem = useSelector(
        (state: RootState) => state.cartReducer.cartItem,
    );
    const [checkoutError, setCheckoutError] = useState(false);

    useEffect(() => {
    }, [cartItem]);
    const goToCheckout = () => {
        if (selectedItems.length > 0) {
            setCheckoutError(false);
            const selectedCartIds = selectedItems.map((item) => item.id);
            dispatch(selectedCart(selectedCartIds))
                .then(() => {
                    navigate("/checkout");
                })
                .catch(() => {
                });
        } else {
            setCheckoutError(true);
        }
    };
    return (
        <div className="container px-4">
            <div className="rounded-3xl bg-white px-5 py-10 shadow-lg">
                <h3 className="text-xl font-bold text-[#131C23]">
                    {t('shopCart.orderConfirmation')}
                </h3>
                <div className="mt-5 rounded-3xl bg-[#3F8CFF]/10 p-5">
                    <div className="flex items-center justify-between text-lg text-[#131C23]">
                        <div className="flex">
                            <span className="font-bold">{selectedItems.length} {t('shopCart.item')}</span>
                        </div>
                        <div>
                            <p className="text-lg font-bold text-[#3F8CFF]">
                                {selectedCurrency.symbol} {(priceWithoutFees * selectedCurrency.exchange_rate).toFixed(2)}
                            </p>
                        </div>
                    </div>
                    <div className="flex items-center justify-between text-lg mt-3 text-[#131C23]">
                        <div className="relative group z-50">
                            <div className="flex gap-2 items-center">
                                <p className="text-sm font-bold"> {t('shopCart.serviceFees')} </p>
                                <img
                                    src="/assets/svgs/common/message-icon.svg"
                                    alt="Message Icon"
                                    className="cursor-pointer group-hover:opacity-75 w-4"
                                />
                            </div>
                            <div
                                className="hidden md:max-w-xs w-max absolute bg-white font-bold p-4 shadow-md border border-gray-300 group-hover:block left-0 translate-y-1">
                                <h3 className="text-md"> {t('shopCart.serviceTitle')}</h3>
                                <div className="border-b-2 border-[#3F8CFF] my-1 opacity-[0.5]"></div>
                                <p className="text-[#3F8CFF] text-xs">
                                    {t('shopCart.serviceText')}
                                </p>
                            </div>
                        </div>
                        <p className="text-lg font-bold text-[#3F8CFF]">
                            {selectedCurrency.symbol} {(fees * selectedCurrency.exchange_rate).toFixed(2)}
                        </p>
                    </div>
                    <div className="flex items-center justify-between mt-3 text-lg text-[#131C23]">
                        <p className="pr-1 font-medium">{t('shopCart.totalPrice')}: </p>{" "}
                        <p className="text-lg font-bold text-[#3F8CFF]">
                            {selectedCurrency.symbol} {(selectedTotal * selectedCurrency.exchange_rate).toFixed(2)}
                        </p>
                    </div>
                </div>
                <div
                    className={`mt-5 flex justify-center ${
                        selectedItems.length > 0
                            ? ""
                            : "opacity-50 disabled:cursor-not-allowed"
                    }`}
                    onClick={goToCheckout}
                >
                    <p className="flex w-full cursor-pointer items-center justify-center rounded-full bg-[#FF913F] py-2 text-white hover:bg-[#FF913F]/80 disabled:cursor-not-allowed">
                        <span className="px-1">{t('shopCart.checkout')}</span>
                        <img src="/assets/svgs/common/white-arrow.svg" className="w-4"/>
                    </p>
                </div>
                {checkoutError && (
                    <p className="flex items-center py-3 text-sm font-bold text-[#FF913F]">
                        <img src="/assets/svgs/common/error.svg" className="h-8 w-8 pr-2"/>
                        {t('shopCart.checkoutError')}
                    </p>
                )}
                <div className="mt-5 flex items-center">
                    <img src="/assets/svgs/common/payments.svg"/>
                    <div className="pl-3">
                        <p className="text-md font-bold">{t('shopCart.securePayments')}</p>
                        <p className="text-md">
                             {t('shopCart.payment')}
                            <span className="font-bold text-[#65CC93]"> {t('shopCart.percentage')}</span> {t('shopCart.secure')}
                        </p>
                    </div>
                </div>
                <div className="flex items-center pt-4">
                    <img src="/assets/svgs/common/cost.svg"/>
                    <div className="pl-3">
                        <p className="text-md font-bold"> {t('shopCart.hiddenCost')}</p>
                        <p className="text-md"> {t('shopCart.fees')}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OrderConfirmation;
