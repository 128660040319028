import React, { useEffect, useState } from "react";
import EventCard from "../../component/EventCard";
import { useAppSelector } from "../../store/redux";
import { useParams, useSearchParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { useAppDispatch } from "../../store/redux";
import { filterAllEventsData } from "../../constants/filterAllEventsData";
import TabComponent from "../../component/TabComponent";
import DropdownTab from "../../component/DropdownFilter";
import SearchButton from "../../component/SearchButton";
import { fetchTypesAxios } from "../../networking/filterByType/filterByType";
import { ITypes } from "../../networking/filterByType/types";
import { filterDropdownData } from "../../constants/filterDropdownData";
import { TEventsFilters } from "../../store/events/types";
import { scrollToTop } from "../../store/GlobalState/ActionGlobalState";
import { IEventCategory, IEventsData } from "../../networking/events/types";
import Header from "../../component/Header/index";
import { useTranslation } from "react-i18next";
import { setAuthModal } from "../../store/Auth/ActionAuth";
import { getLocationsAPI, useAllEvents } from "../../networking/seeAllEvents/allEvents";
import { getCategoriesAxios } from "../../networking/events/events";
import Spinner from "./Spinner";
import { searchEvents } from "../../store/events/ActionEvents";
import Pagination from "@mui/material/Pagination";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { countriesAPI } from "../../networking/crowdfunding/crowdfunding";

const SeeAllEvents = ({ filters = true }: { filters?: boolean }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const auth = useAppSelector(state => state.authReducer);
  const { searchedEvents } = useAppSelector(state => state.eventsReducer);
  const [, setSelectedFilters] = useState(
    filterAllEventsData.map(() => false)
  );
  const { eventType } = useParams();
  const [events] = useState<IEventsData[]>([]);
  const [categoryEvents, setCategoryEvents] = useState<IEventCategory[]>([]);
  const [exclusiveCategoryEvents, setExclusiveCategoryEvents] = useState<IEventCategory[]>([]);
  const [, setActiveTab] = useState(0);
  const [maxPrice, setMaxPrice] = useState<number | null>(null);
  const [selectedCategoryId, setSelectedCategoryId] = useState<number | null>(null);
  const [checkboxValues, setCheckboxValues] = useState<boolean[]>([]);
  const [tabTitlesData, setTabTitlesData] = useState<{ id: number, label: string }[]>([]);
  const selectedLanguage = useAppSelector(state => state.languageReducer.selectedLanguage);
  const [searchParams] = useSearchParams();
  const [categoryId, setCategoryId] = useState(searchParams.get("categoryId") || "0");

  const [isDurationOpen, setIsDurationOpen] = useState(false);
  const [isStarOpen, setIsStarOpen] = useState(false);
  const [selectedDuration, setSelectedDuration] = useState("");
  const [selectedStar, setSelectedStar] = useState("");
  const [selectedCheckboxes, setSelectedCheckboxes] = useState<string[]>([]);
  const [eventTypes, setEventTypes] = useState<ITypes[]>([]);
  const [, setIsNoEventsFound] = useState(false);
  const [searchData, setSearchData] = useState<TEventsFilters>({
    type_id: [],
    min_price: 0,
    max_price: maxPrice,
    duration: "",
    all_stars: "",
    status: "",
    location: ""
  });
  const [loadEvents, setLoadEvents] = useState(8);
  const navigate = useNavigate();
  const { currentPage: currentPageParam } = useParams();
  const initialPage = parseInt(sessionStorage.getItem("currentPage") || "1");
  const [currentPage, setCurrentPage] = useState(initialPage);
  const currentPageFromURL = currentPageParam ? parseInt(currentPageParam) : 1;
  const [country, setCountry] = useState({ name: "", code: "" });
  const [countries, setCountries] = useState<string[]>([]);

  // const {allEvents, allEventsIsLoading, refetch} = useAllEvents(searchData, parseInt(categoryId), eventType,loadEvents);
  // const { allEvents, allEventsIsLoading, refetch } = useAllEvents(
  //     searchData,
  //     parseInt(categoryId),
  //     eventType,
  //     currentPage,
  //     loadEvents
  // );
  const { allEvents, allEventsIsLoading, refetch, hasNextPage, lastPage } = useAllEvents(
    searchData,
    parseInt(categoryId),
    eventType,
    currentPage,
    loadEvents
  );

  // useEffect(() => {
  //   if (!auth.token && eventType === "exclusive") {
  //     return;
  //   } else {
  //     refetch();
  //   }
  // }, [currentPage, loadEvents, refetch]);

  useEffect(() => {
    const storedPage = sessionStorage.getItem("currentPage");
    if (storedPage !== null) {
      const newPage = parseInt(storedPage);
      setCurrentPage(newPage);
    }
  }, [currentPage]);

  useEffect(() => {
    setCurrentPage(currentPageFromURL);
  }, [currentPageFromURL]);

  const handlePageChange = (event: any, page: any) => {
    setCurrentPage(page);
    sessionStorage.setItem("currentPage", page.toString());
  };
  useEffect(() => {
  }, [currentPage, loadEvents]);

  const showSignInContent = useAppSelector(state => state.authReducer.openAuthModal);
  const [isSearching, setIsSearching] = useState(false);
  const handleLoadMoreClick = () => {
    setCurrentPage((prevPage) => prevPage + 1);
    setLoadEvents((prevNumEvents) => prevNumEvents + 8);
  };
  const handlePreviousClick = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
      setLoadEvents((prevNumEvents) => prevNumEvents - 8);
    }
  };
  const handleCheckboxChange = (index: number) => {
    const updatedCheckboxValues = [...checkboxValues];
    updatedCheckboxValues[index] = !updatedCheckboxValues[index];
    setCheckboxValues(updatedCheckboxValues);
    const selectedEventType = eventTypes[index];
    setSelectedCheckboxes((prevSelectedCheckboxes) => {
      if (prevSelectedCheckboxes.includes(selectedEventType.title)) {
        return prevSelectedCheckboxes.filter((item) => item !== selectedEventType.title);
      } else {
        return [...prevSelectedCheckboxes, selectedEventType.title];
      }
    });
  };
  const handleMinPriceChange = (event: any) => {
    setSearchData((prevSearchData) => ({
      ...prevSearchData,
      min_price: event.target.value
    }));
  };
  const handleMaxPriceChange = (event: any) => {
    setSearchData((prevSearchData) => ({
      ...prevSearchData,
      max_price: event.target.value
    }));
  };
  const handleResetClick = () => {
    setIsSearching(false);
    if (eventType === "large") {
      const emptyFilters: TEventsFilters = {
        type_id: [],
        status: "",
        min_price: 0,
        max_price: null,
        duration: "",
        all_stars: "",
        location: ""
      };
      // dispatch(getAllEvents(eventType, emptyFilters));
    }
    setSelectedFilters(filterAllEventsData.map(() => false));
    setMaxPrice(null);
    setSelectedCategoryId(null);
    setCheckboxValues([]);
    setIsDurationOpen(false);
    setIsStarOpen(false);
    setSelectedDuration("");
    setSelectedStar("");
    setSelectedCheckboxes([]);
    setSearchData({
      type_id: [],
      min_price: 0,
      max_price: null,
      duration: "",
      all_stars: "",
      status: "",
      location: ""
    });
  };
  const toggleDropdown = () => {
    setIsDurationOpen(!isDurationOpen);
    setIsStarOpen(false);
  };
  const toggleSecondDropdown = () => {
    setIsStarOpen(!isStarOpen);
    setIsDurationOpen(false);
  };
  const handleDurationSelect = (option: string) => {
    setSearchData((prevSearchData) => ({
      ...prevSearchData,
      duration: option
    }));
    setSelectedDuration(option);
    setIsDurationOpen(false);
  };
  const handleStarSelect = (option: string) => {
    setSearchData((prevSearchData) => ({
      ...prevSearchData,
      all_stars: option
    }));
    setSelectedStar(option);
    setIsStarOpen(false);
  };
  const handleSearchClick = () => {
    setCurrentPage(1);

    if (auth.token !== null) {
      let array =[] as number[];
      selectedCheckboxes.map((title) => {
        const selectedType = eventTypes.find((type) => type.title === title);
        array.push(selectedType ? selectedType.id : 0)
        return selectedType ? selectedType.id : 0;
      })

      const updatedSearchData: TEventsFilters = {
        type_id: array,
        min_price: parseFloat(searchData.min_price?.toString() || "0"),
        max_price: searchData.max_price ? parseFloat(searchData.max_price?.toString() || "0") : null,
        duration: selectedDuration,
        all_stars: selectedStar,
        status: "",
        location: country.code
      };
      setSearchData(updatedSearchData);

      const noEventsFound = events?.length === 0;

      setIsNoEventsFound(noEventsFound);
      setIsSearching(true);

      // refetch();
      // dispatch(searchEvents(eventType, updatedSearchData, selectedCategoryId));
    }
  };
  const getLocations = async () => {
    try {
      const data = await getLocationsAPI();
      if (data) {
        setCountries(data);
      }
    } catch (error) {

    }
  };
  useEffect(() => {
    getLocations();
  }, []);

  useEffect(() => {
    dispatch(scrollToTop);
    fetchTypesAxios()
      .then((data) => {
        if (data) {
          setMaxPrice(data.max_price);
          setSearchData((prevSearchData) => ({
            ...prevSearchData,
            max_price: data.max_price
          }));
          setEventTypes(data.types);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (eventType === "large") {
      if (selectedLanguage === "en") {
        setTabTitlesData(prev => prev = [
          { id: 0, label: t("allEvents") }
        ]);
        categoryEvents.map((category) => {
            setTabTitlesData(prev => prev = [
              ...tabTitlesData, { id: category.id, label: category.title }
            ]);
          }
        );
      } else {
        setTabTitlesData(prev => prev = [
          { id: 0, label: t("allEvents") }
        ]);
        categoryEvents.map((category) => {
            setTabTitlesData(prev => prev = [
              ...tabTitlesData, {
                id: category.id,
                label: category.translations ? category.translations?.[selectedLanguage].title : category.title
              }
            ]);
          }
        );
      }
    }
  }, [selectedLanguage, categoryEvents]);

  const fetchCategories = async () => {
    try {
      const categoriesData = await getCategoriesAxios();
      setCategoryEvents(categoriesData);
      setExclusiveCategoryEvents(categoriesData);
    } catch (error) {
    }
  };
  useEffect(() => {
    refetch();
    setActiveTab(0);
  }, [categoryId]);

  useEffect(() => {
    const translatedCategories = (categories: IEventCategory[]) =>
      categories.map((category) => ({
        id: category.id,
        label: category.translations && category.translations[selectedLanguage]
          ? category.translations[selectedLanguage].title
          : category.title,
        categoryId: category.id
      }));
    setTabTitlesData((prev) => [
      { id: 0, label: t("allEvents") },
      ...(eventType === "large" || eventType === "exclusive" ? translatedCategories(categoryEvents) : translatedCategories(categoryEvents))
    ]);
  }, [categoryEvents, exclusiveCategoryEvents, selectedLanguage]);
  useEffect(() => {
    fetchCategories();
  }, []);
  const handleTabClick = (
    { categoryId, id, label }: { categoryId: number; id: number; label: string },
    tabId: number
  ) => {
    handleResetClick();
    setActiveTab(tabId);
    setSelectedCategoryId(tabId !== 0 ? id : 0);
    if (tabId !== 0) {
      setCategoryId(String(tabId));
    } else {
      setCategoryId(String(0));
    }
  };
  // if (!auth.token && eventType === "exclusive") {
  //   return (
  //     <main id="unauthenticated-events">
  //       <Header />
  //       <img
  //         src="/assets/images/pages/seeAll/exclusive-events.png"
  //         alt="Exclusive Events"
  //         className="w-full"
  //       />
  //     </main>
  //   );
  // }
  return (
    <main id="all-events">
      <Header />
      <div className="container">
        <div className="">
          {eventType === "large" || eventType === "exclusive" ? (
            <TabComponent
              titles={tabTitlesData}
              activeTab={categoryId ? parseInt(categoryId) : 0}
              handleTabClick={handleTabClick}
            />
          ) : null}
          {filters && <div>
            <div className="md:flex py-2 items-center justify-between max-md:my-8">
              <div
                className="flex max-md:flex max-md:flex-wrap overflow-x-auto md:justify-between max-md:bg-[#131C23]/10 max-md:p-3 max-md:rounded-xl max-md:mb-10">
                {eventTypes.map((item, index) => (
                  <div key={index}
                       className="flex items-center justify-between checkbox-container md:mb-0 mb-3 gap-1">
                    <input
                      id={`bordered-checkbox-${index}`}
                      type="checkbox"
                      value=""
                      name="bordered-checkbox"
                      className="w-4 h-4 border-gray-300 bg-gray-300 rounded accent-[#FF913F] focus:ring-transparent text-white checbox-input"
                      checked={checkboxValues[index]}
                      onChange={() => handleCheckboxChange(index)}
                    />
                    <img src={item.logo_path} className="w-5 h-5" />
                    <label
                      htmlFor={`bordered-checkbox-${index}`}
                      className="w-full py-2 font-medium text-[#131C23] text-xs checkbox-label custom-checkbox whitespace-nowrap"
                    >
                      {item.translations && item.translations[selectedLanguage] ? item.translations[selectedLanguage].title : item.title}
                    </label>
                  </div>
                ))}
              </div>
              <div className="flex items-center md:justify-center mb-4 md:mb-0">
                <label htmlFor="price"
                       className="block text-sm font-medium text-gray-900">{t("minPrice")}:</label>
                <input
                  type="number"
                  id="price"
                  value={searchData.min_price === null ? "" : searchData.min_price.toString()}
                  onChange={handleMinPriceChange}
                  className="bg-gray-200 mx-1 text-gray-900 text-sm md:rounded-lg max-md:rounded-2xl block md:w-[50px] max-md:w-full p-1.5 focus:outline-[#FF913F] dark:border-gray-600 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
              <div className="flex items-center md:justify-center mb-4 md:mb-0">
                <label htmlFor="price"
                       className="block text-sm font-medium text-gray-900">{t("maxPrice")}:</label>
                <input
                  type="number"
                  id="price"
                  value={searchData.max_price === null ? "" : searchData.max_price.toString()}
                  onChange={handleMaxPriceChange}
                  className="bg-gray-200 mx-3 text-gray-900 text-sm md:rounded-lg max-md:rounded-2xl focus:outline-[#FF913F] focus:ring-blue-500 focus:border-red-500 focus-visible:border-blue-500 block md:w-[50px] max-md:w-full p-1.5 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
           <div className="md:hidden">
             <Select
               value={{ label: country.name, value: country.code }}
               onChange={(selectedOption) => {
                 if (selectedOption) {
                   setCountry({
                     name: selectedOption.label,
                     code: selectedOption.value
                   });
                 }
               }}
               placeholder="Enter a country"
               options={countries?.map((country) => ({
                 label: country,
                 value: country
               }))}
             />
           </div>
              <div className="hidden md:flex md:justify-between items-center">
                <div className="hidden md:flex md:justify-between items-center">
                  <div className="relative">
                    {/*<button*/}
                    {/*    type="button"*/}
                    {/*    onClick={toggleDropdown}*/}
                    {/*    className="flex items-center text-sm font-medium text-gray-700 focus:outline-none"*/}
                    {/*>*/}
                    {/*    {selectedDuration || 'Duration'}*/}
                    {/*    <svg*/}
                    {/*        className="ml-1 h-5 w-5"*/}
                    {/*        xmlns="http://www.w3.org/2000/svg"*/}
                    {/*        viewBox="0 0 20 20"*/}
                    {/*        fill="currentColor"*/}
                    {/*        aria-hidden="true"*/}
                    {/*    >*/}
                    {/*        <path*/}
                    {/*            fillRule="evenodd"*/}
                    {/*            d="M6.293 6.293a1 1 0 011.414 0L10 8.586l2.293-2.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"*/}
                    {/*            // clipRule="evenodd"*/}
                    {/*        />*/}
                    {/*    </svg>*/}
                    {/*</button>*/}
                    {isDurationOpen && (
                      <div
                        className="origin-top-right absolute right-0 mt-2 w-[130px] rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-10"
                        role="menu"
                        aria-orientation="vertical"
                        aria-labelledby="options-menu"
                      >
                        <div className="py-1">
                          {filterDropdownData[0].options.map((option, index) => (
                            <a
                              key={index}
                              onClick={() => handleDurationSelect(option)}
                              href="#"
                              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                              role="menuitem"
                            >
                              {option}
                            </a>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="relative w-[150px] px-3">
                    {/*<button*/}
                    {/*    type="button"*/}
                    {/*    onClick={toggleSecondDropdown}*/}
                    {/*    className="flex items-center text-sm font-medium text-gray-700 focus:outline-none"*/}
                    {/*>*/}
                    {/*    {selectedStar || 'All Star'}*/}
                    {/*    <svg*/}
                    {/*        className="ml-1 h-5 w-5"*/}
                    {/*        xmlns="http://www.w3.org/2000/svg"*/}
                    {/*        viewBox="0 0 20 20"*/}
                    {/*        fill="currentColor"*/}
                    {/*        aria-hidden="true"*/}
                    {/*    >*/}
                    {/*        <path*/}
                    {/*            fillRule="evenodd"*/}
                    {/*            d="M6.293 6.293a1 1 0 011.414 0L10 8.586l2.293-2.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"*/}
                    {/*        />*/}
                    {/*    </svg>*/}
                    {/*</button>*/}
                    {isStarOpen && (
                      <div
                        className="origin-top-right absolute right-0 mt-2 w-[130px] rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-10"
                        role="menu"
                        aria-orientation="vertical"
                        aria-labelledby="options-menu"
                      >
                        <div className="py-1">
                          {filterDropdownData[1].options.map((option, index) => (
                            <a
                              key={index}
                              onClick={() => handleStarSelect(option)}
                              href="#"
                              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                              role="menuitem"
                            >
                              {option}
                            </a>
                          ))}
                        </div>
                      </div>
                    )}

                    <Select
                      value={{ label: country.name, value: country.code }}
                      onChange={(selectedOption) => {
                        if (selectedOption) {
                          setCountry({
                            name: selectedOption.label,
                            code: selectedOption.value
                          });
                        }
                      }}
                      placeholder="Enter a country"
                      options={countries?.map((country) => ({
                        label: country,
                        value: country
                      }))}
                    />
                  </div>
                  <SearchButton onClick={handleSearchClick} />
                  <button className="px-3" onClick={handleResetClick}>
                    <img src="/assets/svgs/common/reset.svg" alt="reset" />
                  </button>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-2 md:hidden place-content-between max-md:block">
              <div className="relative">
                {/*<button*/}
                {/*  type="button"*/}
                {/*  onClick={toggleDropdown}*/}
                {/*  className="flex items-center text-sm font-medium text-gray-700 focus:outline-none max-md:bg-[#131C23]/10 max-md:w-full max-md:rounded-full max-md:p-2 max-md:justify-between max-md:mb-2"*/}
                {/*>*/}
                {/*  {selectedDuration || "Duration"}*/}
                {/*  <svg*/}
                {/*    className="ml-1 h-5 w-5"*/}
                {/*    xmlns="http://www.w3.org/2000/svg"*/}
                {/*    viewBox="0 0 20 20"*/}
                {/*    fill="currentColor"*/}
                {/*    aria-hidden="true"*/}
                {/*  >*/}
                {/*    <path*/}
                {/*      fillRule="evenodd"*/}
                {/*      d="M6.293 6.293a1 1 0 011.414 0L10 8.586l2.293-2.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"*/}
                {/*      // clipRule="evenodd"*/}
                {/*    />*/}
                {/*  </svg>*/}
                {/*</button>*/}
                {isDurationOpen && (
                  <div
                    className="origin-top-right absolute right-0 w-[150px] rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-10"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="options-menu"
                  >
                    <div className="py-1">
                      {filterDropdownData[0].options.map((option, index) => (
                        <a
                          key={index}
                          onClick={() => handleDurationSelect(option)}
                          href="#"
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                          role="menuitem"
                        >
                          {option}
                        </a>
                      ))}
                    </div>
                  </div>
                )}
              </div>
              <div className="relative">
                {/*<button*/}
                {/*  type="button"*/}
                {/*  onClick={toggleSecondDropdown}*/}
                {/*  className="flex items-center text-sm font-medium text-gray-700 focus:outline-none max-md:bg-[#131C23]/10 max-md:w-full max-md:rounded-full max-md:p-2 max-md:justify-between max-md:mb-2"*/}
                {/*>*/}
                {/*  {selectedStar || "All Star"}*/}
                {/*  <svg*/}
                {/*    className="ml-1 h-5 w-5"*/}
                {/*    xmlns="http://www.w3.org/2000/svg"*/}
                {/*    viewBox="0 0 20 20"*/}
                {/*    fill="currentColor"*/}
                {/*    aria-hidden="true"*/}
                {/*  >*/}
                {/*    <path*/}
                {/*      fillRule="evenodd"*/}
                {/*      d="M6.293 6.293a1 1 0 011.414 0L10 8.586l2.293-2.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"*/}
                {/*    />*/}
                {/*  </svg>*/}
                {/*</button>*/}
                {isStarOpen && (
                  <div
                    className="origin-top-right absolute right-0 w-[130px] rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-10"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="options-menu"
                  >
                    <div className="py-1">
                      {filterDropdownData[1].options.map((option, index) => (
                        <a
                          key={index}
                          onClick={() => handleStarSelect(option)}
                          href="#"
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                          role="menuitem"
                        >
                          {option}
                        </a>
                      ))}
                    </div>
                  </div>
                )}
              </div>
              <div className="flex justify-between py-4">
                <SearchButton onClick={handleSearchClick} />
                <button className="ml-5" onClick={handleResetClick}><img
                  src="/assets/svgs/common/reset.svg" alt="reset" /></button>
              </div>
            </div>
          </div>}
        </div>
        {allEventsIsLoading ? (
            <div className="flex justify-center items-center h-[50vh]">
              <Spinner />
            </div>
          ) :
          allEvents && allEvents.length > 0 ? (
              <div className="grid md:grid-cols-3 lg:grid-cols-4 gap-4 min-h-[calc(100vh-500px)] my-10">
                {allEvents.map((event) => (
                  <Link to={`/eventDetails/${event.id}`} key={event.id}>
                    <EventCard filterTabs={true} filters={true} event={event} />
                  </Link>
                ))}
              </div>
            ) :
            <div className="flex justify-center items-center">
              <div className="text-center text-2xl text-[#FF913F] font-bold py-10">
                <img src="/assets/images/empty.png" alt="Empty Wishlist" />
                {t("noEvents")}
              </div>
            </div>}
        {/*<div*/}
        {/*    className="text-center flex gap-3 justify-center py-4 text-primary-orange font-bold underline text-lg cursor-pointer">*/}
        {/*    {currentPage > 1 && <p onClick={handlePreviousClick}>Previous</p>}*/}
        {/*    {hasNextPage && <p onClick={handleLoadMoreClick}>Next</p>}*/}
        {/*</div>*/}
        <div className="mb-10 flex justify-center">
          {lastPage && (
            <Pagination
              count={lastPage}
              page={currentPage}
              onChange={handlePageChange}
              variant="outlined"
              shape="rounded"
              color="primary"
            />
          )}
        </div>
      </div>
    </main>
  );
};
export default SeeAllEvents;
