import { useEffect, useState } from "react";
import DatePicker from "react-multi-date-picker";
import "./style.css";
import { useAppDispatch, useAppSelector } from "../../../../../store/redux";
import { getBalanceStatistics } from "../../../../../store/BusinessDashboard/ActionBusinessDashboard";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store/store";
import moment from "moment";
import {useTranslation} from "react-i18next";
const Balance = () => {
  const dispatch = useAppDispatch();
  const {t}=useTranslation();
  const today = new Date();
  const prevDate = new Date();
  prevDate.setDate(prevDate.getDate() - 7);
  const [value, setValues] = useState<Date[]>([prevDate, today]);
  const balance = useSelector(
    (state: RootState) => state.businessDashboardReducer.businessBalance,
  );
  const currencies = useAppSelector((state) => state.currenciesReducer);
  useEffect(() => {
    let object = {
      start_date: moment(value[0]).format("YYYY-MM-DD"),
      end_date: moment(value[1]).format("YYYY-MM-DD"),
    };
    dispatch(getBalanceStatistics(object));
  }, []);
  const handleChangeTime = (time: any) => {
    setValues(time);
    if(time.length===2)
    {
       let object = {
            start_date:moment(new Date(time[0])).format('YYYY-MM-DD'),
            end_date: moment(new Date(time[1])).format('YYYY-MM-DD'),
          };
          dispatch(getBalanceStatistics(object));
    }
  };


  return (
    <div className="rounded-xl bg-[#131C23]/5 p-5">
      <div className="mb-2 flex items-center">
        <h3 className="text-xl font-bold">{t('businessProfile.overview.balance.title')}</h3>
      </div>
      <div className="overview-calendar-section flex items-center gap-2">
        <p className="text-sm text-[#131C23]/40">{t('businessProfile.overview.balance.period')}:</p>
        <div className="flex">
          <img loading="lazy" src="/assets/svgs/dashboard/businessProfile/blue-calendar.svg" />
          <DatePicker
            className="!cursor-pointer"
            range
            value={value}
            format="YYYY-MM-DD"
            dateSeparator="-"
            onChange={(val) => handleChangeTime(val)}
          />
        </div>
      </div>
      <div className="mt-6 w-full rounded-lg bg-white py-6">
        <div className="flex flex-col items-center justify-center text-[40px]">
          <div>
            <span className="text-[#898E91]">
              {currencies.selectedCurrency.symbol}{" "}
            </span>
            {(balance * currencies.selectedCurrency.exchange_rate).toFixed(2)}
          </div>
          {/* <div className="mt-2 flex w-full items-center justify-center rounded-full">
            <div className="flex gap-2 rounded-full bg-[#ECF9F4] px-3 py-2">
              <img src="/assets/svgs/dashboard/businessProfile/growth-green-arrow.svg" />
              <div className="text-lg text-[#3FBA8B]">
                + 1201.40 <span className="text-[#95DABF]">{`(7 days)`} </span>
              </div>
            </div>
          </div> */}
        </div>
      </div>
      <div className="mt-6 flex gap-5">
        <div className="h-fit rounded-full bg-white p-2.5">
          <img loading="lazy" src="/assets/svgs/dashboard/businessProfile/coins-orange.svg" />
        </div>
        <div className="flex flex-col">
          <p className="text-sm font-medium	">{t('businessProfile.overview.balance.balanceSummary')}</p>
          <p>
            <span className="text-[#FF913F]">{t('businessProfile.overview.balance.trackEarnings')}</span>
              {t('businessProfile.overview.balance.info')}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Balance;
