import Subscribe from "./components/Subscribe";
import ContactUs from "./components/ContactUs";
import Company from "./components/Company";
import PaymentSystems from "./components/PaymentSystems";
import Support from "./components/Support";
import {Divider} from "@mui/material";
import {useTranslation} from "react-i18next";
import React from "react";
import {Link} from "react-router-dom";
import Partners from "./components/Partners";
import {setActiveTab, setAuthModal, setShowSignUpContent} from "../../store/Auth/ActionAuth";
import {useAppDispatch} from "../../store/redux";

export const Footer = () => {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();

    return (
        <footer id="site-footer"
                className="flex flex-col items-center gap-3 py-5 md:pt-20 md:pb-5 bg-[url(/public/assets/images/footer-bg.png)] bg-cover  bg-no-repeat max-md:py-10">
            <div className="container">
                <div className="grid md:grid-cols-[2fr_1fr_1fr_1fr] gap-8">
                    <Subscribe/>
                    <ContactUs/>
                    <div className="text-white max-md:text-center">
                        <h3 className="font-medium text-md mb-2">{t('footer.becomeFamily.title')}</h3>
                        <ul className="opacity-40 text-sm cursor-pointer">
                            <li className="py-2">
                                <button
                                    onClick={() => {
                                        dispatch(setActiveTab("client"));
                                        dispatch(setAuthModal(true));
                                        dispatch(setShowSignUpContent(true))
                                    }}
                                >
                                    {t('footer.becomeFamily.becomeExplorer')}
                                </button>
                            </li>
                            <li className="py-2">
                                <a
                                    href="mailto:HQ@explorenearme.co.uk?subject=Request%20to%20become%20one%20of%20our%20Vendors"
                                >
                                    {t('footer.becomeFamily.request')}
                                </a>
                            </li>
                            <li className="py-2">
                                <a
                                    href="mailto:HQ@explorenearme.co.uk?subject=Become%20one%20of%20our%20SEO%20Partners"
                                >
                                    {t('footer.becomeFamily.becomeSeo')}
                                </a>
                            </li>
                        </ul>
                        <Link to="/our-mission" className="max-md:flex max-md:justify-center cursor-pointer">
                            <button
                              className="bg-primary-orange text-black rounded-full md:px-3 max-md:px-10 py-3 my-5 text-sm flex items-center justify-between gap-1">
                                Join our mission
                                <img src="/assets/svgs/common/black-arrow.svg" />
                            </button>
                        </Link>
                    </div>
                    {/* <Company/>
                    <Support/> */}
                    <PaymentSystems/>
                </div>
                <div className="md:flex justify-between items-center max-md:my-8">
                    <Partners/>
                    <div className="trustpilot-widget md:pt-5 max-md:py-8" data-locale="en-GB"
                         data-template-id="56278e9abfbbba0bdcd568bc" data-businessunit-id="65abee1451242ae96f523354"
                         data-style-height="52px" data-style-width="100%">
                        <a href="https://uk.trustpilot.com/review/explorenearme.co.uk" target="_blank"
                           rel="noopener" className="[&_.tp--widget-wrapper]:!text-start"> Trustpilot </a>
                    </div>
                </div>
                <div className="h-px rounded-xl my-4 md:my-8 bg-white/40"></div>
                <div className="flex w-full flex-col justify-between md:flex-row text-sm">
                    <div className="text-white max-md:text-center font-medium">{t("footer.copyrights")} {" "}
                        <span className="text-primary-orange font-bold uppercase">{t("footer.exploreNearMe")}</span>
                        {" "}
                        <span>{t("footer.rightsReserved")}</span>
                    </div>
                    <div>
                        <div className="max-md:text-center max-md:pt-3">
                            <Link
                                to={"/terms-and-condition?type=client"}
                                target={"_blank"}
                                className="hover-link pr-1 text-sm !text-white !no-underline"
                            >
                                {t("footer.termsConditions")}
                            </Link>
                            <span className="font-bold text-primary-orange px-2">|</span>
                            <Link
                                to={"/privacy-policy"}
                                className="hover-link text-sm !text-white !no-underline"
                            >
                                {" "}
                                {t("footer.privacyPolicy")}{" "}
                            </Link>
                            <span className="font-bold text-primary-orange px-2">|</span>
                            <Link
                                to={"/aboutUs"}
                                className="hover-link text-sm !text-white !no-underline"
                            >
                                {" "}
                                {t("footer.aboutUs")}{" "}
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};
export default Footer;
