import {useTranslation} from "react-i18next";
import Partners from "./Partners";
import React from "react";
import LogoSvg from "../../SvgComponents/LogoSvg";
import { useNavigate } from "react-router-dom";

export const Subscribe = () => {
    const {t} = useTranslation()
  const navigate = useNavigate();

  const goToHomePage = () => {
    navigate("/");
  };
    return (
        <section className="">
            <div className="max-md:flex max-md:justify-center">
                {/*<img loading="lazy" src="/assets/svgs/events/logos/logo.svg" alt="explore" className="mb-2 w-[200px]"/>*/}
              <LogoSvg className={"fill-white"} onClick={goToHomePage}/>
            </div>
            <p className="text-white text-sm py-4 opacity-40 max-md:text-center"> {t('footer.title')}</p>
        </section>
    );
};

export default Subscribe;
