import axios from "axios";
export const getTermsAndConditionAPI = async (type:string) => {
  try {
    const response = await axios.get(`/api/terms-conditions?type=${type}`);

    return response.data.data;
  } catch (error) {
    throw error
    return null;
  }
};
export const getPrivacyPolicyAPI = async () => {
  try {
    const response = await axios.get("/api/privacy-policy");
    return response.data.data;
  } catch (error) {
    throw error
    return null;
  }
};
export const getOurMissionAPI = async (selectedLanguage: string) => {
  try {
    const response = await axios.get<{data:{id: number, information: string,translations:any}}>("/api/our-mission");
    return response.data.data;
  } catch (error) {
    throw error
    return null;
  }
};