
import {useTranslation} from "react-i18next";
const data = [
    {label: "Tasks", value: 300, color: "#813fff"},
    {label: "Levels", value: 400, color: "#ff913f"},
    {label: "Refunds", value: 300, color: "#3fabff"},
];

const Token = () => {
    const {t} = useTranslation();
    return (
        // <>
        //     <div className="grid grid-cols-[1fr_2.5fr] gap-5">
        //         <div className="bg-[#f3f3f4] rounded-xl px-3 py-3">
        //             <p className="text-xl font-bold">Your Token</p>
        //             <div className="grid h-[100%]  justify-center justify-content-center">
        //             <PieChart
        //                 series={[
        //                     {
        //                         paddingAngle: 2,
        //                         innerRadius: 90,
        //                         outerRadius: 100,
        //                         data,

        //                     },
        //                 ]}
        //                 margin={{ right: 5 }}
        //                 width={300}
        //                 height={330}
        //                 legend={{
        //                     direction: "row",
        //                     position: {
        //                       vertical: "bottom",
        //                       horizontal: "middle"
        //                     }
        //                   }}
        //            />
        //             </div>

        //         </div>
        //         <div>
        //             <TokenInformation />
        //         </div>
        //     </div>
        //     <div className="mt-5">
        //         <p className="text-lg font-bold">Token Receipt</p>
        //     </div>
        //     <div className="grid ">
        //         <div className={"grid grid-cols-5 bg-[#f3f3f4] gap-4 rounded-xl px-5 py-3  mt-5 "}>
        //             {
        //                 TokenReceiptHeader.map((item, index) => (
        //                     <p className={"text-[#9fa3a5]"}>{item.label}</p>
        //                 ))
        //             }
        //         </div>
        //     </div>

        //     <div>
        //         {
        //             TokenReceiptData.map((item, index) => (
        //                 <>
        //                     <div className="grid grid-cols-5 px-5 py-3 gap-4 mt-3">
        //                         <p className="text-sx ">{item.date}</p>
        //                         <p className="text-sx ">{item.time}</p>
        //                         <p className="text-sx ">{item.type}</p>
        //                         <p className="text-sx ">{item.amount_tokens}</p>
        //                         <p className="text-sx ">{item.description}</p>

        //                     </div>
        //                     <div className="flex mt-3">
        //                         <div className="bg-[#d0d2d3] w-full h-px bg-opacity-50" />
        //                     </div>
        //                 </>
        //             ))
        //         }
        //     </div>
        // </>

        <div className="empty grid place-items-center pb-20">
            <img loading="lazy" src="/assets/images/empty.png" alt="Empty Wishlist"/>
            <h3 className="px-4 text-lg font-bold text-primary-orange 2xl:px-7 2xl:text-xl">
                {t('userProfile.token.comingSoon')}
            </h3>
        </div>
    );
};
export default Token;
