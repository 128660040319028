export const socialData = [
    {
        id: "instagram",
        image: "/assets/svgs/common/social/instagram.svg",
        path: "https://www.instagram.com/explore_nearme?igshid=MzRlODBiNWFlZA%3D%3D",
    },
    {
        id: "twitter",
        image: "/assets/svgs/common/social/twitter.svg",
        path: "https://twitter.com/explorenearme",
    },
    {
        id: "facebook",
        image: "/assets/svgs/common/social/tiktok.svg",
        path: "https://www.tiktok.com/@explorenearme?_t=8gkZ7raf41w&_r=1",
    },
];
