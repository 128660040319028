import {TSvgComponent} from "./types";

const TicketSvg : TSvgComponent = ({...props}) =>{
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g opacity="0.5">
                <path d="M13.1503 1.66667H6.85033C3.70033 1.66667 2.91699 2.50834 2.91699 5.86667V15.25C2.91699 17.4667 4.13366 17.9917 5.60866 16.4083L5.61699 16.4C6.30033 15.675 7.34199 15.7333 7.93366 16.525L8.77533 17.65C9.45033 18.5417 10.542 18.5417 11.217 17.65L12.0587 16.525C12.6587 15.725 13.7003 15.6667 14.3837 16.4C15.867 17.9833 17.0753 17.4583 17.0753 15.2417V5.86667C17.0837 2.50834 16.3003 1.66667 13.1503 1.66667ZM12.292 8.95834H7.70866C7.36699 8.95834 7.08366 8.675 7.08366 8.33334C7.08366 7.99167 7.36699 7.70834 7.70866 7.70834H12.292C12.6337 7.70834 12.917 7.99167 12.917 8.33334C12.917 8.675 12.6337 8.95834 12.292 8.95834Z" />
            </g>
        </svg>
    )
}
export default TicketSvg
