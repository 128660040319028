import React, { useState } from "react";
import EmailIcon from "@mui/icons-material/Email";
import LockIcon from "@mui/icons-material/Lock";
import CustomInput from "../../common/Inputs/index";
import { UserLogin } from "../../../store/Auth/types";
import { useAppDispatch } from "../../../store/redux";
import {
  loginWithApple,
  loginWithGoogle,
  setShowForgotPasswordContent,
  setShowSignUpContent,
  userLogin
} from "../../../store/Auth/ActionAuth";
import PrimaryButton from "../../PrimaryButton";
import { useTranslation } from "react-i18next";
import SendEmailRequest from "./ForgotPassword/SendEmailRequest";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import AuthTabs from "./AuthTabs";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import AppleSignin from 'react-apple-signin-auth';
import { jwtDecode } from "jwt-decode";

type TLoginModal = {
  open?: boolean;
  onClose?: () => void;
  role?: "client" | "business" | null;
  isLoggingIn?: boolean;
  activeTab?: string;
  onSignUpClick?: () => void;
  onForgotPasswordClick?: () => void;
};
const LogIn = ({ open, onClose, onSignUpClick, onForgotPasswordClick }: TLoginModal) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [showPassword] = useState(false);
  const showForgotPasswordContent = useSelector((state: RootState) => state.authReducer.showForgotPasswordContent);
  const [loginPayload, setLoginPayload] = useState<UserLogin>({
    email: "",
    password: ""
  });
  const activeTab = useSelector((state: RootState) => state.authReducer.activeTab);
  const handleChangeInput = (name: string, value: any) => {
    setLoginPayload((prevLoginPayload) => ({
      ...prevLoginPayload,
      [name]: value
    }));
  };
  const handleSubmitLogin = () => {
    onClose && dispatch(userLogin(loginPayload)).then(() => {
      onClose();
    }).catch(() => {
    });
  };
  const handleForgotPasswordClick = () => {
    dispatch(setShowForgotPasswordContent(true));
  };

  const googleLogin = useGoogleLogin({
    onSuccess: async (response) => {
      try {
        const userInfoResponse = await fetch("https://www.googleapis.com/oauth2/v3/userinfo", {
          headers: {
            Authorization: `Bearer ${response.access_token}`
          }
        });
        const userInfo = await userInfoResponse.json();
        const data = {
          email: userInfo.email,
          id: userInfo.sub,
          profile_pic: userInfo.picture,
          token: response.access_token,
          name: userInfo.name
        };

        dispatch(loginWithGoogle(data));

        onClose && onClose();

      } catch (error) {
        console.error("Error:", error);
      }
    },
    onError: () => console.error("Error in google login")
  });

  const handleAppleLogin = async (authResponse: any) => {
    try {
      if (authResponse.error === 'popup_closed_by_user') {
        console.log('Apple login popup closed');
        return;
      }
      console.log("authResponse",authResponse)

      const { authorization , user } = authResponse;
      console.log("authorization.code",authorization.code)

      const idToken = authorization.id_token;
      const id = authorization.code;

      const decodedJwt = jwtDecode(idToken)
      let data;

      if (user) {
        const email =user?.email

        const firstName = user?.name?.firstName || '';
        const lastName = user?.name?.lastName || '';
        const name = `${firstName}${lastName ? ' ' + lastName : ''}`;

         data = {
          token: idToken,
          id: decodedJwt.sub ?? "" ,
          email:email,
          name:name
        };
      } else {
          data = {
            token: idToken,
            id: decodedJwt.sub ?? ""
          };
      }

      await dispatch(loginWithApple(data));
      onClose && onClose();
    } catch (error) {
      console.error("Error:", error);
    }
  };

  if (showForgotPasswordContent) {
    return <>
      {showForgotPasswordContent && (
        <SendEmailRequest
          onForgotPasswordClick={() => dispatch(setShowForgotPasswordContent(true))}
        />
      )}
    </>;
  }
  return (
    <div>
      <AuthTabs activeTab={activeTab} />
      <div className="py-3 text-center">
        <h3 className="text-2xl font-bold "> {t("welcome")}</h3>
        <p className="font-medium text-[#131C23]/40">
          {t("logInOrSignUp")}
        </p>
      </div>
      <div className="px-7">
        <CustomInput
          onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
          name="email"
          type="text"
          placeholder={t("mail")}
          icon={<EmailIcon />}
        />
        <CustomInput
          onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
          name="password"
          type="password"
          placeholder={t("password")}
          icon={<LockIcon />}
          showPassword={showPassword}
        />
      </div>
      <div className="px-7">
        <div className="text-sm font-medium text-[#3F87FF] cursor-pointer" onClick={handleForgotPasswordClick}>
          {t("forgotPassword")}
        </div>
        {showForgotPasswordContent && (
          <button onClick={() => setShowForgotPasswordContent(false)}>Close</button>
        )}
        <div className="py-4 text-center font-medium">
          {t("haveAccount")}
          <span
            className="cursor-pointer px-2 text-[#FF913F]"
            onClick={() => dispatch(setShowSignUpContent(true))}
          >
                                {t("signUp")}
                            </span>
        </div>
        <PrimaryButton className="gap-2" type="button" onClick={handleSubmitLogin}>
          {t("signIn")}
          <img loading="lazy" src="/assets/svgs/common/white-arrow.svg" className="w-4" />
        </PrimaryButton>
        {activeTab === 'client' &&
          <>
            <div className="grid grid-cols-3 items-center py-4">
              <div className="border-b-[1px] border-[#131C23]/40"></div>
              <p className="text-center text-[#131C23]/50">Or Log in with </p>
              <div className="border-b-[1px] border-[#131C23]/40"></div>
            </div>
            <div className="flex items-center justify-center">
              {/*<img*/}
              {/*  src="/assets/svgs/common/apple.svg" onClick={handleAppleLogin}*/}
              {/*  className="m-3 rounded-full border-[1px] border-[#131C23]/20 p-2"*/}
              {/*/>*/}
              {/*<img*/}
              {/*  src="/assets/svgs/common/apple.svg"*/}
              {/*  onClick={() => {*/}
              {/*    const authOptions = {*/}
              {/*      clientId: "uk.co.explorenearme.login",*/}
              {/*      scope: "email name",*/}
              {/*      redirectURI: "https://explore-nearme.klev.tech/socialMedia/apple",*/}
              {/*      state: "",*/}
              {/*      nonce: "nonce",*/}
              {/*      usePopup: true,*/}
              {/*    };*/}
              {/*    // You might need to adjust this part depending on how you plan to trigger the Apple login process*/}
              {/*    // This is an example of directly invoking the function assuming it can handle the login process without the AppleSignin component*/}
              {/*    handleAppleLogin(authOptions);*/}
              {/*  }}*/}
              {/*  className="m-3 cursor-pointer rounded-full border-[1px] border-[#131C23]/20 p-2"*/}
              {/*/>*/}
              <img
                src="/assets/svgs/common/apple.svg"
                onClick={() => {
                  const clientId = "uk.co.explorenearme.login";
                  const scope = "email%20name";
                  const redirectURI = encodeURIComponent("https://explore-nearme.klev.tech/socialMedia/apple");
                  const state = "";
                  const nonce = "nonce";
                  const responseType = "code";
                  const responseMode = "form_post";

                  const appleLoginUrl = `https://appleid.apple.com/auth/authorize?client_id=${clientId}&redirect_uri=${redirectURI}&scope=${scope}&response_type=${responseType}&response_mode=${responseMode}&state=${state}&nonce=${nonce}`;

                  window.location.href = appleLoginUrl;
                }}
                className="m-3 cursor-pointer rounded-full border-[1px] border-[#131C23]/20 p-2"
              />

              {/*<AppleSignin*/}
              {/*  onSuccess={handleAppleLogin}*/}
              {/*  onError={(error: any) => console.error("Error in login", error)}*/}
              {/*  authOptions={{*/}
              {/*    clientId: "uk.co.explorenearme.login",*/}
              {/*    scope: "email name",*/}
              {/*    redirectURI: "https://explore-nearme.klev.tech/socialMedia/apple",*/}
              {/*    state: "",*/}
              {/*    nonce: "nonce",*/}
              {/*    usePopup: true,*/}
              {/*  }}*/}
              {/*  uiType="dark"*/}
              {/*  className="m-3 rounded-full border-[1px] border-[#131C23]/20 p-2"*/}
              {/*/>*/}

              <img
                src="/assets/svgs/common/google.svg"
                className="m-3 rounded-full border-[1px] border-[#131C23]/20 p-2"
                onClick={() => googleLogin()}
              />
            </div>
          </>
        }
      </div>
    </div>
  );
};
export default LogIn;
