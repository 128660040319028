import {TSvgComponent} from "./types";

const LightningSvg: TSvgComponent = ({...props}) => {
    return (
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M12.3955 10.7741L11.433 11.0017C11.2172 11.0542 11.048 11.2175 11.0013 11.4333L10.7972 12.2908C10.6863 12.7575 10.0913 12.9033 9.78215 12.5358L8.03798 10.5291C7.89798 10.3658 7.97382 10.1091 8.18382 10.0566C9.21632 9.80582 10.1438 9.22832 10.8263 8.40582C10.9372 8.27165 11.1355 8.25415 11.258 8.37665L12.553 9.67165C12.9963 10.115 12.8388 10.6691 12.3955 10.7741Z" />
            <path d="M1.57485 10.7741L2.53735 11.0017C2.75318 11.0542 2.92235 11.2175 2.96902 11.4333L3.17318 12.2908C3.28402 12.7575 3.87902 12.9033 4.18818 12.5358L5.93235 10.5291C6.07235 10.3658 5.99652 10.1091 5.78652 10.0566C4.75402 9.80582 3.82652 9.22832 3.14402 8.40582C3.03318 8.27165 2.83485 8.25415 2.71235 8.37665L1.41735 9.67165C0.974018 10.115 1.13152 10.6691 1.57485 10.7741Z" />
            <path d="M7.00033 1.16666C4.74283 1.16666 2.91699 2.99249 2.91699 5.24999C2.91699 6.09582 3.16783 6.87166 3.59949 7.51916C4.22949 8.45249 5.22699 9.11166 6.38783 9.28082C6.58616 9.31582 6.79033 9.33332 7.00033 9.33332C7.21033 9.33332 7.41449 9.31582 7.61283 9.28082C8.77366 9.11166 9.77116 8.45249 10.4012 7.51916C10.8328 6.87166 11.0837 6.09582 11.0837 5.24999C11.0837 2.99249 9.25783 1.16666 7.00033 1.16666ZM8.78533 5.12166L8.30116 5.60582C8.21949 5.68749 8.17283 5.84499 8.20199 5.96166L8.34199 6.56249C8.45283 7.03499 8.20199 7.22166 7.78199 6.97082L7.19866 6.62666C7.09366 6.56249 6.91866 6.56249 6.81366 6.62666L6.23033 6.97082C5.81033 7.21582 5.55949 7.03499 5.67033 6.56249L5.81033 5.96166C5.83366 5.85082 5.79283 5.68749 5.71116 5.60582L5.21533 5.12166C4.92949 4.83582 5.02283 4.54999 5.41949 4.48582L6.04366 4.38082C6.14866 4.36332 6.27116 4.26999 6.31783 4.17666L6.66199 3.48832C6.84866 3.11499 7.15199 3.11499 7.33866 3.48832L7.68283 4.17666C7.72949 4.26999 7.85199 4.36332 7.96283 4.38082L8.58699 4.48582C8.97783 4.54999 9.07116 4.83582 8.78533 5.12166Z" />
        </svg>
    )
}
export default LightningSvg

