import {filterAllEventsData} from "../../constants/filterAllEventsData";
import DropdownTab from "../DropdownFilter";
import SearchButton from "../SearchButton";
import './style.css'
import HomePageTabComponent from "../../pages/Home/components/HomePageTabComponent";
import {useEvents} from "../../pages/Home/hooks";
import React from "react";

const Events = ({filters = false, filterTabs = false}) => {
    const {
        tabTitlesData,
        activeTab,
        selectedCategoryId,
        events,
        minPrice,
        maxPrice,
        checkboxValues,
        large_events,
        handleCategoryClick,
        handleMinPriceChange,
        handleMaxPriceChange,
        handleCheckedTypes,
        handleResetSelectedOptions
    } = useEvents();

    return (
        <section id="events" className="md:py-5">
            <div>
                {filters &&
                    <div>
                        <div className="md:flex py-2 items-center justify-between">
                            {filterAllEventsData.map((tab, index) => (
                                <div className="flex items-center checkbox-container">
                                    <input
                                        id={`bordered-checkbox-${index}`}
                                        type="checkbox"
                                        value=""
                                        name="bordered-checkbox"
                                        className="w-4 h-4 border-gray-300 bg-gray-300 rounded accent-[#FF913F] focus:ring-transparent text-white checbox-input"
                                        checked={checkboxValues[index]}
                                        onChange={() => handleCheckedTypes(index)}
                                    />
                                    <label
                                        htmlFor={`bordered-checkbox-${index}`}
                                        className="w-full py-2 ml-2 font-medium text-[#131C23] text-xs checkbox-label custom-checkbox"
                                    >
                                        {tab.title}
                                    </label>
                                </div>
                            ))}
                            <div className="flex items-center md:justify-center mb-4 md:mb-0">
                                <label htmlFor="price"
                                       className="block text-sm font-medium text-gray-900">Min.Price</label>
                                <input
                                    type="number"
                                    id="price"
                                    value={minPrice}
                                    onChange={handleMinPriceChange}
                                    className="bg-gray-200 mx-3 text-gray-900 text-sm rounded-lgblock w-1/2 p-1.5 focus:outline-[#FF913F] rounded-lg dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                />
                            </div>
                            <div className="flex items-center md:justify-center mb-4 md:mb-0">
                                <label htmlFor="price"
                                       className="block text-sm font-medium text-gray-900">Max.Price</label>
                                <input
                                    type="number"
                                    id="price"
                                    value={maxPrice}
                                    onChange={handleMaxPriceChange}
                                    className="bg-gray-200 mx-3 text-gray-900 text-sm rounded-lg focus:outline-[#FF913F] focus:ring-blue-500 focus:border-red-500 focus-visible:border-blue-500 block w-1/2 p-1.5 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                />
                            </div>
                            <div className="hidden md:flex md:justify-between items-center">
                                <DropdownTab/>
                                <SearchButton onClick={() => {
                                }}/>
                                <button className="px-3" onClick={handleResetSelectedOptions}><img loading="lazy"
                                                                                         src="/assets/svgs/common/reset.svg"
                                                                                         alt="reset"/></button>
                            </div>
                        </div>
                        <div className="grid grid-cols-2 md:hidden place-content-between">
                            <DropdownTab/>
                            <div className="flex justify-between py-4">
                                <SearchButton onClick={() => {
                                }}/>
                                <button className="ml-5" onClick={handleResetSelectedOptions}><img loading="lazy"
                                                                                         src="/assets/svgs/common/reset.svg"
                                                                                         alt="reset"/></button>
                            </div>
                        </div>
                    </div>
                }
            </div>
            {selectedCategoryId !== null &&
            large_events &&
            large_events.filter(
                (event) => event.category_id === selectedCategoryId
            ).length === 0 ? (
                <div className="flex justify-center items-center">
                </div>
            ) : (
                <div className="overflow-hidden [&_.swiper-wrapper]:gap-4 ">
                    {events && events.length > 0 ? (
                        <> </>
                    ) : (
                        <div className="flex justify-center items-center">
                        </div>
                    )}
                </div>
            )}
            {filterTabs && (
                <HomePageTabComponent
                    titles={tabTitlesData}
                    activeTab={activeTab}
                    handleTabClick={handleCategoryClick}
                />
            )}
        </section>
    );
};
export default Events;
