import axios, { AxiosResponse } from "axios";
import { IEventCategory, IEventsData } from "../events/types";
import { TEventsFilters } from "../../store/events/types";
import { useQuery } from "react-query";
import { useAppDispatch } from "../../store/redux";
import { getCategories } from "../../store/events/ActionEvents";
import { eventsSlice } from "../../store/events/EventsSlice";
import { getCategoriesAxios } from "../events/events";
import { useEffect } from "react";

// export const useLargeEvents = async (types: TEventsFilters, categoryId: string): Promise<{
//     all: IEventsData[],
//     categories: IEventCategory[]
// }> => {
//     try {
//         const dispatch = useAppDispatch();
//         const response = await axios.get<{
//             data: { all: IEventsData[], categories: IEventCategory[] }
//         }>(`/api/events/large-events/${categoryId}`, {
//             params: {
//                 ...types,
//                 type_id: types.type_id.length > 0 ? JSON.stringify(types.type_id) : ''
//             },
//         });
//         dispatch(getCategories());
//         return response.data.data;
//
//     } catch (error) {
//         throw error;
//     }
// };
export const getPromotionalEvents = async (
  types: TEventsFilters,
  page: number
): Promise<{ data: IEventsData[]; next_page_url: string | null, last_page: number }> => {
  try {
    const response: AxiosResponse<any> = await axios.get(
      `/api/events/promotional-events?page=${page}`,
      {
        params: {
          ...types,
          type_id: types.type_id.length > 0 ? JSON.stringify(types.type_id) : ""
        }
      }
    );
    return {
      data: response.data.data.data,
      next_page_url: response.data.data.next_page_url,
      last_page: response.data.data.last_page
    };
  } catch (error) {
    throw error;
  }
};

export const getExclusiveEvents = async (): Promise<{ all: IEventsData[], categories: IEventCategory[] }> => {
  try {
    const response = await axios.get<{
      data: { all: IEventsData[], categories: IEventCategory[] }
    }>(`/api/events/exclusive`);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};
export const useAllEvents = (filters: TEventsFilters, categoryId: number, eventType: string | undefined, page: number, numEventsToLoad: number) => {
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["all-events", categoryId, eventType, page, filters],
    queryFn: async () => {
      let url = "/api/events/" + (eventType === "large" ? "large-events" : "exclusive");
      if (categoryId !== null && categoryId !== 0) {
        url += `/${categoryId}`;
      }
      url += `?page=${page}`;

      for (const [key, value] of Object.entries(filters)) {
        if (value !== "" && value !== null && value !== undefined) {
          if (Array.isArray(value)) {
            url += `&${key}=${value.length > 0 ? JSON.stringify(value) : ''}`;
          } else {
            url += `&${key}=${value}`;
          }
        }
      }
      const response = await axios.get<{
        data: {
          data: IEventsData[];
          next_page_url: string | null;
          last_page: number | null;
        };
      }>(url);
      return response.data.data;
    }
  });
  return {
    allEvents: data?.data || [],
    hasNextPage: !!data?.next_page_url,
    allEventsIsLoading: isLoading,
    refetch,
    lastPage: data?.last_page
  };
};

// export const useExclusiveEvents = (types: TEventsFilters, categoryId: number ) => {
//     const {data} = useQuery({
//         queryKey: ["all-events-exclusive", categoryId, types],
//         queryFn: async () => {
//             let url = '/api/events/exclusive';
//             if (categoryId !== 0) {
//                 url += `/${categoryId}`;
//             }
//             const response = await axios.get<{
//                 data: IEventsData[];
//             }>(url, {
//                 params: {
//                     ...types,
//                     type_id: types.type_id.length > 0 ? JSON.stringify(types.type_id) : '',
//                 },
//             });
//             getCategoriesAxios()
//             return response.data.data;
//         },
//     });
//     return data;
// };
export const usePromotionalEvents = (types: TEventsFilters) => {
  const { data } = useQuery({
    queryKey: ["all-promotional-events", types], queryFn: async () => {
      const response: AxiosResponse<{
        data: IEventsData[]
      }> = await axios.get("/api/events/promotional-events", {
        params: {
          ...types,
          type_id: types.type_id.length > 0 ? JSON.stringify(types.type_id) : ""
        }
      });
      return response.data.data;
    }
  });
  return data;
};


export const getLocationsAPI = async () => {
  try {
    const response = await axios.get("api/events-locations");
    return response.data.data;
  } catch (error) {
    throw error;
  }
};
