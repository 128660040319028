import { useEffect } from "react";
import Header from "../../component/Header";
import { useAppDispatch, useAppSelector } from "../../store/redux";
import { RootState } from "../../store/store";
import { getPrivacyPolicy } from "../../store/TermsAndCondition/TermsAction";
import "./style.css";
const PrivacyPolicy = () => {
  const dispatch = useAppDispatch();
  const terms = useAppSelector((state:RootState)=> state.termsReducer.privacyPolicy)
  useEffect(() => {
    dispatch(getPrivacyPolicy());
  }, []);

  return <div className="privacy-policy-content bg-white">
    <Header/>
    <div className="p-10" dangerouslySetInnerHTML={{ __html: terms }} />
  </div>;
};

export default PrivacyPolicy;
