import React, {
  FC,
  ForwardedRef,
  HTMLAttributes,
  InputHTMLAttributes,
  useState,
} from "react";
import { twMerge } from "tailwind-merge";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";

type TProps = InputHTMLAttributes<HTMLInputElement> & {
  label?: string;
  containerClass?: HTMLAttributes<HTMLDivElement>["className"];
  icon?: React.ReactNode;
  endText?: string;
  inputType?: string;
  wrapperClassName?: string;
  showPassword?: boolean;
  inputBgColor?: string;
} & { ref?: ForwardedRef<HTMLInputElement> };

const CustomInput: FC<TProps> = React.forwardRef<HTMLInputElement, TProps>(
  (
    {
      label,
      containerClass,
      icon,
      inputType,
      type,
      endText,
      wrapperClassName,
      inputBgColor,
      ...props
    },
    ref,
  ) => {
    const isPasswordInput = type === "password";
    const [showPassword, setShowPassword] = useState(false);

    const handleTogglePasswordVisibility = () => {
      setShowPassword((prevShowPassword) => !prevShowPassword);
    };
    return (
      <div className={containerClass}>
        <label
          hidden={!label}
          className="block text-xl font-bold"
          htmlFor={props.name}
        >
          {/* {label ?? props.name} */}
          {label}
        </label>
        <div
          className={`my-3 flex items-center rounded-xl p-2 text-[#131C23]/30 ${
            inputBgColor ?? "bg-[#131C23]/5"
          }  focus:border-2 focus:border-red-500 ${wrapperClassName}`}
        >
          {icon}
          <input
            {...props}
            onChange={props.onChange}
            ref={ref}
            type={type === "password" && showPassword ? "text" : type}
            title={props.name}
            id={props.name}
            className={twMerge(
              "ml-2 flex w-full items-center bg-transparent text-[#131C23]/50 focus:outline-none",
              props.className,
            )}
          />
          {endText && <div className="w-1/2 text-end">{endText}</div>}
          {isPasswordInput && (
            <>
              {showPassword ? (
                <VisibilityOffIcon
                  className="cursor-pointer text-gray-400"
                  onClick={() => setShowPassword(false)}
                />
              ) : (
                <VisibilityIcon
                  className="cursor-pointer text-gray-400"
                  onClick={() => setShowPassword(true)}
                />
              )}
            </>
          )}
        </div>
      </div>
    );
  },
);
export default CustomInput;
