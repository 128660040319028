import {TSvgComponent} from "./types";

const HeartSvg :TSvgComponent = ({...props}) =>{
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path fillRule="evenodd" clipRule="evenodd" d="M13.3243 3.12547C12.2324 3.10967 11.2086 3.48113 10.52 4.51385C10.4041 4.68772 10.209 4.79215 10 4.79215C9.79106 4.79215 9.59592 4.68772 9.48 4.51385C8.79527 3.48681 7.75285 3.12553 6.66669 3.12553C4.47771 3.12553 2.29169 4.96217 2.29169 7.50035C2.29169 10.1488 4.004 12.4918 5.93759 14.2218C6.89317 15.0768 7.87454 15.7559 8.66288 16.2189C9.0573 16.4505 9.3967 16.6241 9.6544 16.7375C9.78375 16.7944 9.88521 16.8329 9.95806 16.856C9.9752 16.8615 9.98911 16.8655 10 16.8685C10.0109 16.8655 10.0248 16.8615 10.042 16.856C10.1148 16.8329 10.2163 16.7944 10.3457 16.7375C10.6033 16.6241 10.9427 16.4505 11.3372 16.2189C12.1255 15.7559 13.1069 15.0768 14.0624 14.2218C15.996 12.4918 17.7084 10.1488 17.7084 7.50035C17.7084 4.96946 15.5285 3.15736 13.3243 3.12547ZM9.99872 3.17504C10.9403 2.20392 12.1651 1.85856 13.3424 1.8756C16.1383 1.91605 18.9584 4.19813 18.9584 7.50035C18.9584 10.6851 16.9207 13.3419 14.8959 15.1534C13.8723 16.0692 12.8225 16.7962 11.9702 17.2968C11.5443 17.5469 11.161 17.7444 10.8491 17.8816C10.6936 17.9501 10.5485 18.0068 10.4199 18.0475C10.307 18.0833 10.1528 18.125 10 18.125C9.84727 18.125 9.693 18.0833 9.58016 18.0475C9.45159 18.0068 9.30647 17.9501 9.15099 17.8816C8.83903 17.7444 8.45577 17.5469 8.02987 17.2968C7.17759 16.7962 6.12771 16.0692 5.10412 15.1534C3.07938 13.3419 1.04169 10.6851 1.04169 7.50035C1.04169 4.20542 3.85567 1.87553 6.66669 1.87553C7.81282 1.87553 9.05002 2.20564 9.99872 3.17504Z"/>
        </svg>

    )
}
export default HeartSvg
