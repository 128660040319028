export const historyHeader = [
    {
        id: "image",
        label:"Image",
    },
    {
        id: "title",
        label:"Title",
    },
    {
        id:"location",
        label:"Location",
    },
    // {
    //     id:"rating",
    //     label:"Rating",
    // },
    // {
    //     id:"tags",
    //     label:"Tags",
    // },
    {
        id:"date",
        label:"Reserved Date",
    },
    {
        id:"time",
        label:"Reserved Time",
    },
    {
        id:"cost",
        label:"Cost",
    }
];
