import SettingsSvg from "../../component/SvgComponents/SettingsSvg";
import ClipboardSvg from "../../component/SvgComponents/ClipboardSvg";
import AwardSvg from "../../component/SvgComponents/AwardSvg";
import TokenSvg from "../../component/SvgComponents/TokenSvg";
import ReferralSvg from "../../component/SvgComponents/ReferralSvg";
import TicketSvg from "../../component/SvgComponents/TicketSvg";

export const userProfileSideBar = [
    {
        id: "settings",
        path: "/profile-settings",
        title: "Setting",
        svg: SettingsSvg,
        isActive:false
    },
    {
        id: "history",
        path: "/history",
        title: "History",
        svg: ClipboardSvg,
        isActive:false

    },
    {
        id: "gamification",
        path: "/gamification",
        title: "Gamification",
        svg: AwardSvg,
        isActive:false

    },
    {
        id: "token",
        path: "/token",
        title: "Token",
        svg: TokenSvg,
        isActive:false

    },
    {
        id: "referral_program",
        path: "/referral-program",
        title: "Referral Program",
        svg: ReferralSvg,
        isActive:false
    },
    {
        id: "tickets",
        path: "/tickets",
        title: "Tickets",
        svg: TicketSvg,
        isActive:false

    },
];
