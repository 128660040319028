import {TSvgComponent} from "./types";

const ClipboardSvg : TSvgComponent = ({...props}) => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g opacity="0.5">
                <path d="M11.9585 1.66666H8.0418C7.17513 1.66666 6.4668 2.36666 6.4668 3.23332V4.01666C6.4668 4.88332 7.1668 5.58332 8.03346 5.58332H11.9585C12.8251 5.58332 13.5251 4.88332 13.5251 4.01666V3.23332C13.5335 2.36666 12.8251 1.66666 11.9585 1.66666Z" />
                <path d="M14.3667 4.01668C14.3667 5.34168 13.2834 6.42501 11.9584 6.42501H8.0417C6.7167 6.42501 5.63337 5.34168 5.63337 4.01668C5.63337 3.55001 5.13337 3.25835 4.7167 3.47501C3.5417 4.10001 2.7417 5.34168 2.7417 6.76668V14.6083C2.7417 16.6583 4.4167 18.3333 6.4667 18.3333H13.5334C15.5834 18.3333 17.2584 16.6583 17.2584 14.6083V6.76668C17.2584 5.34168 16.4584 4.10001 15.2834 3.47501C14.8667 3.25835 14.3667 3.55001 14.3667 4.01668ZM10.3167 14.125H6.6667C6.32503 14.125 6.0417 13.8417 6.0417 13.5C6.0417 13.1583 6.32503 12.875 6.6667 12.875H10.3167C10.6584 12.875 10.9417 13.1583 10.9417 13.5C10.9417 13.8417 10.6584 14.125 10.3167 14.125ZM12.5 10.7917H6.6667C6.32503 10.7917 6.0417 10.5083 6.0417 10.1667C6.0417 9.82501 6.32503 9.54168 6.6667 9.54168H12.5C12.8417 9.54168 13.125 9.82501 13.125 10.1667C13.125 10.5083 12.8417 10.7917 12.5 10.7917Z" />
            </g>
        </svg>


    )
}
export default ClipboardSvg
