import React from "react";
import {useAppSelector} from "../../../store/redux";
import {useTranslation} from "react-i18next";

type AdditionalDetailsProps = {
    highlights: string[];
    includes: string[];
    important_information: string[];
    selectedLanguage: string;
};

const AdditionalDetails: React.FC<AdditionalDetailsProps> = ({
                                                                 highlights,
                                                                 includes,
                                                                 important_information,
                                                                 selectedLanguage,
                                                             }) => {
    const {eventDetails, isLoading} = useAppSelector(state => state.eventDetailsReducer);
    const content = eventDetails.translations?.[selectedLanguage];
    const {t} = useTranslation();

    return (
        <div className="md:grid md:grid-cols-3 py-4 gap-10">
            <div className="border-2 rounded-2xl p-4 md:mb-0 mb-5 max-h-[300px] overflow-y-auto">
                <div className="flex items-center mb-4">
                    <h3 className="font-bold text-[#131C23] text-base ml-2">{t('highlights')}</h3>
                </div>
                <ul className="list-disc px-5 text-xs text-[#131C23]/80 font-medium">
                    {highlights?.map((highlight, index) => (
                        <li key={index}>{ content && content.highlights ? content?.highlights[index] : highlight}</li>
                    ))}
                </ul>
            </div>
            <div className="border-2 rounded-2xl p-4 md:mb-0 mb-5 max-h-[300px] overflow-y-auto">
                <div className="flex items-center mb-4">
                    <h3 className="font-bold text-[#131C23] text-base ml-2">{t('includes')}</h3>
                </div>
                <ul className="list-disc px-5 text-xs text-[#131C23]/80 font-medium">
                    {includes?.map((include, index) => (
                        <li key={index}>{content && content.includes ? content?.includes[index] : include}</li>
                    ))}
                </ul>
            </div>
            <div className="border-2 rounded-2xl p-4 md:mb-0 mb-5 max-h-[300px] overflow-y-auto">
                <div className="flex items-center mb-4">
                    <h3 className="font-bold text-[#131C23] text-base ml-2">{t('information')}</h3>
                </div>
                <ul className="list-disc px-5 text-xs text-[#131C23]/80 font-medium">
                    {important_information?.map((info, index) => (
                        <li key={index}>{content && content.important_information ? content?.important_information[index] : info}</li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default AdditionalDetails;
