import {TSvgComponent} from "./types";

const ReferralSvg :TSvgComponent = ({...props}) => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20"  xmlns="http://www.w3.org/2000/svg" {...props}>
            <g opacity="0.5">
                <path d="M7.49984 1.66667C5.3165 1.66667 3.5415 3.44167 3.5415 5.62501C3.5415 7.76667 5.2165 9.50001 7.39984 9.57501C7.4665 9.56667 7.53317 9.56667 7.58317 9.57501C7.59984 9.57501 7.60817 9.57501 7.62484 9.57501C7.63317 9.57501 7.63317 9.57501 7.6415 9.57501C9.77484 9.50001 11.4498 7.76667 11.4582 5.62501C11.4582 3.44167 9.68317 1.66667 7.49984 1.66667Z" />
                <path d="M11.7333 11.7917C9.4083 10.2417 5.61663 10.2417 3.27497 11.7917C2.21663 12.5 1.6333 13.4583 1.6333 14.4833C1.6333 15.5083 2.21663 16.4583 3.26663 17.1583C4.4333 17.9417 5.96663 18.3333 7.49997 18.3333C9.0333 18.3333 10.5666 17.9417 11.7333 17.1583C12.7833 16.45 13.3666 15.5 13.3666 14.4667C13.3583 13.4417 12.7833 12.4917 11.7333 11.7917Z" />
                <path d="M16.6583 6.11666C16.7916 7.73333 15.6416 9.15 14.05 9.34166C14.0416 9.34166 14.0416 9.34166 14.0333 9.34166H14.0083C13.9583 9.34166 13.9083 9.34166 13.8666 9.35833C13.0583 9.4 12.3166 9.14166 11.7583 8.66666C12.6166 7.9 13.1083 6.75 13.0083 5.5C12.95 4.825 12.7166 4.20833 12.3666 3.68333C12.6833 3.525 13.05 3.425 13.425 3.39166C15.0583 3.25 16.5166 4.46666 16.6583 6.11666Z" />
                <path d="M18.3249 13.825C18.2582 14.6333 17.7415 15.3333 16.8749 15.8083C16.0415 16.2667 14.9915 16.4833 13.9499 16.4583C14.5499 15.9167 14.8999 15.2417 14.9665 14.525C15.0499 13.4917 14.5582 12.5 13.5749 11.7083C13.0165 11.2667 12.3665 10.9167 11.6582 10.6583C13.4999 10.125 15.8165 10.4833 17.2415 11.6333C18.0082 12.25 18.3999 13.025 18.3249 13.825Z" />
            </g>
        </svg>
    )
}
export default ReferralSvg
