import PersonIcon from "@mui/icons-material/Person";
import EmailIcon from "@mui/icons-material/Email";
import CustomInput from "../../../../../component/common/Inputs";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {useEffect, useState} from "react";
import {Button, styled} from "@mui/material";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import {useSelector} from "react-redux";
import {useAppDispatch} from "../../../../../store/redux";
import {updateUserProfile} from "../../../../../store/Auth/ActionAuth";
import dayjs, {Dayjs} from "dayjs";
import {RootState} from "../../../../../store/store";
import {UserInfo} from "../../../../../networking/auth/types";
import PrimaryButton from "../../../../../component/PrimaryButton";
import {useTranslation} from "react-i18next";

const UserDetails = () => {
    const dispatch = useAppDispatch()
    const [dialCode, setDialCode] = useState<string>('')
    const [phoneNumber, setPhoneNumber] = useState<string>('')
    const [updateProfilePayload, setUpdateProfilePayload] = useState<UserInfo>({
        id: null,
        name: '',
        email: '',
        phone_number: '',
        address: null,
        organization_name: null,
        birth_date: null,
        profile_pic: null,
        email_verified_at: null,
    })

    const [selectedImage, setSelectedImage] = useState<string>('')
    const {t} = useTranslation();
    const userInfo = useSelector((state: RootState) => state.authReducer.userInfo);
    const handleChangeInput = (event: any) => {
        setUpdateProfilePayload((prevPayload) => ({
            ...prevPayload,
            [event?.target?.name]: event?.target?.value,
        }));
    };
    const updateProfile = () => {

        dispatch(updateUserProfile(updateProfilePayload))
    }

    const handleCreateImage = (event: any) => {
        const file = event?.target?.files?.[0];
        if (file) {
            const imageUrl = URL.createObjectURL(file);
            setSelectedImage(imageUrl);
        } else {
            setSelectedImage('');
        }
    };

    const handleUploadImage = (e: any) => {
        handleCreateImage(e)
        setUpdateProfilePayload((prevValue) => ({
            ...prevValue,
            profile_pic: e?.target?.files?.[0]
        }));
    }
    useEffect(() => {
        if (userInfo) {
            setUpdateProfilePayload(userInfo)

        }
    }, [])
    const VisuallyHiddenInput = styled('input')`
      clip: rect(0 0 0 0);
      clip-path: inset(50%);
      height: 1px;
      overflow: hidden;
      position: absolute;
      bottom: 0;
      left: 0;
      white-space: nowrap;
      width: 1px;
    `;
    return (
        <div className={"rounded-xl bg-[#f3f3f4]"}>
            <div className="container-fluid px-4 pb-3 pt-3">
                <div className="item-center flex justify-between">
                    <p className={"text-xl font-bold"}>{t('userProfile.profileSettings.userDetails.title')}</p>
                    <img
                        alt="edit-icon"
                        src="/assets/svgs/dashboard/userProfile/edit-icon.svg"
                    />
                </div>
                <div className="mt-5">
                    <CustomInput
                        inputBgColor="bg-white"
                        name="name"
                        type="text"
                        value={updateProfilePayload['name']}
                        placeholder={t('userProfile.profileSettings.userDetails.username')}
                        onChange={handleChangeInput}
                        icon={<PersonIcon sx={{color: "#d0d2d3"}}/>}
                    />
                </div>
                <div className="mt-5">
                    <CustomInput
                        inputBgColor="bg-white"
                        name="email"
                        type="text"
                        value={updateProfilePayload['email']}
                        placeholder={t('userProfile.profileSettings.userDetails.email')}
                        onChange={handleChangeInput}
                        icon={<EmailIcon sx={{color: "#d0d2d3"}}/>}
                    />
                </div>
                {/* <div className={"mt-5 mb-5 grid h-9 md:grid-cols-[1.5fr_2.5fr] sm:grid-cols-[0.5fr_2.5fr] grid-cols-[1.2fr_2.5fr] gap-3"}>
          <div
            className={
              "grid w-full grid-cols-[1fr_1fr] items-center rounded-lg bg-white px-3"
            }
          >
            <LocalPhoneIcon className={"left-3"} sx={{ color: "#d0d2d3" }} />
            <input onChange={(event) => {
              setDialCode(event.target.value)
            }} className={"w-full rounded-lg focus-visible:outline-none"} />
          </div>
          <div  className={"flex w-full items-center rounded-lg bg-white px-3"}>
            <input value={updateProfilePayload['phone_number']} onChange={(event) => {
              setPhoneNumber(event.target.value)
            }} className={"w-full rounded-lg focus-visible:outline-none"} />
          </div>
        </div> */}
                <div className={"mt-5 mb-5 h-9 bg-white flex justify-center rounded-lg px-3"}>
                    <PhoneInput
                        initialValueFormat="national"
                        placeholder={t('userProfile.profileSettings.userDetails.phone')}
                        value={updateProfilePayload['phone_number']}
                        className="w-full"
                        onChange={(val) => setUpdateProfilePayload((prevPayload) => ({
                            ...prevPayload,
                            phone_number: val?.toString() ?? ''
                        }))}/>
                </div>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        sx={{width: "100%", background: "white", padding: "0px 12px"}}
                        className="rounded-lg h-9 mt-5 flex justify-center"
                        format={"D-M-YYYY"}
                        value={updateProfilePayload['birth_date'] ? dayjs(updateProfilePayload['birth_date']) : null}
                        onChange={(newValue: Dayjs | null) => {
                            const formattedDate = dayjs(newValue).format("D-M-YYYY");
                            setUpdateProfilePayload((prevPayload) => ({
                                ...prevPayload,
                                birth_date: formattedDate,
                            }));
                        }}
                    />
                </LocalizationProvider>
                <div
                    className={
                        "min-h-20 mt-5 flex w-full flex-col items-center justify-between rounded-lg bg-white px-3 py-3 text-center md:flex-row"
                    }
                >
                    <div className={"w-[100px] flex justify-center"}>
                        <img
                        loading="lazy"
                            src={selectedImage ? selectedImage : updateProfilePayload['profile_pic'] as string}
                            alt={""}
                            className={" w-[50px] h-[50px] rounded-full"}
                        />
                    </div>

                    <div>
                        <p className={"text-sm font-bold"}>{t('userProfile.profileSettings.userDetails.avatar')}</p>
                        <p className={"mt-2 text-sm md:mt-0"}>{t('userProfile.profileSettings.userDetails.required')}</p>
                    </div>
                    <div className={"items-center"}>
                        <Button
                            href="#file-upload"
                            component="label"
                            sx={{backgroundColor: '#FF913F', color: 'white', borderRadius: '25px', marginTop: '10px'}}

                        >
                          {t('userProfile.profileSettings.userDetails.upload')}
                            <VisuallyHiddenInput name="profile_pict" onChange={handleUploadImage} type="file"/>
                        </Button>
                    </div>

                </div>
                <div className="w-[100%] bottom-3 mt-5">
                    <PrimaryButton onClick={updateProfile} type="submit"> {t('userProfile.profileSettings.userDetails.updateProfile')}</PrimaryButton>
                </div>
            </div>
        </div>
    );
};
export default UserDetails;
