import {TSvgComponent} from "./types";

const OverviewSvg: TSvgComponent = ({...props}) => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="grey" xmlns="http://www.w3.org/2000/svg"  {...props}>
            <path
                d="M18.0585 5.79151C17.5251 3.98317 16.0168 2.47484 14.2085 1.94151C12.8335 1.54151 11.8835 1.57484 11.2251 2.06651C10.4335 2.65817 10.3418 3.72484 10.3418 4.48317V6.55817C10.3418 8.60817 11.2751 9.64984 13.1085 9.64984H15.5001C16.2501 9.64984 17.3251 9.55817 17.9168 8.76651C18.4251 8.11651 18.4668 7.16651 18.0585 5.79151Z"
                fill="grey"/>
            <path
                d="M15.7575 11.133C15.5409 10.883 15.2242 10.7413 14.8992 10.7413H11.9158C10.4492 10.7413 9.25752 9.54964 9.25752 8.08297V5.09964C9.25752 4.77464 9.11585 4.45797 8.86585 4.2413C8.62418 4.02464 8.29085 3.92464 7.97418 3.9663C6.01585 4.2163 4.21585 5.2913 3.04085 6.90797C1.85752 8.53297 1.42418 10.5163 1.79918 12.4996C2.34085 15.3663 4.63252 17.658 7.50752 18.1996C7.96585 18.2913 8.42418 18.333 8.88252 18.333C10.3908 18.333 11.8492 17.8663 13.0908 16.958C14.7075 15.783 15.7825 13.983 16.0325 12.0246C16.0742 11.6996 15.9742 11.3746 15.7575 11.133Z"
                fill="grey"/>
        </svg>
    )
}
export default OverviewSvg