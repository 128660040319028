export const missionBanner = {
  firstBackground: "/assets/images/mobile-mission-banner.png",
  secondBackground:"/assets/images/mission-banner.png",
  title:"Our Mission"
};

export const crowdfundingBanner ={
  firstBackground: "/assets/images/mobile-crowdfunding-banner.png",
  secondBackground: "/assets/images/crowdfunding-banner.png",
  title: "We are here to make a difference",
}