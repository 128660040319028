import { useEffect, useState } from "react";
import TabComponent from "../../../../component/TabComponent";
import { EventListTabs } from "../../../../constants/dashboard/EventListTabs";
import { EventListHeader } from "../../../../constants/dashboard/EventListHeader";
import { useAppDispatch, useAppSelector } from "../../../../store/redux";
import Rating from "@mui/material/Rating";
import {
  deleteEventById,
  getAllEVents,
} from "../../../../store/BusinessDashboard/ActionBusinessDashboard";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { useNavigate } from "react-router-dom";
import { scrollToTop } from "../../../../store/GlobalState/ActionGlobalState";
import { Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";

const EventList = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(1);
  const navigate = useNavigate();
  const eventList: any = useAppSelector(
    (state) => state.businessDashboardReducer.eventList,
  );
  const [selectedTab, setSelectedTab] = useState("accepted");
  const handleTabClicked = (value: any, index: number) => {
    setActiveTab(index);
    let tab = value.label;
    setSelectedTab(tab.toLowerCase());
  };

  useEffect(() => {
    dispatch(scrollToTop);
    dispatch(getAllEVents());
  }, []);
  return (
    <div className="overflow-x-hidden">
      <div className="mb-5 mt-4 flex items-center gap-2">
        <p className={"text-xl font-bold text-black"}>
          {t("businessProfile.eventList.title")}
        </p>
      </div>
      <div className="grid">
        <TabComponent
          titles={EventListTabs.map((tab) => ({
            id: tab.id,
            label: t(`businessProfile.eventList.EventListTabs.${tab.label}`),
          }))}
          activeTab={activeTab}
          handleTabClick={handleTabClicked}
        />
      </div>
      <div className="">
        <div className="overflow-x-scroll min-w-[800px] max-w-full">
            <div
              className={
                "mt-5 grid grid-cols-[1fr_4fr_5fr_3fr_3fr_2.5fr] gap-4 rounded-xl bg-[#f3f3f4] px-5 py-3"
              }
            >
              {EventListHeader.map((item, index) => (
                <p key={`eventHeader_${index}`} className={"text-[#9fa3a5] "}>
                  {t(`businessProfile.eventList.sideBar.${item.id}`)}
                </p>
              ))}
            </div>
            {eventList[selectedTab]?.length > 0 ? (
              eventList[selectedTab]?.map((item: any, index: number) => (
                <div key={`eventList_${index}`}>
                  <div
                    className={
                      "mt-3 grid grid-cols-[1fr_4fr_5fr_3fr_3fr_2.5fr] items-center gap-4 px-5 py-3"
                    }
                  >
                    <img
                      loading="lazy"
                      onClick={() => navigate(`/eventDetails/${item.id}`)}
                      className={"h-10 cursor-pointer w-[50px] rounded-lg"}
                      src={item.images[0]?.image_path}
                    />
                    <Tooltip arrow placement="bottom-end" title={item.title}>
                      <p
                        className={"text-sx cursor-pointer w-fit font-bold line-clamp-1"}
                      >
                        {item.title}
                      </p>
                    </Tooltip>
                    <div className="flex w-fit text-sm items-center rounded-full bg-[#F3F3F4] px-3 py-1">
                      <img
                        loading="lazy"
                        src="/assets/svgs/events/location.svg"
                        alt="icon"
                        className="h-auto"
                      />
                      <Tooltip
                        arrow
                        placement="bottom-end"
                        title={
                          item.location +
                          (item.address ? ` ${item.address}` : "")
                        }
                      >
                        <span className="text-sx ml-2 cursor-pointer line-clamp-1">
                          {item.location} {item.address ?? `, ${item.address}`}
                        </span>
                      </Tooltip>
                    </div>
                    <div className="text-md flex items-center gap-3">
                      {item?.reviews_avg_stars ? (
                        <>
                          <Rating
                            size="small"
                            name="read-only"
                            value={Number(item.reviews_avg_stars)}
                            readOnly
                            precision={0.5}
                          />
                          {Number(item.reviews_avg_stars).toFixed(2)}
                        </>
                      ) : (
                        "No ratings yet"
                      )}
                    </div>
                    <div className="grid grid-cols-2 items-center gap-3 text-center">
                      {item.types.map((element: any, index: any) => (
                        <div
                          key={index}
                          style={{ whiteSpace: "nowrap" }}
                          className="flex w-fit items-center gap-3  rounded-full bg-[#F3F3F4] px-3 py-1 text-sm font-medium text-[#898D91]"
                        >
                          {element.title}
                        </div>
                      ))}
                    </div>
                    <div className="flex items-center justify-end gap-3">
                      <img
                        loading="lazy"
                        onClick={() => navigate(`/update-event/${item.id}`)}
                        className="cursor-pointer"
                        src="/assets/svgs/dashboard/userProfile/edit-icon.svg"
                      />
                      <div
                        onClick={() =>
                          dispatch(deleteEventById(item.id))
                            .then(() => {
                              dispatch(getAllEVents());
                            })
                            .catch(() => {})
                        }
                        className="cursor-pointer"
                      >
                        <DeleteForeverIcon sx={{ fill: "red" }} />
                      </div>
                    </div>
                  </div>
                  <div className="mt-3 flex">
                    <div className="h-px bg-[#d0d2d3] bg-opacity-50" />
                  </div>
                </div>
              ))
            ) : (
              <p className="ml-1 mt-3 font-medium">{t("noAvailableEvents")}</p>
            )}
          </div>

      </div>
    </div>
  );
};
export default EventList;
