import { useEffect } from "react";
import { scrollToTop } from "../../../../store/GlobalState/ActionGlobalState";
import { useAppDispatch } from "../../../../store/redux";
import Balance from "./Balance";
import { EventSection } from "./EventSection";
import History from "./History";

const ProfileOverview = () => {
    const dispatch = useAppDispatch()

    useEffect(()=>{
        dispatch(scrollToTop);
    },[])
    return (
        <><div className={"grid xl:grid-cols-[1fr_2fr]  grid-cols-1 gap-4 mb-4"}>
            <Balance />
            <EventSection />
        </div>
        <History />
        </>


    )
}
export default ProfileOverview
