import {TSvgComponent} from "./types";

const EventCreationSvg: TSvgComponent = ({...props}) => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g opacity="0.5">
                <path
                    d="M17.5739 5.81628C16.8656 5.03294 15.6822 4.64128 13.9656 4.64128H13.7656V4.60794C13.7656 3.20794 13.7656 1.47461 10.6322 1.47461H9.36556C6.23222 1.47461 6.23223 3.21628 6.23223 4.60794V4.64961H6.03223C4.30722 4.64961 3.13223 5.04128 2.42389 5.82461C1.59889 6.74128 1.62389 7.97461 1.70723 8.81628L1.71556 8.87461L1.7801 9.55234C1.79199 9.67716 1.85921 9.78989 1.96412 9.85855C2.16396 9.98932 2.49841 10.2049 2.69889 10.3163C2.81556 10.3913 2.94056 10.4579 3.06556 10.5246C4.49056 11.3079 6.05723 11.8329 7.64889 12.0913C7.72389 12.8746 8.06556 13.7913 9.89056 13.7913C11.7156 13.7913 12.0739 12.8829 12.1322 12.0746C13.8322 11.7996 15.4739 11.2079 16.9572 10.3413C17.0072 10.3163 17.0406 10.2913 17.0822 10.2663C17.4128 10.0794 17.7558 9.84917 18.0684 9.62365C18.1626 9.5557 18.2229 9.45065 18.2357 9.33523L18.2489 9.21628L18.2906 8.82461C18.2989 8.77461 18.2989 8.73294 18.3072 8.67461C18.3739 7.83294 18.3572 6.68294 17.5739 5.81628ZM10.9072 11.5246C10.9072 12.4079 10.9072 12.5413 9.88223 12.5413C8.85722 12.5413 8.85723 12.3829 8.85723 11.5329V10.4829H10.9072V11.5246ZM7.42389 4.64128V4.60794C7.42389 3.19128 7.42389 2.66628 9.36556 2.66628H10.6322C12.5739 2.66628 12.5739 3.19961 12.5739 4.60794V4.64961H7.42389V4.64128Z"
                    fill="#131C23"/>
                <path
                    d="M17.3938 11.4451C17.6884 11.3049 18.0278 11.5384 17.9983 11.8634L17.6992 15.1583C17.5242 16.8249 16.8409 18.5249 13.1742 18.5249H6.82422C3.15755 18.5249 2.47422 16.8249 2.29922 15.1666L2.01529 12.0434C1.98609 11.7222 2.31772 11.4889 2.61158 11.6218C3.5611 12.0514 5.31372 12.8136 6.39636 13.0972C6.53328 13.133 6.64403 13.231 6.70398 13.3592C7.20979 14.441 8.30681 15.0166 9.89089 15.0166C11.4593 15.0166 12.5702 14.4188 13.0777 13.3344C13.1377 13.2061 13.2486 13.1081 13.3856 13.0721C14.5352 12.7696 16.4007 11.9176 17.3938 11.4451Z"
                    fill="#131C23"/>
            </g>
        </svg>
    )
}
export default EventCreationSvg