import {useTranslation} from 'react-i18next';

import {useAppSelector} from "../../../store/redux";
import React from "react";

interface AboutEventProps {
    title: string;
    averageRating: number | null;
    businessId: number;
    description: string;
    duration: string;
    duration_type:string;
    displayAge: string;
    language: string;
    selectedLanguage: string;
    business: {
        id: number;
        name: string;
        profile_pic: string;
    }
}

const AboutEvent: React.FC<AboutEventProps> = ({
                                                   title,
                                                   averageRating,
                                                   businessId,
                                                   description,
                                                   duration,
                                                   displayAge,
                                                   language,
                                                   selectedLanguage,
                                                   business,
                                               }) => {
    const {t} = useTranslation();
    const {eventDetails, isLoading} = useAppSelector(state => state.eventDetailsReducer);
    const content = eventDetails.translations?.[selectedLanguage];

    return (
        <div className="">
            <div className="flex items-center gap-2">
                <p className="text-[#131C23]/40 font-medium">{t('operator')}:</p>
                <img loading="lazy" src={business.profile_pic} className="w-[35px] h-[35px] rounded-full aspect-square object-cover" alt="event"/>
                <p> {business.name}</p>
            </div>
            <h2 className="py-2">{content?.title}</h2>
            <div className="flex gap-5">
                <div className="flex items-center">
                   {/*Review Images goes here*/}
                </div>
                {
                    eventDetails.total_reviews &&
                    <div className="flex items-center">
                        <p>{eventDetails.total_reviews} </p> <span className="text-[#131C23]/40 ml-2"> {t('reviews')}:</span>
                    </div>
                }
                <div className="flex items-center">
                    <img loading="lazy" src="/assets/svgs/common/star.svg" alt="rating"/>
                    <p className="text-sm font-bold text-[#131C23] ml-2">{averageRating}</p>
                </div>
                {/*<div className="flex items-center bg-[#FF585F]/25 px-5 py-2 rounded-full">*/}
                {/*    <img src="/assets/svgs/eventDetails/recomended-heart.svg" alt="recommended event"/>*/}
                {/*    <p className="text-sm font-bold text-[#131C23] ml-1">94% </p> <span className="text-[#131C23]/40 font-medium ml-2">Recommended</span>*/}
                {/*</div>*/}
            </div>
            <h3 className="text-[#131C23] font-bold text-2xl pt-4 mb-3">{t('aboutEvents')}</h3>

            <textarea value={content?.description} className="text-base text-[#131C23] w-[100%] h-[500px]" disabled>
            </textarea>

            <div className="border-b-[1px] border-[#131C23]/30 py-4"></div>
            <div className="md:flex items-center py-5 justify-between">
                <div className="flex items-center text-sm md:my-0 my-4">
                    <img loading="lazy" src="/assets/svgs/eventDetails/duration.svg" alt="time"/>
                    <p className="text-[#131C23]/40 ml-2 font-sm">{t('duration')}:</p>
                    <p className="font-bold ml-1">{duration} {eventDetails.duration_type}</p>
                </div>
                <div className="flex items-center text-sm  md:my-0 my-4">
                    <img loading="lazy" src="/assets/svgs/eventDetails/mobile.svg" alt="ticket"/>
                    <p className="text-[#131C23]/40 ml-2 font-sm">{t('ticket')}: </p>
                    <p className="font-bold ml-1">Mobile</p>
                </div>
                <div className="flex items-center text-sm  md:my-0 my-4">
                    <img loading="lazy" src="/assets/svgs/eventDetails/age.svg" alt="age"/>
                    <p className="text-[#131C23]/40 ml-3 font-sm">{t('ages')}: </p>
                    <p className="font-bold ml-1">{displayAge}</p>
                </div>
                <div className="flex items-center text-sm  md:my-0 my-4">
                    <img loading="lazy" src="/assets/svgs/eventDetails/language.svg" alt="language"/>
                    <p className="text-[#131C23]/40 ml-2 font-medium">{t('language')}: </p>
                    <p className="font-bold ml-1">{language}</p>
                </div>
            </div>
        </div>
    )
}

export default AboutEvent