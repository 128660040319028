import moment from "moment";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  getDocuments,
  getNotifications,
} from "../../../../store/BusinessDashboard/ActionBusinessDashboard";
import { TNotification } from "../../../../store/BusinessDashboard/types";
import { scrollToTop } from "../../../../store/GlobalState/ActionGlobalState";
import { useAppDispatch, useAppSelector } from "../../../../store/redux";
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
export const BusinessNotification = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const notifications: any = useAppSelector(
    (state) => state.businessDashboardReducer.notifications,
  );
  useEffect(() => {
    dispatch(scrollToTop);
    dispatch(getNotifications());
  }, []);
  return (
    <div>
      <div className="mb-5 mt-4 gap-2">
      <p className={"text-xl font-bold text-black"}>{t('dashboardSideBar.sidebar.notifications')}</p>
        <div className="flex flex-col gap-y-6 mt-5">
          {notifications.map((item: TNotification,index:number) => (
            <div key={index} className="py-5 md:grid md:grid-cols-[3fr_3fr] lg:w-4/6 w-full gap-6 px-5 shadow-md shadow-primary-orange/30">
             <div className="flex gap-8">
               <NotificationsActiveIcon className="!fill-primary-orange" />
               <p dangerouslySetInnerHTML={{__html: item.notification}}></p>
             </div>
              <p className="text-end max-md:pt-5 max-md:text-xs flex justify-end">
                {moment(item.created_at).format('YYYY-MM-DD')}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
