import React from "react";
import Box from '@mui/material/Box';
import Rating from '@mui/material/Rating';
import {TEventDetails, TEventReview} from "../../../store/EventDetails/types";
import {useTranslation} from "react-i18next";

type CustomerReviewsProps = {
    reviews: TEventReview[];
    customerReviews: TEventDetails;
};
const CustomerReviews: React.FC<CustomerReviewsProps> = ({reviews, customerReviews}) => {
    const {t} = useTranslation();

    return (
        <div className="md:grid md:grid-cols-2 bg-[#131C23] rounded-3xl my-10">
            <div className="bg-white rounded-3xl md:m-1 md:px-5">
                <h3 className="text-[#131C23] font-medium text-xl pt-4 mb-3 px-3 md:px-0 flex">{t('customerReviews')}<img
                    src="/assets/svgs/eventDetails/warning.svg" className="mx-3"/></h3>
                <div className="bg-[#FFD54E]/40 p-4 rounded-2xl font-bold my-8 flex justify-between">
                    <div>
                        <p className="mb-5">{t('overallRating')}</p>
                        {customerReviews && customerReviews.average_rating !== undefined && (
                            <Box
                                sx={{
                                    '& > legend': {mt: 2},
                                }}
                            >
                                <Rating name="read-only" value={customerReviews.average_rating} readOnly
                                        precision={0.5}/>
                            </Box>
                        )}
                        <div className="flex">
                            <p className="text-[#131C23]/40 font-medium">{t('basedOn')}</p> <span
                            className="font-bold ml-2">{customerReviews.reviews?.total} {t('reviews')}</span>
                        </div>
                    </div>
                    <div className="bg-white rounded-3xl p-10">
                        <p className="font-bold text-3xl text-center">{customerReviews.average_rating}</p>
                    </div>
                </div>
            </div>
            <div className="flex flex-col gap-3 justify-between p-4">
                <h3 className="text-white font-medium text-xl mb-3 flex">{t('reviewSummary')}</h3>
                {customerReviews.calculatedRatings && Object.entries(customerReviews.calculatedRatings).map(([ratingTitle, ratingValue], index) => {
                    const ratingPercentage = (parseFloat(ratingValue) / 5) * 100;
                    return (
                        <div key={index}
                             className="grid grid-cols-[1fr_3fr_1fr] gap-5 items-center py-1 text-white font-medium text-xs">
                            <div className="mb-1">{ratingTitle}</div>
                            <div className="w-full bg-[#FFD54E]/20 rounded-full h-1">
                                <div className="bg-[#FFD54E] h-1 rounded-full"
                                     style={{width: `${ratingPercentage}%`}}></div>
                            </div>
                            <p>{ratingValue}</p>
                        </div>
                    );
                })}
            </div>
        </div>
    )
}

export default CustomerReviews;