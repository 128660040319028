import TabComponent from "../../../../../component/TabComponent";
import{useState} from "react";
import {TasksStatus} from "../../../../../constants/dashboard/TasksStatus";
import AssignmentIcon from '@mui/icons-material/Assignment';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import WalletIcon from '@mui/icons-material/Wallet';
import {useSelector} from "react-redux";
import {RootState} from "../../../../../store/store";
import moment from "moment";
import {useTranslation} from "react-i18next";

export const YourTasks = () => {
    const [activeTab, setActiveTab] = useState(0)
    const [tabName, setTabName] = useState<any>('all')
    const handleTabClicked = (item: any, value: number) => {
        setActiveTab(value)
        setTabName(item?.name)
    }
    const tableData: any = useSelector((state: RootState) => state.userDashboardReducer?.gamificationData?.user_tasks);
    const {t} = useTranslation();

    return (
        <>

            <div className="flex items-center mb-5 mt-10 gap-2">
                <p className={"text-black text-xl font-bold"}>{t('userProfile.gamification.yourTasks.title')}</p>
            </div>
            <div className="w-[100%] overflow-x-auto">
                <div className="min-w-[1000px]">
                    <div className="grid">
                        <TabComponent
                            titles={TasksStatus.map(tab => ({
                                id: tab.id,
                                label: t(`userProfile.gamification.yourTasks.TasksStatus.${tab.label}`)
                            }))}
                            activeTab={activeTab}
                            handleTabClick={handleTabClicked}
                        />
                    </div>
                    {
                        tableData[tabName]?.length > 0 ? tableData[tabName].map((task: any, index: any) => (
                            <div key={index}
                                 className={"grid grid-cols-[1fr_5fr] gap-3 mt-4 rounded-xl px-1 h-14 items-center " + (!task.is_done ? 'bg-[#ecf4ff]' : 'bg-[#edf9f3]')}>
                                <div className={"ml-8  " + (task.is_done ? 'text-[#42c07f]' : 'text-[#3f8cff]')}>
                                    <li>{task.status}</li>
                                </div>
                                <div
                                    className="grid grid-cols-[5fr_4fr_3fr] h-[90%] rounded-xl px-3  bg-white items-center">
                                    <div className="flex items-center">
                                        <AssignmentIcon fontSize={"medium"} style={{color: '#898e91'}}/>
                                        <div className={"text-xs text-[#9fa3a5] ml-3"}>
                                            {t('userProfile.gamification.yourTasks.task')}: <b
                                            className={"text-black"}>{task.description}</b>
                                        </div>
                                    </div>
                                    <div className="flex items-center">
                                        <CalendarMonthIcon fontSize={"medium"} style={{color: '#898e91'}}/>
                                        <div className={"text-xs text-[#9fa3a5] ml-3"}>
                                            {t('yourTasks.untilDate')}: <b
                                            className={"text-black"}>{moment(task?.expiration_date).format('LL')}</b>
                                        </div>
                                    </div>
                                    <div className={"text-xs text-[#9fa3a5] flex items-center text-end"}>
                                        <WalletIcon fontSize={"medium"} style={{color: '#898e91'}}/>
                                        <div className={"text-xs text-[#9fa3a5] ml-3"}>
                                            {t('userProfile.gamification.yourTasks.bonus')}: <b
                                            className={"text-black"}>{task.bonus} {t('gamification.yourTasks.tokens')}</b>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )) : (<p className="mt-3 font-medium">{t('userProfile.gamification.yourTasks.noTasks')}</p>)
                    }
                </div>
            </div>
        </>
    )
}
export default YourTasks
