import React, { useEffect, useState, useTransition } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getContributions } from "../../../networking/crowdfunding/crowdfunding";


interface HighestAmbitionProps {
  goal: string;
  raised: string;
}

const HighestAmbition: React.FC<HighestAmbitionProps> = ({ goal, raised }) => {
  const { t } = useTranslation();
  return (
    <section className="md:mt-32 max-md:mt-20">
      <div className="md:grid md:grid-cols-2 place-items-center">
        <img src="/assets/images/crowdfunding-1.png" />
        <div className="mx-4">
          <h1
            className="font-bold text-3xl max-md:text-center gap-2 flex items-center max-md:justify-center max-md:mt-5">
            {t("crowdfunding.highestAmbition")}
            <div className="border-b-[3px] border-black w-[60px] mt-2 rounded-2xl max-md:hidden"></div>
          </h1>
          <p className="text-primary-orange font-bold max-md:text-center">{t("crowdfunding.builtCommunity")}</p>
          <p className="text-lg text-black opacity-80 max-md:text-center max-md:text-[16px] md:pt-8 max-md:pt-4">
            {t("crowdfunding.highestAmbitionDescription")}
          </p>
          <div className="bg-primary-orange rounded-3xl mt-5">
            <div className="flex justify-evenly font-light text-white md:text-lg max-md:text-center py-4">
              <div>
                <p className="max-md:text-sm">{t("crowdfunding.contributionGoal")}</p>
                <p className="font-bold">£{goal}</p>
              </div>
              <div className="border-l-2 border-white"></div>
              <div>
                <p className="max-md:text-sm">{t("crowdfunding.contributionRaised")}</p>
                <p className="font-bold">£{raised}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mx-4 mt-10">
        <h1 className="font-bold text-3xl gap-2 flex items-center max-md:justify-center">
          {t("crowdfunding.beTheChange")}
          <div className="border-b-[3px] border-black w-[60px] mt-2 rounded-2xl"></div>
        </h1>
          <p className="text-primary-orange font-bold max-md:text-center">{t("crowdfunding.beTheChangeTag")}</p>
        <div className="text-lg text-black opacity-80 max-md:text-center max-md:text-[16px]">
          <h3 className="pt-8">
            {t('crowdfunding.beTheChangeDescription')}
          </h3>
        </div>
        <Link to="/our-mission" className="max-md:flex max-md:justify-center">
          <button
            className="bg-primary-orange text-black rounded-full md:px-5 max-md:px-10 py-3 my-5 flex items-center gap-1">
            {t("ourMission")}
            <img src="/assets/svgs/home/arrow-black.svg" />
          </button>
        </Link>
      </div>
    </section>
  );
};
export default HighestAmbition;