import React from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { useUserInterest} from "../../../../../../pages/Home/hooks";

type UserInterestProps = {
    open: boolean;
    onClose: (value: number[]) => void;
};
const style = {
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: 6,
    width: 400,
    textAlign: "center",
    justifyItems: "center",
};
const UserInterest: React.FC<UserInterestProps> = ({open, onClose}) => {
    const {interests,interestSelected, handleSelectInterest} = useUserInterest();

    return (
        <Modal sx={{minHeight: "fit-content", maxHeight: "100%", height: '100%'}} open={open} onClose={onClose}>
            <Box sx={style}>
                <h3 className="text-2xl font-bold"> What's your interest?</h3>
                <p className="font-medium text-[#131C23]/40">
                    {" "}
                    Choose the categories you are interested in{" "}
                </p>

                <div className="flex  p-4 gap-3 max-h-[350px] overflow-y-scroll flex-wrap">
                    {interests.map((item: any, index: number) => (
                        <div
                            key={index}
                            style={{
                                textAlign: 'start',
                                border: "1px solid #E7E8E9", // Add the border style here
                                borderRadius: "9999px", // Rounded-full style
                                padding: "3px 12px", // px-3 style
                                fontSize: "0.875rem", // text-sm style
                                fontWeight: "500", // font-medium style
                                color: "#898D91", // text-[#898D91] style
                                display: "flex", // flex style
                                alignItems: "center", // items-center style
                                marginRight: "0.75rem", // gap-3 style (adjust as needed)
                            }}
                            onClick={() => handleSelectInterest(item.id)}
                            className={`${
                                interestSelected.includes(item.id)
                                    ? 'bg-[#FF913F] text-white'
                                    : 'bg-white'
                            } cursor-pointer`}
                        >
                            {item.name}
                        </div>
                    ))}
                </div>
                <div className="flex justify-center">
                    <p
                        className="my-5 flex w-full cursor-pointer items-center justify-center rounded-full bg-[#FF913F] py-2 text-white"
                        onClick={() => onClose(interestSelected)}
                    >
                        <span className="px-1">Continue</span>
                        <img loading="lazy" src="/assets/svgs/common/white-arrow.svg" className="w-4"/>
                    </p>
                </div>
            </Box>
        </Modal>
    );
};

export default UserInterest;
