import { CircularProgress } from "@mui/material";
import { ButtonHTMLAttributes, DetailedHTMLProps } from "react";
import { useSelector } from "react-redux";
import { twMerge } from "tailwind-merge";
import { RootState } from "../store/store";

type TProps = {
} & DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;
const PrimaryButton = ({...props }: TProps) => {
  const loading = useSelector((state:RootState)=> state.globalStateReducer.showButtonLoader)
  return (
    <button
      disabled={loading}
      {...props}
      className={twMerge(
        "w-full rounded-full bg-primary-orange flex justify-center items-center px-6 py-3 text-white disabled:bg-gray-300",
        props.className,
      )}
    >
      {loading ? <CircularProgress className="!w-6 !h-6 leading-0 [&_circle]:stroke-primary-orange"/> : props.children}
    </button>
  );
};

export default PrimaryButton;
