import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React from "react";
import {useAppDispatch} from "../../../../store/redux";
import {createWishlist, getWishlistsCategories} from "../../../../store/Wishlist/ActionWishlist";

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: 3,
};

const CreateWishlist = () => {
    const dispatch = useAppDispatch();
    const [wishlistTitle, setWishlistTitle] = React.useState("");

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleDoneClick = async () => {
        if (wishlistTitle.trim() === "") {
            // if no title
        } else {
            try {
                await dispatch(createWishlist(wishlistTitle));
                handleClose();
                await dispatch(getWishlistsCategories());
                setWishlistTitle("");
            } catch (error) {
                console.error("Error:", error);
            }
        }
    };
    return (
        <>
            <div className="flex justify-end">
                <button
                    className="px-3 py-2 border-2 border-[#FF913F] rounded-full font-medium"
                    onClick={handleOpen}
                >
                    Create Wishlist
                </button>
            </div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" className={"text-center"}>
                        <span className="text-[#131C23] font-bold text-2xl">Create a New List </span>
                        <p className="text-sm text-[#131C23]/40 font-bold">Create your own category</p>
                    </Typography>
                    <Typography id="modal-modal-description" sx={{mt: 2}}>
                        <div>
                            <input type="text" id="wishlist"
                                   className="bg-gray-50 border border-[#FF913F] text-gray-900 text-sm rounded-xl focus:ring-[#FF913F] focus:outline-[#FF913F] focus:border-[#FF913F] block w-full p-2.5 dark:border-[#FF913F] dark:focus:ring-[#FF913F] dark:focus:border-[#FF913F]"
                                   placeholder="Wishlist"
                                   value={wishlistTitle}
                                   onChange={(e) => setWishlistTitle(e.target.value)} required/>
                            <button
                                type="submit"  onClick={handleDoneClick}
                                className="items-center flex justify-center rounded-full bg-[#FF913F] text-white text-center w-full py-2 mt-10 cursor-pointer"
                            >
                                <span className="px-1">Done</span>
                                <img loading="lazy" src="/assets/svgs/common/white-arrow.svg" className="w-4" alt="Arrow"/>
                            </button>
                        </div>
                    </Typography>
                </Box>
            </Modal>
        </>
    )
}

export default CreateWishlist;